import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';
import styles from './CoursePublishModal.scss';
import Button, { buttonTypes } from '../../../../components/Button/Button.js';
import HubModalLayout from '../../../../components/HubModalLayout/HubModalLayout.js';
import withLocalizedContent from '../../../../language/withLocalizedContent.js';
import Checkbox from '../../../../components/Checkbox/Checkbox.js';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner.js';
import { clearPublishedCourseData, publishCourseDataRequest } from '../../../../redux/actions/coursePublish.js';
import { GlyphIcon } from '../../../../components/IconEmblem/IconEmblem';
import SVGIcon from '../../../../components/SVGIcon/SVGIcon';

function CoursePublishModal({
  closeModal,
  isOpen,
  modalContentBody,
  courseTitle,
  content,
  publishCourseDataRequestData,
  publishedCourseData,
  clearPublishedCourseDataAction
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSelectedCourse, setIsSelectedCourse] = useState(true);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedProductDefinition, setSelectedProductDefinition] = useState([]);
  const [selectedProductStructure, setSelectedProductStructure] = useState([]);
  const [notesText, setNotesText] = useState('');
  const [pairProducts, setPairProducts] = useState({});
  const [lastPublishedProductStructure, setLastPublishedProductStructure] = useState({});

  const textareaRef = useRef(null);

  const lastPublished = modalContentBody?.lastPublished;
  const formatDate = date => (date ? moment(date).format('DD-MM-YYYY HH:mm') : content.not_published_yet);
  const courseShortCode = modalContentBody?.shortCode;
  const courseTitleAndShortcode = `${courseTitle}(${courseShortCode})`;
  const isDisabledPublishButton =
    !isSelectedCourse &&
    selectedProductDefinition.length === 0 &&
    selectedProductStructure.length === 0 &&
    selectedPackages.length === 0;

  const resetState = () => {
    if (textareaRef.current) {
      textareaRef.current.value = '';
    }

    setIsSelectedCourse(true);
    setSelectedPackages([]);
    setSelectedProductDefinition([]);
    setSelectedProductStructure([]);
    setNotesText('');
  };

  const closeModalFunction = () => {
    closeModal();
    resetState();
    clearPublishedCourseDataAction();
  };

  useEffect(() => {
    if (modalContentBody) {
      setIsLoading(false);

      if (modalContentBody?.productDefinition) {
        const productDefinitionPairs = {};
        const {
          staged: productDefinitionStaged,
          published: productDefinitionPublished
        } = modalContentBody.productDefinition;

        productDefinitionStaged.forEach(productDefinition => {
          const { contentCode, isbn } = productDefinition;
          const publishedProductDefinitionIndex = productDefinitionPublished.findIndex(
            prod => prod.contentCode === contentCode && prod.isbn === isbn
          );
          const isProductDefinitionPublished = publishedProductDefinitionIndex >= 0;

          if (!productDefinitionPairs[contentCode]) {
            productDefinitionPairs[contentCode] = { isExpanded: true };
          }
          productDefinitionPairs[contentCode][isbn] = {
            ...productDefinition,
            isPublished: isProductDefinitionPublished
          };
        });
        setPairProducts(productDefinitionPairs);
      }

      if (modalContentBody?.lastPublishedProductStructure) {
        setLastPublishedProductStructure(modalContentBody.lastPublishedProductStructure);
      }
    }
  }, [modalContentBody]);

  const toggleCheckCourse = () => setIsSelectedCourse(!isSelectedCourse);

  const isProductDefinitionChecked = productId => selectedProductDefinition.includes(productId);
  const isProductStructureChecked = contentCode => selectedProductStructure.includes(contentCode);
  const isPackageChecked = packageName => selectedPackages.includes(packageName);

  const checkProductDefinitions = isbns => {
    let updatedSelectedIsbns = [...selectedProductDefinition];
    isbns.forEach(isbn => {
      if (selectedProductDefinition.includes(isbn)) {
        updatedSelectedIsbns = updatedSelectedIsbns.filter(id => id !== isbn);
      } else {
        updatedSelectedIsbns = [...updatedSelectedIsbns, isbn];
      }
    });
    setSelectedProductDefinition(updatedSelectedIsbns);
  };

  const checkProductStructure = contentCode => {
    let updatedSelectedContentCodes;
    if (selectedProductStructure.includes(contentCode)) {
      updatedSelectedContentCodes = selectedProductStructure.filter(c => c !== contentCode);
    } else {
      updatedSelectedContentCodes = [...selectedProductStructure, contentCode];
    }
    setSelectedProductStructure(updatedSelectedContentCodes);
  };

  const checkPackages = packageName => {
    if (selectedPackages.includes(packageName)) {
      setSelectedPackages(selectedPackages.filter(selectedPackage => selectedPackage !== packageName));
    } else {
      setSelectedPackages([...selectedPackages, packageName]);
    }
  };

  const onNotesChange = useMemo(
    () =>
      debounce(e => {
        setNotesText(e.target.value);
      }, 300),
    [notesText]
  );

  const selectAll = (contentCode, isbns) => {
    const isProductStructureSelected = selectedProductStructure.includes(contentCode);
    if (!isProductStructureSelected) {
      checkProductStructure(contentCode);
    }
    const isbnsToBeAdded = isbns.filter(isbn => !selectedProductDefinition.includes(isbn));
    checkProductDefinitions(isbnsToBeAdded);
  };

  const unselectAll = (contentCode, isbns) => {
    const isProductStructureSelected = selectedProductStructure.includes(contentCode);
    if (isProductStructureSelected) {
      checkProductStructure(contentCode);
    }
    const isbnsToBeAdded = isbns.filter(isbn => selectedProductDefinition.includes(isbn));
    checkProductDefinitions(isbnsToBeAdded);
  };

  const publishContent = () => {
    const allProductDefinitionIsbns = modalContentBody.productDefinition.staged.map(
      productDefinition => productDefinition.isbn
    );
    const productDefinitionsToExclude = allProductDefinitionIsbns.filter(
      isbn => !selectedProductDefinition.includes(isbn)
    );

    const allProductStructureContentCodes = modalContentBody.productStructure.staged.map(
      productStructure => productStructure.contentCode
    );
    const uniqueProductStructureContentCodes = [...new Set(allProductStructureContentCodes)];
    const productStructureToExclude = uniqueProductStructureContentCodes.filter(
      contentCode => !selectedProductStructure.includes(contentCode)
    );

    const allContentPackages = modalContentBody.allContentPackages.staged;

    const contentPackagesToInclude = allContentPackages.filter(contentPackage =>
      selectedPackages.includes(contentPackage)
    );

    const body = {
      courseCode: courseShortCode,
      annotation: notesText,
      excludeProductStructures: productStructureToExclude,
      excludeProductMetadata: productDefinitionsToExclude,
      excludeCourse: !isSelectedCourse,
      includeContentPackages: contentPackagesToInclude
    };

    publishCourseDataRequestData(courseShortCode, body);
  };

  const renderContent = () => {
    const renderCourse = () => (
      <div className={styles.rowContainer}>
        <div className={styles.checkboxTitleContainer}>
          <Checkbox label="label" labelHidden value={isSelectedCourse} onChange={toggleCheckCourse} disabled={false} />

          <p className={styles.courseTitle}>{courseTitleAndShortcode}</p>
        </div>

        <div className={styles.text}>
          Last published <span className={styles.boldText}>{formatDate(lastPublished)}</span>
        </div>
      </div>
    );

    const renderProductPair = (contentCode, isbns) => {
      const pairProduct = pairProducts[contentCode];
      const pairProductTitle = `${contentCode} ${isbns[0]} ${isbns[1] ? `/ ${isbns[1]}` : ''}`;
      const isEverythingSelected =
        selectedProductStructure.includes(contentCode) && isbns.every(isbn => selectedProductDefinition.includes(isbn));

      const toggleExpand = () => {
        const updatedPairProducts = {
          ...pairProducts,
          [contentCode]: { ...pairProducts[contentCode], isExpanded: !pairProducts[contentCode].isExpanded }
        };
        setPairProducts(updatedPairProducts);
      };

      const toggleSelect = () => {
        if (isEverythingSelected) {
          unselectAll(contentCode, isbns);
        } else {
          selectAll(contentCode, isbns);
        }

        if (!pairProduct.isExpanded) {
          toggleExpand();
        }
      };

      return (
        <div className={styles.rowContainer} key={contentCode}>
          <div className={styles.checkboxTitleContainer}>
            <Checkbox label="label" labelHidden value={isEverythingSelected} onChange={toggleSelect} disabled={false} />

            <p className={styles.boldText}>{pairProductTitle}</p>
          </div>

          <div
            className={pairProduct.isExpanded ? styles.caratIconReverse : styles.caratIcon}
            role="button"
            tabIndex={0}
            onKeyDown={toggleExpand}
            onClick={toggleExpand}
          >
            <SVGIcon glyph={GlyphIcon.ICON_DOWN} />
          </div>
        </div>
      );
    };

    const renderProductDefinition = product => (
      <div className={styles.rowContainer} key={product._id}>
        <div className={styles.checkboxTitleContainer}>
          <Checkbox
            label="label"
            labelHidden
            value={isProductDefinitionChecked(product.isbn)}
            onChange={() => checkProductDefinitions([product.isbn])}
            disabled={false}
          />

          <p className={styles.text}>{product.title}</p>
        </div>

        <div className={styles.text}>
          Last published <span className={styles.boldText}>{formatDate(product.lastPublished)}</span>
        </div>
      </div>
    );

    const renderProductStructure = (contentCode, lastPublishedDate) => (
      <div className={styles.rowContainer}>
        <div className={styles.checkboxTitleContainer}>
          <Checkbox
            label="label"
            labelHidden
            value={isProductStructureChecked(contentCode)}
            onChange={() => checkProductStructure(contentCode)}
            disabled={false}
          />

          <p className={styles.text}>Product Structure</p>
        </div>

        <div className={styles.text}>
          Last published <span className={styles.boldText}>{formatDate(lastPublishedDate)}</span>
        </div>
      </div>
    );

    const renderProductsData = () => {
      if (pairProducts && Object.keys(pairProducts).length > 0) {
        return Object.keys(pairProducts).map(contentCode => {
          const pairProduct = pairProducts[contentCode];
          const { isExpanded, ...pairProductIsbns } = pairProduct;
          const isbns = Object.keys(pairProductIsbns);
          return (
            <>
              {renderProductPair(contentCode, isbns)}
              {pairProduct.isExpanded && (
                <div className={styles.child}>
                  {isbns.map(isbn => renderProductDefinition(pairProduct[isbn]))}
                  {renderProductStructure(contentCode, lastPublishedProductStructure[contentCode])}
                </div>
              )}
            </>
          );
        });
      }
      return null;
    };

    const renderPackages = () => {
      const { staged: stagedPackages, published: publishedPackages } = modalContentBody?.allContentPackages || {};
      const filteredStagedPackages = stagedPackages.filter(packageName => !publishedPackages.includes(packageName));

      return [...filteredStagedPackages, ...publishedPackages].map(packageName => (
        <div className={styles.rowContainer} key={packageName}>
          <div className={styles.checkboxTitleContainer}>
            <Checkbox
              label="label"
              labelHidden
              value={isPackageChecked(packageName)}
              onChange={() => checkPackages(packageName)}
              disabled={false}
            />

            <p className={styles.boldText}>{packageName}</p>
          </div>

          <div className={styles.text}>
            Last published <span className={styles.boldText}>{formatDate(lastPublished)}</span>
          </div>
        </div>
      ));
    };

    const renderNotesSection = () => (
      <div className={styles.notesWrapper}>
        <p className={styles.text}>Notes:</p>
        <textarea className={styles.notesTextarea} onChange={onNotesChange} ref={textareaRef} />
      </div>
    );

    if (publishedCourseData?.error) {
      return (
        <div className={styles.modalContentWrapperSuccess}>
          <div>
            <p className={styles.boldText}>{content.there_was_an_error_during_publishing}</p>
          </div>
          <div className={styles.closeButtonWrapper}>
            <Button
              text={content.close}
              type={buttonTypes.SECONDARY}
              onClick={() => closeModalFunction()}
              className={styles.cancelButton}
            />
          </div>
        </div>
      );
    }

    if (publishedCourseData?.status === 'success') {
      return (
        <div className={styles.modalContentWrapperSuccess}>
          <div>
            <p className={styles.boldText}>{courseTitleAndShortcode}</p>
            <p className={styles.text}>{content.was_successfully_published}</p>
          </div>
          <div className={styles.closeButtonWrapper}>
            <Button
              text={content.close}
              type={buttonTypes.SECONDARY}
              onClick={() => closeModalFunction()}
              className={styles.cancelButton}
            />
          </div>
        </div>
      );
    }

    return (
      <>
        <div className={styles.modalContentWrapper}>
          {renderCourse()}
          <hr />
          {renderProductsData()}
          <hr />
          {renderPackages()}
          {renderNotesSection()}
        </div>

        <div className={styles.modalButtonBottom}>
          <Button
            text={content.cancel}
            type={buttonTypes.CANCEL}
            onClick={() => closeModalFunction()}
            className={styles.cancelButton}
          />
          <Button
            text={content.publish_button}
            type={buttonTypes.PRIMARY}
            disabled={isDisabledPublishButton}
            onClick={publishContent}
          />
        </div>
      </>
    );
  };

  return (
    <div className={`${styles.outerModal} ${isOpen ? styles.open : ''}`}>
      <div className={styles.innerModal}>
        <HubModalLayout isOpen={isOpen} id="publishCourseDetailsModal" closeAction={() => closeModalFunction()}>
          <div className={styles.modalContent}>{isLoading ? <LoadingSpinner /> : renderContent()}</div>
        </HubModalLayout>
      </div>
    </div>
  );
}

CoursePublishModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  modalContentBody: PropTypes.object,
  content: PropTypes.object,
  publishCourseDataRequestData: PropTypes.func,
  publishedCourseData: PropTypes.object,
  courseTitle: PropTypes.string,
  clearPublishedCourseDataAction: PropTypes.func
};

const mapStateToProps = ({ coursePublish: { publishedCourseData } }) => ({ publishedCourseData });

const mapDispatchToProps = {
  publishCourseDataRequestData: publishCourseDataRequest,
  clearPublishedCourseDataAction: clearPublishedCourseData
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalizedContent('coursePublishPage')
)(CoursePublishModal);
