import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Footer from '@oup/shared-front-end/src/components/Wizard/Footer';
import Button from '@oup/shared-front-end/src/components/Button';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import PanelHeading from '../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../components/ScrollContainer/ScrollContainer.js';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';

import { confirmJoinClass } from '../../redux/reducers/joinClass.reducer.js';
import cmsContent from '../../utils/cmsContent.js';
import styles from './JoinClass.scss';

function JoinClassPreview({ closePanel, joinClassAction, content, classroomName, organisationName, groupType }) {
  const CMS = cmsContent.addToProfile || {};

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div className={styles.header}>
            <div className={styles.close}>
              <Button
                text={CMS.close_panel_text}
                onClick={closePanel}
                variant="outline"
                icon={{ component: <SVGIcon glyph={GLYPHS.ICON_CLOSE} />, placement: 'right' }}
              />
            </div>
            <PanelHeading
              title={content.enter_a_code_text}
              subtitle={
                groupType === 'CLASS'
                  ? content.reviewing_panel_class_subtitle
                  : content.reviewing_panel_placement_test_subtitle
              }
            />
          </div>
        }
        footerContent={
          <Footer
            primaryButtonLabel={content.join_text}
            secondaryButtonLabel={content.button_cancel_text}
            primaryButtonAction={joinClassAction}
            secondaryButtonAction={closePanel}
          />
        }
      >
        <div className={styles.contentContainer}>
          <div className={styles.productInfo}>
            <div className={styles.thumbnail}>{classroomName?.charAt(0)?.toUpperCase()}</div>
            <div className={styles.names}>
              <p className={styles.title}>{classroomName}</p>
              <div className={styles.organisationContainer}>
                <SVGIcon glyph={GLYPHS.ICON_ORG} className={styles.icon} />
                <p className={styles.organisationName}>{organisationName}</p>
              </div>
            </div>
          </div>
          <ValidationMessage state="information">
            {groupType === 'CLASS'
              ? content.reviewing_panel_class_information_block_text
              : content.reviewing_panel_placement_test_information_block_text}
          </ValidationMessage>
        </div>
      </ScrollContainer>
    </form>
  );
}

JoinClassPreview.propTypes = {
  closePanel: PropTypes.func.isRequired,
  joinClassAction: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  classroomName: PropTypes.string,
  organisationName: PropTypes.string,
  groupType: PropTypes.string
};

export default connect(null, {
  joinClassAction: confirmJoinClass
})(JoinClassPreview);
