import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './SearchOptions.scss';
import Button, { buttonTypes } from '../Button/Button';
import { validGlyphs } from '../SVGIcon/SVGIcon';
import PopupOverlay from '../PopupOverlay/PopupOverlay';
import colors, { hexCodes } from '../../globals/colors';
import keyCodes from '../../globals/keyCodes';

export default class SearchOptions extends Component {
  constructor(props) {
    super(props);

    const { options } = this.props;

    this.state = {
      programmaticId: Math.random()
        .toString(36)
        .substring(7),
      popupOpen: false,
      // Also maintain list of all checked values to pass to onChange events etc:
      // Note: For consistency, this is an array even when inputType is radio.
      selectedValues: props.initialSelectedValues || options.filter(opt => opt.checked).map(opt => opt.value)
    };
  }

  /** creating pagination, filter, sorting, refresh for Front_End
   * orgStudentsSearch check for Org Student Tab
   */
  componentWillReceiveProps(nextProps) {
    const { options } = this.props;
    if (JSON.stringify(nextProps.options) !== JSON.stringify(options)) {
      this.setState({ selectedValues: nextProps.options.filter(opt => opt.checked).map(opt => opt.value) });
    }
    if (nextProps.filterOptionsSelected && typeof nextProps.filterOptionsSelected !== 'undefined') {
      this.setState({ selectedValues: nextProps.filterOptionsSelected });
    }
  }

  togglePopup = () => {
    const { popupOpen } = this.state;
    this.setState(previousState => ({ ...previousState, popupOpen: !popupOpen }));
  };

  optionClicked = (option, checked) => {
    if (option.isDisabled) return;
    if (option.onClick) {
      option.onClick(option.value, checked);
    }
    this.togglePopup();
  };

  render() {
    const {
      buttonId,
      buttonText = '',
      buttonGlyph,
      customClassName,
      a11yHeading,
      ariaControls,
      inputType,
      options,
      shallow,
      iconOnly,
      showLegend,
      overlayHangRight,
      streamlineIcon
    } = this.props;
    const { popupOpen, programmaticId } = this.state;
    const menuId = `${buttonId}-menu`;
    let { selectedValues } = this.state;

    // CES-2230: Set aria-label so screenreaders will read a friendly summary of the current selections:
    // Possible i18n debt :(
    const summaryOfSelections = options
      .filter(option => option.checked)
      .map(option => option.text)
      .join(', ');

    return (
      <div
        className={classnames(styles.container, {
          [styles.containerShallow]: shallow,
          [styles.sortButton]: customClassName?.indexOf('sortButton') !== -1
        })}
      >
        <Button
          id={buttonId}
          type={iconOnly ? buttonTypes.ROUNDED : buttonTypes.DROPDOWN}
          text={buttonText}
          glyph={buttonGlyph}
          glyphFill={hexCodes[colors.PRIMARY]}
          onClick={this.togglePopup}
          aria={{
            haspopup: 'true',
            owns: menuId,
            controls: menuId,
            expanded: popupOpen,
            label: summaryOfSelections ? `${a11yHeading} ${summaryOfSelections}` : null
          }}
          iconOnly={iconOnly}
        />

        <PopupOverlay
          id={menuId}
          customClassName={classnames(
            customClassName,
            styles.popupOverlay,
            overlayHangRight ? styles.hangRight : styles.hangLeft
          )}
          visible={popupOpen}
          hangRight={overlayHangRight}
          onTogglePopup={this.togglePopup}
          returnFocusTo={buttonId}
          aria={{ labelledby: buttonId }}
          buttonElementId={buttonId}
        >
          <fieldset>
            {showLegend && <legend>{a11yHeading}</legend>}

            <ul className={styles.popupContainer} role="menu">
              {options.map((option, index) => (
                <li key={index} role="menuitem">
                  <input
                    id={`control-${option.id}-${programmaticId}`}
                    name={option.name}
                    type={inputType}
                    value={option.value}
                    disabled={option.isDisabled}
                    checked={selectedValues.includes(option.value)}
                    onClick={event => this.optionClicked(option, event.target.checked)}
                    onKeyDown={event => {
                      if (event.keyCode === keyCodes.ENTER) event.target.click();
                    }}
                    onChange={event => {
                      const { onChange } = this.props;
                      if (inputType === 'radio') {
                        selectedValues = [option.value];
                      } else {
                        selectedValues = event.target.checked
                          ? [...selectedValues, option.value]
                          : selectedValues.filter(val => JSON.stringify(val) !== JSON.stringify(option.value));
                      }
                      this.setState({ selectedValues });
                      if (option.onChange) option.onChange(option.value, event.target.checked);
                      if (onChange) onChange(selectedValues);
                    }}
                    aria-controls={ariaControls}
                  />

                  {/* Label follows input so that we can use css ":checked + label" selector to show custom checkmark ✔ */}
                  <label
                    htmlFor={`control-${option.id}-${programmaticId}`}
                    className={streamlineIcon ? styles.streamline : ''}
                  >
                    <span>{option.text}</span>
                  </label>
                </li>
              ))}
            </ul>
          </fieldset>
        </PopupOverlay>
      </div>
    );
  }
}

SearchOptions.defaultProps = {
  showLegend: true,
  streamlineIcon: false,
  overlayHangRight: true
};

SearchOptions.propTypes = {
  buttonId: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonGlyph: PropTypes.oneOf(validGlyphs),
  customClassName: PropTypes.string,
  a11yHeading: PropTypes.string.isRequired,
  ariaControls: PropTypes.string,
  inputType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  shallow: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      id: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.any.isRequired,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      isDisabled: PropTypes.bool
    })
  ).isRequired,
  filterOptionsSelected: PropTypes.array,
  iconOnly: PropTypes.bool,
  showLegend: PropTypes.bool,
  overlayHangRight: PropTypes.bool,
  streamlineIcon: PropTypes.bool,
  initialSelectedValues: PropTypes.arrayOf(PropTypes.string)
};
