/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { courseResourceIsTeacherOnly } from '@oup/shared-node-browser/course.js';

// Redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Heading from '@oup/shared-front-end/src/components/Heading';
import { hubDownloadResource, changeResourceView } from '../../../redux/actions/hubResource';
// Style
import styles from './ResourceHome.scss';
// Components
import ResourceFolder from './ResourceFolder/ResourceFolder';
import Resource from '../../../components/Resource/Resource';
import ResourceModal from '../../../components/ResourceModal/ResourceModal';
import ResourceFormHidden from './ResourceFormHidden';
// Services
import getResourceLink from '../Services/getResourceLink';
import getCourseLevel from '../../HubDashboardLayout/Services/getCourseLevel';
import { hasFoldersWithStudentResources } from '../Services/filterResources';
// Constants
import { ResourceConstants, ErrorModalType, HubLayoutConstants, COURSE_FOLDERS } from '../../../globals/hubConstants';
import withBreakpoint from '../../../decorators/withBreakpoint';
import { hasHideableKeywords, hasLockableKeywords } from '../../HubCourseAssessments/Utils/isLockedNode';
import { featureIsEnabled } from '../../../globals/envSettings';

class ResourceHome extends Component {
  state = {
    resourceId: '',
    isDownloadable: true,
    isExpiredResourceId: null,
    isNotStartedResourceId: null,
    errorModalType: ErrorModalType.NONE
  };

  getTitlePage = (resourceFolderName, courseTitle, courseProperties) => {
    const { hubContent } = this.props;
    if (resourceFolderName === COURSE_FOLDERS.GET_STARTED) return hubContent.get_started;
    if (resourceFolderName === COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT) return hubContent.professional_development;
    return `${courseTitle} ${getCourseLevel(courseProperties)} ${this.getTitleMenuItem(resourceFolderName)}`;
  };

  getHeadingPage = resourceFolderName => {
    const { hubContent } = this.props;

    switch (resourceFolderName) {
      case COURSE_FOLDERS.GET_STARTED:
        return hubContent.get_started_heading;
      case COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT:
        return hubContent.professional_development_heading;
      case COURSE_FOLDERS.TESTS:
        return hubContent.tests_heading;
      default:
        return hubContent.resources;
    }
  };

  getTitleMenuItem = resourceFolderName => {
    const { hubContent } = this.props;
    if (resourceFolderName === COURSE_FOLDERS.GET_STARTED) return hubContent.get_started;
    if (resourceFolderName === COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT) return hubContent.professional_development;
    return resourceFolderName;
  };

  isTeacherOnlyFolder = resourceFolderName => {
    if (resourceFolderName === COURSE_FOLDERS.GET_STARTED) return true;
    if (resourceFolderName === COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT) return true;

    return false;
  };

  _closeModal = () => {
    this.setState(() => ({
      resourceId: ''
    }));
  };

  _downloadResource = resource => {
    const { downloadResource } = this.props;
    downloadResource({ ...resource }, 'download');
  };

  _openResource = url => e => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, '_blank');
  };

  _openExpiredModal = isExpiredResourceId => {
    this.setState(() => ({
      isExpiredResourceId,
      errorModalType: ErrorModalType.EXPIRED
    }));
  };

  _openNotStartedModal = isNotStartedResourceId => {
    this.setState(() => ({
      isNotStartedResourceId,
      errorModalType: ErrorModalType.NOT_STARTED
    }));
  };

  _openModal = (resourceId, isDownloadable) => {
    const {
      courses,
      match: { params },
      downloadResource
    } = this.props;

    this.setState(() => ({
      resourceId,
      isDownloadable
    }));
    const resources = get(courses, [params.courseId, 'resources']);

    const { PREVIEWABLE_FORMATS } = ResourceConstants;
    if (PREVIEWABLE_FORMATS.includes((resources[resourceId].format || '').toLowerCase())) {
      downloadResource({ ...resources[resourceId] }, 'play');
    }
  };

  _renderFolders = courseUserRole => {
    const {
      courses,
      courseId,
      match: { params },
      viewerType,
      isPreview = false
    } = this.props;
    const resourceFolders = get(courses, [courseId, 'resourceFolders', params.folder]);
    const resources = get(courses, [params.courseId, 'resources']);

    if (courses && resourceFolders) {
      return Object.entries(resourceFolders).reduce((result, [key, value]) => {
        if (
          key === 'resources' ||
          (courseUserRole === HubLayoutConstants.COURSE_USER_ROLES.TEACHER &&
            viewerType === HubLayoutConstants.COURSE_USER_ROLES.STUDENT &&
            !hasFoldersWithStudentResources(value, resources))
        ) {
          return result;
        }
        result.push(
          <ResourceFolder
            key={key}
            title={value.title}
            itemLink={`${getResourceLink(courseId, params.folder, isPreview)}/${key}`}
          />
        );
        return result;
      }, []);
    }

    return null;
  };

  _handleResourceView = userView => {
    const { handleResourceView } = this.props;
    handleResourceView(userView);
  };

  _renderViewButton = () => {
    const { hubContent, viewerType } = this.props;

    return (
      <div className={styles.classworkButtons}>
        <button
          type="button"
          className={viewerType === HubLayoutConstants.COURSE_USER_ROLES.TEACHER ? styles.active : styles.inactive}
          onClick={() => this._handleResourceView(HubLayoutConstants.COURSE_USER_ROLES.TEACHER)}
        >
          {hubContent.classwork_resource_teacher_view}
        </button>
        <button
          type="button"
          className={viewerType === HubLayoutConstants.COURSE_USER_ROLES.STUDENT ? styles.active : styles.inactive}
          onClick={() => this._handleResourceView(HubLayoutConstants.COURSE_USER_ROLES.STUDENT)}
        >
          {hubContent.classwork_resource_student_view}
        </button>
      </div>
    );
  };

  _renderResources = (resources, folderResourceIds, hasLicence, isExpired, startedLicence) => {
    const { breakpoint, hubContent, downloadIsLoading, viewerType, resourceIdsDownloading } = this.props;
    const filteredResourceIds =
      viewerType === HubLayoutConstants.COURSE_USER_ROLES.STUDENT
        ? folderResourceIds.filter(id => !courseResourceIsTeacherOnly(resources[id]))
        : folderResourceIds;

    return filteredResourceIds.map(
      id =>
        !hasHideableKeywords(resources[id].keywords) && (
          <Resource
            key={id}
            resourceId={id}
            resource={resources[id]}
            downloadResource={this._downloadResource}
            openResource={this._openResource}
            openModal={this._openModal}
            hubContent={hubContent}
            downloadIsLoading={downloadIsLoading}
            hasLicence={hasLicence}
            isExpired={isExpired || (resources[id].keywords && hasLockableKeywords(resources[id].keywords))}
            breakpoint={breakpoint}
            startedLicence={startedLicence}
            openExpiredModal={() => {
              this._openExpiredModal(id);
            }}
            openNotStartedModal={() => {
              this._openNotStartedModal(id);
            }}
            resourceIdsDownloading={resourceIdsDownloading}
          />
        )
    );
  };

  render() {
    const {
      props: {
        courses,
        courseId,
        hubContent,
        match: { params },
        downloadIsLoading,
        breakpoint
      },
      state: { resourceId, isDownloadable },
      _closeModal,
      _downloadResource
    } = this;

    const resourceFolders = get(courses, [courseId, 'resourceFolders', params.folder], {});
    const courseProperties = get(courses, [courseId, 'properties'], {});
    const courseTitle = get(courses, [courseId, 'title']);
    const courseUserRole = get(courses, [courseId, 'userRole'], 'student');
    const resources = get(courses, [courseId, 'resources']);

    const pageTitle = courseTitle
      ? `${courseTitle} ${getCourseLevel(courseProperties)} | ${this.getTitleMenuItem(params.folder)}`
      : 'Loading';

    const course = get(courses, [params.courseId]);
    const courseData = get(course, ['products', 'data']);

    const courseDataValues = courseData && Object.values(courseData);
    const hasLicence = courseDataValues && courseDataValues.filter(product => product.hasLicence).length > 0;
    const isExpired =
      courseDataValues &&
      courseDataValues.every(product => {
        const date = product.expiryDate;
        const productIsExpired = date && moment(date).isValid() && moment(date).isBefore(moment());
        return hasLicence && productIsExpired;
      });

    const startedLicence =
      courseDataValues &&
      courseDataValues.filter(product => {
        const date = product.expiryDate;
        const productIsExpired = date && moment(date).isValid() && moment(date).isBefore(moment());
        const productHasLicence = product.hasLicence;
        return !product.notStartedLicence && !productIsExpired && productHasLicence;
      }).length > 0;

    const hasOnlyResources = Object.keys(resourceFolders).every(subFolder => subFolder === 'resources');
    const title = this.getTitlePage(params.folder, courseTitle, courseProperties);

    const showHeading = featureIsEnabled('navigation-changes');
    const headingArgs = {
      text: this.getHeadingPage(params.folder),
      size: 'small',
      variant: 'h1'
    };
    return resourceFolders && courseTitle ? (
      <div>
        <Helmet title={pageTitle} />
        {showHeading && <Heading {...headingArgs} />}
        {courseUserRole === HubLayoutConstants.COURSE_USER_ROLES.TEACHER &&
          !this.isTeacherOnlyFolder(params.folder) &&
          this._renderViewButton()}
        {!showHeading && <div className={styles.resourceHomeHeader}>{title}</div>}
        <div>
          <div>
            {resourceFolders?.resources
              ? this._renderResources(resources, resourceFolders.resources, hasLicence, isExpired, startedLicence)
              : null}
          </div>
          {!hasOnlyResources ? (
            <div className={styles.resourceFolderTitle}>{hubContent.assessment_home_assessment_folders}</div>
          ) : null}
          <div className={styles.resourceHomeFolderContainer}>{this._renderFolders(courseUserRole)}</div>
        </div>
        <ResourceFormHidden />
        <ResourceModal
          breakpoint={breakpoint}
          resourceId={resourceId}
          closeModal={_closeModal}
          downloadResource={isDownloadable ? _downloadResource : null}
          resource={resourceId ? resources[resourceId] : {}}
          hubContent={hubContent}
          downloadIsLoading={downloadIsLoading}
        />
      </div>
    ) : (
      <div />
    );
  }
}

ResourceHome.propTypes = {
  match: PropTypes.object,
  courses: PropTypes.object,
  courseId: PropTypes.string,
  downloadResource: PropTypes.func.isRequired,
  downloadIsLoading: PropTypes.bool.isRequired,
  breakpoint: PropTypes.string,
  hubContent: PropTypes.object,
  handleResourceView: PropTypes.func,
  viewerType: PropTypes.string,
  resourceIdsDownloading: PropTypes.object,
  isPreview: PropTypes.bool
};

const mapStateToProps = state => ({
  downloadIsLoading: state.hubResourceDownload?.downloadIsLoading,
  resourceIdsDownloading: state.hubResourceDownload?.resourceIdsDownloading,
  viewerType: state.teacherResources.viewerType
});

const mapDispatchToProps = dispatch => ({
  downloadResource: (resource, resourceAction) => {
    dispatch(hubDownloadResource(resource, resourceAction));
  },
  handleResourceView: viewerType => {
    dispatch(changeResourceView(viewerType));
  }
});

export default compose(withBreakpoint, connect(mapStateToProps, mapDispatchToProps))(ResourceHome);
