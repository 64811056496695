import omit from 'lodash.omit';
import { select } from 'redux-saga/effects';
import { API_VERSIONS, ORG_REGISTRATION_CONTEXT } from '@oup/shared-node-browser/constants';
import createOrgSchema from '../../../../../../static/schema/draft-4/create-org-request-body.json';
import createOrgWithOrgAdminSchema from '../../../../../../static/schema/draft-4/create-org-with-orgadmin-request-body.json';
import { orgRoles } from '../../../../../globals/orgRoles';
import { isOptMode } from '../../../../../utils/platform';

function getRequestObject(
  body,
  excludeFields = [],
  version = API_VERSIONS.API_VERSION_0_2,
  schema = createOrgSchema,
  orgId = null
) {
  const orgIdPathParameter = orgId ? `/${orgId}` : '';
  return {
    name: orgId ? 'editOrg' : 'createOrg',
    url: `${__API_BASE__}/org${orgIdPathParameter}`,
    version,
    method: orgId ? 'PUT' : 'POST',
    body: omit(body || {}, excludeFields),
    schema
  };
}

function* getCreateOrgRequestApiContext(input, branch) {
  let excludeFields = [];
  let version = API_VERSIONS.API_VERSION_0_2;
  let schema = createOrgSchema;
  const optFields = ['address', 'hearAboutUs', 'department', 'city', 'taxRegistration', 'province'];
  const { orgId } = yield select(state => state.orgRegistration);

  if (![orgRoles.PRIMARY_SCHOOL, orgRoles.OIC_SCHOOL].includes(input?.role)) {
    excludeFields.push('curriculumType');

    if (branch !== ORG_REGISTRATION_CONTEXT.PLACEMENT) {
      excludeFields.push('orgUniqueId');
    }
  }

  if (branch !== ORG_REGISTRATION_CONTEXT.OUP_CUSTOMER_SUPPORT) {
    excludeFields.push('orgRegion', 'orgPostcode', 'sapNumber', 'adminUser');
  } else {
    version = API_VERSIONS.API_VERSION_0_3;
    schema = createOrgWithOrgAdminSchema;
  }

  if (!orgId && branch !== ORG_REGISTRATION_CONTEXT.OUP_CUSTOMER_SUPPORT) {
    excludeFields.push('registrationStatus');
  }

  if (!isOptMode() && branch !== ORG_REGISTRATION_CONTEXT.PLACEMENT) {
    excludeFields = [...excludeFields, ...optFields];
  }

  return getRequestObject(input, excludeFields, version, schema, orgId);
}

export default getCreateOrgRequestApiContext;
