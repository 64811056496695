import React from 'react';
import PropTypes from 'prop-types';

import content from '../../utils/cmsContent';
import ROLES, { userRoleFriendlyNames } from '../../globals/userRoles';
import parseMarkdown from '../../utils/markdown/markdown';
import TextInputLabel from '../TextInputLabel/TextInputLabel';
import TextInputField from '../TextInputField/TextInputField';

/**
 * A text input label and field.
 */
function TextInputFederatedAccess({
  id,
  name,
  label,
  placeholder,
  value,
  title,
  customStyleName,
  onChange,
  onClick,
  onBlur,
  onPaste,
  type,
  disableAutoComplete,
  disabled,
  readOnly,
  readOnlyPreserveStyle,
  required,
  maxLength,
  textArea,
  aria,
  autoFocus,
  autoHighlight,
  labelHidden,
  min,
  max,
  onFocus
}) {
  return (
    <div>
      <TextInputLabel id={id} label={label} labelHidden={labelHidden} />
      <TextInputField
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        title={title}
        customStyleName={customStyleName}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        onPaste={onPaste}
        type={type}
        disableAutoComplete={disableAutoComplete}
        disabled={disabled}
        readOnly={readOnly}
        readOnlyPreserveStyle={readOnlyPreserveStyle}
        required={required}
        maxLength={maxLength}
        textArea={textArea}
        aria={aria}
        autoFocus={autoFocus}
        autoHighlight={autoHighlight}
        min={min}
        max={max}
      />
    </div>
  );
}

TextInputFederatedAccess.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  /** the name of the input field needs to be unique */
  name: PropTypes.string.isRequired,
  /** the inputs text label */
  label: PropTypes.string.isRequired,
  /** the inputs placeholder text */
  placeholder: PropTypes.string.isRequired,
  /** Actual value of the input. If not preset then the placeholder is shown */
  value: PropTypes.any,
  /** The optional tooltip */
  title: PropTypes.string,
  customStyleName: PropTypes.string,
  /** function that if the input is touched, will be called */
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  /** Function to bind to the native input onBlur event */
  onBlur: PropTypes.func,
  /** Function called when user pastes content into the field */
  onPaste: PropTypes.func,
  /** The type attribute of the <input> element. Restricted to text looking inputs (ie not checkbox etc). */
  type: PropTypes.oneOf(['text', 'password', 'number', 'date', 'email']),
  /** Set this to true to disable the browser's native autoComplete suggestions */
  disableAutoComplete: PropTypes.bool,
  /** Set this to true to disable any interaction on the input field */
  disabled: PropTypes.bool,
  /** Set whether the field is for presentation and is not editable. */
  readOnly: PropTypes.bool,
  readOnlyPreserveStyle: PropTypes.bool,
  /** Set this to false to prevent textbox from defaulting to required, for screenreaders etc */
  required: PropTypes.bool,
  /** Max Length value for the input element */
  maxLength: PropTypes.number,

  /* Min/Max limits for both number and date fields */
  min: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  /** Make the text box of type text area */
  textArea: PropTypes.bool,
  /** map of aria attribute names and values, eg: aria={{ role:'textbox', live: 'assertive' }} */
  aria: PropTypes.object,
  autoFocus: PropTypes.bool,
  autoHighlight: PropTypes.bool,
  /** The input label can be hidden to users and only visible to screen readers */
  labelHidden: PropTypes.bool,

  onFocus: PropTypes.func
};

export default TextInputFederatedAccess;

TextInputFederatedAccess.defaultsFor = () => {
  const CMS = content.textInputFederatedAccessComponent || {};
  return {
    firstName: {
      id: 'firstName',
      label: `${CMS.first_name_label}:`,
      placeholder: CMS.first_name_placeholder
    },

    lastName: {
      id: 'lastName',
      label: `${CMS.last_name_label}:`,
      placeholder: CMS.last_name_placeholder
    },

    userName: {
      id: 'userName',
      type: 'email',
      label: `${CMS.username_label}:`,
      placeholder: CMS.username_placeholder,
      tip: parseMarkdown(CMS.username_tip_markdown)
    },

    emailConfirm: {
      id: 'emailConfirmation',
      label: `${CMS.confirm_email_label}:`,
      type: 'email',
      placeholder: CMS.confirm_email_placeholder
    },

    email: {
      id: 'email',
      type: 'email',
      label: `${CMS.email_label}:`,
      placeholder: CMS.email_placeholder,
      tip: parseMarkdown(CMS.email_tip_markdown)
    },

    addClassName: {
      id: 'addClassName',
      type: 'text',
      label: `${CMS.input_enter_new_class_name_label}:`,
      placeholder: `${CMS.input_enter_new_class_name_placeholder}`
    },

    // Role field is typically a Dropdown:
    userRole: {
      id: 'userRole',
      label: `${CMS.role_label}:`,
      placeholder: CMS.role_placeholder,
      options: Object.keys(ROLES).map(key => ({
        value: key,
        text: userRoleFriendlyNames(key) || ROLES[key]
      }))
    },

    role: {
      id: 'role',
      label: `${CMS.roles_label}:`,
      placeholder: CMS.roles_placeholder
    },

    school: {
      id: 'school',
      label: `${CMS.school_label}:`,
      placeholder: CMS.school_placeholder
    },

    country: {
      id: 'country',
      label: `${CMS.country_label}:`,
      placeholder: CMS.country_default_option
    },

    password: {
      id: 'password',
      label: `${CMS.password_label}:`,
      type: 'password',
      tip: CMS.password_tip
    }
  };
};
