/* eslint-disable*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import isEmpty from 'lodash.isempty';
import Button, { buttonTypes } from '../../components/Button/Button';
import ContentWithThumbnail from '../../components/ContentWithThumbnail/ContentWithThumbnail';
import ENTITY_TYPES from '../../globals/entityTypes';
import EntityPageHeader from '../../components/EntityPageHeader/EntityPageHeader';
import InformationPopupModal from '../../components/InformationPopupModal/InformationPopupModal';
import PageHeading from '../../components/PageHeading/PageHeading';
import SearchInitialiser from '../../components/SearchInitialiser/SearchInitialiser';
import OicProductsTab from '../MySchool/tabs/OicProductsTab/OicProductTab';
import { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import { SIZES as ThumbnailSizes } from '../../components/Thumbnail/Thumbnail';
import UrlControlledTabs from '../../components/UrlControlledTabs/UrlControlledTabs';
import { featureIsEnabled, socialIdpIsEnabled } from '../../globals/envSettings';
import { isOrbMode, getCurrentPlatform, isEltAssessmentMode, isOicMode } from '../../utils/platform';
import colors from '../../globals/colors';
import userRoles from '../../globals/userRoles';
import { setInformationPopupViewed } from '../../redux/reducers/app.reducer';
import getCurrentUser from '../../redux/selectors/getCurrentUser';
import withLocalizedContent from '../../language/withLocalizedContent';
import MyProfileEditPanel from '../../panels/MyProfileEditPanel';
import { safePanelLink, safePanelUnlink } from '../../utils/links/panelLinks';
import Gradebook from '../Gradebook';
import MyProfileClassSearch from './tabs/MyProfileClassSearch';
import MyProfileMaterialSearch from './tabs/MyProfileMaterialSearch';
import getLearnMoreContent from '../../language/learnMoreContent.js';
import { orgRoles } from '../../globals/orgRoles';
import SkeletonLoader from '../../components/SkeletonLoader/SkeletonLoader';
import Feature from '../../environment/Feature';
import { prepareTargetUrl } from '../../utils/url';
import JoinAClass from './tabs/panels/JoinAClass/JoinAClass';
import { getUserDetails } from '../../redux/reducers/user/userProfile.reducer.js';
import { initialiseInstance } from '../../redux/reducers/data/search.reducer';
import { getProductServicesRequest, resetProductServices } from '../../redux/actions/productServices';
import { isAtLeastTeacher } from '../../redux/selectors/authorization/user';
import actions from '../../redux/actions';
import OicAssessmentsTab from '../MySchool/tabs/OicAssessmentsTab/OicAssessmentsTab.js';

const extractProductIds = profileAssignments => {
  const productIds = Object.keys(profileAssignments.data) || [];
  return productIds;
};
class MyProfile extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    this.state = {
      showPopup: false,
      isStudentTabSwitch: false,
      isShowGBTab: params.tabName === 'gradebook',
      isOrbWithNoSubscriptions: false
    };
    this.isInitialLoad = true;
  }

  componentWillMount() {
    const { initGetProductServices, getUserDetailsAction } = this.props;
    const { orgId, userId } = this.props;
    initGetProductServices();
    getUserDetailsAction(orgId, userId);
    this._offlineAppRedirectHandler();
    this._checkIfRedirectToTargetUrl();
    const { profileRole } = this.props;
    const isStudentTabSwitch = !profileRole || profileRole === userRoles.USER || profileRole === userRoles.LEARNER;

    this.setState({
      isStudentTabSwitch
    });

    if (!isStudentTabSwitch) {
      this.initialise(userId);
    }
  }

  componentDidMount() {
    const { orgId, getOrgDetails } = this.props;

    if (isOicMode()) {
      getOrgDetails(orgId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { profileAssignments, productServicesResult, productServicesRequest, subscriptions } = nextProps;
    if (!profileAssignments.loading && isEmpty(subscriptions)) {
      this.setState({
        isOrbWithNoSubscriptions: isOrbMode() && isEmpty(profileAssignments.data)
      });
    }

    if (profileAssignments && !isEmpty(profileAssignments.data) && this.isInitialLoad) {
      const parameters = {
        productIds: extractProductIds(profileAssignments)
      };
      if (!isOrbMode()) {
        productServicesRequest(parameters);
        this.isInitialLoad = false;
      }
    }

    if (productServicesResult && !isEmpty(productServicesResult.serviceResults)) {
      if (productServicesResult.serviceResults.isOLBGradebook) {
        this.setState({ isShowGBTab: true });
      }
    }
  }

  get isManagedUser() {
    const { profileRole } = this.props;
    return profileRole === userRoles.MANAGED_USER;
  }

  _checkIfRedirectToTargetUrl() {
    const { identity } = this.props;
    const redirectTo =
      !identity.missingFields.includes('tsAndCsAccepted') &&
      !window.location.pathname.includes('editUser') &&
      prepareTargetUrl();

    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }

  _offlineAppRedirectHandler() {
    const { identity } = this.props;
    const offlineAppInitialState = sessionStorage.getItem('offlineAppInitialState');

    const shouldRedirect = !identity.missingFields.includes('tsAndCsAccepted');
    if (shouldRedirect && offlineAppInitialState) {
      window.location.href = `/offlineApp/AUTH_SUCCES`;
    }
  }

  initialise(userId) {
    const initialFilters = {
      userId,
      active: true,
      expired: true,
      expiring: true,
      noLicence: true,
      notStarted: true,
      searchAssignments: true
    };
    const { initialiseSearchInstance } = this.props;
    initialiseSearchInstance('profileAssignments', false, initialFilters);
  }

  render() {
    const {
      isPrimarySchool,
      isOicSchool,
      match: { params },
      location,
      profileName,
      profileUsername,
      profileRole,
      profileLoading,
      history,
      identity,
      informationPopupViewed,
      setInformationPopupViewedAction,
      localizedContent: { myProfilePage: content, learnMoreFirstVisit: platformNewsContent },
      orgDetails
    } = this.props;
    const { panelName, tabName } = params;
    const { isStudentTabSwitch, isShowGBTab } = this.state;
    const { userId, orgId } = this.props;
    const { showPopup, isOrbWithNoSubscriptions } = this.state;
    const isOicWithoutSchool = isOicMode() && !isOicSchool;
    const { orgConfig: { configurations: { CONFIGURATION_JANISON: janisonConfiguration = false } = {} } = {} } = orgDetails;
    const isOrgLinkedToJanison = janisonConfiguration !== false;

    const learningMaterialTab = {
      urlName: 'learningMaterial',
      color: colors.LEARNERS,
      tabText: content.tabs_myLearningMaterial_text,
      panelContent: (
        <SearchInitialiser
          searchSource="profileAssignments"
          initialFilters={{
            userId,
            searchAssignments: true,
            ...(isPrimarySchool
              ? {
                  active: true,
                  expired: false,
                  noLicence: false,
                  expiring: true,
                  notStarted: true
                }
              : {
                  active: true,
                  expired: true,
                  noLicence: true,
                  expiring: true,
                  notStarted: true
                })
          }}
        >
          <MyProfileMaterialSearch params={params} profileIsSelfLearner={!profileRole || profileRole === 'USER'} />
        </SearchInitialiser>
      )
    };

    const myProductsTab = {
      urlName: 'subscriptions',
      color: colors.LEARNERS,
      tabText: content.tabs_subscriptions_text,
      panelContent: <OicProductsTab orgId={orgId} tabName={tabName} panelName={panelName} />
    };

    const oicAssessmentsTab = {
      urlName: 'oisa-assessments',
      color: colors.LEARNERS,
      tabText: content.tabs_assessments_text,
      panelContent:  <OicAssessmentsTab orgId={orgId} tabName={params.tabName} panelName={params.panelName} isOrgLinkedToJanison={isOrgLinkedToJanison}/>
    };

    const myClassesTab = {
      urlName: 'myClasses',
      color: colors.LEARNERS,
      tabText: content.tabs_myClasses_text,
      panelContent:
        !profileRole || profileRole === 'USER' ? (
          // Learner with no org
          <div style={{ textAlign: 'center' }}>
            <div className="pad-top4">
              <div className="row cols-center">
                <ContentWithThumbnail
                  size={ThumbnailSizes.HEADING}
                  glyph={GLYPHS.ICON_CLASS}
                  backgroundColor={colors.CLASSROOM}
                />
              </div>

              <PageHeading title={content.no_classes_title} subtitle={content.no_classes_subtitle} />

              <div style={{ flexWrap: 'wrap' }} className="pad3 row cols-center">
                <Feature
                  name="join-a-class"
                  render={() => (
                    <div className="pad1">
                      <Button
                        id="joinClassBtn"
                        type={buttonTypes.PRIMARY}
                        text={content.button_join_a_class}
                        to={safePanelLink('join-a-class')}
                      />
                    </div>
                  )}
                />
                <div className="pad1">
                  <Button
                    id="createOrgBtn"
                    type={buttonTypes.PRIMARY}
                    text={content.button_register_org}
                    to="/org/register"
                  />
                </div>
                <div className="pad1">
                  <Button
                    id="learnMoreBtn"
                    type={buttonTypes.SECONDARY}
                    text={content.button_learn_more_text}
                    onClick={() => this.setState({ showPopup: true })}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <SearchInitialiser
            searchSource="profileClasses"
            initialFilters={{
              userId,
              orgId,
              active: true,
              isProfileClassTab: true
            }}
          >
            <MyProfileClassSearch />
          </SearchInitialiser>
        )
    };

    let items = [myClassesTab, learningMaterialTab];
    if (isOicMode() && isAtLeastTeacher && !isOicWithoutSchool) {
      items = [oicAssessmentsTab, myProductsTab];
    } else if (isEltAssessmentMode() || isOrbWithNoSubscriptions || isOicMode()) {
      items = [];
    } else if (isStudentTabSwitch) {
      items = [learningMaterialTab, myClassesTab];
    }

    const gradebookItem = {
      urlName: 'gradebook',
      tabText: 'My progress',
      panelContent: <Gradebook params={params} location={location} myProgress />
    };

    const showGradebookTab = featureIsEnabled('olb-my-account-gradebook-uis') && !isOrbMode() && isShowGBTab;
    const tabItems = showGradebookTab ? items.concat(gradebookItem) : items;

    const contentList = getLearnMoreContent(platformNewsContent);

    let editUserURL = safePanelLink('editUser');
    if (isEltAssessmentMode()) {
      editUserURL = '/myProfile/myClasses/editUser';
    } else if (params.tabName === 'gradebook') {
      editUserURL = '/myProfile/gradebook/editUser';
    }

    return (
      <div style={{ padding: '0 0 4rem' }}>
        <Helmet title={content.pageTitle} />
        <div className="color-profile-dark">
          {profileLoading ? (
            <SkeletonLoader loadingResults={profileLoading} />
          ) : (
            <EntityPageHeader
              entityType={ENTITY_TYPES.PROFILE}
              entityTitle={profileName}
              {...(!this.isManagedUser
                ? {
                    entitySubtitle: profileUsername,
                    editButtonId: 'myProfileEditButton',
                    editButtonTo: editUserURL,
                    editButtonText: content.editUser_trigger_button_text,
                    displayEditButton: true
                  }
                : {})}
            />
          )}
        </div>
        {this.isManagedUser ? (
          tabItems[1].panelContent
        ) : (
          <UrlControlledTabs
            items={tabItems}
            tabName={params.tabName}
            pathname={location.pathname}
            backgroundColor={colors.PROFILE}
          />
        )}

        {isOrbWithNoSubscriptions || isOicWithoutSchool ? (
          <div className="pad4 grid" style={{ textAlign: 'center' }}>
            <div className="row cols-center">
              <ContentWithThumbnail
                size={ThumbnailSizes.HEADING}
                glyph={GLYPHS.ICON_LEARNING_MATERIAL}
                backgroundColor={colors.COURSE}
              />
            </div>

            {isOrbWithNoSubscriptions && (
              <PageHeading
                title={content.no_subscriptions_page_title}
                subtitle={content.no_subscriptions_page_subtitle}
              />
            )}

            {isOicWithoutSchool && (
              <PageHeading
                title={content.no_oic_organizations_page_title}
                subtitle={content.no_oic_organizations_page_subtitle}
              />
            )}
          </div>
        ) : null}

        <MyProfileEditPanel
          isOpen={panelName === 'editUser' && !identity.missingFields.includes('tsAndCsAccepted')}
          closePanel={() => history.push(safePanelUnlink('editUser'))}
        />
        {/* Information Popup Modal */}
        {(!socialIdpIsEnabled(getCurrentPlatform()) || !identity.missingFields.includes('tsAndCsAccepted')) &&
          ((!informationPopupViewed && !isOrbMode()) || showPopup) && (
            <div>
              <InformationPopupModal
                negativeClick={() => {
                  this.setState({ showPopup: false });
                  setInformationPopupViewedAction(true);
                  document.getElementById('informationPopupModalClose').focus();
                }}
                displayAFinalPanel
                finalPanelTitle={platformNewsContent.learn_more_content_final_page_title}
                finalPanelSubtitle={platformNewsContent.learn_more_content_final_page_subtitle}
                firstSuggestionButton={platformNewsContent.learn_more_content_final_page_button1_path}
                firstSuggestionButtonText={platformNewsContent.learn_more_content_final_page_button1_text}
                secondSuggestionButtonText={platformNewsContent.learn_more_content_final_page_button2_text}
                secondSuggestionButton={platformNewsContent.learn_more_content_final_page_button2_path}
                contentList={contentList}
              />
            </div>
          )}
        <div>
          <Feature
            name="join-a-class"
            render={() => (
              <JoinAClass
                id="joinAClassPopout"
                ariaLabel={content.aria_label_add_students}
                isOpen={panelName === 'join-a-class'}
                closePanel={() => history.push(safePanelUnlink('join-a-class'))}
                onComplete={() => history.push(safePanelUnlink('join-a-class'))}
              />
            )}
          />
        </div>
      </div>
    );
  }
}

MyProfile.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
  orgId: PropTypes.string.isRequired,
  isPrimarySchool: PropTypes.bool.isRequired,
  isOicSchool: PropTypes.string,
  userId: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  profileUsername: PropTypes.string.isRequired,
  profileRole: PropTypes.string,
  profileLoading: PropTypes.bool,
  identity: PropTypes.object,
  history: PropTypes.object.isRequired,
  informationPopupViewed: PropTypes.bool.isRequired,
  setInformationPopupViewedAction: PropTypes.func.isRequired,
  localizedContent: PropTypes.object,
  getUserDetailsAction: PropTypes.func.isRequired,
  initialiseSearchInstance: PropTypes.func.isRequired,
  productServicesRequest: PropTypes.func.isRequired,
  subscriptions: PropTypes.func.isRequired,
  initGetProductServices: PropTypes.func,
  profileAssignments: PropTypes.object,
  productServicesResult: PropTypes.object,
  orgDetails: PropTypes.object
};

export default compose(
  withLocalizedContent('myProfilePage', 'learnMoreFirstVisit'),
  withRouter,
  connect(
    state => {
      const currentUser = getCurrentUser(state);
      const orgId = state.identity.currentOrganisationId;
      const subscriptions = state.subscriptions;
      const identity = state.identity;

      return {
        orgId,
        subscriptions,
        identity,
        isPrimarySchool:
          state.organisations.data && (state.organisations.data[orgId] || {}).role === orgRoles.PRIMARY_SCHOOL,
        isOicSchool: state.organisations.data && (state.organisations.data[orgId] || {}).role === orgRoles.OIC_SCHOOL,
        userId: state.identity.userId,
        profileName: `${currentUser.firstName || currentUser.firstname} ${currentUser.lastName ||
          currentUser.lastname}`,
        profileUsername: currentUser.username || currentUser.userName,
        profileRole: state.identity.role,
        profileLoading: !currentUser,
        informationPopupViewed: state.app.informationPopupViewed,
        productServicesResult: state.productServices,
        profileAssignments: state.search.profileAssignments || {},
        orgDetails: state.orgDetails.data || {}
      };
    },
    {
      initialiseSearchInstance: initialiseInstance,
      productServicesRequest: getProductServicesRequest,
      initGetProductServices: resetProductServices,
      getUserDetailsAction: getUserDetails,
      setInformationPopupViewedAction: setInformationPopupViewed,
      getOrgDetails: actions.getOrgRequest
    }
  )
)(MyProfile);
