import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import pick from 'lodash.pick';
import LearnerProgress from '../../components/LearnerProgress/LearnerProgress';
import flipSortDirection from '../../components/GradebookTable/flipSortDirection';
import actions from '../../redux/actions';
import { loadSavedSettings, storeSavedSettings } from '../../redux/reducers/savedSettings';
import content from '../../utils/cmsContent';

class LearnerProgressPage extends Component {
  componentDidMount() {
    const { myProgress, params, loadSavedSettingsAction, loadGradebookDetailsAction, sectionUid } = this.props;
    const loadGradebookDetailsParams = myProgress ? { ...params, myProgress } : params; // To unblock testing...
    loadSavedSettingsAction();
    loadGradebookDetailsAction(sectionUid, 'learner', loadGradebookDetailsParams);
  }

  componentDidUpdate(prevProps) {
    const { myProgress, params, loadGradebookDetailsAction, sectionUid } = this.props;
    const loadGradebookDetailsParams = myProgress ? { ...params, myProgress } : params; // To unblock testing...
    if (params.learnerId !== prevProps.params.learnerId) {
      loadGradebookDetailsAction(sectionUid, 'learner', loadGradebookDetailsParams);
    }
  }

  componentWillUnmount() {
    const { gradebookDetailsLoadingAction } = this.props;
    gradebookDetailsLoadingAction();
  }

  static setQueryString = key => {
    const newState = {
      sortKey: key,
      sortDirection: flipSortDirection(key)
    };
    window.history.pushState(newState, 'Sorting', `?${qs.stringify(newState)}${window.location.hash}`);
  };

  loadGradebookLearner = () => {
    const { myProgress, params, loadGradebookDetailsAction, sectionUid } = this.props;
    const loadGradebookDetailsParams = myProgress ? { ...params, myProgress } : params;
    return loadGradebookDetailsAction(sectionUid, 'learner', loadGradebookDetailsParams);
  };

  switchAttempt = attemptFilter => {
    const { myProgress, params, storeSavedSettingsAction, loadGradebookDetailsAction, sectionUid } = this.props;
    const loadGradebookDetailsParams = myProgress ? { ...params, myProgress } : params; // To unblock testing...
    storeSavedSettingsAction({ attemptFilter });
    loadGradebookDetailsAction(sectionUid, 'learner', loadGradebookDetailsParams);
  };

  filterStudent = (people, classDetails) => {
    const { params: classroomId } = this.props;
    return classDetails.data[classroomId]?.studentIdList.reduce(
      (filteredPeople, studentId) => Object.assign(filteredPeople, pick(people, studentId)),
      {}
    );
  };

  render() {
    const {
      learnerId,
      myProgress,
      gradebookLoading,
      learnerProgressData,
      learnerProgressUsers,
      attemptFilter,
      classTitle,
      hierarchy,
      learnerEmail,
      productTitle = 'Product',
      cmsContent = content.learnerProgressPage || {},
      classDetails,
      params,
      role
    } = this.props;
    const { sortKey = 'none', sortDirection = 'none' } = qs.parse(window.location.search);

    return (
      <LearnerProgress
        role={role}
        key={learnerId}
        learnerId={learnerId}
        data={learnerProgressData}
        users={myProgress ? [] : this.filterStudent(learnerProgressUsers, classDetails)}
        myProgress={myProgress}
        switchAttempt={this.switchAttempt}
        attemptFilter={attemptFilter}
        tableLoading={gradebookLoading}
        classTitle={classTitle}
        hierarchy={hierarchy}
        learnerEmail={learnerEmail}
        productTitle={productTitle}
        classDetails={classDetails}
        sortOnChange={key => LearnerProgressPage.setQueryString(key)}
        sortKey={sortKey}
        sortDirection={sortDirection}
        cmsContent={cmsContent}
        params={params}
        loadGradebookLearner={this.loadGradebookLearner}
      />
    );
  }
}

LearnerProgressPage.propTypes = {
  params: PropTypes.object.isRequired,
  sectionUid: PropTypes.string.isRequired,
  learnerId: PropTypes.string,
  myProgress: PropTypes.bool.isRequired,
  gradebookLoading: PropTypes.bool.isRequired,
  learnerProgressData: PropTypes.object.isRequired,
  learnerProgressUsers: PropTypes.object.isRequired,
  attemptFilter: PropTypes.string.isRequired,
  loadSavedSettingsAction: PropTypes.func.isRequired,
  storeSavedSettingsAction: PropTypes.func.isRequired,
  loadGradebookDetailsAction: PropTypes.func.isRequired,
  gradebookDetailsLoadingAction: PropTypes.func.isRequired,
  classTitle: PropTypes.string,
  hierarchy: PropTypes.string.isRequired,
  learnerEmail: PropTypes.string,
  productTitle: PropTypes.string,
  cmsContent: PropTypes.object,
  classDetails: PropTypes.object,
  role: PropTypes.string
};

LearnerProgressPage.defaultProps = {
  classTitle: 'Class'
};

export default connect(
  (state, { params }) => ({
    learnerId: params.learnerId,
    learnerEmail: (state.people.data[params.learnerId] || {}).email,
    gradebookLoading: state.gradebookClassReport.loading,
    learnerProgressData: state.people.data[params.learnerId],
    learnerProgressUsers: state.people.data,
    hierarchy: state.gradebookClassReport.hierarchy,
    attemptFilter: state.savedSettings.settings.attemptFilter,
    productTitle: (state.gradebookClassReport || {}).selectedProduct,
    classDetails: state.classrooms
  }),
  {
    loadSavedSettingsAction: loadSavedSettings,
    storeSavedSettingsAction: storeSavedSettings,
    loadGradebookDetailsAction: actions.gradebookClassReportRequest,
    gradebookDetailsLoadingAction: actions.gradebookClassReportLoading
  }
)(LearnerProgressPage);
