import gt from 'lodash.gt';

/**
 * Return a comparable numeric representation of an Oxford Reading Level.
 *
 * @param {string} level
 *
 * @return {number}
 */
export function getNumericLevel(level) {
  // eslint-disable-next-line prefer-template
  return parseFloat(level.includes('+') ? level.slice(0, -1) + '.5' : level, 10);
}

/**
 * Determine if the given Oxford Reading Level is greater than another.
 *
 * @param {string} level
 * @param {string} other
 *
 * @return {boolean}
 */
export function isLevelGreaterThan(level, other) {
  return gt(...[level, other].map(getNumericLevel));
}
