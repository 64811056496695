import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch';
import ClassCode from '../../components/ClassCode/ClassCode';
import { setClassCode, setIsClassCodeEnable } from '../../redux/reducers/classroomEdit';
import withLocalizedContent from '../../language/withLocalizedContent';

import styles from './ClassCodeInput.scss';

function ClassCodeInput({
  isClassCodeEnable,
  isClassCodeEditable = true,
  setClassCodeToggle,
  customOrgId,
  classJoiningCode,
  setClassCodeChange,
  validationParams,
  localizedContent: { classCodeComponent }
}) {
  const displayJoiningCode = {
    customOrgId,
    classJoiningCode
  };
  return (
    <>
      <h3>{classCodeComponent.class_code}</h3>
      <div className={styles.classCodeInput}>
        <div className={styles.customiseToggle}>
          <ToggleSwitch id="class-code-toggle" value={isClassCodeEnable} onChange={setClassCodeToggle} />
        </div>
        <ClassCode
          isClassCodeEditable={isClassCodeEditable}
          classJoiningCode={displayJoiningCode}
          isToggleEnable={isClassCodeEnable}
          onCodeChange={setClassCodeChange}
          validationParams={validationParams}
        />
      </div>
    </>
  );
}

ClassCodeInput.propTypes = {
  isClassCodeEnable: PropTypes.bool.isRequired,
  isClassCodeEditable: PropTypes.bool,
  setClassCodeToggle: PropTypes.func.isRequired,
  classJoiningCode: PropTypes.string.isRequired,
  customOrgId: PropTypes.string.isRequired,
  setClassCodeChange: PropTypes.func.isRequired,
  validationParams: PropTypes.object,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('classCodeComponent'),
  connect(
    state => ({
      classJoiningCode: state.classroomEdit.joiningCode && state.classroomEdit.joiningCode.code,
      isClassCodeEnable: state.classroomEdit.joiningCode && state.classroomEdit.joiningCode.enabled,
      customOrgId: state.organisations.data[state.classroomPage.orgId]?.customId,
      validationParams: {
        classroomJoiningCodeValidationIsInvalid: state.classroomEdit.classroomJoiningCodeValidationIsInvalid,
        classroomJoiningCodeValidationMessage: state.classroomEdit.classroomJoiningCodeValidationMessage,
        classroomCodeValidationPending: state.classroomEdit.classroomCodeValidationPending,
        classroomJoiningCodeValidationIsvalid: state.classroomEdit.classroomJoiningCodeValidationIsvalid
      }
    }),
    {
      setClassCodeToggle: setIsClassCodeEnable,
      setClassCodeChange: setClassCode
    }
  )
)(ClassCodeInput);
