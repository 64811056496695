import { put, call, race, delay } from 'redux-saga/effects';

import unsignedFetch from '../../../apiCalls/util/unsignedFetch.js';
import constants from '../../../../../components/RvsForm/constants';

import actions from '../../../../actions';
import * as t from '../../../../actionTypes';

import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import rvsFormSchema from '../../../../../../static/schema/draft-4/contact-us-submission-OLB-request-body.json';
import getRVSApiBase from '../../../utils/rvs/getRVSApiBase';
import { addToValidCertChannel, addToInvalidCertChannel } from '../../../utils/rvs/certificateChannels';

function* submit(data) {
  const { SUBMIT_TIMEOUT } = constants;
  const apiBase = getRVSApiBase();
  const payload = {
    ...data
  };

  const errors = yield validateInputUsingSchema(rvsFormSchema, t.RVS_FORM_VALIDATE_COMPLETED, payload);
  const hasErrors = Object.values(errors).includes(true);

  if (hasErrors) {
    return;
  }

  const { response } = yield race({
    response: call(unsignedFetch, '', `${apiBase}/open/rvs-certificate-manual`, 'POST', payload),
    timeout: delay(SUBMIT_TIMEOUT)
  });

  if (!response) {
    yield put(actions.rvsFormReset());
  } else if (response.valid) {
    addToValidCertChannel(response.certificate);
    yield put(actions.rvsFormSuccess());
  } else if (response.verification_unavailable) {
    yield put(actions.rvsUnavailableApi());
  } else if (response.isBot) {
    yield put(actions.rvsFormBot());
  } else {
    addToInvalidCertChannel(response.certificate || payload);
    yield put(actions.rvsFormFailure());
  }
}

export default submit;
