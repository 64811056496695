import { call, spawn } from 'redux-saga/effects';
import appFunctions from './appFunctions/appFunctions.saga.js';
import userProfile from './appFunctions/authorisedFunctions/user/userProfile.saga.js';
import bindFormHandlers from './appFunctions/formHandlers/bindFormHandlers.saga.js';
import contactUsRootSaga from './appFunctions/unauthorisedFunctions/contactUs';
import rvsUploadSaga from './appFunctions/unauthorisedFunctions/rvsUpload';
import rvsFormSaga from './appFunctions/unauthorisedFunctions/rvsForm';
import progressBarNavigationCheckSaga from './appFunctions/unauthorisedFunctions/progressBarNavigationCheck';
import contentPreviewSaga from './appFunctions/unauthorisedFunctions/contentPreview/index';
import coursePreviewSaga from './appFunctions/authorisedFunctions/coursePreview/index';
import deleteProductSaga from './appFunctions/authorisedFunctions/deleteProduct/index';
import registrationSaga from './appFunctions/unauthorisedFunctions/registration/registration.saga.js';
import supportSearchSaga from './appFunctions/unauthorisedFunctions/support/supportSearch.saga.js';
import postMessageRootSaga from './messaging/postMessage.saga.js';
import activationCodeDashboardSaga from './appFunctions/unauthorisedFunctions/activationCodes/index.js';
import productDashboardSaga from './appFunctions/unauthorisedFunctions/product/index.js';
import userConsoleSaga from './appFunctions/unauthorisedFunctions/userConsole/index.js';
// eslint-disable-next-line import/no-cycle
import initialiseAuth from './preRender/auth/initialiseAuth.saga.js';
import preRender from './preRender/preRender.saga.js';
import fetchProductsSaga from './appFunctions/unauthorisedFunctions/ngi/getOpenProducts.saga.js';
import fetchLinkedProductsSaga from './appFunctions/unauthorisedFunctions/ngi/getLinkedProducts.saga.js';
import inspectionCopy from './appFunctions/unauthorisedFunctions/inspectionCopy';
import fetchJwtAndDeeplinkReturnUrlSaga from './appFunctions/unauthorisedFunctions/ngi/getJwtAndDeeplinkReturnUrl.saga.js';

export default function* appSaga() {
  if (window.self !== window.top && window.location.search.indexOf('auth-refresh') >= 0) {
    console.log('[AppSaga] Running auth code only');
    yield call(initialiseAuth);
  } else {
    // Logo git SHA version to console
    console.log('SHA version', __SHA_VERSION__);
    // Start the registration sga here as it needs to be run before the auth kicks in
    // so that it can handle partially registered users
    console.groupCollapsed('[looseSagas]');
    yield spawn(registrationSaga);
    yield spawn(supportSearchSaga);
    yield spawn(contactUsRootSaga);
    yield spawn(rvsUploadSaga);
    yield spawn(rvsFormSaga);
    yield spawn(progressBarNavigationCheckSaga);
    yield spawn(activationCodeDashboardSaga);
    yield spawn(userConsoleSaga);
    yield spawn(contentPreviewSaga);
    yield spawn(coursePreviewSaga);
    yield spawn(deleteProductSaga);
    yield spawn(bindFormHandlers);
    yield spawn(userProfile);
    yield spawn(postMessageRootSaga);
    yield spawn(productDashboardSaga);
    yield spawn(fetchProductsSaga);
    yield spawn(fetchLinkedProductsSaga);
    yield spawn(inspectionCopy);
    yield spawn(fetchJwtAndDeeplinkReturnUrlSaga);

    console.groupEnd('[looseSagas]');

    // Deal with stuff required to render the page
    yield preRender();

    // Begin app functionality
    yield call(appFunctions);

    // This should never be reached
  }
}
