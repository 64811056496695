import { put, select, all, takeLatest } from 'redux-saga/effects';
import { pollStartAction, pollStopAction } from '../../../../reducers/polling.reducer';
import generateSignInCard from '../../../../../globals/generateSignInCard';
import getUserQueueApi from '../../../apiCalls/getUserQueueApi';
import {
  DOWNLOAD_SIGNIN_CARD_PROCESS_POLLING_TRIGGER,
  DOWNLOAD_SIGN_IN_CARD,
  DOWNLOAD_SIGNIN_CARD_PROCESS_START,
  downloadSigninCardProcessSuccess,
  downloadSigninCardProcessFail,
  downloadSigninCard,
  downloadSigninCardProcessPolling,
  addCountIteration,
  DOWNLOAD_ERROR_DETAILS
} from '../../../../reducers/downloadSigninCardProcess.reducer';
import userRoles from '../../../../../globals/userRoles';
import { SIGN_IN_CARD_USER_STATUS } from '../../../../../globals/appConstants';
import downloadAsFile from '../../../../../utils/downloadAsFile';
import content from '../../../../../utils/cmsContent.js';

const { Parser } = require('json2csv');
const moment = require('moment');

const customErrorMessages = [{ errorCode: 2026, errorMessage: 'This username already exists' }];

function* downloadCard() {
  const { activeLearners, archivedLearners, errorLearners } = yield select(state => ({
    activeLearners: state.downloadSigninCardProcess.activeLearners,
    archivedLearners: state.downloadSigninCardProcess.archivedLearners,
    errorLearners: state.downloadSigninCardProcess.errorLearners
  }));
  yield generateSignInCard({ successUsers: activeLearners, archiveUsers: [...archivedLearners, ...errorLearners] });
}

function populateCustomErrorMessage(errorMsg) {
  let msg = errorMsg;
  const errorObject = customErrorMessages.filter(err => err.errorCode === parseInt(errorMsg.slice(2, 6), 10));
  if (errorObject.length) {
    msg = errorObject[0].errorMessage;
  }
  return msg;
}

function* downloadErrorDetailsCSV() {
  const { errorLearners } = yield select(state => ({
    errorLearners: state.downloadSigninCardProcess.errorLearners
  }));
  const json2csvParser = new Parser({ quote: '' });
  const csvStudentsList = [];
  const customId = errorLearners[0].userName.split('_')[0];
  errorLearners.forEach(user => {
    const obj = {};
    obj[content.downloadSigninCardProcess.first_name] = user.firstName;
    obj[content.downloadSigninCardProcess.last_name] = user.lastName;
    obj[content.downloadSigninCardProcess.user_name] = user.userName.split('_')[1];
    obj[content.downloadSigninCardProcess.password] = user.password;
    obj[content.downloadSigninCardProcess.year_group] = user.yearGroup;
    obj[content.downloadSigninCardProcess.class] = user.class ? user.class : '';
    obj[content.downloadSigninCardProcess.status] = user.status;
    obj[content.downloadSigninCardProcess.message] = populateCustomErrorMessage(user.message);
    csvStudentsList.push(obj);
  });
  const csvContent = json2csvParser.parse(csvStudentsList);
  const timestamp = moment().format('YYYYMMDD');
  const fileName = `${customId}_failure_${timestamp}.csv`;
  downloadAsFile(csvContent, fileName, 'text/csv');
}

function* processHandler(orgId) {
  const { batchId, totalRecords, error, completedRecords } = yield select(state => ({
    batchId: state.downloadSigninCardProcess.batchId,
    totalRecords: state.downloadSigninCardProcess.totalRecords,
    error: state.downloadSigninCardProcess.error,
    completedRecords: state.downloadSigninCardProcess.completedRecords
  }));
  let { iterationCount } = yield select(state => ({
    iterationCount: state.downloadSigninCardProcess.iterationCount
  }));
  const res = yield getUserQueueApi(orgId, batchId);
  const isAllUsersAvailable = res.data.length === parseInt(totalRecords, 10);

  // If total records and response data length are equal for 2 consecutive calls it will stop polling.
  if (completedRecords === res.data.length) {
    yield put(addCountIteration((iterationCount += 1)));
  } else {
    yield put(addCountIteration(0));
  }

  if (res.status !== 'success') {
    yield put(downloadSigninCardProcessFail());
  } else if ((res.status === 'success' && isAllUsersAvailable) || iterationCount > 2) {
    const activeLearners = res.data.filter(user => user.status === SIGN_IN_CARD_USER_STATUS.ACTIVE);
    const archivedLearners = res.data.filter(user => user.status === SIGN_IN_CARD_USER_STATUS.ARCHIVED);
    const errorLearners = res.data.filter(user => user.status === SIGN_IN_CARD_USER_STATUS.ERROR);
    yield all([
      put(pollStopAction()),
      put(downloadSigninCardProcessSuccess(activeLearners, archivedLearners, errorLearners, activeLearners.length)),
      put(downloadSigninCard())
    ]);
  } else if (!error) {
    yield put(downloadSigninCardProcessPolling(res.data.length));
  }
}

function* processStart() {
  const { role, orgRoleMap } = yield select(state => ({
    role: state.identity.role,
    orgRoleMap: state.identity.orgRoleMap
  }));

  const splitPath = window.location.pathname.split('/');
  const currentOrgId = Object.keys(orgRoleMap).filter(orgId => orgId === splitPath[2]);
  const isValidOrgAndRole =
    currentOrgId.length > 0
      ? [userRoles.TEACHER_ADMIN, userRoles.ORG_ADMIN].includes(orgRoleMap[currentOrgId[0]])
      : false;
  const isOupAdminOrOupSupportRole = [userRoles.OUP_ADMIN, userRoles.OUP_SUPPORT].includes(role);
  const isValidationFailed = !(isOupAdminOrOupSupportRole || isValidOrgAndRole);
  if (isValidationFailed) {
    yield put(downloadSigninCardProcessFail());
  } else {
    yield processHandler(splitPath[2]);
  }
}

function* pollingStart() {
  yield all([processStart(), put(pollStartAction('DOWNLOAD_SIGNIN_CARD_PROCESS'))]);
}
export default function* triggerDownloadSigninCardProcess() {
  yield all([
    takeLatest(DOWNLOAD_SIGNIN_CARD_PROCESS_POLLING_TRIGGER, pollingStart),
    takeLatest(DOWNLOAD_SIGNIN_CARD_PROCESS_START, processStart),
    takeLatest(DOWNLOAD_SIGN_IN_CARD, downloadCard),
    takeLatest(DOWNLOAD_ERROR_DETAILS, downloadErrorDetailsCSV)
  ]);
}
