import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../ScrollContainer/ScrollContainer';

function SendInvitationReminderProcessing({
  localizedContent: { sendInvitationReminderProcessingComponent: content }
}) {
  return (
    <ScrollContainer>
      <PopoutPanelIconHeading {...pick(content, ['title', 'subtitle'])} type={popoutPanelIconHeadingTypes.LOADING} />
    </ScrollContainer>
  );
}

SendInvitationReminderProcessing.propTypes = { localizedContent: PropTypes.object.isRequired };
export default withLocalizedContent('sendInvitationReminderProcessingComponent')(SendInvitationReminderProcessing);
