import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import createLMSConfigApi from '../../../apiCalls/lmsConfig/createLMSConfigApi';

function* createLMSConfig(orgId, toolId = '', data) {
  const response = yield createLMSConfigApi(orgId, toolId, data);

  if (response && response.status === 'success') {
    yield put(actions.createLMSConfigSuccess(response.data));
  } else {
    yield put(actions.createLMSConfigFailure(response.message));
  }
}

export default createLMSConfig;
