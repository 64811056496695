import classnames from 'classnames';
import isEmpty from 'lodash.isempty';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import MaterialRepresentation from '../../components/MaterialRepresentation/MaterialRepresentation';
import ProductSelection from '../../components/ProductSelection/ProductSelection';
import PieChartProgressDetails from '../../components/PieChartProgressDetails/PieChartProgressDetails';
import ProgressBar, { progressTypes } from '../../components/ProgressBar/ProgressBar';
import SubSectionSkeletonLoader from '../../components/SkeletonLoader/SubSectionSkeletonLoader';
import { SIZES as thumbnailSizes } from '../../components/Thumbnail/Thumbnail';
import actions from '../../redux/actions';
// @ts-ignore
import readersCollection from './readersCollection.png';
import withLocalizedContent from '../../language/withLocalizedContent';
import withLocalizedErrors from '../../language/withLocalizedErrors';
import { featureIsEnabled } from '../../globals/envSettings';
import { isHubMode } from '../../utils/platform.js';
import styles from '../../components/ProductSelection/ProductSelection.scss';
import colors from '../../globals/colors';
import { ALIGNMENT } from '../../globals/appConstants';

class ProductSelectionPage extends Component {
  componentDidMount() {
    const { myProgress, params, identity, workbookRequestAction, gradedReadersRequestAction } = this.props;
    console.log('[ProductSelectionPage] Initialising page');
    const propParams = myProgress ? { ...params, myProgress, userId: identity?.userId } : params; // To unblock testing...
    workbookRequestAction('', 'products', propParams);
    // Apply feature flag and check classroom id for graded readers API call to server.
    if (featureIsEnabled('olb-gradebook-graded-readers') && params.classroomId) {
      gradedReadersRequestAction('products', propParams);
    }
  }

  componentDidUpdate(prevProps) {
    const { myProgress, params, identity, workbookRequestAction, gradedReadersRequestAction } = this.props;
    if (isHubMode()) {
      const propParams = myProgress ? { ...params, myProgress, userId: identity?.userId } : params; // To unblock testing...
      if (prevProps.params.classroomId !== params.classroomId) {
        workbookRequestAction('', 'products', propParams);
        gradedReadersRequestAction('products', propParams);
      }
    }
  }

  componentWillUnmount() {
    const { workbookLoadingAction, gradedReadersLoadingAction } = this.props;
    workbookLoadingAction();
    gradedReadersLoadingAction();
  }

  get _localizedContent() {
    const { localizedContent } = this.props;
    return localizedContent.productSelectionPage;
  }

  _formatWorkbookProductRows = data => {
    const { role, workbook, assignSelectedProduct, myProgress } = this.props;
    if (!Object.values(data).length) return [];

    return Object.entries(data).map(([productId, product], index) => ({
      id: `workbookRow${index}`,
      cells: [
        <MaterialRepresentation
          key="cell-rep"
          thumbnailSize={thumbnailSizes.TABLE}
          textAlignment={ALIGNMENT.LEFT}
          imageSrc={product.url || null}
          linkTo={product.totalNumberOfActivities ? `${window.location.pathname}/workbook/${productId}` : ''}
          name={product.name}
          deletedStyle={!product.productScoreAvailable}
          onClick={() => assignSelectedProduct(product.name)}
        />,
        <div key="cell-com" className={styles.progressContainer}>
          <PieChartProgressDetails
            completed={
              myProgress
                ? product.submittedNumberOfActivities
                : product.submittedNumberOfActivities / workbook.studentsInClass
            }
            total={product.totalNumberOfActivities}
            content={this._localizedContent}
            customClassName={styles.gradebookProgressPieChart}
            displayShortLabelOnMobile
          />
        </div>,
        <p key="cell-sco" className={styles.smallText}>
          <ProgressBar
            role={role}
            percentage={
              product.productScoreAvailable ? (product.productScoreAchieved / product.productScoreAvailable) * 100 : 0
            }
            type={progressTypes.SECONDARY}
            color={colors.CORRECT}
            ariaLabelText="Average score"
            label={numeral(product.productScoreAchieved / product.productScoreAvailable).format('0%')}
            content={this._localizedContent}
            displayShortLabelOnMobile
          />
        </p>
      ]
    }));
  };

  _formatGradedReadersProductRows = data => {
    if (!Object.values(data).length || isEmpty(data.products)) return [];

    return [
      {
        id: 'gradedReadersRow',
        cells: [
          <MaterialRepresentation
            key="cell-rep"
            thumbnailSize={thumbnailSizes.TABLE}
            textAlignment={ALIGNMENT.LEFT}
            imageSrc={readersCollection}
            linkTo={`${window.location.pathname}/reader`}
            name={this._localizedContent.grade_reader_title_text}
          />,
          <div key="cell-bks" className={styles.books}>
            <p className={styles.bigText}>
              {parseFloat(data.averageProductsCompleted).toFixed(1)}
              <span className={styles.booksText}>books</span>
            </p>
          </div>,
          <p key="cell-lvl" className={styles.bigText}>
            {data.mostReadLevel}
          </p>
        ]
      }
    ];
  };

  // eslint-disable-next-line class-methods-use-this
  _isProductAvailable = data => !isEmpty(data.products);

  render() {
    const { workbook, gradedReaders, getLocalizedErrorMessage, params, myProgress } = this.props;
    const largeCompletionHeading = myProgress
      ? this._localizedContent.grade_reader_completion_text
      : this._localizedContent.grade_reader_average_completion_text;
    const largeScoreHeading = myProgress
      ? this._localizedContent.grade_reader_score_text
      : this._localizedContent.grade_reader_average_score_text;

    const workbookLoad = this._isProductAvailable(workbook) ? (
      <ProductSelection
        products={this._formatWorkbookProductRows(workbook.products)}
        heading={this._localizedContent.course_book_title_text}
        localizedContent={this._localizedContent}
        largeCompletionHeading={largeCompletionHeading}
        largeScoreHeading={largeScoreHeading}
        shortCompletionText={this._localizedContent.grade_reader_short_completion_text}
      />
    ) : null;

    const gradedReadersLoad = () => {
      if (featureIsEnabled('olb-gradebook-graded-readers')) {
        return this._isProductAvailable(gradedReaders.products) ? (
          <ProductSelection
            products={this._formatGradedReadersProductRows(gradedReaders.products)}
            gradedReaders
            heading={this._localizedContent.grade_reader_title_text}
            localizedContent={this._localizedContent}
          />
        ) : null;
      }
      return null;
    };

    return (
      <div className={classnames('grid', styles['product-selection-page'])}>
        {!isHubMode() && (workbook.failure || gradedReaders.failure) ? (
          <p className={classnames('row', styles.message)}>
            {(workbook.errors && workbook.errors.error && getLocalizedErrorMessage(workbook.errors.error)) ||
              (gradedReaders.errors &&
                gradedReaders.errors.error &&
                getLocalizedErrorMessage(gradedReaders.errors.error)) ||
              'There is a problem which means we cannot load this data at the moment. Please try again later or contact Customer Support for help.'}
            {/* TODO: Need a better handling */}
          </p>
        ) : null}

        {workbook.loading ? (
          <SubSectionSkeletonLoader
            speed={2}
            tabName={params.tabName}
            foregroundColor={colors.COLOR_GREY_DISABLED}
            backgroundColor={colors.COLOR_WHITE}
          />
        ) : (
          workbookLoad
        )}
        {featureIsEnabled('olb-gradebook-graded-readers') && gradedReaders.loading && params.classroomId ? (
          <SubSectionSkeletonLoader
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED}
            backgroundColor={colors.COLOR_WHITE}
          />
        ) : (
          gradedReadersLoad()
        )}
      </div>
    );
  }
}

ProductSelectionPage.propTypes = {
  params: PropTypes.object.isRequired,
  myProgress: PropTypes.bool,
  identity: PropTypes.object,
  workbook: PropTypes.object.isRequired,
  gradedReaders: PropTypes.object.isRequired,
  workbookRequestAction: PropTypes.func.isRequired,
  workbookLoadingAction: PropTypes.func.isRequired,
  gradedReadersRequestAction: PropTypes.func.isRequired,
  gradedReadersLoadingAction: PropTypes.func.isRequired,
  assignSelectedProduct: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  getLocalizedErrorMessage: PropTypes.func,
  role: PropTypes.string
};

export default compose(
  withLocalizedContent('productSelectionPage'),
  withLocalizedErrors('eps-product-selection-page'),
  connect(
    state => ({
      workbook: state.gradebookClassReport,
      gradedReaders: state.results.gradedReaders
    }),
    {
      workbookRequestAction: actions.gradebookClassReportRequest,
      workbookLoadingAction: actions.gradebookClassReportLoading,
      gradedReadersRequestAction: actions.gradedReadersResultsRequest,
      gradedReadersLoadingAction: actions.gradedReadersResultsLoading,
      assignSelectedProduct: actions.gradebookAssignSelectedProduct
    }
  )
)(ProductSelectionPage);
