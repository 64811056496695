import pick from 'lodash.pick';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withLocalizedContent from '../../language/withLocalizedContent';
import ActionList, { Action } from '../ActionList/ActionList';
import UserAgreement from '../UserAgreement/UserAgreement';
import ControlledForm from '../ControlledForm/ControlledForm';
import PersonRepresentation from '../PersonRepresentation/PersonRepresentation';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import TextInput from '../TextInput/TextInput';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import { SIZES as THUMBNAIL_SIZES } from '../Thumbnail/Thumbnail';
import { USERNAME_MAX_LIMIT } from '../../globals/validations';

class ManagedUserChangeUsernameForm extends Component {
  // Store initial username value independent of form data. The form header
  // will not to need update during the lifetime of the component.
  constructor(props) {
    super(props);
    this.state = pick(this.props, 'username');
  }

  _getValidationStatus = () => {
    const {
      username,
      usernameTaken,
      usernameValidationLoading,
      errors,
      localizedContent: { managedUserChangeUsernamePanel: content }
    } = this.props;

    switch (true) {
      case usernameValidationLoading:
        return {
          type: 'waiting',
          isActive: true
        };
      case !username:
        return {
          isActive: true,
          message: content.input_username_error_default
        };
      case username.length > USERNAME_MAX_LIMIT:
        return {
          isActive: true,
          message: content.input_username_error_max_limit
        };
      case usernameTaken:
        return { isActive: true, message: content.input_username_error_unique };
      default:
        return errors.newUserName
          ? {
              isActive: true,
              message: content.input_username_error_allowed_characters
            }
          : { isActive: false };
    }
  };

  render() {
    const {
      localizedContent: { managedUserChangeUsernamePanel: content },
      firstName,
      lastName,
      username,
      usernameChanged,
      usernameValidationLoading,
      userLocked = false,
      errors = {},
      createChangeHandler,
      createBlurHandler,
      onSubmit
    } = this.props;
    const { username: usernameAlias } = this.state;
    const submitDisabled = !usernameChanged || usernameValidationLoading || Object.values(errors).some(Boolean);

    return (
      <ScrollContainer
        headerContent={
          <header className="gin-top2 gin-bot2">
            <PersonRepresentation
              thumbnailSize={THUMBNAIL_SIZES.MEDIUM}
              headerSizeName
              name={`${firstName} ${lastName}`}
              email={usernameAlias}
              locked={userLocked}
            />
          </header>
        }
        footerContent={
          <ActionList>
            <Action label={content.button_save_text} onClick={onSubmit} primary disabled={submitDisabled} />
          </ActionList>
        }
      >
        <div className="pad2">
          <h2>{content.title}</h2>
          <p>{content.title_detail}</p>
          <ControlledForm className="gin-top3">
            <ValidationStatus forId="newUserName" {...this._getValidationStatus()}>
              <TextInput
                id="newUserName"
                label={content.input_username_label}
                value={username}
                onChange={createChangeHandler('username')}
                onBlur={createBlurHandler('username')}
                required
              />
            </ValidationStatus>
            <UserAgreement termsAndConditions privacyPolicy />
          </ControlledForm>
        </div>
      </ScrollContainer>
    );
  }
}

ManagedUserChangeUsernameForm.propTypes = {
  localizedContent: PropTypes.object,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  usernameChanged: PropTypes.bool,
  usernameTaken: PropTypes.bool,
  usernameValidationLoading: PropTypes.bool,
  userLocked: PropTypes.bool,
  errors: PropTypes.object,
  createChangeHandler: PropTypes.func.isRequired,
  createBlurHandler: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default withLocalizedContent('managedUserChangeUsernamePanel')(ManagedUserChangeUsernameForm);
