import { API_VERSIONS, JSON_ENCODED } from '@oup/shared-node-browser/constants';
import signedFetch from '../util/signedFetch';

export default function* getXApiLaunchToken(params) {
  let URL = `${__API_BASE__}/player/launch/${params.activityId}?`;

  if (typeof params.contextCode !== 'undefined') {
    URL += `contextCode=${params.contextCode}`;
    if (typeof params.version !== 'undefined') {
      URL += `&`;
    }
  }

  if (typeof params.version !== 'undefined') {
    URL += `version=${params.version}`;
  }

  const headers = { 'Content-Type': JSON_ENCODED, Accept: API_VERSIONS.API_VERSION_0_2 };

  return yield signedFetch('getXApiLaunchToken', URL, 'POST', undefined, headers);
}
