import pick from 'lodash.pick';
import { put, select } from 'redux-saga/effects';
import { userEnrolValidateInputSuccess, userEnrolFailure, userEnrolSuccess } from '../../../../actions/userEnrol';
import registerManagedUser from '../../../api/registerManagedUser';
import { pollOrgUsersAdded } from '../dataRecency/pollOrgUsers';
import validateUserEnrolInput from './validateUserEnrolInput';
import userRoles from '../../../../../globals/userRoles';
import { featureIsEnabled } from '../../../../../globals/envSettings';

function* enrolUser(orgId, user) {
  const { customId } = yield select(state => state.organisations.data[orgId]);
  const { selectedClassroomIds } = yield select(state => state.organisationPage);
  const errors = yield validateUserEnrolInput(orgId, user);
  const body = { ...user, userName: `${customId}_${user.userName}` };
  if (featureIsEnabled('add-managed-user-to-class-on-creation')) {
    body.existingClassIds = selectedClassroomIds;
  }
  const hasErrors = Object.values(errors).some(Boolean);
  if (hasErrors) {
    yield put(userEnrolFailure());
    return;
  }
  yield put(userEnrolValidateInputSuccess());
  const response = yield registerManagedUser(orgId, body);
  if (response?.status?.toLowerCase() === 'success') {
    const { userId } = response.data;
    yield put(userEnrolSuccess(userId));
    const people = {
      [userId]: { ...{ roleName: userRoles.MANAGED_USER }, ...pick(response.data, ['userId', 'roleName']) }
    };
    yield pollOrgUsersAdded([userId], people);
    return;
  }
  yield put(userEnrolFailure());
}

export default enrolUser;
