import pick from 'lodash.pick';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { ErrorStatus, PageWrapper, PopoutPanelIconHeading } from '../../../components';
import actions from '../../../redux/actions';

import PageHeading from '../../../components/PageHeading/PageHeading';
import DeferEmailsForm from '../../../components/DeferEmailsForm/DeferEmailsForm';
import { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import DeferEmailsSuccess from '../../../components/DeferEmailsForm/DeferEmailsSuccess';

class EmailToolsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { orgId: '', userRole: '' };
  }

  _changeOrgHandler = value => {
    this.setState({ orgId: value });
  };

  _getDeferredEmails = userRole => {
    const { getDeferredEmails } = this.props;
    getDeferredEmails(userRole);
  };

  _relaseDeferredEmails = userRole => {
    const { relaseDeferredEmails } = this.props;
    const { orgId } = this.state;
    relaseDeferredEmails(orgId, userRole);
  };

  _reset = () => {
    const { reset } = this.props;
    this.setState({
      orgId: '',
      userRole: ''
    });
    reset();
  };

  _changeUserRole = userRole => {
    this.setState({ userRole });
    this._getDeferredEmails(userRole);
  };

  render() {
    const {
      localizedContent: { emailToolsPage: content },
      deferredEmailsOrgs,
      submitting,
      success,
      failure
    } = this.props;
    const { orgId, userRole } = this.state;

    return (
      <PageWrapper>
        <Helmet title={content.title} />
        <PageHeading className="pad-bot4" title={content.page_title} />

        {(() => {
          switch (true) {
            case submitting:
              return <PopoutPanelIconHeading type={types.LOADING} title={content.loading} />;
            case success:
              return <DeferEmailsSuccess secondaryButtonAction={this._reset} />;
            case failure:
              return (
                <ErrorStatus
                  title={content.error_title}
                  buttonText={content.error_button}
                  buttonOnClickHandler={this._reset}
                />
              );
            default:
              return (
                <DeferEmailsForm
                  deferredEmailsOrgs={deferredEmailsOrgs}
                  orgId={orgId}
                  userRole={userRole}
                  changeOrgHandler={this._changeOrgHandler}
                  releaseDeferredEmails={this._relaseDeferredEmails}
                  changeUserRole={this._changeUserRole}
                />
              );
          }
        })()}
      </PageWrapper>
    );
  }
}

EmailToolsPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  deferredEmailsOrgs: PropTypes.arrayOf(
    PropTypes.shape({
      deferrals: PropTypes.number,
      name: PropTypes.string
    })
  ),
  getDeferredEmails: PropTypes.func.isRequired,
  relaseDeferredEmails: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('emailToolsPage'),
  connect(
    state => ({
      deferredEmailsOrgs: state.deferredEmails.orgs,
      ...pick(state.sendDeferredEmails, ['submitting', 'success', 'failure', 'errors'])
    }),
    dispatch => ({
      getDeferredEmails: userRole => {
        dispatch(actions.getDeferredEmailsRequest(userRole));
      },
      relaseDeferredEmails: (orgId, userRole) => {
        dispatch(actions.sendDeferredEmailsRequest(orgId, userRole));
      },
      reset: () => {
        dispatch(actions.sendDeferredEmailsReset());
      }
    })
  )
)(EmailToolsPage);
