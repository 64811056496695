import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import withLocalizedContent from '../../language/withLocalizedContent';
import { toInitials } from '../../utils/string';
import ActionList, { Action } from '../ActionList/ActionList';
import ControlledForm from '../ControlledForm/ControlledForm';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import UserAgreement from '../UserAgreement/UserAgreement';
import UserFormBody from '../UserFormBody/UserFormBody';
import styles from './MyProfileEditForm.scss';
import PasswordFieldDetail from '../PasswordFieldDetail/PasswordFieldDetail';
import { socialIdpIsEnabled, featureIsEnabled } from '../../globals/envSettings';
import { getCurrentPlatform } from '../../utils/platform';

const IDENTITY_FORM = 'IDENTITY_FORM';
const AUTH_FORM = 'AUTH_FORM';
const SOCIAL_FORM = 'SOCIAL_FORM';
const UNLINK_SOCIAL_CONFIRM = 'UNLINK_SOCIAL_CONFIRM';

function MyProfileEditForm({
  localizedContent: { myProfileEditFormComponent: content },
  firstName,
  lastName,
  userName,
  email,
  isSupervised,
  formInputChanges,
  formInputErrors,
  isCheckingUserNameTaken = false,
  isCheckingEmailTaken = false,
  isUserNameTaken,
  isEmailTaken,
  onBlur,
  userAccount,
  onChange,
  editIdentity,
  editAuth,
  isOupRole,
  goToForm,
  goToFormWithData,
  unlinkSocialAccounts,
  form,
  linkUserAccountsData,
  newPassword,
  confirmPassword,
  oldPassword,
  hasOTCEntitlement
}) {
  const getHiddenInputs = () => {
    // eslint-disable-next-line react/destructuring-assignment
    switch (form) {
      case UNLINK_SOCIAL_CONFIRM:
        return [
          'firstName',
          'lastName',
          'userName',
          'email',
          'password',
          'newPassword',
          'oldPassword',
          'confirmPassword',
          'roleName',
          'yearGroup',
          'social'
        ];
      case SOCIAL_FORM:
        return [
          'firstName',
          'lastName',
          'userName',
          'email',
          'oldPassword',
          'password',
          'newPassword',
          'confirmPassword',
          'roleName',
          'yearGroup',
          'social-unlink-confirm'
        ];
      case AUTH_FORM:
        return socialIdpIsEnabled(getCurrentPlatform())
          ? [
              'firstName',
              'lastName',
              'password',
              'confirmPassword',
              'roleName',
              'yearGroup',
              'social',
              'social-unlink-confirm'
            ]
          : ['firstName', 'lastName', 'password', 'roleName', 'yearGroup', 'social', 'social-unlink-confirm'];
      case IDENTITY_FORM:
      default:
        return [
          'userName',
          'email',
          'oldPassword',
          'password',
          'newPassword',
          'confirmPassword',
          'roleName',
          'yearGroup',
          'social',
          'social-unlink-confirm'
        ];
    }
  };
  const getEditPanelActions = saveButtonDisabled => {
    const { push } = useHistory();

    const showChangeUserInfoAction = form === IDENTITY_FORM;
    const showSocialButton = form === IDENTITY_FORM && socialIdpIsEnabled(getCurrentPlatform()) && !isSupervised;
    const appleLoginIsEnabled = featureIsEnabled('enable-apple-login');
    const showBackButton = form === SOCIAL_FORM || form === AUTH_FORM || form === UNLINK_SOCIAL_CONFIRM;
    const showNextButton = form === UNLINK_SOCIAL_CONFIRM;
    const showSaveButton = form !== UNLINK_SOCIAL_CONFIRM && form !== SOCIAL_FORM;
    const showOTCRegistrationButton =
      form === IDENTITY_FORM && hasOTCEntitlement && featureIsEnabled('otc-registration');

    return (
      <>
        {showOTCRegistrationButton && (
          <Action
            className={styles.changeText}
            label={content.edit_otc_registration_data}
            onClick={() => push('/platform-registration/otc')}
          />
        )}
        {showChangeUserInfoAction && (
          <Action
            className={styles.changeText}
            label={content.change_auth_button}
            onClick={() => goToForm(AUTH_FORM)}
          />
        )}
        {showSocialButton && (
          <Action
            className={styles.changeText}
            label={
              appleLoginIsEnabled
                ? content.my_profile_edit_form_link_or_unlink_button_with_apple
                : content.my_profile_edit_form_link_or_unlink_button
            }
            onClick={() => goToForm(SOCIAL_FORM)}
          />
        )}
        {showBackButton && (
          <Action
            back
            className={styles.button + (form === SOCIAL_FORM ? ` ${styles.socialBackButton}` : ` ${styles.backButton}`)}
            label={content.back_button}
            onClick={() => {
              if (form === UNLINK_SOCIAL_CONFIRM) {
                goToForm(SOCIAL_FORM);
              } else {
                goToForm(IDENTITY_FORM);
              }
            }}
          />
        )}
        {showNextButton && (
          <Action
            label={content.my_profile_edit_social_next_button}
            className={`${styles.button} ${styles.saveButton}`}
            primary
            onClick={() => unlinkSocialAccounts(linkUserAccountsData)}
          />
        )}
        {showSaveButton && (
          <Action
            label={content.submit_button}
            dataTestId="MY_PROFILE_EDIT_FORM_SAVE_CHANGES"
            className={form === IDENTITY_FORM ? undefined : `${styles.button} ${styles.saveButton}`}
            primary
            disabled={saveButtonDisabled}
            onClick={
              {
                [IDENTITY_FORM]: editIdentity,
                [AUTH_FORM]: editAuth,
                [SOCIAL_FORM]: editIdentity
              }[form]
            }
          />
        )}
      </>
    );
  };
  const hidden = getHiddenInputs();
  if (isSupervised && userName === formInputChanges?.userName) {
    hidden.push('email');
  }
  const errorValues = Object.values(omit(formInputErrors, hidden));
  const currentFirstName = formInputChanges?.firstName || firstName;
  const currentLastName = formInputChanges?.lastName || lastName;
  const currentUserName = formInputChanges?.userName || userName;
  const currentNewPassword = formInputChanges?.newPassword || newPassword || '';
  const currentOldPassword = formInputChanges?.oldPassword || oldPassword || '';
  const currentConfirmPassword = formInputChanges?.confirmPassword || confirmPassword || '';
  const checkFirstName = hidden.indexOf('oldPassword') > -1 && currentFirstName === undefined;
  const checkLastName = hidden.indexOf('oldPassword') > -1 && currentLastName === undefined;
  const checkOldPasswordVisibility =
    !(hidden.indexOf('oldPassword') > -1) && (currentOldPassword === undefined || currentOldPassword === '');

  const isOupUserEmailInvalid = isOupRole ? !currentUserName.endsWith('@oup.com') : false;
  const initDisabled =
    isCheckingUserNameTaken ||
    isCheckingEmailTaken ||
    !errorValues.length ||
    errorValues.some(Boolean) ||
    isOupUserEmailInvalid ||
    checkOldPasswordVisibility ||
    checkFirstName ||
    checkLastName ||
    (!socialIdpIsEnabled(getCurrentPlatform()) &&
      currentNewPassword.length &&
      currentNewPassword !== currentConfirmPassword);

  const showPersonalInfo = form !== UNLINK_SOCIAL_CONFIRM;

  let saveButtonDisabled = initDisabled;

  if (form === AUTH_FORM && !socialIdpIsEnabled(getCurrentPlatform())) {
    saveButtonDisabled = initDisabled || !formInputChanges.oldPassword;
  }

  return (
    <ScrollContainer
      headerContent={
        <header className={styles.header}>
          <div className={styles.initials}>{toInitials(firstName, lastName)}</div>
          <div>
            <h2 className={styles.heading}>{`${firstName} ${lastName}`}</h2>
            <div className={styles.userName}>{userName}</div>
          </div>
        </header>
      }
      footerContent={
        <ActionList>
          {!socialIdpIsEnabled(getCurrentPlatform()) ? <UserAgreement privacyPolicy /> : null}

          {getEditPanelActions(saveButtonDisabled)}
        </ActionList>
      }
    >
      <ControlledForm className="pad2">
        <UserFormBody
          firstName={firstName}
          lastName={lastName}
          userName={userName}
          email={email}
          oldPassword={oldPassword}
          newPassword={newPassword}
          confirmPassword={confirmPassword}
          formInputErrors={formInputErrors}
          isCheckingUserNameTaken={isCheckingUserNameTaken}
          isUserNameTaken={isUserNameTaken}
          isCheckingEmailTaken={isCheckingEmailTaken}
          isEmailTaken={isEmailTaken}
          onChange={onChange}
          onBlur={onBlur}
          userAccount={userAccount}
          goToForm={goToForm}
          goToFormWithData={goToFormWithData}
          {...formInputChanges}
          localizedContent={content}
          origUserName={userName}
          origEmail={email}
          hidden={hidden}
          newPasswordGuidance={<PasswordFieldDetail />}
          isOupUserEmailInvalid={isOupUserEmailInvalid}
          isSupervised={isSupervised}
        />
        {socialIdpIsEnabled(getCurrentPlatform()) && showPersonalInfo ? (
          <div className="gin-top3">
            <hr />
            <div className={styles.personalInfoContainer}>
              <UserAgreement personalInfoEditForm />
            </div>
          </div>
        ) : null}
      </ControlledForm>
    </ScrollContainer>
  );
}

MyProfileEditForm.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isSupervised: PropTypes.bool.isRequired,
  oldPassword: PropTypes.string,
  newPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  formInputChanges: PropTypes.object,
  formInputErrors: PropTypes.object,
  isCheckingUserNameTaken: PropTypes.bool,
  isUserNameTaken: PropTypes.bool,
  isCheckingEmailTaken: PropTypes.bool,
  isEmailTaken: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  editIdentity: PropTypes.func.isRequired,
  unlinkSocialAccounts: PropTypes.func,
  editAuth: PropTypes.func.isRequired,
  isOupRole: PropTypes.bool.isRequired,
  form: PropTypes.string.isRequired,
  goToForm: PropTypes.func,
  linkUserAccountsData: PropTypes.object,
  goToFormWithData: PropTypes.func,
  userAccount: PropTypes.object,
  hasOTCEntitlement: PropTypes.bool
};
export default withLocalizedContent('myProfileEditFormComponent')(MyProfileEditForm);
