import trimEnd from 'lodash.trimend';

// Attempt at generic method of catching error results
export const requestFailed = result => !result || !!result.error || !result.status || result.status !== 'success';

// Common field access with a little trim helper for string values
export const getFieldValue = (state, formName, fieldName) => {
  const value = (state.fields[`${formName}.${fieldName}`] || {}).value;
  return typeof value === 'string' ? trimEnd(value) : value;
};
