import * as t from '../actionTypes';

const requestStatusOptions = { isLoading: true, success: false, error: false };
const successStatusOptions = { isLoading: false, success: true, error: false };
const failureStatusOptions = { isLoading: false, success: false, error: true };

export const initialState = {
  signInCardUrl: '',
  userId: '',
  userName: '',
  firstName: ''
};
export default function regenerateSupervisedUserSignInCard(state = initialState, { type, payload }) {
  switch (type) {
    case t.SET_REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_DATA:
      return {
        ...initialState,
        userId: payload.userId,
        userName: payload.userName,
        firstName: payload.firstName,
        ...requestStatusOptions
      };
    case t.REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_REQUEST:
      return {
        ...state,
        ...requestStatusOptions
      };
    case t.REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_SUCCESS:
      return {
        ...state,
        signInCardUrl: payload,
        ...successStatusOptions
      };
    case t.REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_ERROR:
      return { ...state, ...failureStatusOptions };
    case t.RESET_REGENERATE_SUPERVISED_USER_SIGN_IN_CARD_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
