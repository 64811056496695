import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import Button, { buttonTypes } from '../../components/Button/Button.js';
import { getIdpLoginUrl } from '../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import styles from './RedeemCodeSuccess.scss';
import CardWithThumbnail from '../../components/CardWithThumbnail/CardWithThumbnail.js';
import colors from '../../globals/colors.js';
import { HubLayoutConstants } from '../../globals/hubConstants';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import { isHubMode, getCurrentPlatform } from '../../utils/platform';
import { socialIdpIsEnabled } from '../../globals/envSettings';
import Link from '../../components/Link/Link.js';
import { resetActivationCode } from '../../redux/actions/activationCode';

class RedeemCodeSuccessPage extends Component {
  static handleRegister = async (e, redeemCodeSuccessPage) => {
    e.preventDefault();
    const registerPath = socialIdpIsEnabled(getCurrentPlatform()) ? '/register-choice' : '/register';
    const url = isHubMode()
      ? `${registerPath}?returnUrl=${window.location.host}${HubLayoutConstants.PATH_NAMES.DASHBOARD_REDEEM_PATH}`
      : `${registerPath}?returnUrl=${redeemCodeSuccessPage.returnUrl.replace('https://', '')}`;
    window.location.href = url;
    return false;
  };

  componentDidMount() {}

  handleSignIn = async () => {
    const { platform } = this.props;
    const url = await getIdpLoginUrl(null, { platform });
    window.location.href = url;
  };

  render() {
    const {
      redeemCodeValue,
      localizedContent: { redeemCodeSuccessPage },
      history,
      resetActivationCodeAction
    } = this.props;

    if (!redeemCodeValue) history.push('/redeem');
    return redeemCodeValue ? (
      <div>
        <Helmet title={redeemCodeSuccessPage.page_title} />
        <form>
          <div className="grid">
            <div className="bg">
              <div className="grid">
                <div className={`row ${styles.row}`}>
                  <div className={`col sm7 md6 lg5 ${styles.redeemCodeSuccessPage}`}>
                    <CardWithThumbnail thumbnailColor={colors.PRIMARY_BLUE_HOVER} thumbnailGlyph={GLYPHS.ICON_USER}>
                      <div className={styles.validRedeemCode}>
                        <div className={styles.content}>
                          <h1>
                            {redeemCodeValue}&nbsp;&nbsp;
                            <SVGIcon className={styles.SVGIcon} glyph={GLYPHS.ICON_CHECK_CIRCLE} />
                          </h1>
                          <Link to="/redeem" className={styles.registerLink} onClick={resetActivationCodeAction}>
                            {redeemCodeSuccessPage.change_code}
                          </Link>
                        </div>
                      </div>
                      <hr />
                      <div className={styles.linkItem}>
                        <p>{redeemCodeSuccessPage.sign_in_text}</p>
                        <div className={styles.content}>
                          <Button
                            id="signin-button"
                            type={buttonTypes.PRIMARY}
                            text={redeemCodeSuccessPage.sign_in_button_text}
                            disableExternalBehaviour
                            onClick={this.handleSignIn}
                            fullWidth
                          />
                        </div>
                      </div>
                      <div className={styles.linkItems}>
                        <div className={styles.content}>
                          <p>
                            {`${redeemCodeSuccessPage.sign_up_text} `}
                            <Link
                              underlined
                              active
                              className={styles.registerLink}
                              onClick={e => RedeemCodeSuccessPage.handleRegister(e, redeemCodeSuccessPage)}
                              disabled={false}
                            >
                              {redeemCodeSuccessPage.sign_up_button_text}
                            </Link>
                          </p>
                        </div>
                      </div>
                    </CardWithThumbnail>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    ) : null;
  }
}

RedeemCodeSuccessPage.propTypes = {
  platform: PropTypes.string,
  redeemCodeValue: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  resetActivationCodeAction: PropTypes.func
};

export default compose(
  withLocalizedContent('redeemCodeSuccessPage'),
  connect(
    state => ({
      redeemCodeValue: state.activationCode.redeemCodeValue
    }),
    {
      resetActivationCodeAction: resetActivationCode
    }
  )
)(withRouter(RedeemCodeSuccessPage));
