import classnames from 'classnames';
import dot from 'dot-object';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import React from 'react';
import { HUB_SUPPORT_REGISTER_ORGANISATION_URL } from '@oup/shared-node-browser/constants';
import Button from '@oup/shared-front-end/src/components/Button';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { Link as RouterLink } from 'react-router-dom';

import appSettings from '../../globals/appSettings';
import colors from '../../globals/colors';
import { orgRoles } from '../../globals/orgRoles';
import withLocalizedContent from '../../language/withLocalizedContent';
import UserAgreement from '../UserAgreement/UserAgreement';
import Card from '../Card/Card';
import ControlledForm from '../ControlledForm/ControlledForm';
import OrgFormBody from '../OrgFormBody/OrgFormBody';
import PageHeading from '../PageHeading/PageHeading';
import styles from './RegisterOrg.scss';
import { isHubMode, isOptMode } from '../../utils/platform';

function RegisterOrg({
  localizedContent: { registerOrgComponent: content, curriculumTypeNames },
  roles = {},
  countries = {},
  hearAboutUsValues = {},
  id,
  adminUser = {},
  name = '',
  role = '',
  countryCode = '',
  orgRegion = '',
  orgPostcode = '',
  curriculumType = '',
  orgUniqueId = '',
  primaryEmail = '',
  webAddress = '',
  isLmsLtiTool = false,
  province = '',
  taxRegistration = '',
  sapNumber = '',
  department = '',
  hearAboutUs = '',
  address = '',
  city = '',
  errors = {},
  disabled = [],
  hidden = [],
  preview = false,
  showSupportLink = false,
  acceptedTermsAndConditions = false,
  createChangeHandler = () => {},
  createBlurHandler = () => {},
  generateOrgUniqueId = () => {},
  onContinue = () => {},
  onChange = () => {},
  onSubmit = () => {},
  setTermsAndConditions = () => {}
}) {
  const isPrimarySchool = role === orgRoles.PRIMARY_SCHOOL;
  const dotErrors = dot.dot(errors);
  const errorValues = Object.values(isPrimarySchool ? dotErrors : omit(dotErrors, ['curriculumType', 'orgUniqueId']));
  const isSubmitDisabled = !errorValues.length || errorValues.some(Boolean) || acceptedTermsAndConditions === false;

  const renderSupportLink = () => {
    if (isHubMode()) {
      return (
        <TextLink
          to={HUB_SUPPORT_REGISTER_ORGANISATION_URL}
          target="_blank"
          className={styles.helpCard}
          rel="noreferrer"
        >
          {content.aside_link_label}
        </TextLink>
      );
    }

    return (
      <TextLink
        to={content.aside_link_url}
        component={RouterLink}
        target="_blank"
        className={styles.helpCard}
        rel="noreferrer"
      >
        {content.aside_link_label}
      </TextLink>
    );
  };

  return (
    <ControlledForm>
      <PageHeading
        title={preview ? content.preview_title : content.title}
        subtitle={preview ? content.preview_subtitle : content.subtitle}
      />
      <div className="gin-top4">
        <div className="row">
          <div className="col md8">
            <Card headingText={content.header} headingColor={colors.ORGANIZATION}>
              <div className={classnames({ row: preview })}>
                <div className={classnames({ 'col sm8': preview })}>
                  <OrgFormBody
                    localizedContent={content}
                    localizedCurriculumTypeNames={curriculumTypeNames}
                    roles={roles}
                    countries={countries}
                    hearAboutUsValues={hearAboutUsValues}
                    curriculumTypes={appSettings.curriculumTypes}
                    curriculumType={curriculumType}
                    adminUser={adminUser}
                    name={name}
                    role={role}
                    countryCode={countryCode}
                    orgRegion={orgRegion}
                    orgPostcode={orgPostcode}
                    orgUniqueId={orgUniqueId}
                    primaryEmail={primaryEmail}
                    webAddress={webAddress}
                    isLmsLtiTool={isLmsLtiTool}
                    province={province}
                    taxRegistration={taxRegistration}
                    sapNumber={sapNumber}
                    department={department}
                    hearAboutUs={hearAboutUs}
                    city={city}
                    address={address}
                    errors={errors}
                    disabled={disabled}
                    hidden={hidden}
                    preview={preview}
                    createChangeHandler={createChangeHandler}
                    createBlurHandler={createBlurHandler}
                    generateOrgUniqueId={generateOrgUniqueId}
                  />
                  {id || isOptMode() ? (
                    <UserAgreement
                      termsAndConditions
                      privacyPolicy
                      dataUsage
                      acceptedTermsAndConditions={acceptedTermsAndConditions}
                      createChangeHandler={createChangeHandler}
                      hidden={hidden}
                      setTermsAndConditions={setTermsAndConditions}
                      preview={preview}
                    />
                  ) : null}
                </div>
                {preview ? (
                  <div className="col md4">
                    <div className={styles.changeDetailsButtonContainer}>
                      <Button variant="outline" text={content.change_details_button} onClick={onChange} />
                    </div>
                  </div>
                ) : null}
              </div>
            </Card>
          </div>
          <div className="col md4">
            <Card headingText={content.aside_header} headingColor={colors.DASHBOARD}>
              <h1 className={styles.asideHeading}>{content.aside_heading}</h1>
              <p>{content.aside_content}</p>
              {showSupportLink ? renderSupportLink() : null}
            </Card>
          </div>
        </div>
        <div className="gin-top3">
          <Button
            variant="filled"
            text={preview ? content.submit_button : content.continue_button}
            disabled={isSubmitDisabled}
            onClick={preview ? onSubmit : onContinue}
          />
        </div>
      </div>
    </ControlledForm>
  );
}

RegisterOrg.propTypes = {
  localizedContent: PropTypes.object,
  roles: PropTypes.object,
  countries: PropTypes.object,
  hearAboutUsValues: PropTypes.object,
  id: PropTypes.string,
  adminUser: PropTypes.shape({ email: PropTypes.string }),
  name: PropTypes.string,
  role: PropTypes.string,
  countryCode: PropTypes.string,
  orgRegion: PropTypes.string,
  orgPostcode: PropTypes.string,
  curriculumType: PropTypes.string,
  orgUniqueId: PropTypes.string,
  primaryEmail: PropTypes.string,
  webAddress: PropTypes.string,
  isLmsLtiTool: PropTypes.bool,
  province: PropTypes.string,
  taxRegistration: PropTypes.string,
  sapNumber: PropTypes.string,
  department: PropTypes.string,
  hearAboutUs: PropTypes.string,
  city: PropTypes.string,
  address: PropTypes.string,
  errors: PropTypes.object,
  disabled: PropTypes.arrayOf(PropTypes.string),
  hidden: PropTypes.arrayOf(PropTypes.string),
  preview: PropTypes.bool,
  showSupportLink: PropTypes.bool,
  acceptedTermsAndConditions: PropTypes.bool,
  createChangeHandler: PropTypes.func,
  createBlurHandler: PropTypes.func,
  generateOrgUniqueId: PropTypes.func,
  onContinue: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  setTermsAndConditions: PropTypes.func
};

export default withLocalizedContent('registerOrgComponent', 'curriculumTypeNames')(RegisterOrg);
