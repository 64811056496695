import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import querySelectorAllFocusable from '../../utils/dom/querySelectorAllFocusable';
import trapFocusIn from '../../utils/dom/trapFocusIn';
import MouseEvent from '../../utils/polyfill/MouseEvent';
import Button, { buttonTypes } from '../Button/Button';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './ConfirmationModal.scss';

const ANIMATION_DELAY = 300; // 500 ms

function clickPanelCloseButton() {
  // Instead of understanding the intricacies of each panel's close action, let's just fake a click on the Close button:
  const closeButton = document.getElementById('closeModalBtn');
  if (closeButton) {
    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    closeButton.dispatchEvent(event);
  }
}

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // If the page has been refreshed with url for the panel to be open, we'll make use of the url when the panel closes:
      urlWhenComponentMounted: window.location.href,
      triggeredBy: document.activeElement === document.body ? null : document.activeElement
    };
  }

  componentDidMount() {
    this.onShow();
    if (this.wrapper) {
      this.wrapper.addEventListener('keydown', this.onKeyEscape, true);
    }
  }

  componentWillUnmount() {
    this.onHide();
    if (this.wrapper) {
      this.wrapper.removeEventListener('keydown', this.onKeyEscape, true);
    }
  }

  onShow = () => {
    // Bind events before the animation so we minimise the chance of jank:
    // this.wrapper.addEventListener('keydown', this.onKeyPress, true);
    // window.addEventListener('focus', this.onFocus, false);

    setTimeout(this.onAfterShow, ANIMATION_DELAY);
  };

  onHide = () => {
    // Unbind events before the animation so we minimise the chance of jank:
    trapFocusIn(false);

    setTimeout(this.onAfterHide, ANIMATION_DELAY);
  };

  onAfterShow = () => {
    // Set focus on the first field when popup opens:
    setTimeout(() => {
      trapFocusIn(this.wrapper);
      querySelectorAllFocusable(this.wrapper)
        ?.pop()
        ?.focus();
    }, 0);
  };

  onAfterHide = () => {
    const { triggeredBy, urlWhenComponentMounted } = this.state;
    // If the page wasn't opened by a link but by a page refresh then we have no button to return focus to.
    // Instead we'll try to be smartarses and find a likely link to the panel's URL:
    const triggeredByState =
      triggeredBy || querySelectorAllFocusable().filter(elem => elem.href === urlWhenComponentMounted)[0];

    // For accessibility, reset focus back to the element that triggered the panel:
    if (triggeredByState) triggeredByState.focus();
  };

  // Helper to close popout when user presses escape:
  onKeyEscape = e => {
    const { negativeClick } = this.props;
    if (e.keyCode === 27) {
      // stop modal from closing any panels below it:
      e.stopPropagation();

      // Ideally, the code that opened the panel also provided a method for closing it:
      // Otherwise we have to use some cunning and look for a close button to fake-click:
      const closeAction = negativeClick || clickPanelCloseButton;
      closeAction();
    }
  };

  render() {
    const {
      iconType,
      children,
      // eslint-disable-next-line react/prop-types
      transition,
      negativeClick,
      negativeClickText,
      positiveClickText,
      positiveClick,
      title,
      body
    } = this.props;
    const transitionClass = transition ? styles.active : null;

    return (
      <div
        id="confirmationModal"
        role="alertdialog"
        aria-labelledby="modalTitle"
        className={styles.confirmationModal}
        ref={elem => {
          this.wrapper = elem;
        }}
      >
        <div className={classnames(styles.modal, transitionClass)}>
          {iconType ? <SVGIcon glyph={iconType} /> : null}
          {children || (
            <div>
              {negativeClick && (
                <button
                  id="closeModalBtn"
                  className={styles.close}
                  type="button"
                  onClick={negativeClick}
                  aria-label="close"
                >
                  <SVGIcon glyph={GLYPHS.ICON_CLOSE_BOLD} />
                </button>
              )}
              <div className={styles.title}>
                <h2 id="modalTitle">{title}</h2>
              </div>
              <div role="document" className={styles.body}>
                <p>{body}</p>
              </div>
              <div className={styles.buttons}>
                <Button
                  type={buttonTypes.PRIMARY}
                  text={positiveClickText}
                  onClick={positiveClick}
                  id="confirmationModalPositiveButton"
                  dataTestId="confirmationModalPositiveButton"
                />
                {negativeClick && (
                  <Button
                    type={buttonTypes.NO_BORDER}
                    text={negativeClickText}
                    onClick={negativeClick}
                    id="confirmationModalNegativeButton"
                    dataTestId="confirmationModalNegativeButton"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ConfirmationModal.propTypes = {
  /** The title of the modal */
  title: PropTypes.string.isRequired,
  /** The body of the modal */
  body: PropTypes.string,
  /** The text of the primary click of the modal */
  positiveClickText: PropTypes.string,
  /** The text of the secondary click of the modal */
  negativeClickText: PropTypes.string,
  /** The primary click of the modal */
  positiveClick: PropTypes.func,
  /** The secondary click of the modal */
  negativeClick: PropTypes.func,
  /** Optional icon to diplay above title */
  iconType: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(Object.values(GLYPHS))]),
  /** Optional custom content for the entire modal */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

ConfirmationModal.defaultProps = {
  positiveClickText: 'Cancel',
  negativeClickText: 'Okay',
  iconType: ''
};

export default ConfirmationModal;
