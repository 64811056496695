import pick from 'lodash.pick';
import org from '@oup/shared-node-browser/org.js';

export const orgRoles = org.orgRoles;
export const selfRegisterOrgRoles = org.selfRegisterOrgRoles;
export const managedOrgRoles = org.managedOrgRoles;
export const systemGeneratedOrgRoles = org.selfRegisterOrgRoles;
export const userGeneratedOrgRoles = org.selfRegisterOrgRoles;
export const orgRoleDescriptions = org.orgRoleDescriptions;
export const eltAmRoles = org.eltAmRoles;

export const oupCustomerSupportEnabledOrgRoles = pick(orgRoleDescriptions, [
  orgRoles.PRIMARY_SCHOOL,
  orgRoles.OIC_SCHOOL
]);

// TODO: what are these supposed to mean? Does this mean "user editable"? All org roles are enabled
export const otherEnabledOrgRoles = pick(orgRoleDescriptions, [orgRoles.SECONDARY_SCHOOL]);
export const allEnabledOrgRoles = pick(orgRoleDescriptions, [orgRoles.PRIMARY_SCHOOL, orgRoles.SECONDARY_SCHOOL]);

// Export organisation types in a Dropdown component friendly form
export const formattedOrgRoles = Object.entries(orgRoleDescriptions).map(([value, text]) => ({
  value,
  text
}));

// limit to secondary schools for initial release (https://edukoup.atlassian.net/browse/EUE-1682)
export const defaultOrgRole = 'SECONDARY_SCHOOL';

// Export the object to be used in this format: `orgRoles.SCHOOL`
export default orgRoleDescriptions;
