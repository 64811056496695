import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { PageWrapper } from '../../../components';
import PageHeading from '../../../components/PageHeading/PageHeading';
import SystemNotification from '../../../components/SystemNotification/SystemNotification';
import updateSystemNotification from '../../../redux/actions/systemNotification';

function SystemNotificationPage({
  systemNotification,
  updateSystemNotificationAction,
  localizedContent: { systemNotification: content }
}) {
  return (
    <PageWrapper>
      <Helmet title={content.title} />
      <PageHeading className="pad-bot4" title={content.title} />
      <SystemNotification
        systemNotification={systemNotification}
        updateSystemNotificationAction={updateSystemNotificationAction}
        content={content}
      />
    </PageWrapper>
  );
}

SystemNotificationPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  systemNotification: PropTypes.array.isRequired,
  updateSystemNotificationAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('systemNotification'),
  connect(
    state => ({
      systemNotification: state.app.systemNotification
    }),
    {
      updateSystemNotificationAction: updateSystemNotification
    }
  )
)(SystemNotificationPage);
