import { put, take, select } from 'redux-saga/effects';
import { showData, START_DOWNLOAD, showError } from '../../../../reducers/downloadStudentData.reducer.js';
import downloadStudentDataRequest from './downloadStudentDataRequest.js';
import downloadAsFile from '../../../../../utils/downloadAsFile.js';

const { Parser } = require('json2csv');
const moment = require('moment');

function* downloadStudentsCSV() {
  const json2csvParser = new Parser({ quote: '' });
  const csvStudentsList = [];
  const { studentsList } = yield select(state => ({
    studentsList: state.search.orgStudents.users
  }));
  const { studentData } = yield select(state => ({
    studentData: state.downloadStudentData.studentData
  }));
  const { orgId } = yield select(state => ({
    orgId: state.organisationPage.orgId
  }));
  const { orgName } = yield select(state => ({
    orgName: state.organisations.data[orgId].name
  }));

  const studentsIds = Object.keys(studentsList);
  studentsIds.forEach(studentId => {
    const userDetails = studentsList[studentId];
    const classDetails = studentData[studentId];
    if (userDetails.orgArchiveStatus === 'ACTIVE') {
      const obj = {};
      obj.FirstName = userDetails.firstname;
      obj.LastName = userDetails.lastname;
      obj.UserName = userDetails.username.includes('_') ? userDetails.username.split('_')[1] : userDetails.username;
      obj.Password = '';
      obj.YearGroup = userDetails.yearGroup;
      obj.Class = classDetails ? classDetails.className : '';
      csvStudentsList.push(obj);
    }
  });

  const csvContent =
    csvStudentsList.length > 0
      ? json2csvParser.parse(csvStudentsList)
      : 'FirstName,LastName,UserName,Password,YearGroup,Class';
  const timestamp = moment().format('YYYYMMDD');
  const fileName = `${orgName}_${timestamp}.csv`;
  downloadAsFile(csvContent, fileName, 'text/csv');
}

export default function* downloadStudentData() {
  console.log('[downloadStudentData Saga] Beginning');

  while (true) {
    console.log('[downloadStudentData Saga] Waiting for user to start downloading');
    yield take(START_DOWNLOAD);

    console.log('[downloadStudentData Saga] Start downloading');
    const result = yield downloadStudentDataRequest();
    const status = result.status ? result.status.toLowerCase() : 'error';

    const failed = !!result.error || status !== 'success';
    const apiError = result.error || status !== 'success' ? { error: result.message } : null;

    if (!failed) {
      console.log('[downloadStudentData Saga] Download request complete, start creating csv file');
      yield put(showData(result.data));
      yield downloadStudentsCSV();
    } else {
      console.log('[downloadStudentData Saga] Download request complete, but failed, showing error');
      yield put(showError(apiError));
    }
  }
}
