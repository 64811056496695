import groupBy from 'lodash.groupby';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ragStatuses from '../../globals/ragStatuses';
import withLocalizedContent from '../../language/withLocalizedContent';
import { bgClass } from '../../utils/colorClassNames';
import Button, { buttonTypes } from '../Button/Button';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './LevelProgressBar.scss';

export const RAG_STATUS_MAP = {
  [ragStatuses.AHEAD]: GLYPHS.ICON_STAR,
  [ragStatuses.EXPECTED]: GLYPHS.ICON_CIRCLE,
  [ragStatuses.BEHIND]: GLYPHS.ICON_DIAMOND,
  [ragStatuses.VERY_BEHIND]: GLYPHS.ICON_HEXAGON
};

export const STYLE_MAP = {
  COMPLETED: 'progress-completed',
  INCOMPLETED: 'progress-incompleted',
  LEVEL_UP: 'progress-level-up',
  LEVEL_UP_WITH_COMPLETED: 'progress-level-up-with-completed',
  LEVEL_DOWN: 'progress-level-down',
  LEVEL_DOWN_WITH_COMPLETED: 'progress-level-down-with-completed'
};

/**
 * This component comprises of the titles only for the LevelProgressBar.
 */
export function LevelProgressTitles({ levels, expectedFocus = null, onLevelClick = () => {} }) {
  return (
    <div className={styles.levelProgressTitles}>
      <strong className={styles.mobileTitle}>Progress</strong>
      <ul className={styles.levelList}>
        {levels.list
          ? levels.list.map((level, $0) => {
              const expectedLevel = levels.active.find(item => item.levelId === level.id);
              const levelSelected = expectedFocus === level.id;

              return (
                <li
                  key={$0}
                  className={classnames(styles.levelListTitlesItem, {
                    [styles['levelListTitlesItem--expected']]: !!expectedLevel,
                    [styles['levelListItem--expectedFocus']]: levelSelected // Grey expected band highlighted
                  })}
                >
                  {expectedLevel ? (
                    <button
                      type="submit"
                      data-tooltip={expectedLevel.description}
                      data-placement="top"
                      className={classnames(styles.levelBox, styles['levelBox--title'], {
                        [styles['levelBox--titleFocus']]: levelSelected
                      })}
                      tabIndex="-1"
                      onClick={onLevelClick(level.id)}
                    >
                      <strong className={styles.title}>{level.name}</strong>
                    </button>
                  ) : (
                    <div className={classnames(styles.levelBox, styles['levelBox--title'])}>
                      <strong className={styles.title}>{level.name}</strong>
                    </div>
                  )}
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
}

function compileProgressLevel(levelProgress) {
  if (levelProgress.length === 0) return null;
  if (levelProgress.length === 1) return levelProgress[0];

  const levelProgressByStyle = groupBy(levelProgress, 'style');

  const completedLevelProgress = levelProgressByStyle[STYLE_MAP.COMPLETED] || [];
  const levelUpProgress = levelProgressByStyle[STYLE_MAP.LEVEL_UP] || [];
  const levelDownProgress = levelProgressByStyle[STYLE_MAP.LEVEL_DOWN] || [];

  if (completedLevelProgress.length && levelUpProgress.length) {
    return {
      ...completedLevelProgress[0],
      description: levelUpProgress[0].description,
      style: STYLE_MAP.LEVEL_UP_WITH_COMPLETED
    };
  }

  if (completedLevelProgress.length && levelDownProgress.length) {
    return {
      ...completedLevelProgress[0],
      description: levelDownProgress[0].description,
      style: STYLE_MAP.LEVEL_DOWN_WITH_COMPLETED
    };
  }

  return levelProgress[0];
}

function generateDescription({ style, days, levelId }, currentLevel, i18n) {
  if (style === STYLE_MAP.LEVEL_UP) {
    return i18n.move_up_message.replace('{days}', days);
  }
  if (style === STYLE_MAP.LEVEL_DOWN) {
    return i18n.move_down_message.replace('{days}', days);
  }
  if (style === STYLE_MAP.COMPLETED) {
    return i18n.completed_level_message;
  }
  if (style === STYLE_MAP.INCOMPLETED) {
    return i18n.partially_completed_message;
  }
  if (levelId === currentLevel.levelId) {
    return i18n.current_level_message;
  }
  return null;
}

/**
 * Main component that renders the level progress bar as
 * seen in the ORB Class Report pages.
 */
function LevelProgressBar({
  barId = 0,
  levels,
  progress,
  barFocus = false,
  activeItem = null,
  expectedFocus = null,
  onLevelClick = () => {},
  localizedContent: { levelProgressBar: i18n }
}) {
  const progressByLevelMap = groupBy(progress, 'levelId');

  return (
    <div className="levelProgressBar">
      <ul className={styles.levelList}>
        {levels.list
          ? levels.list.map((level, $0) => {
              const expectedLevel = levels.active.some(item => item.levelId === level.id);
              const levelSelected = expectedFocus === level.id;
              const progressLevel = compileProgressLevel(progressByLevelMap[level.id] || []);

              if (progressLevel) {
                const currentLevel = progress.find(({ status }) => status != null);
                progressLevel.description = generateDescription(progressLevel, currentLevel, i18n);
              }

              return (
                <li
                  key={$0}
                  className={classnames(styles.levelListItem, {
                    [styles['levelListItem--barFocus']]: !!barFocus, // Entire bar focus
                    [styles['levelListItem--active']]: activeItem === level.id, // Active state (arrow) when clicked on
                    [styles['levelListItem--expected']]: !!expectedLevel, // Grey background indicator
                    [styles['levelListItem--expectedFocus']]: levelSelected // Grey background active (darker)
                  })}
                >
                  <LevelBox
                    levelId={level.id}
                    barId={barId}
                    progressLevel={progressLevel}
                    onLevelClick={onLevelClick}
                  />
                  <div
                    className={classnames(styles.levelButton, {
                      [styles['levelButton--current']]: !!(progressLevel && progressLevel.status)
                    })}
                  >
                    <Button
                      glyph={progressLevel ? RAG_STATUS_MAP[progressLevel.status] : null}
                      glyphClassName={progressLevel ? bgClass(progressLevel.status) : null}
                      glyphRounded
                      type={buttonTypes.DROPDOWN_JUMBO}
                      text={`Level ${level.name}`}
                      onClick={onLevelClick(barId, level.id, progress[barId])}
                    />
                  </div>
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
}

/**
 * Repeated level box component
 */
function LevelBox({ levelId, barId, progressLevel, onLevelClick }) {
  const { description, style, status } = progressLevel || false;
  const isArrowDisplay = [STYLE_MAP.LEVEL_UP, STYLE_MAP.LEVEL_DOWN].includes(style);
  const Box = progressLevel && !isArrowDisplay ? 'button' : 'div';

  const showLevelDirectionArrow = direction => {
    switch (direction) {
      case STYLE_MAP.LEVEL_UP:
        return <SVGIcon glyphViewBox="0 0 500 500" glyph={GLYPHS.ICON_ARROW_RIGHT} className={styles.levelUpIcon} />;
      case STYLE_MAP.LEVEL_DOWN:
        return (
          <SVGIcon
            glyphViewBox="0 0 500 500"
            glyph={GLYPHS.ICON_ARROW_RIGHT}
            rotate={180}
            className={styles.levelDownIcon}
          />
        );
      case STYLE_MAP.LEVEL_UP_WITH_COMPLETED:
        return (
          <SVGIcon
            glyphViewBox="0 0 500 500"
            glyph={GLYPHS.ICON_ARROW_RIGHT}
            className={styles.levelUpWithCompletedIcon}
          />
        );
      case STYLE_MAP.LEVEL_DOWN_WITH_COMPLETED:
        return (
          <SVGIcon
            glyphViewBox="0 0 500 500"
            glyph={GLYPHS.ICON_ARROW_RIGHT}
            rotate={180}
            className={styles.levelDownIcon}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      title={description}
      className={classnames(styles.levelBox, {
        [styles[`levelBox--style-${style}`]]: !!style,
        [styles[`levelBox--status-${status}`]]: !!status
      })}
      onClick={progressLevel && !isArrowDisplay ? onLevelClick(barId, levelId, progressLevel) : null}
    >
      <strong className="a11y-hide">{description}</strong>

      {RAG_STATUS_MAP[status] ? <SVGIcon glyph={RAG_STATUS_MAP[status]} className={styles.currentIcon} /> : null}
      {style ? showLevelDirectionArrow(style) : null}
    </Box>
  );
}

LevelProgressTitles.propTypes = {
  levels: PropTypes.object.isRequired,
  onLevelClick: PropTypes.func,
  expectedFocus: PropTypes.number
};

LevelProgressBar.propTypes = {
  barId: PropTypes.number,
  levels: PropTypes.object.isRequired,
  progress: PropTypes.array.isRequired,
  onLevelClick: PropTypes.func,
  barFocus: PropTypes.bool,
  activeItem: PropTypes.number,
  expectedFocus: PropTypes.number,
  localizedContent: PropTypes.object
};

LevelBox.propTypes = {
  barId: PropTypes.number.isRequired,
  levelId: PropTypes.number.isRequired,
  onLevelClick: PropTypes.func,
  progressLevel: PropTypes.object
};

export default withLocalizedContent('levelProgressBar')(LevelProgressBar);
