import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withLocalizedContent from '../language/withLocalizedContent';
import ErrorStatus from '../components/ErrorStatus/ErrorStatus';
import getUsernameParts from '../utils/getUsernameParts';
import PopoutPanelIconHeading, { types } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../components/ScrollContainer/ScrollContainer';
import SidePanel from '../components/SidePanel/SidePanel';
import ManagedUserChangeUsernameForm from '../components/ManagedUserChangeUsernameForm/ManagedUserChangeUsernameForm';
import UserUpdateConfirmation from '../components/UserUpdateConfirmation/UserUpdateConfirmation';
import {
  clearManagedUserChangeUsernameFailure,
  submitManagedUserChangeUsername,
  validateManagedUserNewUsername
} from '../redux/actions/managedUserChangeUsername';

class ManagedUserChangeUsernamePanel extends Component {
  constructor(props) {
    super(props);
    const { user } = props;

    this.state = {
      origUsername: getUsernameParts(user.username).username,
      ...getUsernameParts(user.username || '')
    };
  }

  componentWillReceiveProps({ user: { username } }) {
    const { user } = this.props;
    if (user.username !== username) {
      this.setState({
        origUsername: getUsernameParts(username).username,
        ...getUsernameParts(username)
      });
    }
  }

  get _newUsername() {
    const { orgCustomId, username } = this.state;
    return `${orgCustomId}_${username}`;
  }

  _createChangeHandler = name => value => {
    this.setState({ [name]: value });
  };

  _onUsernameBlur = () => {
    const { origUsername, username } = this.state;
    const { validateUsername } = this.props;
    if (origUsername !== username) {
      validateUsername(this._newUsername);
    }
  };

  _handleSubmit = () => {
    const { submit } = this.props;
    submit({
      newUserName: this._newUsername
    });
  };

  _renderActiveSection() {
    const {
      localizedContent: { managedUserChangeUsernamePanel: content },
      user: userObject,
      user: { isLocked = false },
      submitting,
      success,
      failure,
      clearManagedUserChangeUsernameFailureAction,
      navigateChangePassword,
      onClosePanel
    } = this.props;

    const { origUsername, username } = this.state;

    switch (true) {
      case submitting:
        return (
          <ScrollContainer>
            <PopoutPanelIconHeading
              type={types.LOADING}
              title={content.saving_heading}
              subtitle={content.saving_subheading}
            />
          </ScrollContainer>
        );
      case success:
        return (
          <UserUpdateConfirmation
            title={content.success_heading}
            actions={[
              {
                text: content.button_change_password_text,
                action: navigateChangePassword
              }
            ]}
            onSubmit={onClosePanel}
            users={[{ ...userObject, ...this.state }]}
          />
        );
      case failure:
        return (
          <ScrollContainer>
            <ErrorStatus
              title={content.error_title}
              buttonText={content.error_retry_button}
              buttonOnClickHandler={clearManagedUserChangeUsernameFailureAction}
            />
          </ScrollContainer>
        );
      default:
        return (
          <ManagedUserChangeUsernameForm
            {...pick(userObject, ['firstName', 'lastName'])}
            username={username}
            usernameChanged={origUsername !== username}
            userLocked={isLocked}
            {...pick(this.props, ['errors', 'usernameTaken', 'usernameValidationLoading'])}
            createChangeHandler={this._createChangeHandler}
            createBlurHandler={() => this._onUsernameBlur}
            onSubmit={this._handleSubmit}
          />
        );
    }
  }

  render() {
    const { open, onClosePanel } = this.props;

    return (
      <SidePanel
        id="managedUserEditChangeUsernamePanel"
        ariaLabel="Edit managed user"
        isOpen={open}
        onClose={onClosePanel}
      >
        {this._renderActiveSection()}
      </SidePanel>
    );
  }
}

ManagedUserChangeUsernamePanel.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  orgId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    isLocked: PropTypes.bool
  }).isRequired,
  usernameTaken: PropTypes.bool,
  usernameValidationLoading: PropTypes.bool.isRequired,
  validateUsername: PropTypes.func.isRequired,
  clearManagedUserChangeUsernameFailureAction: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  errors: PropTypes.array,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  navigateChangePassword: PropTypes.func.isRequired,
  onClosePanel: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('managedUserChangeUsernamePanel'),
  connect(
    state => ({ ...state.managedUserChangeUsername }),
    (dispatch, { orgId, userId }) => ({
      validateUsername: fragment => {
        dispatch(validateManagedUserNewUsername(fragment));
      },
      submit: data => {
        dispatch(submitManagedUserChangeUsername(orgId, userId, data));
      },
      clearManagedUserChangeUsernameFailureAction: () => {
        dispatch(clearManagedUserChangeUsernameFailure());
      }
    })
  )
)(ManagedUserChangeUsernamePanel);
