import React, { Component } from 'react';
import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {
  ManagedUserUpdatePasswordForm,
  PopoutPanelIconHeading,
  SidePanel,
  UserUpdateConfirmation
} from '../components';
import { types } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import {
  validateManagedUserPasswordInput,
  generateManagedUserPasswordRequest,
  generateManagedUserSignInCardRequest,
  changeManagedUserPasswordRequest,
  changeManagedUserPasswordClearFailure
} from '../redux/actions/managedUser';
import ScrollContainer from '../components/ScrollContainer/ScrollContainer';
import Button from '../components/Button/Button';
import ErrorStatus, { ErrorStatusIconTypes } from '../components/ErrorStatus/ErrorStatus';
import getUsernameParts from '../utils/getUsernameParts';
import withLocalizedContent from '../language/withLocalizedContent';

class ManagedUserChangePasswordPanel extends Component {
  _getSignInCardActionLabel = () => {
    const {
      localizedContent: { managedUserUpdatePasswordForm: content },
      generateSignedURLSubmitting,
      generateSignedURLSuccess
    } = this.props;
    switch (true) {
      case generateSignedURLSubmitting:
        return content.successful_page_generating_sign_in_card;
      case generateSignedURLSuccess:
        return content.successful_page_download_sign_in_card;
      default:
        return content.successful_page_generate_sign_in_card;
    }
  };

  _getPanel = () => {
    const {
      title = 'School-managed account',
      detail = 'This student cannot change their own details or password.',
      user,
      password,
      errors,
      generateManagedUserSignInCard,
      generateSignedURLSubmitting,
      generateSignedURLSuccess,
      passwordMinLength,
      passwordUsed,
      multiple = false,
      submitting,
      success,
      signedURL,
      failure,
      validate,
      generatePassword,
      generating,
      changePassword,
      clearFailure,
      onClosePanel,
      userLocked = false,
      localizedContent: { managedUserUpdatePasswordForm: content, userUpdateConfirmation }
    } = this.props;

    switch (true) {
      case multiple:
        return (
          <ScrollContainer
            footerContent={
              <div>
                <div className="pad2">
                  <Button text="Done" onClick={onClosePanel} fullWidth />
                </div>
              </div>
            }
          >
            <PopoutPanelIconHeading
              type={types.WARNING_ERROR}
              title="This action cannot be performed on multiple students. Please select one student and try again"
            />
          </ScrollContainer>
        );
      case submitting:
        return (
          <ScrollContainer>
            <PopoutPanelIconHeading type={types.LOADING} title="Please wait..." subtitle="Updating student password" />
          </ScrollContainer>
        );
      case success:
        return (
          <UserUpdateConfirmation
            title={content.successful_page_title}
            subtitle={content.successful_page_subtitle}
            actions={[
              {
                text: this._getSignInCardActionLabel(),
                ...(generateSignedURLSuccess
                  ? { redirectUrl: signedURL, target: '_blank' }
                  : { action: generateManagedUserSignInCard }),
                loading: generateSignedURLSubmitting
              }
            ]}
            onSubmit={onClosePanel}
            users={[
              {
                firstName: user.firstName,
                lastName: user.lastName,
                username: getUsernameParts(user.username || '').username
              }
            ]}
            localizedContent={userUpdateConfirmation}
          />
        );
      case failure:
        return (
          <ScrollContainer>
            <ErrorStatus
              type={ErrorStatusIconTypes.ERROR}
              title={content.error_title}
              subtitle={content.error_subtitle}
              buttonText={content.error_retry}
              buttonOnClickHandler={clearFailure}
            />
          </ScrollContainer>
        );
      default:
        return (
          <ManagedUserUpdatePasswordForm
            title={title}
            detail={detail}
            firstName={user.firstName}
            lastName={user.lastName}
            username={getUsernameParts(user.username || '').username}
            password={password}
            errors={errors}
            onGeneratePassword={generatePassword}
            onPasswordChange={validate}
            onSubmit={changePassword}
            passwordMinLength={passwordMinLength}
            isPasswordUsed={passwordUsed}
            userLocked={userLocked}
            generatingPassword={generating}
          />
        );
    }
  };

  render() {
    const { open = true, onClosePanel, panelId = 'sidePanel', ariaLabel = 'Side Panel', orgId, userId } = this.props;

    return (
      <SidePanel id={panelId} ariaLabel={ariaLabel} isOpen={open} onClose={() => onClosePanel(orgId, userId)}>
        {this._getPanel()}
      </SidePanel>
    );
  }
}

ManagedUserChangePasswordPanel.propTypes = {
  orgId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  panelId: PropTypes.string,
  title: PropTypes.string.isRequired,
  detail: PropTypes.string,
  generateSignedURLSuccess: PropTypes.bool.isRequired,
  generateSignedURLSubmitting: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired
  }).isRequired,
  password: PropTypes.string,
  passwordMinLength: PropTypes.number,
  passwordUsed: PropTypes.bool,
  open: PropTypes.bool,
  multiple: PropTypes.bool,
  signedURL: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  errors: PropTypes.array,
  generatePassword: PropTypes.func.isRequired,
  generating: PropTypes.bool.isRequired,
  generateManagedUserSignInCard: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  clearFailure: PropTypes.func.isRequired,
  onClosePanel: PropTypes.func,
  ariaLabel: PropTypes.string,
  userLocked: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired
};

ManagedUserChangePasswordPanel.defaultProps = { onClosePanel: () => {}, passwordMinLength: 5 };

export default compose(
  withRouter,
  withLocalizedContent('managedUserUpdatePasswordForm', 'userUpdateConfirmation'),
  connect(
    state => ({
      ...state.managedUserChangePassword,
      ...pick(state.managedUserSignInCard, ['signedURL']),
      generateSignedURLSuccess: state.managedUserSignInCard.success,
      generateSignedURLSubmitting: state.managedUserSignInCard.submitting
    }),
    (dispatch, { orgId, userId, history }) => ({
      validate: input => {
        dispatch(validateManagedUserPasswordInput(input));
      },
      generatePassword: () => {
        dispatch(generateManagedUserPasswordRequest(orgId));
      },
      changePassword: () => {
        dispatch(changeManagedUserPasswordRequest(orgId, userId));
      },
      clearFailure: () => {
        dispatch(changeManagedUserPasswordClearFailure());
      },
      generateManagedUserSignInCard: () => {
        dispatch(generateManagedUserSignInCardRequest(orgId, userId));
      },
      onClosePanel: () => {
        history.push(`/org/${orgId}/user/${userId}`);
      }
    })
  )
)(ManagedUserChangePasswordPanel);
