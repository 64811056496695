import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Button, { buttonTypes } from '../Button/Button';
import styles from './Modals.scss';

class ModalAssignLevel extends Component {
  static checkVisibilityBool = visibilityBool => {
    if (visibilityBool) {
      return styles.show;
    }
    return styles.hide;
  };

  render() {
    const {
      visibilityChecker,
      noCustomStyles,
      closeCallBack,
      bottomBorderForClose,
      title,
      subTitle,
      bodyHtml,
      cancelCallBack,
      cancelButtonText,
      successCallback,
      confirmButtonText,
      customClassConfModal
    } = this.props;

    return (
      <div className={ModalAssignLevel.checkVisibilityBool(visibilityChecker)}>
        <div
          className={classnames(styles.confirmationModal, customClassConfModal, {
            [styles.noCustomStyles]: noCustomStyles
          })}
        >
          <div className={styles.modal}>
            {closeCallBack ? (
              <div className={bottomBorderForClose ? styles.bottomBorder : styles.button}>
                <Button text="Close" onClick={closeCallBack} type={buttonTypes.CLOSE} />
              </div>
            ) : null}
            <div className={styles.centerer}>
              <h1>{title}</h1>
              <p className={styles.greySubheader}>{subTitle}</p>
              {bodyHtml}
            </div>
            <div className={styles.flexRighter}>
              {cancelCallBack || cancelButtonText ? (
                <Button text={cancelButtonText} onClick={cancelCallBack} type={buttonTypes.NO_BORDER} />
              ) : null}
              {successCallback || confirmButtonText ? (
                <Button text={confirmButtonText} onClick={successCallback} type={buttonTypes.PRIMARY} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ModalAssignLevel.propTypes = {
  /**  True === visible, false === hidden */
  visibilityChecker: PropTypes.bool.isRequired,
  /** If there's no prop given for it, the element will not render (including buttons) */
  /** Defines the <h1 /> title */
  title: PropTypes.string,
  /** Defines the grey subtitle */
  subTitle: PropTypes.string,
  /** Defines the body's HTML, place HTML inside mixed array to output e.g. [<div><p>Correct</p><br /><h5>HTML!</h5></div>] */
  bodyHtml: PropTypes.array,
  /** Defines the cancel button text */
  cancelButtonText: PropTypes.string,
  /** Defines the confirm button text */
  confirmButtonText: PropTypes.string,
  /** Called when user clicks confirm */
  successCallback: PropTypes.func,
  /** Called when user clicks cancel */
  cancelCallBack: PropTypes.func,
  /** Called when the user clicks the close button */
  closeCallBack: PropTypes.func,
  noCustomStyles: PropTypes.bool,
  bottomBorderForClose: PropTypes.bool,
  customClassConfModal: PropTypes.string
};

export default ModalAssignLevel;
