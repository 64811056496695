import SessionStorage from '../../../../utils/storage/Session';

// Names non-indicative on purpose due to requirements that their nature not be obvious from the dev tools
const validChannel = 'channel19';
const invalidChannel = 'channel8';
const retryChannel = 'channel9';

// saves an cert, encoded for obfuscation
const addToChannel = (data, channelName) => {
  const storage = new SessionStorage(channelName);
  const encodedObject = {};
  Object.keys(data).forEach(key => {
    const encodedKey = btoa(key);
    const encodedValue = btoa(data[key]);
    encodedObject[encodedKey] = encodedValue;
  });
  storage.set(encodedObject);
};

const addToValidCertChannel = cert => addToChannel(cert, validChannel);
const addToInvalidCertChannel = cert => addToChannel(cert, invalidChannel);
const addToRetryChannel = cert => addToChannel(cert, retryChannel);

const clearChannel = channelName => {
  const storage = new SessionStorage(channelName);
  storage.clear();
};

const clearValidChannel = () => clearChannel(validChannel);
const clearInvalidChannel = () => clearChannel(invalidChannel);
const clearRetryChannel = () => clearChannel(retryChannel);
const clearAllCertChannels = () => {
  clearValidChannel();
  clearInvalidChannel();
  clearRetryChannel();
};

// Reading includes emptying the channel both for syntactic sugar and to make sure no one forgets to empty
// it after it's been used
const readFromChannel = channelName => {
  const storage = new SessionStorage(channelName);
  const data = storage.get();
  // If channel is empty no need to decode
  if (!data) {
    return data;
  }
  clearChannel(channelName);
  const decodedObject = {};
  Object.keys(data).forEach(key => {
    const decodedKey = atob(key);
    const decodedValue = atob(data[key]);
    decodedObject[decodedKey] = decodedValue;
  });
  return decodedObject;
};

const readValidCertChannel = () => readFromChannel(validChannel);
const readInvalidCertChannel = () => readFromChannel(invalidChannel);
const readRetryChannel = () => readFromChannel(retryChannel);

export {
  addToValidCertChannel,
  addToInvalidCertChannel,
  addToRetryChannel,
  clearValidChannel,
  clearInvalidChannel,
  clearRetryChannel,
  clearAllCertChannels,
  readValidCertChannel,
  readInvalidCertChannel,
  readRetryChannel
};
