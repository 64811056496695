import moment from 'moment';
import constants from '../../../../../components/RvsForm/constants';

import validateInputUsingSchema from '../../../utils/validateInputUsingSchema';
import rvsCertificateSchema from '../../../../../../static/schema/draft-4/rvs-certificate-data.json';

const { REGEX, REGEX_TYPE, ERROR_TYPE } = constants;

const validateInputFormat = (fieldValue, regexType) => {
  const expression = REGEX[regexType];
  const hasError = !fieldValue.match(RegExp(expression));

  if (!hasError) {
    return null;
  }

  switch (regexType) {
    case 'CEFR_LEVEL':
      return ERROR_TYPE.INVALID_CEFR_LEVEL;
    case 'CRN':
      return ERROR_TYPE.INVALID_CRN;
    default:
      return ERROR_TYPE.INVALID_FORMAT;
  }
};

const validateDateFormat = fieldValue => {
  const hasError = !moment(fieldValue).isBefore(new Date());
  return hasError ? ERROR_TYPE.INVALID_FORMAT : null;
};

const validateFormField = (fieldName, fieldValue) => {
  let emptyFieldError;

  switch (fieldName) {
    case 'testTakerName':
      emptyFieldError = ERROR_TYPE.EMPTY_TEST_TAKER_NAME;
      break;
    case 'dateOfBirth':
      emptyFieldError = ERROR_TYPE.EMPTY_DOB;
      break;
    case 'testTakerNumber':
      emptyFieldError = ERROR_TYPE.EMPTY_TEST_TAKER_NUMBER;
      break;
    case 'certificateReferenceNumber':
      emptyFieldError = ERROR_TYPE.EMPTY_CERTIFICATE_REFERENCE_NUMBER;
      break;
    case 'certificateType':
      emptyFieldError = ERROR_TYPE.INVALID_CERTIFICATE_TYPE;
      break;
    case 'overallScore':
      emptyFieldError = ERROR_TYPE.EMPTY_OVERALL_SCORE;
      break;
    case 'overallCefrLevel':
      emptyFieldError = ERROR_TYPE.EMPTY_OVERALL_CEFR_LEVEL;
      break;
    case 'speakingScore':
      emptyFieldError = ERROR_TYPE.EMPTY_SPEAKING_SCORE;
      break;
    case 'speakingCefrLevel':
      emptyFieldError = ERROR_TYPE.EMPTY_SPEAKING_CEFR_LEVEL;
      break;
    case 'speakingDateTaken':
      emptyFieldError = ERROR_TYPE.EMPTY_SPEAKING_DATE_TAKEN;
      break;
    case 'listeningScore':
      emptyFieldError = ERROR_TYPE.EMPTY_LISTENING_SCORE;
      break;
    case 'listeningCefrLevel':
      emptyFieldError = ERROR_TYPE.EMPTY_LISTENING_CEFR_LEVEL;
      break;
    case 'listeningDateTaken':
      emptyFieldError = ERROR_TYPE.EMPTY_LISTENING_DATE_TAKEN;
      break;
    case 'readingScore':
      emptyFieldError = ERROR_TYPE.EMPTY_READING_SCORE;
      break;
    case 'readingCefrLevel':
      emptyFieldError = ERROR_TYPE.EMPTY_READING_CEFR_LEVEL;
      break;
    case 'readingDateTaken':
      emptyFieldError = ERROR_TYPE.EMPTY_READING_DATE_TAKEN;
      break;
    case 'writingScore':
      emptyFieldError = ERROR_TYPE.EMPTY_WRITING_SCORE;
      break;
    case 'writingCefrLevel':
      emptyFieldError = ERROR_TYPE.EMPTY_WRITING_CEFR_LEVEL;
      break;
    case 'writingDateTaken':
      emptyFieldError = ERROR_TYPE.EMPTY_WRITING_DATE_TAKEN;
      break;
    default:
      emptyFieldError = ERROR_TYPE.EMPTY_FIELD;
  }

  const schemaValidationParams = [rvsCertificateSchema, null, { [fieldName]: fieldValue }];
  const schemaResult = validateInputUsingSchema(...schemaValidationParams).next().value;
  const [hasError] = Object.values(schemaResult);

  if (hasError) {
    return emptyFieldError;
  }

  switch (fieldName) {
    case 'testTakerName':
      return validateInputFormat(fieldValue, REGEX_TYPE.NAME);
    case 'testTakerNumber':
      return validateInputFormat(fieldValue, REGEX_TYPE.TTN);
    case 'certificateReferenceNumber':
      return validateInputFormat(fieldValue, REGEX_TYPE.CRN);
    case 'overallScore':
    case 'speakingScore':
    case 'listeningScore':
    case 'readingScore':
    case 'writingScore':
      return validateInputFormat(fieldValue, REGEX_TYPE.SCORE);
    case 'overallCefrLevel':
    case 'speakingCefrLevel':
    case 'listeningCefrLevel':
    case 'readingCefrLevel':
    case 'writingCefrLevel':
      return validateInputFormat(fieldValue, REGEX_TYPE.CEFR_LEVEL);
    case 'dateOfBirth':
    case 'speakingDateTaken':
    case 'listeningDateTaken':
    case 'readingDateTaken':
    case 'writingDateTaken':
      return validateDateFormat(fieldValue);
    default:
      return null;
  }
};

export default validateFormField;
