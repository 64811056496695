import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import { SidePanel } from '../../../../../../components';
import { types } from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ClassUserRequestSubmitting from './ClassUserRequestSubmitting';
import ClassUserRequestReview from './ClassUserRequestReview';
import ClassUserRequestConfirmation from './ClassUserRequestConfirmation';
import { clearForm, formStates, submitForm } from '../../../../../../redux/reducers/manageJoinAClassRequest.reducer';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';

class ManageJoinRequest extends React.Component {
  static _handleNotifyToggle = () => true;

  _getConfirmationHeading = () => {
    const {
      localizedContent: { manageJoinRequest: content },
      requestFailed,
      failedIds,
      numberOfStudents,
      classroomName,
      requestStatus
    } = this.props;

    if (requestFailed) {
      return {
        title: content.join_students_error_title,
        type: types.ERROR
      };
    }
    if (failedIds.length) {
      return {
        title: (content.join_students_partial_error_title || '').replace('{className}', classroomName),
        type: types.WARNING
      };
    }
    return {
      title: (requestStatus === 'ACCEPTED' ? content.join_students_success_title : content.join_students_rejected_title)
        .replace('{numberOfStudents}', numberOfStudents)
        .replace('{plural}', numberOfStudents === 1 ? '' : 's')
        .replace('{className}', classroomName),
      type: types.VALID
    };
  };

  _handleClosePanel = () => {
    const { closePanel, resetForm } = this.props;
    resetForm();
    closePanel();
  };

  _handleOnComplete = () => {
    const { onComplete, resetForm } = this.props;

    resetForm();
    if (onComplete()) onComplete();
  };

  _renderContent = () => {
    const {
      formState,
      failedIds,
      students,
      localizedContent: { manageJoinRequest: content },
      onConfirmClick,
      classroomName,
      requestStatus
    } = this.props;

    switch (formState) {
      case formStates.REVIEW_SELECTIONS:
        return (
          <ClassUserRequestReview
            users={students}
            localizedContent={content}
            onSubmitClick={onConfirmClick}
            classroomName={classroomName}
          />
        );
      case formStates.SUBMITTING:
        return <ClassUserRequestSubmitting status={requestStatus} />;

      case formStates.CONFIRMATION:
        return (
          <ClassUserRequestConfirmation
            {...this._getConfirmationHeading()}
            localizedContent={content}
            users={students}
            failedIds={failedIds}
            onDoneClick={this._handleOnComplete}
            status={requestStatus}
          />
        );

      default:
        return (
          <div>
            <p>Error. Missing a form for `{formState}`.</p>
          </div>
        );
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <SidePanel isOpen={isOpen} onClose={this._handleClosePanel}>
        {this._renderContent()}
      </SidePanel>
    );
  }
}

ManageJoinRequest.propTypes = {
  formState: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  students: PropTypes.object.isRequired,
  numberOfStudents: PropTypes.number.isRequired,
  failedIds: PropTypes.array.isRequired,
  classroomName: PropTypes.string.isRequired,
  curriculumType: PropTypes.string.isRequired,
  notifyByEmail: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onNotifyByEmailToggle: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  requestStatus: PropTypes.string
};

export default compose(
  withLocalizedContent('manageJoinRequest'),
  connect(
    state => {
      const { classrooms, classroomPage, people, manageJoinAClassRequest } = state;
      const classroom = classrooms.data[classroomPage.classroomId];

      return {
        formState: manageJoinAClassRequest.formState,
        students: pick(people.data, manageJoinAClassRequest.submittedIds),
        classroomName: classroom.name,
        requestStatus: manageJoinAClassRequest.requestStatus,
        numberOfStudents: (manageJoinAClassRequest.submittedIds || []).length,
        requestFailed: !!manageJoinAClassRequest.requestFailed,
        failedIds: manageJoinAClassRequest.failedIds || []
      };
    },
    {
      resetForm: clearForm,
      onConfirmClick: submitForm
    }
  )
)(ManageJoinRequest);
