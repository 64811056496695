import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import classnames from 'classnames';
import { connect } from 'react-redux';
// Utils
import { sanitizeUrl } from '../../utils/url';
import openProductPage from '../../utils/openProductPage';
// Constants
import { licenceStatus as LICENCE_STATUS, HubLayoutConstants } from '../../globals/hubConstants.js';
// Redux
import { openRedeemModal } from '../../redux/actions/hubUi';
// Components
import Link from '../Link/Link';
import { HubFallbackImg as FallbackImage } from '../HubImage';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import HubLabel from '../HubLabel/HubLabel';
import HubLicenceStatusItem from '../../structure/HubOrganizationLayout/OrganizationLicences/HubLicenceStatusItem';
// Services
import getUserLicencesStatusData from '../../structure/HubOrganizationLayout/OrganizationLicences/services/getUserLicencesStatusData';
// ..
// Style
import styles from './Product.scss';

function Product({
  product,
  hubContent,
  assignmentId,
  openRedeemModalAction,
  downloadIsLoading,
  downloadResourceId,
  breakpoint
}) {
  const { hasLicence } = product;
  const licenceDetails = hasLicence ? { hasLicence: true, expiryDate: product.expiryDate } : {};
  if (product.notStartedLicence) {
    licenceDetails.licenceNotStartedDetails = product.licenceNotStartedDetails;
  }
  const statusDetails = getUserLicencesStatusData(licenceDetails, hubContent);
  const { status: licenceStatus } = statusDetails;

  const { EXPIRED, NO_LICENCE, ACTIVE, EXPIRING, NOT_STARTED } = LICENCE_STATUS;
  const isLicenseExpired = licenceStatus === EXPIRED || licenceStatus === NO_LICENCE;
  const isLicenseActive = licenceStatus === ACTIVE || licenceStatus === EXPIRING || licenceStatus === NOT_STARTED;
  const isProductReadyToLaunch = product.readyToLaunch;
  const isStudentMaterial = product && product.productUserRole === HubLayoutConstants.COURSE_USER_ROLES.STUDENT;
  const isTeacherMaterial = product && product.productUserRole === HubLayoutConstants.COURSE_USER_ROLES.TEACHER;

  const handleClick = (e, isReadytolaunch) => {
    if (!isReadytolaunch) {
      return false;
    }

    if (downloadIsLoading) {
      return false;
    }

    if (isLicenseExpired) {
      openRedeemModalAction();
      return true;
    }

    const vstDomain = get(product, 'vstDomain');

    if ((vstDomain || product.platform === 'VST') && isLicenseActive) {
      e.preventDefault();
      e.stopPropagation();
      openProductPage(HubLayoutConstants.DOWNLOAD_TYPES.VST, product.isbn, {
        domain: vstDomain,
        assignmentId
      });
    }

    return true;
  };

  const getProductImage = () => {
    const image = get(product, ['image', 'defaultUrl'], '') || get(product, ['coverImage'], '');
    const altText = get(product, ['image', 'altText'], '') || product.title;
    return {
      image,
      altText
    };
  };
  const { image, altText } = getProductImage();

  const getProductLink = () => {
    if (isLicenseExpired) {
      return null;
    }

    return product.productLaunchUrl;
  };

  return (
    <div data-list="product">
      <Link
        to={getProductLink()}
        onClick={e => handleClick(e, isProductReadyToLaunch)}
        className={classnames(styles.product, { [styles[licenceStatus] || '']: licenceStatus })}
      >
        <div className={styles.imgContainer}>
          {(isLicenseExpired || !isProductReadyToLaunch) && (
            <div className={styles.lockIconContainer}>
              <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />
            </div>
          )}
          {image ? (
            <img
              className={isLicenseExpired || !isProductReadyToLaunch ? styles.lockedImage : ''}
              src={sanitizeUrl(image)}
              alt={`Course Material: ${altText}`}
            />
          ) : (
            <FallbackImage
              className={isLicenseExpired || !isProductReadyToLaunch ? styles.lockedImage : ''}
              customClassname="svgFullWidth"
              breakpoint={breakpoint}
            />
          )}
        </div>
        <div className={styles.textContainer}>
          {(!isProductReadyToLaunch || isTeacherMaterial || isStudentMaterial) && (
            <div className={styles.labelsContainer}>
              {!isProductReadyToLaunch && (
                <HubLabel
                  text={hubContent.hub_label_coming_soon}
                  customClassName={styles.comingSoonLabel}
                  isCollapsed={false}
                  PrefixIcon={false}
                />
              )}
              {isTeacherMaterial && (
                <HubLabel text={hubContent.hub_label_teacher_material} isCollapsed={false} PrefixIcon={false} />
              )}
              {isStudentMaterial && (
                <HubLabel text={hubContent.hub_label_student_material} isCollapsed={false} PrefixIcon={false} />
              )}
            </div>
          )}
          <div>
            <span>
              {product.title}
              {isLicenseActive && <SVGIcon glyph={GLYPHS.ICON_LINK} />}
              {product.isbn === downloadResourceId && downloadIsLoading && (
                <SVGIcon className={styles.loading} glyph={GLYPHS.ICON_LOADING} />
              )}
            </span>
            <span className="a11y-hide">{hubContent.ally_opens_in_new_tab}</span>
            {product.level && <p>{product.level}</p>}
            {product.hasAdditionalResources && (
              <p className={styles.addResourcesFlag}>{hubContent.has_additional_resources}</p>
            )}
          </div>

          <div className={styles.statusLicenceContainer}>
            <HubLicenceStatusItem
              licencesContext={HubLayoutConstants.LICENCES_CONTEXT.MY_COURSES}
              statusDetails={statusDetails}
              hubContent={hubContent}
              styles={styles}
            />
          </div>
        </div>
      </Link>
    </div>
  );
}

const mapStateToProps = ({ hubResourceDownload: { downloadIsLoading, downloadResourceId } }) => ({
  downloadIsLoading,
  downloadResourceId
});

const mapDispatchToProps = {
  openRedeemModalAction: openRedeemModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);

Product.propTypes = {
  product: PropTypes.object.isRequired,
  hubContent: PropTypes.object,
  assignmentId: PropTypes.string,
  openRedeemModalAction: PropTypes.func,
  downloadIsLoading: PropTypes.bool,
  downloadResourceId: PropTypes.string,
  breakpoint: PropTypes.string
};
