import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import isEmpty from 'lodash.isempty';
import Button from '../../components/Button/Button.js';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal.js';
import EntityPageHeader from '../../components/EntityPageHeader/EntityPageHeader.js';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
import withDataRecency from '../../dataRecency/withDataRecency';
import ENTITY_TYPES from '../../globals/entityTypes';
import userRoles, { canUserEditClass } from '../../globals/userRoles.js';
import { isAtLeastTeacher } from '../../redux/selectors/authorization/user';
import { closeForm } from '../../redux/reducers/classroomEdit.js';
import { setActiveClassId } from '../../redux/reducers/classroomPage.js';
import { loadClassDetails } from '../../redux/reducers/data/classrooms.js';
import content from '../../utils/cmsContent.js';
import { initialiseInstance, triggerSearch } from '../../redux/reducers/data/search.reducer';
import { getProductServicesRequest, resetProductServices } from '../../redux/actions/productServices';
import LoadedClassPageContent from './LoadedClassPageContent.js';
import SkeletonLoader from '../../components/SkeletonLoader/SkeletonLoader';
import { setActiveOrgId } from '../../redux/reducers/organisationPage.reducer';

class ClassroomPage extends Component {
  static extractProductIds = classAssignments => {
    let productIds = [];
    productIds = Object.keys(classAssignments.data);
    return productIds;
  };

  isInitialLoad = true;

  constructor(props) {
    super(props);
    const { match } = this.props;

    // Set classroomId in state for easy access
    this.initialise(match.params.orgId, match.params.classroomId);

    // TODO: Replace with Redux/URL state:

    const isProgressPage = match.params.tabName === 'gradebook';
    this.state = {
      isEditClassOpen: false,
      isConfirmCloseOpen: false,
      isShowGBTab: isProgressPage
    };
  }

  componentWillReceiveProps(nextProps) {
    const { match, productServicesRequest } = this.props;
    const { classroomId: currentClassroomId, orgId: currentOrgId } = match.params;
    const { classroomId: nextClassroomId, orgId: nextOrgId } = nextProps.match.params;
    const { classAssignments, productServicesResult } = nextProps;

    if (currentClassroomId !== nextClassroomId || currentOrgId !== nextOrgId) {
      // Class being viewed has changed, update state
      this.initialise(nextOrgId, nextClassroomId);
    }

    if (classAssignments && !isEmpty(classAssignments.data) && this.isInitialLoad) {
      const parameters = {
        productIds: ClassroomPage.extractProductIds(classAssignments)
      };
      productServicesRequest(parameters);
      this.isInitialLoad = false;
    }

    if (productServicesResult && !isEmpty(productServicesResult.serviceResults)) {
      if (productServicesResult.serviceResults.isOLBGradebook) {
        this.setState({ isShowGBTab: true });
      }
    }

    if (nextProps.location.hash === '#openEditPanel') {
      this.openEditPanel();
    }
  }

  initialise = (orgId, classId) => {
    const {
      currentOrg,
      setActiveOrgIdAction,
      loadClassDetailsAction,
      setActiveClassIdAction,
      initGetProductServices,
      initialiseSearchInstance
    } = this.props;
    console.log('[ClassroomPage] Initialising page with ID: ', classId);
    const initialFilters = {
      classId,
      orgId,
      searchAssignments: true,
      active: true
    };

    if (!Object.keys(currentOrg).length) {
      setActiveOrgIdAction(orgId);
    }

    loadClassDetailsAction(orgId, classId);
    setActiveClassIdAction(orgId, classId);
    initGetProductServices();
    initialiseSearchInstance('classAssignments', false, initialFilters);
  };

  _getBackToURL = () => {
    const { orgId, userRole } = this.props;
    switch (userRole) {
      case userRoles.OUP_SUPPORT:
      case userRoles.OUP_ADMIN:
        return `/org/${orgId}/classes`;
      default:
        return '/mySchool/classes';
    }
  };

  openEditPanel = () => this.setState({ isEditClassOpen: true });

  closeEditPanel = value => {
    const { closeFormAction } = this.props;
    this.setState(value ? { isConfirmCloseOpen: value } : { isConfirmCloseOpen: !!value, isEditClassOpen: false });
    window.location.hash = '';
    closeFormAction();
  };

  render() {
    const CMS = content.classPage || {};
    const CMSrlmModal = content.removeLearningMaterialModal || {};
    const cmsMyProfile = content.myProfilePage || {};
    const {
      match: { params },
      location,
      orgId,
      orgRole,
      orgName,
      userRole,
      classroomName = CMS.class_name_loading_text,
      classArchived,
      classLoading,
      classLoadError,
      currentUserCanEditClass,
      closeFormAction,
      classStudentsDataRecency,
      classTeachersDataRecency,
      classesDataRecency,
      classAssignmentsDataRecency,
      canManageAssignments,
      classStudents,
      loadClassDetailsAction
    } = this.props;
    const { isEditClassOpen, isConfirmCloseOpen, isShowGBTab } = this.state;
    let entityTitle = '';
    if (userRole === userRoles.LEARNER) {
      entityTitle = CMS.class_load_error_text;
    } else {
      entityTitle = classLoadError ? CMS.class_load_error_text : classroomName;
    }

    return (
      <div style={{ padding: '0 0 4rem' }}>
        <Helmet title={classroomName} />

        <div id="classroomPageHeader" className="classroom-dark">
          {classLoading ? (
            <SkeletonLoader loadingResults={classLoading} />
          ) : (
            <EntityPageHeader
              entityType={classArchived ? ENTITY_TYPES.ARCHIVED_CLASS : ENTITY_TYPES.CLASS}
              entityTitle={entityTitle}
              backButtonTo={this._getBackToURL()}
              backButtonText={`${CMS.button_back_to_text} ${
                userRole === userRoles.LEARNER ? cmsMyProfile.pageTitle : orgName
              }`}
              editButtonOnClick={
                !classLoading && !classLoadError && currentUserCanEditClass ? this.openEditPanel : null
              }
              displayEditButton={!classArchived && currentUserCanEditClass}
            />
          )}
        </div>
        {userRole !== userRoles.LEARNER ? (
          <div>
            {!classLoading && classLoading !== undefined && !classLoadError && (
              <LoadedClassPageContent
                orgId={orgId}
                orgRole={orgRole}
                classId={params.classroomId}
                classArchived={classArchived}
                params={params}
                location={location}
                closeEditPanel={this.closeEditPanel}
                isEditClassOpen={isEditClassOpen}
                currentUserCanEditClass={currentUserCanEditClass}
                classStudentsDataRecency={classStudentsDataRecency}
                classTeachersDataRecency={classTeachersDataRecency}
                classesDataRecency={classesDataRecency}
                classAssignmentsDataRecency={classAssignmentsDataRecency}
                canManageAssignments={canManageAssignments}
                showGBTabOnAssignments={isShowGBTab}
                classStudents={classStudents}
                openEditPanelOnClick={
                  !classLoading && !classLoadError && currentUserCanEditClass ? this.openEditPanel : null
                }
              />
            )}

            {classLoadError && (
              <div style={{ textAlign: 'center' }}>
                <div style={{ width: '5rem', margin: '2rem auto 0' }}>
                  <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
                </div>
                <p className="gin2">{CMS.class_details_error_text}</p>
                <Button text={CMS.button_retry_text} onClick={() => loadClassDetailsAction(params.classroomId)} />
              </div>
            )}
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <div style={{ width: '5rem', margin: '2rem auto 0' }}>
              <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
            </div>
            <p className="gin2">{CMS.class_details_error_text}</p>
          </div>
        )}
        {isConfirmCloseOpen && (
          <ConfirmationModal
            title={CMSrlmModal.title}
            body={CMSrlmModal.body}
            positiveClickText={CMSrlmModal.positiveClickText}
            negativeClickText={CMSrlmModal.negativeClickText}
            positiveClick={() => {
              // Close the Popout panel
              this.setState({ isEditClassOpen: false });
              this.setState({ isConfirmCloseOpen: false });
              closeFormAction();
            }}
            negativeClick={() => this.setState({ isConfirmCloseOpen: false })}
          />
        )}
      </div>
    );
  }
}

ClassroomPage.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
  userRole: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  orgRole: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  classroomName: PropTypes.string,
  classArchived: PropTypes.bool,
  classLoading: PropTypes.bool,
  classLoadError: PropTypes.bool,
  loadClassDetailsAction: PropTypes.func.isRequired,
  setActiveClassIdAction: PropTypes.func.isRequired,
  closeFormAction: PropTypes.func.isRequired,
  currentUserCanEditClass: PropTypes.bool.isRequired,
  classStudentsDataRecency: PropTypes.object.isRequired,
  classTeachersDataRecency: PropTypes.object.isRequired,
  classAssignmentsDataRecency: PropTypes.object.isRequired,
  classStudents: PropTypes.object,
  classesDataRecency: PropTypes.object.isRequired,
  canManageAssignments: PropTypes.bool.isRequired,
  classAssignments: PropTypes.object,
  initialiseSearchInstance: PropTypes.func.isRequired,
  productServicesRequest: PropTypes.func,
  productServicesResult: PropTypes.object,
  initGetProductServices: PropTypes.func,
  currentOrg: PropTypes.object,
  setActiveOrgIdAction: PropTypes.func
};

export default compose(
  withDataRecency('classStudents', 'classTeachers', 'classes', 'classAssignments'),
  connect(
    (state, { match: { params } }) => {
      const currentOrg = state.organisations.data[params.orgId] || {};
      const currentClass = state.classrooms.data[params.classroomId] || {};

      return {
        orgId: params.orgId,
        orgName: currentOrg.name,
        currentOrg,
        orgRole: currentOrg.role,
        productServicesResult: state.productServices,
        classAssignments: state.search.classAssignments,
        classroomName: currentClass.name,
        classArchived: currentClass.archived,
        classLoading: currentClass.loading,
        classLoadError: currentClass.errorLoading,
        classStudents: state.search.classStudents,
        currentUserCanEditClass: canUserEditClass(state.identity.userId, state.identity.role, currentClass),
        userRole: state.identity.role,
        canManageAssignments: isAtLeastTeacher(state) // Checking if teacher role, since the classroom page is only accessible to a role >= Teacher
      };
    },
    {
      loadClassDetailsAction: loadClassDetails,
      setActiveClassIdAction: setActiveClassId,
      initialiseSearchInstance: initialiseInstance,
      triggerSearchAction: () => triggerSearch('classAssignments'),
      productServicesRequest: getProductServicesRequest,
      initGetProductServices: resetProductServices,
      closeFormAction: closeForm,
      setActiveOrgIdAction: setActiveOrgId
    }
  )
)(ClassroomPage);
