import pick from 'lodash.pick';
import * as t from '../actionTypes';

const initialState = {
  products: [], // all products
  orgProducts: [], // products already added to org
  submitting: false,
  success: false,
  errors: {}
};

function subscriptionProducts(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_PRODUCTS_REQUEST:
      return {
        ...initialState,
        submitting: true
      };
    case t.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        submitting: false,
        success: false,
        errors: payload
      };
    case t.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        submitting: false,
        errors: {},
        success: true,
        ...pick((payload || {}).data, ['products'])
      };
    case t.GET_ORG_PRODUCTS_SUCCESS:
      return {
        ...state,
        submitting: false,
        errors: {},
        success: true,
        orgProducts: payload
      };
    default:
      return state;
  }
}

export default subscriptionProducts;
