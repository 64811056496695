import { put } from 'redux-saga/effects';
import actions from '../../../../actions';

import validateStartEndDates from '../../../utils/validateStartEndDates';
import { ISO_DATE } from '../../../../../globals/dateFormats';

function* validateInputInvoiceReport(input) {
  const { startDate, endDate } = input.formData;
  const { field } = input;

  const bodyErrors = {};

  if ((['startDate', 'endDate'].includes(field) && startDate !== undefined) || endDate !== undefined) {
    const dateErrors =
      startDate && endDate ? validateStartEndDates(input.formData, { startDate, endDate }, ISO_DATE, true) : {};
    const errors = { ...bodyErrors, ...dateErrors };
    yield put(actions.invoiceReportValidated(errors));
  }
}

export default validateInputInvoiceReport;
