import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import keys from 'lodash.keys';
import get from 'lodash.get';

import Thumbnail, { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import Link from '../../../components/Link/Link';
import getCourseAssessmentLink from '../../HubCourseAssessments/Services/getCourseAssessmentLink';
import getResourceLink from '../Services/getResourceLink';

import styles from './CourseComponentsMenu.scss';

const RESOURCES = 'resources';
const REQUIRE_SIGNIN = 'requiresAdditionalSignIn';

function CourseComponentsMenuItems({
  folderName,
  resourceFolderName,
  folder = {},
  course,
  activeItem,
  toggledItem,
  toggleNavbarItem,
  handleCategorySelection,
  assessmentId = '',
  dropdownPath = '',
  isPreview = false
}) {
  const resourceFolderKeys = keys(folder);
  const hasOnlyResources = resourceFolderKeys.every(subFolder => subFolder === RESOURCES);
  const navbarItemAriaLabel = `${folderName} ${folderName === toggledItem ? 'expanded' : 'collapsed'}`;

  return (
    <li>
      {hasOnlyResources ? (
        // Non dropdown folder (resources only)
        <Link
          to={dropdownPath}
          className={classnames([styles.navBarItem], {
            [styles.navBarItemActive]: folderName === activeItem && activeItem !== undefined
          })}
          onClick={() => handleCategorySelection(folderName, true)}
        >
          <div className={styles.buttonName}>{folderName}</div>
        </Link>
      ) : (
        // Dropdown folder
        <div>
          <div className={styles.dropdownButtonContainer}>
            <Link
              className={classnames([styles.navBarItem], {
                [styles.navBarItemActive]: activeItem === folderName || activeItem === resourceFolderName
              })}
              aria-label={navbarItemAriaLabel}
              to={dropdownPath}
              onClick={() => handleCategorySelection(folderName, true)}
            >
              <div className={styles.buttonName}>{folderName}</div>
            </Link>
            <button
              type="button"
              className={classnames([styles.thumbnailContainer], {
                [styles.thumbnailActive]: folderName === toggledItem
              })}
              onClick={() => toggleNavbarItem(folderName, assessmentId)}
            >
              <Thumbnail glyph={GLYPHS.CHEVRON_DOWN_THICK} size={thumbnailSizes.SMALL} />
            </button>
          </div>
          <FolderContent
            toggledItem={toggledItem}
            activeItem={activeItem}
            handleCategorySelection={handleCategorySelection}
            folder={folder}
            course={course}
            folderName={folderName}
            resourceFolderName={resourceFolderName}
            assessmentId={assessmentId}
            dropdownPath={dropdownPath}
            isPreview={isPreview}
          />
        </div>
      )}
    </li>
  );
}

export default CourseComponentsMenuItems;

CourseComponentsMenuItems.propTypes = {
  resourceFolderName: PropTypes.string,
  toggleNavbarItem: PropTypes.func.isRequired,
  handleCategorySelection: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  toggledItem: PropTypes.string,
  course: PropTypes.object.isRequired,
  folderName: PropTypes.string,
  folder: PropTypes.object,
  assessmentId: PropTypes.string,
  dropdownPath: PropTypes.string.isRequired,
  isPreview: PropTypes.bool
};

function FolderContent({
  toggledItem,
  activeItem,
  handleCategorySelection,
  course,
  folder = {},
  resourceFolderName,
  folderName,
  assessmentId = '',
  dropdownPath = '',
  isPreview
}) {
  const getMenuItem = (subFolderKey, itemLink) => (
    <Link
      key={subFolderKey}
      to={itemLink}
      className={classnames([styles.dropdownItemChild], {
        [styles.dropdownItemChildActive]: activeItem === subFolderKey
      })}
      onClick={() => handleCategorySelection(subFolderKey)}
    >
      <div className={styles.border} />
      <div className={styles.linkContainer}>
        <div>{folder[subFolderKey]?.title}</div>
      </div>
    </Link>
  );

  return (
    <div
      className={classnames([styles.dropdownItemsContainer], {
        [styles.childrenActive]: toggledItem === folderName
      })}
    >
      <Link
        key={`${folderName}Home`}
        to={dropdownPath}
        className={classnames([styles.dropdownItemChild], {
          [styles.dropdownItemChildActive]:
            activeItem === `${folderName}Home` || (activeItem === `${resourceFolderName}Home` && resourceFolderName)
        })}
        onClick={() => handleCategorySelection(`${folderName}Home`)}
      >
        <div className={styles.border} />
        <div className={styles.linkContainer}>
          <div className={styles.link}>{`${folderName} home`}</div>
        </div>
      </Link>
      {/* Handle resource folder */}
      {!assessmentId &&
        keys(folder).map(subFolderKey =>
          subFolderKey !== RESOURCES
            ? getMenuItem(subFolderKey, `${getResourceLink(course?.id, resourceFolderName, isPreview)}/${subFolderKey}`)
            : null
        )}
      {/* Handle assessment folder */}
      {assessmentId &&
        keys(folder).map(subFolderKey =>
          folder[subFolderKey] && folder[subFolderKey].title && get(folder, [subFolderKey])
            ? getMenuItem(
                subFolderKey,
                `${getCourseAssessmentLink(course?.id, assessmentId, folderName)}/${subFolderKey}`
              )
            : null
        )}
    </div>
  );
}

FolderContent.propTypes = {
  handleCategorySelection: PropTypes.func.isRequired,
  resourceFolderName: PropTypes.string,
  activeItem: PropTypes.string,
  toggledItem: PropTypes.string,
  course: PropTypes.object.isRequired,
  folder: PropTypes.object,
  folderName: PropTypes.string,
  assessmentId: PropTypes.string,
  dropdownPath: PropTypes.string.isRequired,
  isPreview: PropTypes.bool
};

function AdditionalResources({
  toggledItem,
  hubContent,
  additionalResources,
  resourceFolderName,
  hasOnlyAdditionalResources,
  course
}) {
  return (
    <div
      className={classnames([styles.dropdownItemsContainer], {
        [styles.childrenActive]: toggledItem === resourceFolderName
      })}
    >
      {!hasOnlyAdditionalResources && <b className={styles.additionalResources}>{hubContent.additional_resources} </b>}
      {additionalResources.resources.map((resource, index) => (
        <div key={index} className={styles.dropdownItemChild}>
          <div className={styles.border} />
          <div className={styles.linkContainer}>
            <Link className={styles.link} to={course.resources[resource].url}>
              {course.resources[resource].title}
              <span className="a11y-hide">{hubContent.ally_opens_in_new_tab}</span>
              <div className={styles.svgContainer}>
                {course.resources[resource].url && <SVGIcon glyph={GLYPHS.ICON_LINK} className={styles.linkIcon} />}
              </div>
            </Link>
            {course.resources[resource].keywords.indexOf(REQUIRE_SIGNIN) >= 0 && (
              <div className={styles.subtext}>{hubContent.require_signin}</div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

AdditionalResources.propTypes = {
  toggledItem: PropTypes.string,
  hubContent: PropTypes.object,
  additionalResources: PropTypes.object,
  resourceFolderName: PropTypes.string,
  hasOnlyAdditionalResources: PropTypes.bool,
  course: PropTypes.object
};
