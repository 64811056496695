import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import checkActivationCodeStatus from './checkActivationCode.saga';
import createActivationCode from './createActivationCode.saga';
import createProduct from '../product/createProduct.saga';
import getOrganizationalUnits from '../product/getOrganizationalUnits.saga';
import deleteActivationCode from './deleteActivationCode.saga';
import getActivationCodeDetails from './getActivationCodeDetails.saga';
import lookupProducts from './lookUpProduct.saga';
import searchProductGroups from './productGroupSearch.saga';
import resetProductGroups from './productGroupReset.saga';
import searchActivationCodeBatch from './searchActivationCodeBatch.saga';
import downloadActCodesForBatch from './downloadActCodesForBatch.saga';
import getPlatforms from '../product/getPlatforms.saga';

// eslint-disable-next-line func-names
export default function*() {
  yield takeLatest(t.SEARCH_ACT_CODE_FOR_DASHBOARD, data => checkActivationCodeStatus(data));
  yield takeLatest(t.CREATE_ACT_CODE_FOR_DASHBOARD, () => createActivationCode());
  yield takeLatest(t.CREATE_CODE_BATCH_REQUEST, data => createActivationCode(data));
  yield takeLatest(t.CREATE_PRODUCT_REQUEST, data => createProduct(data));
  yield takeLatest(t.CREATE_PRODUCT_ORG_UNITS, () => getOrganizationalUnits());
  yield takeLatest(t.CREATE_PRODUCT_GET_PLATFORMS, () => getPlatforms());
  yield takeLatest(t.DELETE_ACT_CODE_FOR_DASHBOARD, () => deleteActivationCode());
  yield takeLatest(t.GET_ACT_CODE_DETAILS_FOR_DASHBOARD, data => getActivationCodeDetails(data));
  yield takeLatest(t.LOOKUP_PRODUCT_FOR_COMPLETION, data => lookupProducts(data));
  yield takeLatest(t.SEARCH_PRODUCT_GROUP, data => searchProductGroups(data));
  yield takeLatest(t.RESET_PRODUCT_GROUP, () => resetProductGroups());
  yield takeLatest(t.SEARCH_ACT_CODE_BATCH, data => searchActivationCodeBatch(data));
  yield takeLatest(t.DOWNLOAD_ACT_CODE_BATCH_CODES, data => downloadActCodesForBatch(data));
}
