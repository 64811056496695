import PropTypes from 'prop-types';
import React from 'react';
import Button from '@oup/shared-front-end/src/components/Button';
import styles from './DeclineOrgInvitation.scss';
import content from '../../utils/cmsContent';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import { isHubMode } from '../../utils/platform';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import PopoutPanelIllustrationHeading from '../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import { HubIllustrationAltText, HubIllustrationConstants } from '../../globals/hubConstants';

function DeclineOrgInvitationReview({ orgName, offeredRole, onContinueDecline, onDismissPage }) {
  const inviteHeading = content.invitationBanner.invitation_confirmation_heading
    .replace('{orgName}', orgName)
    .replace('{orgRole}', offeredRole);
  const inviteSubHeading = content.invitationBanner.invitation_confirmation_sub_heading.replace('{orgName}', orgName);
  return (
    <div className="row">
      <div className="col sm3" />
      <div className="col sm6">
        {isHubMode() ? (
          <PopoutPanelIllustrationHeading
            title={inviteHeading}
            subtitle={inviteSubHeading}
            illustrationSrc={HubIllustrationConstants.WARNING}
            illustrationAltText={HubIllustrationAltText.WARNING}
          />
        ) : (
          <PopoutPanelIconHeading type={types.WARNING} title={inviteHeading} subtitle={inviteSubHeading} />
        )}
        <div className="col">
          <div className={`gin-top4 row cols-center ${styles.declineOrgInvitation}`}>
            {isHubMode() && (
              <div className="col">
                <Button variant="outline" text={content.hubGlossary.cancel} onClick={onDismissPage} />
              </div>
            )}
            <div className="col">
              <Button
                onClick={onContinueDecline}
                icon={{ component: <SVGIcon glyph={GLYPHS.ICON_DELETE} /> }}
                className={isHubMode() ? styles.buttonValidationRed : ''}
                text={content.invitationBanner.button_text_not_join}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DeclineOrgInvitationReview.propTypes = {
  orgName: PropTypes.string.isRequired,
  offeredRole: PropTypes.string.isRequired,
  onContinueDecline: PropTypes.func.isRequired,
  onDismissPage: PropTypes.func
};

export default DeclineOrgInvitationReview;
