/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/state-in-constructor */
import classnames from 'classnames';
import numeral from 'numeral';
import qs from 'query-string';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { pick, has } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

import { RESOURCE_TYPE } from '@oup/shared-node-browser/constants';
import Button from '@oup/shared-front-end/src/components/Button';
import colors from '../../globals/colors';
import Breadcrumbs, { breadcrumbPathsFromHierarchy, pathnameUpNLevels } from '../Breadcrumbs/Breadcrumbs';
import LegacyButton, { buttonTypes } from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import dataFormatter from './dataFormatter';
import LearnerProgressTable from '../LearnerProgressTable/LearnerProgressTable';
import Link from '../Link/Link';
import SubSectionSkeletonLoader from '../SkeletonLoader/SubSectionSkeletonLoader';
import ProgressBar, { progressTypes } from '../ProgressBar/ProgressBar';
import PieChartProgressDetails from '../PieChartProgressDetails/PieChartProgressDetails';
import ResultsBar from '../ResultsBar/ResultsBar';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import SpacingOptions, { OPTIONS as SPACING_OPTIONS, THEMES as SPACING_THEMES } from '../SpacingOptions/SpacingOptions';
import TableAccordion, { columnTypes } from '../TableAccordion/TableAccordion';
import tableStyles from '../TableAccordion/TableAccordion.scss';
import ToggleSwitch, { toggleTypes } from '../ToggleSwitch/ToggleSwitch';
import SortLabel from '../GradebookTable/SortLabel';
import SortRecords from '../ClassProgress/SortRecords';
import styles from './LearnerProgress.scss';
import getLearnerProgressResultsArray from './getLearnerProgressResultsArray';
import { featureIsEnabled } from '../../globals/envSettings';
import downloadAsFile from '../../utils/downloadAsFile';
import formatLastAccessed from '../../utils/date/formatLastAccessed';
import { replaceCommaWithSpace, removeHtmlTags } from '../../utils/string';
import DataRefresher from '../DataRefresher/DataRefresher';
import FilterByScore from '../GradebookTable/FilterByScore';
import { storeSavedSettings } from '../../redux/reducers/savedSettings';
import ResourceFormHidden from '../../structure/HubProductLayout/Resources/ResourceFormHidden';
import { PLATFORMS, HubLayoutConstants } from '../../globals/hubConstants';
import { isHubMode } from '../../utils/platform';
import actions from '../../redux/actions';
import openProductPage from '../../utils/openProductPage';
import getIndividualAndLinkedProducts from '../../routes/Gradebook/Services/getIndividualAndLinkedProducts';

export const attemptOtions = (cmsContent = {}, featureEnable = true, isHubMyProgress = false) => {
  const ATTEMPT_OPTIONS = {
    LATEST: { value: 'latest', text: cmsContent.show_latest_attempt, tableHeading: 'Latest attempt' },
    FIRST: { value: 'first', text: cmsContent.show_first_attempt, tableHeading: 'First attempt' },
    BEST: { value: 'best', text: cmsContent.show_best_attempt, tableHeading: 'Best attempt' },
    AVERAGE: { value: 'average', text: cmsContent.show_average_attempt, tableHeading: 'Average of all attempts' }
  };
  const OLD_ATTEMPT_OPTIONS = {
    LATEST: { value: 'latest', text: 'Show latest', tableHeading: 'Latest attempt' },
    FIRST: { value: 'first', text: 'Show first', tableHeading: 'First attempt' },
    BEST: { value: 'best', text: 'Show best', tableHeading: 'Best attempt' },
    AVERAGE: { value: 'average', text: 'Show average', tableHeading: 'Average of all attempts' }
  };
  const HUB_MY_PROGRESS_OPTIONS = {
    LATEST: { value: 'latest', text: cmsContent.show_latest_attempt, tableHeading: 'Latest attempt' },
    FIRST: { value: 'first', text: cmsContent.show_first_attempt, tableHeading: 'First attempt' }
  };

  switch (true) {
    case isHubMyProgress:
      return HUB_MY_PROGRESS_OPTIONS;
    case featureEnable:
      return ATTEMPT_OPTIONS;
    default:
      return OLD_ATTEMPT_OPTIONS;
  }
};

const formatFraction = (numerator, denominator, usePercentages) => {
  if (usePercentages) {
    return numeral(numerator / denominator).format('0.[0]%');
  }

  return `${Math.round(numerator)}/${Math.round(denominator)}`;
};

class LearnerProgress extends Component {
  state = {
    showPercentages: true,
    useCompletedOnly: true,
    fileName: '',
    fileType: 'csv',
    generateCSV: false,
    isTooltipOpen: false,
    key: null
  };

  componentDidUpdate() {
    const { gradebookClassReport, myProgress, params, identity, loadGradebookDetailsAction, tableLoading } = this.props;
    const { generateCSV } = this.state;
    if (!Object.keys(gradebookClassReport?.products || {}).length && !gradebookClassReport.loading) {
      const productParams = myProgress
        ? { ...params, myProgress, userId: identity?.userId }
        : {
            classroomId: params.classroomId,
            orgId: params.orgId,
            tabName: params.tabName
          };
      loadGradebookDetailsAction('', 'products', productParams);
    }
    if (tableLoading === false && generateCSV === true) {
      this._generateCSV();
    }
  }
  /**
   * Takes a table/rows object prop (outlined in LearnerProgressTable) and
   * outputs both completion & score averages of all rows.
   *
   * @param {array} rows An array of objects containing the content of
   * individual learner progress rows.
   */

  getTotalAverages = rows =>
    rows.reduce(
      (carry, { levelTotals: currentLevelTotals }) => {
        carry.totalLevelsAvailable += currentLevelTotals.totalLevelsAvailable || 0;
        carry.totalLevelsCompleted += currentLevelTotals.totalLevelsCompleted || 0;
        carry.totalScoreAchieved +=
          currentLevelTotals.totalNumberOfAttempts && currentLevelTotals.totalScoreAchieved
            ? currentLevelTotals.totalScoreAchieved
            : 0;
        carry.totalScoreAvailable +=
          currentLevelTotals.totalNumberOfAttempts && currentLevelTotals.totalScoreAvailable
            ? currentLevelTotals.totalScoreAvailable
            : 0;
        carry.totalNumberOfAttempts += currentLevelTotals.totalNumberOfAttempts || 0;
        carry.allActivityTotalScoreAvailable += currentLevelTotals.allActivityTotalScoreAvailable || 0;
        return carry;
      },
      {
        totalLevelsAvailable: 0,
        totalLevelsCompleted: 0,
        totalScoreAchieved: 0,
        totalScoreAvailable: 0,
        totalNumberOfAttempts: 0,
        allActivityTotalScoreAvailable: 0
      }
    );

  /**
   * Returns the basepath minus the learner ID.
   *
   * @return {string} The basepath string.
   */
  getLearnerBasepath = () =>
    window.location.pathname
      .split('/')
      .slice(0, -1)
      .join('/');

  /**
   * Returns the ResultsBar component
   *
   * @param {Object} level Current level object.
   * @param {string} attemptFilter Current filter value.
   * @param {object} levelTotals Aggregated totals for the current level.
   * @param {boolean} showDash Show a dash instead of score or not
   * @param {boolean} isVstProduct If the product is of type VST
   * @param {boolean} vstActivityLink Link for the activity in the VST platform if the product is of type VST
   */
  getResultsBar = (
    { totalScoreAchieved, totalScoreAvailable, totalNumberOfAttempts, pendingMarks },
    attemptFilter,
    levelTotals,
    showDash,
    isVstProduct,
    productForTeacher,
    activityId
  ) => {
    const { params } = this.props;
    const { showPercentages } = this.state;

    const getLinkVSTProducts = () => {
      this.handleClickVSTProducts(activityId, params.classroomId, params.learnerId, productForTeacher);
    };

    const score = !showDash
      ? formatFraction(
          levelTotals.totalScoreAchieved,
          levelTotals.totalScoreAvailable,
          showPercentages || attemptFilter === 'average'
        )
      : 0;

    return totalNumberOfAttempts && !pendingMarks ? (
      <ResultsBar label={score} results={getLearnerProgressResultsArray(totalScoreAchieved, totalScoreAvailable)} />
    ) : (
      <ResultsBar
        label="-"
        results={[0, 0, 0, 0, 0, 0, 0, 0]}
        addPencilReview={isVstProduct && pendingMarks}
        getActivityLink={isVstProduct && getLinkVSTProducts}
        vstActivityLink
      />
    );
  };

  /**
   * Returns the attempts label
   *
   * @param {Object} level Current level object.
   * @param {string} attemptFilter Current filter value.
   */
  getAttemptsLabel = ({ attempt, totalNumberOfAttempts }, attemptFilter) => {
    const { cmsContent } = this.props;
    const label =
      attemptOtions(cmsContent).AVERAGE.value === attemptFilter
        ? `${totalNumberOfAttempts} ${totalNumberOfAttempts === 1 ? 'attempt' : 'attempts'}`
        : `(${attempt} of ${totalNumberOfAttempts})`;
    return totalNumberOfAttempts ? (
      <div className={styles.activityLabel}>
        <div>{label}</div>
        <div className={styles.count}>{totalNumberOfAttempts}</div>
      </div>
    ) : (
      'No attempts'
    );
  };

  /**
   * Returns the appropriate label string to render given an amount of set attempts via filter.
   *
   * @param {string} attemptFilterValue Current attempt filter value selected in filter.
   *
   * @return {string} Heading text associated to selected filter value.
   */
  getTableFilterLabel = attemptFilterValue => {
    const { cmsContent } = this.props;
    const attemptFilterKey = attemptFilterValue.toUpperCase();
    return attemptOtions(cmsContent)[attemptFilterKey].tableHeading;
  };

  getActivityDetailsLink = (studentAnswerViewLink, level, id, attemptFilter) => {
    const { isTooltipOpen, key } = this.state;
    const { cmsContent } = this.props;
    return featureIsEnabled('gradebook-first-and-last-answer') ? (
      <>
        {attemptFilter === 'first' || attemptFilter === 'latest' ? (
          <>
            <LegacyButton
              disabled={!level.attemptId && featureIsEnabled('gradebook-first-and-last-answer')}
              to={`/studentAnswerView/${studentAnswerViewLink}?attemptId=${level.attemptId}`}
              iconOnly
              type={buttonTypes.ROUNDED_SMALL}
            />
          </>
        ) : (
          <div
            role="button"
            aria-hidden="true"
            className={styles.tooltip}
            onClick={() => {
              this.setState({ isTooltipOpen: true, key: `${level.activityName}-${id}` });
            }}
            onBlur={() => this.setState({ isTooltipOpen: false, key: null })}
            onMouseOut={() => {
              this.setState({ isTooltipOpen: false, key: null });
            }}
          >
            <LegacyButton iconOnly disabled type={buttonTypes.ROUNDED_SMALL} />
            {isTooltipOpen && key === `${level.activityName}-${id}` && (
              <div className={styles.tooltiptext}>
                <span>{cmsContent.tooltip_text_2}</span>
                <i />
              </div>
            )}
          </div>
        )}
      </>
    ) : (
      <LegacyButton to={`/studentAnswerView/${studentAnswerViewLink}`} iconOnly type={buttonTypes.ROUNDED_SMALL} />
    );
  };

  getActivityDetailsLinkVstProduct = (product, activityId, level, id) => {
    const { params, cmsContent, attemptFilter } = this.props;
    const { isTooltipOpen, key } = this.state;

    return (
      <>
        {product?.vstDomain && attemptFilter === 'latest' ? (
          <LegacyButton
            iconOnly
            type={buttonTypes.ROUNDED_SMALL}
            onClick={() => this.handleClickVSTProducts(activityId, params.classroomId, params.learnerId, product)}
          />
        ) : (
          <div
            role="button"
            aria-hidden="true"
            className={styles.tooltip}
            onClick={() => {
              this.setState({ isTooltipOpen: true, key: `${level.activityName}-${id}` });
            }}
            onBlur={() => this.setState({ isTooltipOpen: false, key: null })}
            onMouseOut={() => {
              this.setState({ isTooltipOpen: false, key: null });
            }}
          >
            <LegacyButton iconOnly disabled type={buttonTypes.ROUNDED_SMALL} />
            {isTooltipOpen && key === `${level.activityName}-${id}` && (
              <div className={styles.tooltiptextMissingVSTDomain}>
                <span>
                  {attemptFilter !== 'latest' && product?.vstDomain
                    ? cmsContent.vst_tooltip_text_2
                    : cmsContent.tooltip_text_missing_vst_domain}
                </span>
                <i />
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  /**
   * Returns the content for the score cell
   *
   * @param {object} level Current level object.
   * @param {object} levelTotals Aggregated totals for the current level.
   * @param {boolean} showDash Show a dash instead of percentage/score or not
   * @param {string} attemptFilter Current filter value.
   * @param {bool} isVstProduct If the product is of VST type.
   * @param {object} productForTeacher The data of the product for which to show the score of the students.
   * @param {string} activityId The id of the activity for which to show the score.
   */
  getActivityScoreCell = (level, levelTotals, showDash, attemptFilter, isVstProduct, productForTeacher, activityId) => {
    const { showPercentages, useCompletedOnly } = this.state;
    const { role } = this.props;
    if (level.activityScore) {
      const denominator = useCompletedOnly
        ? levelTotals.totalScoreAvailable
        : levelTotals.allActivityTotalScoreAvailable;
      const score = formatFraction(
        levelTotals.totalScoreAchieved,
        denominator,
        showPercentages || attemptFilter === 'average'
      );
      return (
        <ProgressBar
          addPencilReview={isVstProduct && this.getPendingMarkFlag(level)}
          getActivityLink={() => {
            const { params } = this.props;
            this.handleClickVSTProducts(activityId, params.classroomId, params.learnerId, productForTeacher);
          }}
          role={role}
          percentage={showDash ? 0 : (levelTotals.totalScoreAchieved / denominator) * 100}
          type={progressTypes.SECONDARY}
          color={colors.CORRECT}
          ariaLabelText="Learner score"
          label={showDash ? '-' : score}
          customClassName={styles.learnerProgressBar}
          key="Learner progress"
        />
      );
    }
    return this.getResultsBar(level, attemptFilter, levelTotals, showDash, isVstProduct, productForTeacher, activityId);
  };

  getPendingMarkFlag = level => {
    let atLeastOnePendingMarkActivity = false;
    level.activityScore.some(activity => {
      if (activity.activityScore) {
        atLeastOnePendingMarkActivity = this.getPendingMarkFlag(activity);
      } else if (activity.pendingMarks) {
        atLeastOnePendingMarkActivity = true;
        return atLeastOnePendingMarkActivity;
      }
      return atLeastOnePendingMarkActivity;
    });

    return atLeastOnePendingMarkActivity;
  };

  isVstProduct = product => product?.platform === PLATFORMS.VST;

  handleClickVSTProducts = (activityId, classroomId, learnerId, product) => {
    if (product?.vstDomain && product.isbn) {
      openProductPage(HubLayoutConstants.DOWNLOAD_TYPES.VST, product.isbn, {
        domain: product.vstDomain,
        productTitle: product.title,
        contextId: classroomId,
        activityId,
        studentId: learnerId
      });
    }
  };

  /**
   * Gets averages from base, second, or third nested level data-structures.
   *
   * @param {object} level Current level object.
   */
  _getNestedAverages = level => {
    if (has(level, 'activityScore[0].activityScore')) {
      return level.activityScore.reduce((acc, section) => acc.concat(section.activityScore), []);
    }
    return level.activityScore || level;
  };

  /**
   * Checks if the `name` is part of the split URL segments.
   */
  isInNavigation = name =>
    window.location.hash &&
    window.location.hash
      .split('/')
      .slice(1)
      .includes(name);

  /**
   * Checks if the `name` is part of the split URL segments.
   */
  isInHierarchy = (name, hierarchy) => {
    const hierarchyData = hierarchy.split('/').map(hierarchyLevels => {
      const hierarchySecondLevel = hierarchyLevels.split('~');
      return hierarchySecondLevel[hierarchySecondLevel.length - 1];
    });
    return hierarchyData.includes(name);
  };
  /**
   * Constructs a conifg array for the expanded rows in the TableAccordion.
   *
   * @param {object} level The current level object
   */

  _getExpandedRows = (level, hierarchy) => {
    if (hierarchy) {
      return level.map(row => this.isInHierarchy(row.activityName, hierarchy));
    }
    return level.map(row => this.isInNavigation(row.activityName));
  };

  /**
   * Transforms the data into an object and JSX for display in the
   * <Table /> component.
   *
   * @param {object} data The formatted data object for display
   */
  _renderTableRows = (data, attemptFilter) => {
    const {
      params,
      products,
      classAssignments,
      profileAssignments,
      gradebookClassReport,
      useFilterByScore,
      rangeValue,
      hierarchy,
      myProgress
    } = this.props;

    const { showPercentages, useCompletedOnly } = this.state;
    const allProducts = getIndividualAndLinkedProducts(products, classAssignments, profileAssignments);

    const gradebookProduct = allProducts?.[params?.itemId];
    const isVstProduct = this.isVstProduct(gradebookProduct);
    let productForTeacher = {};
    const isAssessment =
      gradebookClassReport?.products?.[params?.itemId]?.gradebookType === RESOURCE_TYPE.ASSESSMENT.toLowerCase();
    if (
      gradebookProduct &&
      isVstProduct && // Only VST prducts
      gradebookProduct.title
    ) {
      const productTitle = gradebookProduct.title;

      productForTeacher = Object.values(allProducts).filter(
        product =>
          product?.title === productTitle && product?.target_usertype === HubLayoutConstants.TARGET_USERTYPE.TEACHER
      )[0];
    }

    return data.map((level, id) => {
      const levelTotals = this._getAggregatedScores(level);
      const showDash = levelTotals.totalLevelsCompleted === 0 || levelTotals.totalNumberOfAttempts === 0;
      const bid = level.hierarchy ? level.hierarchy[0] : null;
      const activityId = level.hierarchy ? level.hierarchy[3] : null;
      const orgId = params.orgId;
      const uId = level.hierarchy ? `olb:${bid}-${level.hierarchy[2]}` : null;
      const studentAnswerViewLink = `orgId/${orgId}/class/${params.classroomId}/${params.itemId}/${params.learnerId}/${bid}/${uId}/${activityId}`;
      const thereIsNoPendingMark = !level.pendingMarks;

      return {
        id: `${level.activityName}-${id}`,
        levelTotals,
        shouldHighlightScore:
          !showDash &&
          level.activityScore &&
          useFilterByScore &&
          (levelTotals.totalScoreAchieved /
            (useCompletedOnly ? levelTotals.totalScoreAvailable : levelTotals.allActivityTotalScoreAvailable)) *
            100 <
            rangeValue,
        revealableContent: level.activityScore ? (
          <TableAccordion
            columns={this.tableColumns(true, attemptFilter)}
            rows={this._renderTableRows(level.activityScore, attemptFilter)}
            customClass={classnames(tableStyles.tableAccordionNested, styles.hideLastHeadOnMob)}
            expandedRows={this._getExpandedRows(level.activityScore, hierarchy)}
          />
        ) : (
          false
        ),
        cells: [
          level.activityName,
          level.activityScore ? (
            <PieChartProgressDetails
              completed={levelTotals.totalLevelsCompleted}
              total={levelTotals.totalLevelsAvailable}
              usePercentages={showPercentages}
            />
          ) : (
            this.getAttemptsLabel(level, attemptFilter)
          ),
          this.getActivityScoreCell(
            level,
            levelTotals,
            showDash,
            attemptFilter,
            isVstProduct,
            productForTeacher,
            activityId
          ),
          level.activityScore
            ? 'Show details'
            : featureIsEnabled('olb-gradebook-student-answer-view') &&
              !myProgress &&
              !showDash &&
              !isAssessment &&
              (isVstProduct
                ? thereIsNoPendingMark &&
                  this.getActivityDetailsLinkVstProduct(productForTeacher, activityId, level, id)
                : this.getActivityDetailsLink(studentAnswerViewLink, level, id, attemptFilter))
        ]
      };
    });
  };

  /**
   * Returns the set table columns for the nested table.
   *
   * @param {boolean} nested
   *
   * @param {string} attemptFilter Current filter value string.
   */
  tableColumns = (nested = false, attemptFilter) => {
    const { myProgress } = this.props;
    return nested
      ? [
          { heading: '' },
          {
            heading: this.getTableFilterLabel(attemptFilter),
            type: columnTypes.TEXT
          },
          { heading: 'Score', type: columnTypes.TEXT },
          {
            heading: featureIsEnabled('olb-gradebook-student-answer-view') && !myProgress ? 'View' : '',
            type: columnTypes.BUTTON
          }
        ]
      : [
          { heading: '' },
          { heading: 'Completed', type: columnTypes.TEXT },
          { heading: 'Score', type: columnTypes.TEXT },
          { heading: '', type: columnTypes.BUTTON }
        ];
  };
  /**
   * Attempt switcher.
   *
   * @param {string} attemptFilter The attempt filter string key
   */

  handleAttemptSwitch = attemptFilter => {
    const { switchAttempt } = this.props;
    switchAttempt(attemptFilter);
  };

  /**
   * Toggles the percentage / fraction state.
   */
  toggleFractions = () => {
    const { showPercentages } = this.state;
    return this.setState({ showPercentages: !showPercentages });
  };

  /**
   * Toggles the all activities / completed only state.
   */
  _onUseCompletedOnlyOnChange = () => {
    const { useCompletedOnly } = this.state;
    return this.setState({ useCompletedOnly: !useCompletedOnly });
  };

  /**
   * Averages the totals of the appropriate nested levels into one structure.
   *
   * @param {object} level Current level to calculate
   *
   * @return {object} either the accumulated values of all scores avaliable or
   * both these values and the total completed & avaliable levels.
   */
  _getAggregatedScores = level => {
    const scoreLevels = this._getNestedAverages(level);

    if (scoreLevels.activityScore || scoreLevels[0]) {
      return scoreLevels.reduce(
        (carry, currentLevel) => {
          if (currentLevel.activityScore) return this._getAggregatedScores(currentLevel);
          carry.totalScoreAvailable += currentLevel.totalScoreAvailable || 0;
          carry.totalScoreAchieved += currentLevel.totalScoreAchieved || 0;
          carry.totalLevelsCompleted += +!!currentLevel.totalNumberOfAttempts;
          carry.totalLevelsAvailable += 1;
          carry.totalNumberOfAttempts += currentLevel.totalNumberOfAttempts || 0;
          carry.allActivityTotalScoreAvailable += currentLevel.allActivityTotalScoreAvailable || 0;
          return carry;
        },
        {
          totalScoreAvailable: 0,
          totalScoreAchieved: 0,
          totalLevelsCompleted: 0,
          totalLevelsAvailable: 0,
          totalNumberOfAttempts: 0,
          allActivityTotalScoreAvailable: 0
        }
      );
    }
    // Return this level's data
    return {
      totalScoreAvailable: level.totalScoreAvailable || 0,
      totalScoreAchieved: level.totalScoreAchieved || 0,
      totalNumberOfAttempts: level.totalNumberOfAttempts || 0
    };
  };

  _formatIfZero = (stat, showDash = false) => (parseFloat(stat) === 0 && showDash ? '-' : stat);

  /**
   * Prepends a row to the start of the LearnerProgressTable element.
   *
   * @param {array} existingRows An array of objects containing the content of
   * individual learner progress rows.
   */
  _prependTotalRow = existingRows => {
    const totalAverages = this.getTotalAverages(existingRows);
    const { showPercentages, useCompletedOnly } = this.state;
    const { attemptFilter, role, data } = this.props;

    const newRow = {
      id: 'Total Row',
      cells: [
        'Total',
        <PieChartProgressDetails
          completed={totalAverages.totalLevelsCompleted}
          total={totalAverages.totalLevelsAvailable}
          usePercentages={showPercentages}
          key="TotalRow"
        />,
        <ProgressBar
          addPencilReview={data?.pendingMark}
          role={role}
          percentage={
            (totalAverages.totalScoreAchieved /
              totalAverages[useCompletedOnly ? 'totalScoreAvailable' : 'allActivityTotalScoreAvailable']) *
              100 || 0
          }
          type={progressTypes.SECONDARY}
          color={colors.CORRECT}
          ariaLabelText="Total learner score"
          label={this._formatIfZero(
            formatFraction(
              totalAverages.totalScoreAchieved,
              totalAverages[useCompletedOnly ? 'totalScoreAvailable' : 'allActivityTotalScoreAvailable'],
              showPercentages || attemptFilter === 'average'
            ),
            totalAverages.totalLevelsCompleted === 0
          )}
          customClassName={styles.learnerProgressBar}
          key="totalLearnerScore"
        />,
        ' '
      ]
    };

    return [newRow, ...existingRows];
  };

  /**
   * Navigates to a different learner view.
   *
   * @param {string} userId
   */
  _userNavigation = userId => {
    const { history } = this.props;
    history.push(`${this.getLearnerBasepath()}/${userId}`);
  };

  _exportToCSV = (fileType = 'csv') => {
    const { data } = this.props;
    const fileName = `${data.firstname}_${data.lastname}_${moment().format('DDMMMYYYY')}`;
    this.setState({
      fileName,
      fileType,
      generateCSV: true
    });
  };

  _getCompletedScoreValue = level => {
    const { showPercentages } = this.state;
    const { attemptFilter } = this.props;
    const showDash = level.totalLevelsCompleted === 0 || level.totalNumberOfAttempts === 0;
    const levelTotals = this._getAggregatedScores(level);
    const completed = level.activityScore
      ? formatFraction(levelTotals.totalLevelsCompleted, levelTotals.totalLevelsAvailable, showPercentages)
      : '';
    const score = showDash
      ? '-'
      : formatFraction(
          levelTotals.totalScoreAchieved,
          levelTotals.totalScoreAvailable,
          showPercentages || attemptFilter === 'average'
        );
    return { completed, score };
  };

  _generateCSV = () => {
    let csvData;
    const { fileName, fileType } = this.state;
    const { params, classDetails, data, productTitle, attemptFilter } = this.props;
    const classTeachers =
      classDetails.data[params.classroomId] && classDetails.data[params.classroomId].userDetails
        ? Object.values(
            pick(classDetails.data[params.classroomId].userDetails, classDetails.data[params.classroomId].teacherIdList)
          )
        : [];
    const classTeacherNames = classTeachers.map(
      classTeacher => `${classTeacher.firstname} ${classTeacher.lastname} | `
    );
    let headingContent = '';
    headingContent += `Teacher,${classTeacherNames.join('').slice(0, -2)}\n`;
    headingContent += `Student,${data.firstname} ${data.lastname}\n`;
    headingContent += `Learning Material,${productTitle}\n`;
    headingContent += featureIsEnabled('replacing-last-accessed-with-last-opened')
      ? `Last Opened,${formatLastAccessed(data.lastOpened)}\n`
      : `Last Accessed,${formatLastAccessed(data.lastAccessed)}\n`;
    headingContent += `Attempt,${attemptFilter}\n`;
    headingContent += '\n';

    csvData = headingContent;

    const formatData = dataFormatter(data);

    const headingRow = ['Unit', 'Lesson', 'Activity', 'Completion', 'Score'];
    const TotalData = this._prependTotalRow(this._renderTableRows(formatData, attemptFilter));
    const totalRowData = TotalData[0].cells;
    const completedRaw = Math.round(totalRowData[1].props?.completed);
    const totalRaw = Math.round(totalRowData[1].props?.total);
    const totalCompletedInPercent = numeral(completedRaw / totalRaw).format('0.00%');
    const totalRows = `${totalRowData[0]},,,${' '}${totalCompletedInPercent},${' '}${totalRowData[2].props.label}`;
    const contentRow = [headingRow, [totalRows]];
    formatData.forEach(unit => {
      const { completed, score } = this._getCompletedScoreValue(unit);
      const unitRows = `${removeHtmlTags(
        replaceCommaWithSpace(unit.activityName)
      )},,,${' '}${completed},${' '}${score}`;
      contentRow.push([unitRows]);
      unit.activityScore.forEach(lesson => {
        const lessonValue = this._getCompletedScoreValue(lesson);
        const lessonRows = `${removeHtmlTags(replaceCommaWithSpace(unit.activityName))},${removeHtmlTags(
          replaceCommaWithSpace(lesson.activityName)
        )},,${' '}${lessonValue.completed},${' '}${lessonValue.score}`;
        contentRow.push([lessonRows]);
        lesson.activityScore.forEach(activity => {
          const activityValue = this._getCompletedScoreValue(activity);
          const activityRows = `${removeHtmlTags(replaceCommaWithSpace(unit.activityName))},${removeHtmlTags(
            replaceCommaWithSpace(lesson.activityName)
          )},${removeHtmlTags(replaceCommaWithSpace(activity.activityName))},${' '}${activityValue.completed},${' '}${
            activityValue.score
          }`;
          contentRow.push([activityRows]);
          activity?.activityScore?.forEach(subActivity => {
            const subActivityValue = this._getCompletedScoreValue(subActivity);
            const subActivityRows = `${removeHtmlTags(replaceCommaWithSpace(unit.activityName))},${removeHtmlTags(
              replaceCommaWithSpace(lesson.activityName)
            )},${removeHtmlTags(replaceCommaWithSpace(activity.activityName))},${removeHtmlTags(
              replaceCommaWithSpace(subActivity.activityName)
            )},${' '}${subActivityValue.completed},${' '}${subActivityValue.score}`;
            contentRow.push([subActivityRows]);
          });
        });
      });
    });

    const csvContent = contentRow.map(e => e.join(',')).join('\n');
    csvData += csvContent;

    // Download CSV file
    downloadAsFile(csvData, `${fileName}.${fileType}`, 'text/csv');

    this.setState({
      fileName: '',
      fileType: 'csv',
      generateCSV: false
    });
  };

  /**
   * Slide for update highlight scores of gradebook.
   *
   * @param {String} value update the state of range value
   */
  _sliderOnchange = value => {
    const { storeSavedSettingsAction } = this.props;
    storeSavedSettingsAction({ rangeValue: value });
  };

  render() {
    const {
      learnerId,
      myProgress,
      data,
      users,
      switchSpacing,
      tableSpacing,
      tableLoading,
      hierarchy = false,
      learnerEmail,
      productTitle,
      sortOnChange,
      sortKey,
      sortDirection,
      cmsContent,
      params,
      rangeValue,
      useFilterByScore,
      gradebookClassReport,
      storeSavedSettingsAction,
      currentOrganisationLti
    } = this.props;
    const { showPercentages, useCompletedOnly } = this.state;

    // Pre format the data if we can.
    const formattedData = tableLoading ? false : dataFormatter(data);
    const scoreAllActivitiesFeature = featureIsEnabled('olb-gradebook-score-all-activities');
    const isHubMyProgress = isHubMode() && myProgress;
    const settings = JSON.parse(sessionStorage.getItem('oup-settings'));
    let attemptFilter;
    const isAssessment =
      gradebookClassReport?.products?.[params?.itemId]?.gradebookType === RESOURCE_TYPE.ASSESSMENT.toLowerCase();
    if (settings && settings.attemptFilter) {
      if (isHubMyProgress && (settings.attemptFilter === 'average' || settings.attemptFilter === 'best')) {
        this.handleAttemptSwitch('latest');
      } else {
        attemptFilter = settings.attemptFilter;
      }
    } else {
      attemptFilter = isHubMyProgress ? 'latest' : 'best';
    }

    return (
      <div className={`grid ${styles.gridContainer}`}>
        <div className={`row ${styles.rowContainer}`}>
          <div
            className={classnames('col gin-bot2', {
              sm3: scoreAllActivitiesFeature,
              sm6: !scoreAllActivitiesFeature
            })}
          >
            <ResourceFormHidden />
            <ToggleSwitch
              id="showPercentages"
              type={toggleTypes.STRING_TOGGLE}
              labelBefore={scoreAllActivitiesFeature ? cmsContent.show_figures_as : 'Show completion as'}
              stringOn={cmsContent.fractions}
              stringOff={cmsContent.percentages}
              value={showPercentages}
              onChange={this.toggleFractions}
              blockLabelBefore={scoreAllActivitiesFeature}
            />
          </div>
          {scoreAllActivitiesFeature && !(isHubMode() && myProgress) && !(isHubMode() && isAssessment) && (
            <div className="col md4 sm6 gin-bot2">
              <ToggleSwitch
                id="useCompletedOnly"
                type={toggleTypes.STRING_TOGGLE}
                labelBefore={cmsContent.show_scores_out_of}
                stringOn={cmsContent.all_activities}
                stringOff={cmsContent.completed_only}
                value={useCompletedOnly}
                onChange={this._onUseCompletedOnlyOnChange}
                blockLabelBefore
              />
            </div>
          )}
          {!myProgress && (
            <div className="col md2 sm6 gin-bot2">
              {tableSpacing && (
                <SpacingOptions
                  onSwitch={switchSpacing}
                  selected={tableSpacing}
                  // customClassName="gin-top1 centerSpacingOptions"
                  customClassName={styles.centerSpacingOptions}
                  setTheme={SPACING_THEMES.WHITE}
                />
              )}
            </div>
          )}
          {!isAssessment && (
            <div
              className={classnames('col gin-bot2', {
                sm3: scoreAllActivitiesFeature,
                sm6: !scoreAllActivitiesFeature,
                [styles.marginLeftAuto]: isHubMode() && myProgress
              })}
            >
              <Dropdown
                id="selectOne"
                name="select-attempt"
                label={scoreAllActivitiesFeature ? cmsContent.scores : 'Student attempts'}
                options={Object.values(attemptOtions(cmsContent, scoreAllActivitiesFeature, isHubMyProgress))}
                value={attemptFilter}
                onChange={newAttemptFilter => this.handleAttemptSwitch(newAttemptFilter)}
                inline
              />
            </div>
          )}
        </div>

        {featureIsEnabled('olb-gradebook-student-answer-view') && !myProgress && (
          <FilterByScore
            rangeValue={rangeValue}
            rangeValueOnchange={this._sliderOnchange}
            useFilterByScore={useFilterByScore}
            onUseFilterByScoreOnChange={() => storeSavedSettingsAction({ useFilterByScore: !useFilterByScore })}
          />
        )}
        <div className="row">
          <div className="col">
            {!tableLoading ? (
              <Breadcrumbs
                disabled={!myProgress}
                paths={[
                  {
                    pathname: pathnameUpNLevels(myProgress ? 2 : 3),
                    text: cmsContent.button_back_to_text
                  },
                  ...breadcrumbPathsFromHierarchy(myProgress ? params.itemId : hierarchy, productTitle)
                ]}
              />
            ) : null}
            <div style={{ display: 'flex' }}>
              <h1 className={styles.workbookTitle}>{productTitle}</h1>
              <div className={classnames('pad2', styles.learnerProgress__export_button)}>
                <Button
                  variant="filled"
                  icon={{ component: <SVGIcon glyph={GLYPHS.ICON_DOWNLOAD} /> }}
                  text={cmsContent.button_gradebook_export_label}
                  onClick={() => this._exportToCSV()}
                />
              </div>
            </div>
            {isHubMode() && (
              <DataRefresher
                loading={tableLoading}
                noSidePadding
                showLabel={false}
                refreshData={() => {
                  const { loadGradebookLearner } = this.props;
                  loadGradebookLearner();
                }}
              />
            )}
            {users && !!Object.entries(users).length && (
              <div className="gin-bot1 sm-hide">
                <Dropdown
                  id="learnerNavigation"
                  name="learner-nav"
                  label="Show results for"
                  options={Object.entries(users).map(([id, { firstname, lastname }]) => ({
                    text: `${firstname} ${lastname}`,
                    value: id
                  }))}
                  value={learnerId || null}
                  onChange={this._userNavigation}
                />
              </div>
            )}
            <div className={styles.listTabs}>
              {myProgress || isEmpty(users) ? null : (
                <ul className={styles.listTabs__list}>
                  <li className={classnames(styles.listTabs__listItem, styles['listTabs__listItem--header'])}>
                    <SortLabel
                      group="class-progress"
                      name="studentName"
                      direction={sortKey === 'studentName' ? sortDirection : 'none'}
                      onClick={() => sortOnChange('studentName')}
                    >
                      Student
                    </SortLabel>
                  </li>
                  <SortRecords
                    records={Object.entries(users).map(([id, user]) => ({
                      id,
                      ...user
                    }))}
                    sortKey={sortKey === 'studentName' ? 'firstname' : 'none'}
                    direction={sortDirection}
                  >
                    {sortedRecords => (
                      <div>
                        {sortedRecords.map((record, i) => (
                          <li key={i} className={styles.listTabs__listItem}>
                            <span
                              className={classnames(
                                styles['listTabs__listItem--name'],
                                record.id === learnerId && styles['listTabs__listItem--current']
                              )}
                            >
                              <Link
                                to={{
                                  pathname: `${this.getLearnerBasepath()}/${record.id}`,
                                  search: window.location.search,
                                  hash: window.location.hash
                                }}
                              >
                                {`${record.firstname} ${record.lastname}\u00a0`}
                              </Link>
                            </span>
                            <span>
                              <SVGIcon
                                className={classnames(styles.listTabs__listIcon, styles['listTabs__listItem--chevron'])}
                                glyph={GLYPHS.ICON_RIGHT}
                              />
                            </span>
                          </li>
                        ))}
                      </div>
                    )}
                  </SortRecords>
                </ul>
              )}

              <div className={styles.listTabs__panel}>
                <div className={styles.learnerProgress}>
                  {formattedData ? (
                    <LearnerProgressTable
                      key={learnerId}
                      columns={this.tableColumns()}
                      rows={this._prependTotalRow(this._renderTableRows(formattedData, attemptFilter))}
                      user={data}
                      learnerEmail={
                        currentOrganisationLti ? learnerEmail.slice(0, learnerEmail.indexOf('@')) : learnerEmail
                      }
                      tableSpacing={tableSpacing}
                      expandedRows={this._getExpandedRows(formattedData, hierarchy)}
                      myProgress={myProgress}
                    />
                  ) : (
                    <SubSectionSkeletonLoader
                      panelName={params.panelName}
                      tabName={params.tabName}
                      learnerId={params.learnerId}
                      speed={2}
                      foregroundColor={colors.COLOR_GREY_DISABLED2}
                      backgroundColor={colors.COLOR_WHITE}
                    />
                  )}
                </div>
                {!myProgress && (
                  <div className={styles.learnerProgress__close}>
                    <LegacyButton
                      id="close"
                      type={buttonTypes.BLUE}
                      to={{
                        pathname: this.getLearnerBasepath(),
                        query: qs.parse(window.location.search),
                        hash: window.location.hash
                      }}
                      text="Close"
                      glyph={GLYPHS.ICON_CLOSE}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
LearnerProgress.propTypes = {
  learnerId: PropTypes.string.isRequired,
  myProgress: PropTypes.bool,
  data: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  switchSpacing: PropTypes.func,
  switchAttempt: PropTypes.func.isRequired,
  attemptFilter: PropTypes.oneOf(Object.values(attemptOtions()).map(item => item.value)),
  tableSpacing: PropTypes.oneOf(Object.values(SPACING_OPTIONS)),
  tableLoading: PropTypes.bool.isRequired,
  hierarchy: PropTypes.string,
  learnerEmail: PropTypes.string,
  productTitle: PropTypes.string.isRequired,
  sortOnChange: PropTypes.func,
  sortKey: PropTypes.string,
  sortDirection: PropTypes.string,
  cmsContent: PropTypes.object,
  params: PropTypes.object,
  classDetails: PropTypes.object,
  useFilterByScore: PropTypes.bool,
  rangeValue: PropTypes.string,
  storeSavedSettingsAction: PropTypes.func,
  products: PropTypes.object,
  classAssignments: PropTypes.object,
  profileAssignments: PropTypes.object,
  loadGradebookLearner: PropTypes.func,
  role: PropTypes.string,
  identity: PropTypes.object,
  gradebookClassReport: PropTypes.object,
  loadGradebookDetailsAction: PropTypes.func,
  history: PropTypes.object.isRequired,
  currentOrganisationLti: PropTypes.bool
};

export default withRouter(
  connect(
    state => ({
      rangeValue: Number(state.savedSettings.settings.rangeValue),
      useFilterByScore: state.savedSettings.settings.useFilterByScore,
      products: state.products.data,
      classAssignments: state.search.classAssignments?.data,
      profileAssignments: state.search.profileAssignments?.data,
      gradebookClassReport: state.gradebookClassReport,
      identity: state.identity,
      currentOrganisationLti: state.identity.currentOrganisationLti
    }),
    dispatch => ({
      storeSavedSettingsAction: rangeValue => {
        dispatch(storeSavedSettings(rangeValue));
      },
      loadGradebookDetailsAction: (level, page, param) => {
        dispatch(actions.gradebookClassReportRequest(level, page, param));
      }
    })
  )(LearnerProgress)
);
