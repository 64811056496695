import PropTypes from 'prop-types';
import React, { useState } from 'react';
import colors from '../../globals/colors';
import Card from '../Card/Card';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

function SystemNotification({ systemNotification, updateSystemNotificationAction, content }) {
  const [openModal, setOpenModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const _onConfirmClose = () => {
    updateSystemNotificationAction({
      systemNotification: selectedData
    });
    setOpenModal(false);
  };

  const _onCancel = () => {
    setOpenModal(false);
  };

  const notificationToggleOnChange = (isEnabled, platformCode) => {
    let selectedPlatformData = [];
    if (platformCode === 'allPlatforms') {
      Object.keys(systemNotification || {}).forEach(element => {
        if (element !== 'allPlatforms') {
          selectedPlatformData.push({
            platformCode: element.toLowerCase(),
            enabled: isEnabled
          });
        }
      });
    } else {
      selectedPlatformData = [
        {
          platformCode: platformCode.toLowerCase(),
          enabled: isEnabled
        }
      ];
    }
    setSelectedData(selectedPlatformData);
    setOpenModal(true);
  };
  const { allPlatforms, ...restSystemNotification } = systemNotification;
  const modalDetailsMessage =
    openModal &&
    selectedData.length > 0 &&
    content.confirm_close_details.replace(
      '{platformCode}',
      selectedData.length > 1 ? 'all' : selectedData[0].platformCode
    );
  return (
    <div className="row gin-top">
      <div className="col md8">
        <div className="gin-top3">
          <Card headingText="System notification" headingColor={colors.PROFILE}>
            <div className="row gin-bot3 gin-top3">
              <span className="gin-left3">
                <ToggleSwitch
                  id="sytem_notification_for_all"
                  value={systemNotification.allPlatforms}
                  onChange={isEnabled => notificationToggleOnChange(isEnabled, 'allPlatforms')}
                />
              </span>
              <span className="gin-left3 gin-top2"> {content.notification_for_all} </span>
            </div>
            {Object.keys(restSystemNotification || {}).map((platformCode, i) => (
              <div className="row gin-bot3" key={i}>
                <span className="gin-left3">
                  <ToggleSwitch
                    id={`sytem_notification_${platformCode}`}
                    value={systemNotification[platformCode]}
                    onChange={isEnabled => notificationToggleOnChange(isEnabled, platformCode)}
                  />
                </span>
                <span className="gin-left3 gin-top2"> {`${content.notification_for} ${platformCode}.`} </span>
              </div>
            ))}
          </Card>
        </div>
      </div>

      {openModal ? (
        <ConfirmationModal
          title={content.confirm_close_title}
          body={modalDetailsMessage}
          positiveClickText={content.confirm_close_continue_button}
          negativeClickText={content.confirm_close_cancel_button}
          positiveClick={_onConfirmClose}
          negativeClick={_onCancel}
        />
      ) : null}
    </div>
  );
}

SystemNotification.propTypes = {
  content: PropTypes.object.isRequired,
  systemNotification: PropTypes.array.isRequired,
  updateSystemNotificationAction: PropTypes.func.isRequired
};

export default SystemNotification;
