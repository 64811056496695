import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import parseMarkdown from '../../utils/markdown/markdown';
import FormController from '../CreateOrganisation/formUtil/FormController.js';
import RequestPasswordResetInputting from './RequestPasswordResetInputting.js';
import RequestPasswordResetConfirmation from './RequestPasswordResetConfirmation.js';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import { isOrbMode } from '../../utils/platform';

import cmsContent from '../../utils/cmsContent.js';

function RequestPasswordReset() {
  const location = useLocation();
  const CMS = cmsContent.registerPasswordReset || {};
  const { email, providerId, 'cognito-id': cognitoId } = Object.fromEntries(new URLSearchParams(location.search || {}));
  const helpAndSupportUrl = `/support/contact-us${isOrbMode() ? '?product=ORB' : ''}`;

  return (
    <div>
      <Helmet title={CMS.tabTitle} />
      <FormController
        formName="requestPasswordReset"
        initialFormState="INPUTTING"
        formStateMapping={{
          INPUTTING: (
            <RequestPasswordResetInputting
              email={email}
              providerId={providerId}
              cognitoId={cognitoId}
              helpAndSupportUrl={helpAndSupportUrl}
            />
          ),
          SUBMITTING: (
            <div className="pad-top8 pad-bot8">
              <PopoutPanelIconHeading type={types.LOADING} title={CMS.loading_title_text} />
            </div>
          ),
          LEGACY_MESSAGE: (
            <div className="grid">
              <div className="row">
                <div className="col sm2" />
                <div className="col md8">
                  <div className="pad-top8 pad-bot8">
                    {/* ERROR: User is legacy and has no email address */}
                    <PopoutPanelIconHeading type={types.WARNING} title={CMS.error_no_email_address_text} />
                    {/* eslint-disable react/no-danger */}
                    <p
                      dangerouslySetInnerHTML={{ __html: parseMarkdown(CMS.error_contact_customer_support_text) }}
                      className="pad3"
                      style={{ textAlign: 'center' }}
                    />
                  </div>
                </div>
                <div className="col sm2" />
              </div>
            </div>
          ),
          CONFIRMATION: <RequestPasswordResetConfirmation helpAndSupportUrl={helpAndSupportUrl} />
        }}
      />
    </div>
  );
}

RequestPasswordReset.propTypes = {};

export default RequestPasswordReset;
