import omit from 'lodash.omit';
import pick from 'lodash.pick';
import { put, select } from 'redux-saga/effects';
import { lockedAccountRefreshResponse } from '../../../../actions/lockedAccountRefresh';
import searchUsers from '../../../apiCalls/search/searchUsers.api';

export default function* refreshLockedAccounts() {
  const searchParams = yield select(({ search = {} }) => search.oupUsers);

  if (!searchParams) return;

  const { data = {} } = yield searchUsers({
    ...pick(searchParams, ['term', 'sort', 'size', 'filters']),
    start: searchParams.page
  });

  if (data.users) {
    const users = data.users;
    const userIds = data.ids;
    const userIdsLocked = userIds.filter(userId => users[userId].isLocked) || [];
    const userIdsNotLocked = omit(userIds, userIdsLocked);

    yield put(lockedAccountRefreshResponse({ userIdsLocked, userIdsNotLocked }));
  }
}
