import { spawn, select, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  INITIALISE_INSTANCE,
  TRIGGER_SEARCH,
  TRIGGER_SEARCH_ORG_STUDENT,
  SET_TERM,
  SET_SORT,
  SET_PAGE,
  SET_FILTER,
  setPage,
  setSearchTimestamp,
  setResults,
  setResultsOrgStudent,
  setResultsClass,
  setError,
  warmUpSearch,
  initialiseInstance
} from '../../../../reducers/data/search.reducer.js';
import {
  updateStudentsToClassroom,
  storeClassrooms,
  UPDATE_STUDENTS_TO_CLASSROOM
} from '../../../../reducers/data/classrooms';
import { SET_SEARCH_BY } from '../../../../reducers/library/library.reducer.js';

// Data store functions
import { storePeople } from '../../../../reducers/data/people.js';

import { storeProducts } from '../../../../reducers/data/products.reducer.js';
import { storeOrganisations } from '../../../../reducers/data/organisations.reducer.js';

// Search Api calls
import searchUsersApi from '../../../apiCalls/search/searchUsers.api.js';
import searchUserAssignmentsApi from '../../../apiCalls/search/searchUserAssignments.api.js';
import searchGroupAssignmentsApi from '../../../apiCalls/search/searchGroupAssignments.api.js';
import searchClassesApi from '../../../apiCalls/search/searchClasses.api.js';
import searchSubscriptionsApi from '../../../apiCalls/search/searchSubscriptions.api';
import searchOrgsApi from '../../../apiCalls/search/searchOrgs.api.js';
import searchProductsApi from '../../../apiCalls/search/searchProducts.api.js';
import searchProductsByActivationCodeApi from '../../../apiCalls/search/searchProductsByActivationCode.api.js';
import searchLMSConfig from '../../../apiCalls/lmsConfig/getLMSConfigApi';
import actions from '../../../../actions';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import { isHubMode, getCurrentPlatform, epsPlatformEltHub, epsPlatformDefault } from '../../../../../utils/platform';
import { triggerLoadClassroomEdit } from '../classroom/edit.js';
import searchOicUnlinkedJanisonOrgs from '../../../apiCalls/search/searchOicOrgs.api.js';

const productSearchTransform = ({ term, sort, filters, start, size }) =>
  searchProductsApi({
    term,
    sort,
    start,
    size,
    ...filters
  });

const instanceToCallMapping = {
  // User Selection
  userSelection: searchUsersApi,

  // Product Selection
  assignmentProducts: productSearchTransform,

  // Org Page
  orgStaff: searchUsersApi,
  orgPlacementTests: searchUsersApi,
  orgStudents: searchUsersApi,
  orgClasses: searchClassesApi,
  orgLicences: searchSubscriptionsApi,
  orgSubscriptions: searchSubscriptionsApi,
  orgAssignments: searchGroupAssignmentsApi,
  orgClassAssessments: searchUsersApi,
  // OUP Org Page for Admins:
  oupOrgs: searchOrgsApi,
  oupOrgsEmail: searchOrgsApi,
  oupStaff: searchUsersApi,
  oupUsers: data => searchUsersApi(data, true),

  // Class Page
  classStudents: searchUsersApi,
  classTeachers: searchUsersApi,
  classAssignments: searchGroupAssignmentsApi,

  // My Profile Page
  profileClasses: searchClassesApi,
  profileAssignments: searchUserAssignmentsApi,

  // User Page
  userClasses: searchClassesApi,
  userAssignments: searchUserAssignmentsApi,

  // Library Page
  libProducts: searchProductsApi,
  libCodes: searchProductsByActivationCodeApi,

  // LMS Deployments Page
  lmsConfig: searchLMSConfig,

  // OIC Janison Unlinked Orgs
  oicUlinkedJanisonOrgs: searchOicUnlinkedJanisonOrgs
};

// Extract linked products
const withLinkedProducts = [epsPlatformEltHub, epsPlatformDefault].includes(getCurrentPlatform());

const exclude = !isHubMode() ? 'vst' : '';

export const searchRequest = ({
  instance,
  term,
  sort,
  page,
  size,
  filters,
  platformCode = getCurrentPlatform(),
  isUserEmail = false
}) =>
  !instance.match(/^lib/)
    ? instanceToCallMapping[instance]({
        term,
        sort,
        filters,
        start: size * (page - 1),
        size,
        platformCode,
        withLinkedProducts,
        isUserEmail: ['oupOrgs', 'oupOrgsEmail'].includes(instance) ? isUserEmail : undefined,
        exclude
      })
    : instanceToCallMapping[instance]({
        term,
        orgId: filters.orgId,
        showOnlyRedeemed: filters.showOnlyRedeemed,
        sort,
        start: size * (page - 1),
        size,
        platformCode,
        withLinkedProducts,
        exclude
      });

// eslint-disable-next-line consistent-return
function* performSearch(instance, type, isUserEmail) {
  console.log(`[performSearch:${instance}] Starting search for instance:`, instance);

  const searchInstance = yield select(state => state.search[instance]);
  const { sort, page, size = 10, filters } = searchInstance;
  const instanceTerm = searchInstance.term ? searchInstance.term : '';
  const classId = (filters && filters.classId) || '';

  // Filter class users by ORG Status on HUB side
  if (classId && getCurrentPlatform() === epsPlatformEltHub) {
    filters.classByOrgInviteStatus = true;
  }

  const term = filters && filters.yearGroup !== undefined ? '' : instanceTerm;

  // Set timestamp for use later
  const timestamp = Date.now();
  yield put(setSearchTimestamp(instance, timestamp));

  console.log(`[performSearch:${instance}] Searching using:`, {
    term,
    sort,
    page,
    size,
    filters,
    isUserEmail
  });

  // Enrol user add to class client search implementation
  let apiResults = {};
  const clientSideSearch = searchInstance.clientSideSearch;

  if (clientSideSearch && type === SET_TERM && instance === 'orgClasses') {
    const classRoomsData = yield select(state => state.classrooms.data);
    const searchterm = instanceTerm.trim().toLowerCase();

    const filterClassRoomsData = Object.entries(classRoomsData).filter(([, classData]) => {
      if (
        classData.name &&
        classData.name
          .trim()
          .toLowerCase()
          .indexOf(searchterm) > -1
      ) {
        return true;
      }
      if (classData.teacherNameList) {
        return classData.teacherNameList.some(
          name =>
            name &&
            name
              .trim()
              .toLowerCase()
              .indexOf(searchterm) > -1
        );
      }
      return false;
    });

    // Sorting newly added class to top
    filterClassRoomsData.sort(([, classData]) => (classData.tempNewClass ? -1 : 1));

    const result = {
      status: 'success',
      data: {
        classes: {},
        ids: []
      },
      totalResults: filterClassRoomsData.length
    };

    filterClassRoomsData.forEach(([id, classData]) => {
      result.data.classes[id] = classData;
      result.data.ids.push(id);
    });

    apiResults = result;
  } else if (filters !== undefined && Object.keys(filters).length) {
    apiResults = yield searchRequest({
      instance,
      term,
      sort,
      page,
      size,
      filters,
      platformCode: getCurrentPlatform(),
      isUserEmail
    });
  } else {
    return false;
  }

  // Now that the API has returned - check that this is the most recent search response
  const { timestamp: storedTimestamp } = yield select(state => state.search[instance]);

  if (storedTimestamp !== timestamp) {
    console.log(`[performSearch:${instance}] Search result dropped due to a later timestamp being found in state`);
  } else if (!apiResults || apiResults.error || apiResults.status !== 'success') {
    console.error(`[performSearch:${instance}] Error loading search results`, apiResults);
    if (apiResults.code === 'ROLE_EXCEEDED') {
      // Redirect the user to homepage if his role is changed in the meantime
      window.location.href = '/';
    } else {
      yield put(setError(instance));
    }
  } else {
    console.log(`[performSearch:${instance}] Found ${apiResults.data.totalResults} results`, apiResults);

    if (apiResults.data.totalResults !== 0 && page > Math.ceil(apiResults.data.totalResults / 10)) {
      console.log(`[performSearch:${instance}] The requested page number is higher than the possible amount.`);

      const maxPage = Math.max(1, Math.ceil(apiResults.data.totalResults / 10));
      console.log(
        `[performSearch:${instance}] Setting new page number and triggering another search. New page number:`,
        maxPage
      );

      if (maxPage !== page) {
        yield put(setPage(instance, maxPage));
      }
    } else {
      // Merge data into state
      if (apiResults.data.users) {
        yield put(storePeople(apiResults.data.users));
      }
      if (apiResults.data.classes) {
        const orgId = filters.orgId;
        const { classes } = apiResults.data;

        // reconstruct classes object and
        // eliminate classes from other organizations (if any)
        const relevantClassesForThisOrg = {};
        Object.keys(classes).forEach(key => {
          if (classes[key].orgId === orgId) {
            relevantClassesForThisOrg[key] = classes[key];
          }
        });

        apiResults.data.classes = relevantClassesForThisOrg;
        apiResults.data.ids = Object.keys(relevantClassesForThisOrg);
        apiResults.data.totalResults = Object.keys(relevantClassesForThisOrg).length;
        yield put(storeClassrooms(apiResults.data.classes));
      }
      if (apiResults.data.subscriptions) {
        yield put(actions.storeSubscriptions(apiResults.data.subscriptions));
      }
      if (apiResults.data.products) {
        yield put(storeProducts(apiResults.data.products));
      }
      if (apiResults.data.organisations) {
        yield put(storeOrganisations(apiResults.data.organisations));
      }
      if (apiResults.data.data) {
        yield put(
          storeProducts(
            Object.keys(apiResults.data.data).reduce(
              (data, productId) => Object.assign(data, { [productId]: apiResults.data.data[productId].productDetails }),
              {}
            )
          )
        );
      }

      if (instance === 'orgStudents') {
        // for  orgStudentTab & orgstaffTab
        yield put(
          setResultsOrgStudent(
            instance,
            apiResults.data.ids,
            apiResults.data.totalResults,
            apiResults.data.data,
            apiResults.data.users
          )
        );
      } else if (instance === 'orgStaff' && featureIsEnabled('client-side-pagination')) {
        yield put(
          setResultsOrgStudent(
            instance,
            apiResults.data.ids,
            apiResults.data.totalResults,
            apiResults.data.data,
            apiResults.data.users
          )
        );
      } else if (
        (instance === 'orgClasses' || instance === 'profileClasses' || instance === 'userClasses') &&
        featureIsEnabled('client-side-pagination')
      ) {
        yield put(
          setResultsClass(
            instance,
            apiResults.data.ids,
            apiResults.data.totalResults,
            apiResults.data.data,
            apiResults.data.classes
          )
        );
        if (instance === 'orgClasses' && getCurrentPlatform() === epsPlatformEltHub) {
          /*
            The reason we need this is because in HUB, the 'My classes' view
            shows the profileClasses from state.search. However, when a new class is
            added, when the polling comes back as succesfull, its the orgClasses that get
            updated. Afterwards, going in the single class view, on the newly added class,
            the redux isn't updated, and it doesn't know yet that the current user is a TEACHER
            in that class.
          */
          const { currentOrganisationId, userId } = yield select(state => state.identity);
          yield put(
            initialiseInstance('profileClasses', false, { active: true, orgId: currentOrganisationId, userId })
          );
        }
      } else if (instance === 'lmsConfig') {
        const ids = apiResults.data.map(el => el.tool.toolId);

        yield put(setResults(instance, ids, apiResults.data.length, apiResults.data));
      } else {
        yield put(setResults(instance, apiResults.data.ids, apiResults.data.totalResults, apiResults.data.data));
        if (instance === 'classStudents' && classId && apiResults.data.users) {
          const studentIdList = Object.keys(apiResults.data.users)
            .map(id => {
              const user = apiResults.data.users[id];
              return user[`classArchiveStatus-${classId}`] === 'ACTIVE' && id;
            })
            .filter(Boolean);
          yield put(updateStudentsToClassroom(classId, studentIdList));
        }
      }
    }
  }
}

function* handlePotentialTrigger({ type, instance, preventSearch }) {
  const isUserEmail = instance === 'oupOrgsEmail';
  const asYouTypeEnabled = yield select(state => state.search[instance].searchAsYouType);
  const orgId = yield select(state => state.organisationPage.orgId || state.identity.currentOrganisationId);

  if (preventSearch) return;
  if (type !== SET_TERM || asYouTypeEnabled) {
    yield performSearch(instance, type, isUserEmail);
  }
  if (type !== SET_TERM && instance === 'libProducts') {
    yield put(warmUpSearch('libCodes', false, { orgId }));
  }
  const checkClassTeachersInstance = yield select(state => state.search.classTeachers);
  if (instance === 'classStudents' && !checkClassTeachersInstance) {
    const classId = yield select(state => state.search[instance].filters.classId);
    yield put(
      warmUpSearch('classTeachers', false, { orgId, classId, roles: ['TEACHER'], active: true, invited: true }, true)
    );
  }
}

function* listener() {
  // Listen for all changes to the selection search query values
  yield takeEvery(
    [
      INITIALISE_INSTANCE,
      TRIGGER_SEARCH,
      TRIGGER_SEARCH_ORG_STUDENT,
      SET_TERM,
      SET_SORT,
      SET_PAGE,
      SET_FILTER,
      SET_SEARCH_BY
    ],
    handlePotentialTrigger
  );
  yield takeLatest(UPDATE_STUDENTS_TO_CLASSROOM, triggerLoadClassroomEdit);
}

export default function* search() {
  console.log('[search] Registering action listeners');
  yield spawn(listener);
}
