import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './GradebookTable.scss';
import { OPTIONS as SPACING_OPTIONS } from '../SpacingOptions/SpacingOptions';
import HorizontalCarouselScroller from '../HorizontalCarouselScroller/HorizontalCarouselScroller';

const childrenPropType = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]);

const childrenWithProps = (children, props) =>
  React.Children.map(children, (child, i) =>
    React.cloneElement(child, typeof props === 'function' ? props(child, i) : props)
  );

const BasicTableComponentWithChildren = (Element, elementProps = {}) => {
  function Component({ children }) {
    return <Element {...elementProps}>{childrenWithProps(children)}</Element>;
  }

  Component.propTypes = {
    children: childrenPropType
  };

  return Component;
};

function GradebookTable({ children, spacing = 'medium', stickyColumn = false }) {
  return (
    <div
      className={classnames(styles.gradebookTable, {
        [styles['gradebookTable--stickyColumn']]: stickyColumn,
        [styles['gradebookTable--spacing-narrow']]: spacing === 'narrow',
        [styles['gradebookTable--spacing-wide']]: spacing === 'wide'
      })}
    >
      <HorizontalCarouselScroller scrollTag="table">
        <div className={styles.scrollContainer}>
          <table className={styles.gradebookTable__Table}>{childrenWithProps(children)}</table>
        </div>
      </HorizontalCarouselScroller>
    </div>
  );
}
GradebookTable.propTypes = {
  children: childrenPropType,
  spacing: PropTypes.oneOf(Object.values(SPACING_OPTIONS)),
  stickyColumn: PropTypes.bool
};

const Head = BasicTableComponentWithChildren('thead', { className: styles.gradebookTable__Head });
const Body = BasicTableComponentWithChildren('tbody', { className: styles.gradebookTable__Body });
const Foot = BasicTableComponentWithChildren('tfoot', { className: styles.gradebookTable__Foot });

function Row({ children, className = null, shaded = false, highlight = false }) {
  return (
    <tr
      className={classnames(styles.gradebookTable__Row, className, {
        [styles['gradebookTable__Row--shaded']]: shaded && !highlight,
        [styles['gradebookTable__Row--highlight']]: highlight && !shaded,
        [styles['gradebookTable__Row--shaded-highlight']]: shaded && highlight
      })}
    >
      {children}
    </tr>
  );
}
Row.propTypes = {
  children: childrenPropType,
  className: PropTypes.string,
  shaded: PropTypes.bool,
  highlight: PropTypes.bool
};

const cellPropTypes = {
  children: childrenPropType,
  className: PropTypes.string,
  shaded: PropTypes.bool,
  highlight: PropTypes.bool,
  stickyColumn: PropTypes.bool,
  width: PropTypes.any,
  colSpan: PropTypes.number,
  textAlign: PropTypes.string,
  locked: PropTypes.bool
};

function Cell({
  children,
  className = null,
  shaded = false,
  highlight = false,
  stickyColumn = false,
  width = null,
  height = null,
  headers = null,
  element: Element = 'td',
  colSpan = 1,
  textAlign = 'center',
  isHighlightScore = false,
  locked = false
}) {
  const elem = Element === 'th' ? 'Header' : 'Cell';
  return (
    <Element
      colSpan={colSpan > 1 ? colSpan : null}
      className={classnames(className, {
        [styles[`gradebookTable__${elem}`]]: true,
        [styles[`gradebookTable__${elem}--shaded`]]: shaded && !highlight,
        [styles[`gradebookTable__${elem}--highlight`]]: highlight && !shaded,
        [styles[`gradebookTable__${elem}--shaded-highlight`]]: shaded && highlight,
        [styles[`gradebookTable__${elem}--stickyColumn`]]: stickyColumn,
        [styles[`gradebookTable__${elem}--textAlign-left`]]: textAlign === 'left',
        [styles[`gradebookTable__${elem}--textAlign-right`]]: textAlign === 'right',
        [styles[`gradebookTable__${elem}--filter-highlight`]]: isHighlightScore,
        [styles.locked]: locked === true
      })}
      headers={headers}
      style={{
        ...(width !== null ? { width, minWidth: width, maxWidth: width } : {}),
        ...(height !== null ? { height } : {})
      }}
    >
      {children}
    </Element>
  );
}
Cell.propTypes = { element: PropTypes.node, ...cellPropTypes };

function Header({ children, ...props }) {
  return (
    <Cell element="th" headers="" {...props}>
      {children}
    </Cell>
  );
}
Header.propTypes = cellPropTypes;

GradebookTable.Head = Head;
GradebookTable.Body = Body;
GradebookTable.Foot = Foot;
GradebookTable.Row = Row;
GradebookTable.Cell = Cell;
GradebookTable.Header = Header;

export default GradebookTable;
