import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import unique from 'lodash.uniq';

// Action imports
import { PRODUCT_TARGET_USERTYPE as userTypes } from '@oup/shared-node-browser/constants';
import { HubIllustrationConstants, HubLayoutConstants } from '../../../globals/hubConstants';
import {
  selectProductFinderProduct,
  changeProductSeries,
  getProductFinderProducts,
  setProductFinderFilters,
  selectMultipleProducts,
  clearSelectedProducts,
  selectProductFinderSingleProduct,
  goToPreviousFormState,
  setFormState,
  clearSearchState
} from '../../../redux/actions/productFinderActions';
import { productFinderFormStates, searchTypes } from '../../../redux/reducers/productFinder.reducer';
import { selectProduct } from '../../../redux/reducers/assignLearningMaterial.reducer.js';

// Component imports
import Tabs from '../../Tabs/Tabs';
import PanelNavigationLink from '../../PanelNavigationLink/PanelNavigationLink.js';
import ScrollContainer from '../../ScrollContainer/ScrollContainer';
import SubSectionSkeletonLoader from '../../SkeletonLoader/SubSectionSkeletonLoader';
import ProductFinderProductResults from '../ProductFinderProductResults';
import Thumbnail, { SIZES as thumbnailSizes } from '../../Thumbnail/Thumbnail';
import PanelHeading from '../../PanelHeading/PanelHeading.js';
import ProductFinderFilterBar from '../ProductFinderFilterBar';

import styles from './ProductFinderFormStates.scss';
import colors from '../../../globals/colors';
import { featureIsEnabled } from '../../../globals/envSettings';
import HubEmptyStateRestyle from '../../HubEmptyStateRestyle/HubEmptyStateRestyle';
import PopoutNavFooter from '../../PopoutNavFooter/PopoutNavFooter';
import SelectedRow from '../../SelectedRow/SelectedRow';

function ProductFinderSeriesOverview({
  productsInSelectedSeries,
  filters,
  selectedSeries,
  productFinderContent,
  selectLegacyProductAction,
  changeProductFinderSeriesAction,
  closePopoutAction,
  loadingProducts,
  error,
  selectProductAction,
  setFiltersAction,
  breakpoint,
  selectedProducts,
  selectedCountryCode,
  selectMultipleProductsAction,
  clearSelectedProductsAction,
  selectSingleProductAction,
  goToPreviousFormStateAction,
  setFormStateAction,
  clearSearchStateAction
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [filteredStudentProducts, setFilteredStudentProducts] = useState([]);
  const [filteredTeacherProducts, setFilteredTeacherProducts] = useState([]);
  const [shouldShowStudentProductList, setShouldShowStudentProductList] = useState(true);
  const [shouldShowTeacherProductList, setShouldShowTeacherProductList] = useState(false);
  const [shouldShowTabs, setShouldShowTabs] = useState(false);

  const selectionLimit = HubLayoutConstants.PRODUCT_SELECTION_LIMIT;
  const multipleProductsEnabled = featureIsEnabled('product-finder-multi-select');
  const levels = unique(
    productsInSelectedSeries.filter(product => product['elt:level']).map(product => product['elt:level'])
  );
  const editions = unique(
    productsInSelectedSeries.filter(product => product.edition).map(product => product.edition?.toString())
  );

  const _getImageCover = () => {
    if (selectedTab !== 1 && filteredStudentProducts.length) {
      return filteredStudentProducts[0].cover;
    }
    if (selectedTab !== 0 && filteredTeacherProducts.length) {
      return filteredTeacherProducts[0].cover;
    }
    return selectedSeries?.cover;
  };

  const _renderProducts = (studentOrTeacherProducts, userType) => {
    const shouldRenderProducts = !loadingProducts && !error && studentOrTeacherProducts.length > 0;
    const shouldRenderEmptyState = !loadingProducts && !error && !studentOrTeacherProducts.length;
    const searchType =
      userType === userTypes.TEACHER ? searchTypes.TEACHER_PRODUCTS_IN_SERIES : searchTypes.STUDENT_PRODUCTS_IN_SERIES;

    return (
      <div className={styles.scrollContainerSeriesOverviewOuter}>
        <ScrollContainer role="search">
          <div>
            {loadingProducts ? (
              <SubSectionSkeletonLoader
                largeRectWidth="60%"
                smallRectWidth="40%"
                speed={2}
                foregroundColor={colors.COLOR_GREY_DISABLED2}
                backgroundColor={colors.COLOR_WHITE}
              />
            ) : null}

            {shouldRenderProducts && (
              <>
                {!multipleProductsEnabled && (
                  <h3 className={styles.subtitleProduct}>{productFinderContent.material}</h3>
                )}
                <ProductFinderProductResults
                  levels={levels}
                  products={studentOrTeacherProducts}
                  searchType={searchType}
                  onProductClick={(productId, productSelected) => {
                    if (featureIsEnabled('product-finder-multi-select')) {
                      // its either a product or an array of products
                      const multipleProducts = productId?.length ? productId : [productId];
                      return selectMultipleProductsAction(multipleProducts, productSelected);
                    }
                    if (featureIsEnabled('product-finder-refactor')) {
                      setFormStateAction(productFinderFormStates.REVIEW_LICENCES);
                      return selectProductAction(productId);
                    }
                    return selectLegacyProductAction(productId);
                  }}
                  productFinderContent={productFinderContent}
                  selectedProducts={selectedProducts}
                />
              </>
            )}
            {shouldRenderEmptyState && (
              <HubEmptyStateRestyle
                iconSrc={HubIllustrationConstants.INFORMATION}
                title={productFinderContent.no_teacher_or_student_material_title}
                bodyText={productFinderContent.no_teacher_or_student_material_subtitle}
              />
            )}
          </div>
        </ScrollContainer>
      </div>
    );
  };

  const _renderTabs = () => (
    <Tabs
      items={[
        {
          action: () => {
            setSelectedTab(0);
          },
          tabText: productFinderContent.student_material,
          panelContent: _renderProducts(filteredStudentProducts, userTypes.STUDENT)
        },
        {
          action: () => {
            setSelectedTab(1);
          },
          tabText: productFinderContent.teacher_material,
          panelContent: _renderProducts(filteredTeacherProducts, userTypes.TEACHER)
        }
      ]}
      current={selectedTab}
      backgroundColor={colors.WHITE}
      customClasses={{
        buttons: { selected: styles.selectedTab, unselected: styles.unselectedTab },
        tabsContainer: styles.tabsTopContainer,
        tabPanelContainer: styles.tabPanelContainer,
        container: styles.tabContainer,
        tabsContainerRow: styles.tabsContainerRow,
        tabsContainerTabList: styles.tabsContainerTabList
      }}
    />
  );
  const filterByCountry = product =>
    !product['audience:country_code'] ||
    product['audience:country_code'] === selectedCountryCode ||
    product['audience:country_code'] === 'INTERNATIONAL' ||
    selectedCountryCode === 'UNIVERSAL';

  useEffect(() => {
    const studentProducts = productsInSelectedSeries.filter(product => product.target_usertype !== userTypes.TEACHER);

    const teacherProducts = productsInSelectedSeries.filter(product => product.target_usertype === userTypes.TEACHER);

    setShouldShowStudentProductList(!!(!teacherProducts.length && studentProducts.length));
    setShouldShowTeacherProductList(!!(teacherProducts.length && !studentProducts.length));
    setShouldShowTabs(!!(teacherProducts.length && studentProducts.length));
    // we need to set the selected tab to something else than 0 or 1 when there are no tabs
    // otherwise _getImageCover won't set the correct image when filtering
    if (teacherProducts.length && studentProducts.length) {
      setSelectedTab(0);
    } else {
      setSelectedTab(2);
    }
  }, [productsInSelectedSeries]);

  useEffect(() => {
    setFilteredStudentProducts(
      productsInSelectedSeries
        .filter(product => product.target_usertype !== userTypes.TEACHER)
        .filter(product => (filters.level ? product['elt:level'] === filters.level : true))
        .filter(product => (filters.edition ? product?.edition?.toString() === filters.edition : true))
        .filter(filterByCountry)
    );
    setFilteredTeacherProducts(
      productsInSelectedSeries
        .filter(product => product.target_usertype === userTypes.TEACHER)
        .filter(product => (filters.level ? product['elt:level'] === filters.level : true))
        .filter(product => (filters.edition ? product?.edition?.toString() === filters.edition : true))
        .filter(filterByCountry)
    );
  }, [productsInSelectedSeries, filters]);

  return (
    <>
      <div className={styles.headerProductFinderOverview}>
        <div className="text-right">
          <PanelNavigationLink isLhs={false} text={productFinderContent.close_panel_text} action={closePopoutAction} />
        </div>
        <div className={styles.headerContainer}>
          <div className={styles.thumbnailContainer}>
            <Thumbnail
              size={thumbnailSizes.TABLE}
              imageSrc={_getImageCover()}
              squareImg
              imageAlt={productFinderContent.series_image}
            />
          </div>
          <div className={styles.headerTextContainer}>
            <div className={styles.titleContainer}>
              <PanelHeading customClassName={styles.panelHeading} title={selectedSeries?.name} />
              <div className={styles.changeButton}>
                <PanelNavigationLink
                  noGlyph
                  text={productFinderContent.change}
                  action={changeProductFinderSeriesAction}
                />
              </div>
            </div>
            {((levels.length > 1 && multipleProductsEnabled) || !multipleProductsEnabled) && (
              <div className={styles.filtersContainer}>
                <ProductFinderFilterBar
                  productFinderContent={productFinderContent}
                  levels={levels}
                  editions={editions}
                  breakpoint={breakpoint}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {multipleProductsEnabled ? (
        <div className={styles.scrollContainerTabs}>
          <ScrollContainer
            role="search"
            containerClassName={styles.scrollContainerProducts}
            footerContent={
              <>
                {!!selectedProducts.length && (
                  <div className={styles.productsSelectedContainer}>
                    <SelectedRow
                      content={productFinderContent}
                      selectedIds={selectedProducts}
                      maximumAllowed={selectionLimit}
                    />
                    <div className={styles.productsUnselectLinkContainer}>
                      <PanelNavigationLink
                        noGlyph
                        text={productFinderContent.clear_selected}
                        action={clearSelectedProductsAction}
                      />
                    </div>
                  </div>
                )}
                <PopoutNavFooter
                  backAction={() => {
                    goToPreviousFormStateAction();
                    clearSearchStateAction(searchTypes.TEACHER_PRODUCTS_IN_SERIES);
                    clearSearchStateAction(searchTypes.STUDENT_PRODUCTS_IN_SERIES);
                    setFiltersAction({});
                  }}
                  nextAction={() => {
                    setFormStateAction(productFinderFormStates.REVIEW_LICENCES);
                    selectSingleProductAction(selectedProducts[0]);
                  }}
                  nextButtonText={productFinderContent.next_label}
                  nextButtonDisabled={!selectedProducts.length}
                />
              </>
            }
          >
            {shouldShowTabs && _renderTabs()}
            {shouldShowStudentProductList && _renderProducts(filteredStudentProducts, userTypes.STUDENT)}
            {shouldShowTeacherProductList && _renderProducts(filteredTeacherProducts, userTypes.TEACHER)}
          </ScrollContainer>
        </div>
      ) : (
        <div className={styles.scrollContainerTabs}>
          {shouldShowTabs && _renderTabs()}
          {shouldShowStudentProductList && _renderProducts(filteredStudentProducts, userTypes.STUDENT)}
          {shouldShowTeacherProductList && _renderProducts(filteredTeacherProducts, userTypes.TEACHER)}
        </div>
      )}
    </>
  );
}

ProductFinderSeriesOverview.propTypes = {
  productsInSelectedSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedSeries: PropTypes.object,
  productFinderContent: PropTypes.object,
  closePopoutAction: PropTypes.func,
  changeProductFinderSeriesAction: PropTypes.func,
  loadingProducts: PropTypes.bool,
  error: PropTypes.bool,
  selectProductAction: PropTypes.func,
  selectLegacyProductAction: PropTypes.func,
  setFiltersAction: PropTypes.func,
  breakpoint: PropTypes.string,
  filters: PropTypes.object,
  selectedProducts: PropTypes.array,
  selectedCountryCode: PropTypes.string,
  selectMultipleProductsAction: PropTypes.func,
  clearSelectedProductsAction: PropTypes.func,
  selectSingleProductAction: PropTypes.func,
  goToPreviousFormStateAction: PropTypes.func,
  setFormStateAction: PropTypes.func,
  clearSearchStateAction: PropTypes.func
};

export default connect(
  state => ({
    selectedSeries: state.productFinder.selectedSeries,
    resultsPerPage: state.productFinder.resultsPerPage,
    error: state.productFinder.error,
    loadingProducts: state.productFinder.loadingProducts,
    productsInSelectedSeries: state.productFinder.productsInSelectedSeries,
    filters: state.productFinder.filters,
    selectedCountryCode: state.productFinder.selectedCountryCode,
    selectedProducts: state.productFinder.selectedProducts
  }),
  {
    selectLegacyProductAction: selectProduct,
    selectProductAction: selectProductFinderProduct,
    selectSingleProductAction: selectProductFinderSingleProduct,
    setFiltersAction: setProductFinderFilters,
    getProductFinderProductsAction: getProductFinderProducts,
    changeProductFinderSeriesAction: changeProductSeries,
    selectMultipleProductsAction: selectMultipleProducts,
    clearSelectedProductsAction: clearSelectedProducts,
    goToPreviousFormStateAction: goToPreviousFormState,
    setFormStateAction: setFormState,
    clearSearchStateAction: clearSearchState
  }
)(ProductFinderSeriesOverview);
