import React from 'react';
import PropTypes from 'prop-types';

import FormController from '../CreateOrganisation/formUtil/FormController.js';
import IncompleteAccountForm from './IncompleteAccountForm.js';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import IncompleteAccountConfirmation from './IncompleteAccountConfirmation.js';

import cmsContent from '../../utils/cmsContent.js';

function FullPageWrapper({ children }) {
  return (
    <div style={{ padding: '2rem 0 4rem' }}>
      <div className="grid">
        <div className="row">
          <div className="col">{children}</div>
        </div>
      </div>
    </div>
  );
}

FullPageWrapper.propTypes = {
  children: PropTypes.any.isRequired
};

function IncompleteAccount({ platform }) {
  const CMS = cmsContent.registrationPage || {};
  return (
    <FullPageWrapper>
      <FormController
        formName="incompleteAccount"
        initialFormState="EDITING"
        formStateMapping={{
          EDITING: <IncompleteAccountForm platform={platform} />,
          SUBMITTING: <PopoutPanelIconHeading type={types.LOADING} title={CMS.loading_title_text} />,
          CONFIRMATION: <IncompleteAccountConfirmation />
        }}
      />
    </FullPageWrapper>
  );
}
IncompleteAccount.propTypes = {
  platform: PropTypes.string
};
export default IncompleteAccount;
