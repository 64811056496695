import getPeopleActionButtons from '../../../structure/HubClassLayout/Services/getPeopleActionButtons.js';
import getOrgClassesActionButtons from '../../../structure/HubDashboardLayout/Services/getOrgClassesActionButtons';
import getPlacementTestActionButtons from '../../../structure/HubDashboardLayout/Services/getPlacementTestActionButtons';
import getOrgLicencesActionButtons from '../../../structure/HubOrganizationLayout/OrganizationLicences/services/getOrgLicencesActionButtons';
import getUserLicencesActionButtons from '../../../structure/HubOrganizationLayout/OrganizationLicences/services/getUserlicencesActionButtons';
import getOrgPeopleDropdownItems from '../../../structure/HubOrganizationLayout/Services/getOrgPeopleDropdownItems';
import getClassworkLicencesActionButtons from '../../../structure/HubClassLayout/Classwork/services/getClassworkLicencesActionButtons';
import getTestDropdownItems from '../../../structure/HubDashboardLayout/Services/getTestDropdownItems';
import getClassTestActionButtons from '../../../structure/HubClassLayout/Services/getClassTestActionButtons.js';
import getCourseMaterialActionButtons from '../../../structure/HubCourseAssessments/Services/getCourseMaterialActionButtons';
import getOrgLMSConfigActionButtons from '../../../structure/HubDashboardLayout/Services/getOrgLMSConfigActionButtons';
import getProductLicencesActionButton from '../../../structure/HubDashboardLayout/Services/getProductLicencesActionButtons';

const getDropdownItems = (
  item,
  content,
  dropdownOptions = {},
  dropdownActions = {},
  userRole,
  isSelfSelectedOrgAdmin,
  currentOrganisationLti = false,
  isMyTasksPage = false
) => {
  const data = {
    classTile: {
      menuItems: getOrgClassesActionButtons(content, dropdownActions, dropdownOptions)[userRole],
      menuPanelAriaLabel: content.archive_class_panel_menu_aria,
      menuButtonAriaLabel: content.archive_class_dropdown_aria
    },
    people: {
      menuItems: getPeopleActionButtons(content, dropdownOptions, dropdownActions, currentOrganisationLti),
      menuPanelAriaLabel: content.people_panel_menu_aria,
      menuButtonAriaLabel: content.people_dropdown_aria
    },
    classes: {
      menuItems: getOrgClassesActionButtons(content, dropdownActions, dropdownOptions)[userRole],
      menuPanelAriaLabel: content.org_classes_panel_menu_aria,
      menuButtonAriaLabel: content.org_classes_dropdown_aria
    },
    placementTestSession: {
      menuItems: getPlacementTestActionButtons(content, dropdownActions, dropdownOptions, isMyTasksPage),
      menuPanelAriaLabel: content.placement_test_session_panel_menu_aria,
      menuButtonAriaLabel: content.placement_test_session_dropdown_aria
    },
    licences: {
      menuItems: getOrgLicencesActionButtons(content, dropdownActions, dropdownOptions)[userRole],
      menuPanelAriaLabel: content.org_licences_panel_menu_aria,
      menuButtonAriaLabel: content.org_licences_dropdown_aria
    },
    userLicences: {
      menuItems: getUserLicencesActionButtons(content, dropdownActions, dropdownOptions, currentOrganisationLti)[
        userRole
      ],
      menuPanelAriaLabel: content.org_licences_panel_menu_aria,
      menuButtonAriaLabel: content.org_licences_dropdown_aria
    },
    classworkLicences: {
      menuItems: getClassworkLicencesActionButtons(content, dropdownActions, dropdownOptions, currentOrganisationLti)[
        userRole
      ],
      menuPanelAriaLabel: content.org_licences_panel_menu_aria,
      menuButtonAriaLabel: content.org_licences_dropdown_aria
    },
    orgStudents: {
      menuItems: getOrgPeopleDropdownItems(
        content,
        dropdownOptions,
        dropdownActions,
        isSelfSelectedOrgAdmin,
        currentOrganisationLti
      )[userRole],
      menuPanelAriaLabel: content.org_students_panel_menu_aria,
      menuButtonAriaLabel: content.org_students_dropdown_aria
    },
    orgStaff: {
      menuItems: getOrgPeopleDropdownItems(
        content,
        dropdownOptions,
        dropdownActions,
        isSelfSelectedOrgAdmin,
        currentOrganisationLti
      )[userRole],
      menuPanelAriaLabel: content.org_staff_panel_menu_aria,
      menuButtonAriaLabel: content.org_staff_dropdown_aria
    },
    assessmentTests: {
      menuItems: getTestDropdownItems(content, dropdownActions, dropdownOptions),
      menuPanelAriaLabel: content.assessment_test_panel_menu_aria,
      menuButtonAriaLabel: content.assessment_test_dropdown_aria
    },
    classAssessmentTests: {
      menuItems: getClassTestActionButtons(content, dropdownActions, dropdownOptions, userRole)[userRole],
      menuPanelAriaLabel: content.class_assessment_test_panel_menu_aria,
      menuButtonAriaLabel: content.class_assessment_test_dropdown_aria
    },
    productList: {
      menuItems: getCourseMaterialActionButtons(content, dropdownActions),
      menuPanelAriaLabel: content.product_list_dropdown_panel_aria,
      menuButtonAriaLabel: content.product_list_dropdown_button_aria
    },
    lmsConfigTools: {
      menuItems: getOrgLMSConfigActionButtons(content, dropdownActions, dropdownOptions)[userRole],
      menuPanelAriaLabel: content.org_classes_panel_menu_aria,
      menuButtonAriaLabel: content.org_classes_dropdown_aria
    },
    productLicences: {
      menuItems: getProductLicencesActionButton(content, dropdownActions),
      menuPanelAriaLabel: content.product_licences_menu_aria,
      menuButtonAriaLabel: content.product_licences_dropdown_aria
    }
  };
  return data[item];
};

export default getDropdownItems;
