import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { ORG_STAFF, ORG_STUDENTS } from '@oup/shared-node-browser/constants.js';

// Component imports
import PanelHeading from '../../../../../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import FileUploadInput from '../../../../../../components/FileUploadInput/FileUploadInput.js';
import BulkUploadInformationBubble from '../../../../../../components/BulkUploadInformationBubble/BulkUploadInformationBubble.js';
import { featureIsEnabled } from '../../../../../../globals/envSettings';
import Link from '../../../../../../components/Link/Link';
import {
  selectFile,
  resetFile,
  bulkSubmitForm,
  csvBulkSubmitForm,
  showEnrolUserForm
} from '../../../../../../redux/reducers/enrolUser.reducer.js';
import content from '../../../../../../utils/cmsContent.js';
import { isEltAssessmentMode, isOptMode, isOteMode, isOrbMode, isHubMode } from '../../../../../../utils/platform';
import { orgRoles } from '../../../../../../globals/orgRoles.js';

// const content = {
//   enrolUserBulkPage: {
//     pageTitle: 'Import users',
//     pageHeading: 'Import users',
//     pageSubheading: 'Add users to your institution',
//     fileUpload_input_label: 'Drag CSV file here',
//     fileUpload_button_chooseFile_text: 'Choose file from your computer',
//     fileUpload_button_chooseDifferentFile_text: 'Choose a different file',
//     downloadTemplate_button_text: 'Download Template',

//     processing_message_wait_heading_text: 'Please wait...',
//     processing_message_wait_subHeading_text: 'Uploading {fileName}',
//     processing_heading_text: 'Processing invitations',
//     processing_message_info_intro_text:
//       'Invitations are being sent to the users added to your institution, which may take a short while to process.',
//     processing_message_info_outro_text:
//       'You can continue to use enrolment whilst your invitations are being sent.',
//     processing_message_email_intro_text: 'You will be emailed at:',
//     processing_message_email_outro_text: 'once this task is complete.',

//     error_message_n_errorsInFile_intro_text:
//       'There are {n} errors in your file. No users have been added to your institution.',
//     error_message_n_errorsInFile_outro_text: 'Please correct the following errors and upload your file again:',
//     error_message_uploadError_intro_text: 'There was an error.',
//     error_message_uploadError_outro_text: 'Please upload your file again',
//     template_download_header_text: "Download the template to enter users",
//     close_panel_text: "Close",
//     download_file_template_text: "UserName,FirstName,LastName,EmailAddress,RoleName\nuser.name@example.com,Firstname,Lastname,user.name@example.com,LEARNER",
//     download_file_name: "MultipleUserUpload.csv",
//     download_header_text: 'Download the template to enter users',
//     information_bubble_body_text: "### How to use the template:\n 1. Open your downloaded file in Microsoft Excel, Numbers, or another spreadsheet application.\n 2. Enter a line for each user you want to add containing:\n 1. Their first name in the first column.\n 1. Their last name in the second column.\n 1. Their email address in the third column.\n 1. Their role in the fourth column. A role may be:\n * 'S' for students.\n * 'T' for teachers.\n * 'TA' for teacher administrators.\n * 'OA' for organization administrators.\n 3. Select 'Save as' and pick *'CSV (comma-delimited)'* or *'.csv'* from the file type drop-down list."
//   }
// };
function EnrolUserBulkInputting({
  closePanel,
  selectFileAction,
  resetFileAction,
  bulkSubmitFormAction,
  csvBulkSubmitFormAction,
  showEnrolUserFormAction,
  context,
  orgRole,
  administrationRightsSupportLink,
  isPlacementTest,
  organisationName
}) {
  const CMS = content.enrolUserBulkPage || {};
  const isTestCenter = isOteMode() || orgRole === orgRoles.TEST_CENTER;
  const isPrimarySchool = isOrbMode() || orgRole === orgRoles.PRIMARY_SCHOOL;
  const isHubOverwrite = isHubMode() && featureIsEnabled('supervised-users');
  const isPlacementTestOverwrite = isHubMode() && featureIsEnabled('opt-main-features') && isPlacementTest;

  const getStudentInformationBubbleBodyText = () => {
    let studentInformationBubbleBodyText = '';

    switch (true) {
      case isTestCenter:
        studentInformationBubbleBodyText = CMS.student_information_bubble_body_text_ote;
        break;
      case isOptMode() || isPlacementTestOverwrite:
        studentInformationBubbleBodyText = CMS.student_information_bubble_body_text_opt;
        break;
      case isHubOverwrite:
        studentInformationBubbleBodyText = CMS.student_information_bubble_body_text_hub;
        break;
      default:
        studentInformationBubbleBodyText = CMS.student_information_bubble_body_text;
    }
    return studentInformationBubbleBodyText;
  };

  const getStaffInformationBubbleBodyText = () => {
    let staffInformationBubbleBodyText = '';

    switch (true) {
      case isTestCenter:
        staffInformationBubbleBodyText = CMS.staff_information_bubble_body_text_ote;
        break;
      case isOptMode() || isPlacementTestOverwrite:
        staffInformationBubbleBodyText = CMS.staff_information_bubble_body_text_opt;
        break;
      case isPrimarySchool:
        staffInformationBubbleBodyText = CMS.staff_information_bubble_body_text_orb;
        break;
      case isHubOverwrite:
        staffInformationBubbleBodyText = CMS.staff_information_bubble_body_text_hub;
        break;
      default:
        staffInformationBubbleBodyText = CMS.staff_information_bubble_body_text;
    }

    return staffInformationBubbleBodyText;
  };

  const supportedFileTypes = isHubOverwrite ? ['.csv', '.txt', '.xls', '.xlsx'] : ['.csv', '.txt'];

  const _importText = () =>
    context === ORG_STUDENTS ? getStudentInformationBubbleBodyText() : getStaffInformationBubbleBodyText();

  const _headerText = () => (context === ORG_STUDENTS ? CMS.student_pageHeading : CMS.staff_pageHeading);

  const _subheaderText = () =>
    isPlacementTestOverwrite ? `${CMS.pageSubheadingOpt} <b>${organisationName}</b>` : CMS.pageSubheading;

  // Assessment master remove the class column in bulk template glossary file
  const _bulkTemplateText = (contextType, isTestCenterStatus) => {
    let templateText = '';
    if (contextType === ORG_STUDENTS) {
      templateText = isTestCenterStatus
        ? CMS.download_file_withoutclass_template_text
        : CMS.download_file_template_text || '';
    } else {
      templateText = isTestCenterStatus
        ? CMS.download_file_withoutclass_staff_template_text
        : CMS.download_file_staff_template_text || '';
    }
    if (isHubOverwrite) {
      templateText =
        contextType === ORG_STUDENTS
          ? CMS.download_file_without_username_template_text
          : CMS.download_file_staff_without_username_template_text;
    }
    if (isPlacementTestOverwrite) {
      templateText = CMS.download_file_without_class_and_username_template_text;
    }
    return templateText.replace('\\n', '\n');
  };

  function getTemplateDownloadPath(templateContext) {
    if (isPlacementTestOverwrite) return CMS.download_users_xls_template_path_opt;
    if (isHubOverwrite) {
      return templateContext === ORG_STAFF
        ? CMS.download_staff_xls_template_path
        : CMS.download_users_xls_template_path;
    }
    if (isTestCenter) {
      return templateContext === ORG_STAFF
        ? CMS.download_test_center_user_xls_template_path
        : CMS.download_test_taker_xls_template_path;
    }
    return templateContext === ORG_STAFF ? CMS.download_user_xls_template_path : CMS.download_learner_xls_template_path;
  }

  function getTemplateName(templateContext) {
    let templateName = '';
    if (templateContext === ORG_STAFF) {
      templateName = CMS.download_user_xls_template_name;
      if (isHubOverwrite) {
        templateName = CMS.download_staff_xls_template_name;
      }
    } else {
      templateName = CMS.download_learner_xls_template_name;
    }
    if (isPlacementTestOverwrite) templateName = CMS.download_user_xls_template_name;

    return templateName;
  }

  function getFileName(templateContext) {
    if (isPlacementTestOverwrite) return CMS.download_file_name;
    return templateContext === ORG_STAFF ? CMS.download_staff_file_name : CMS.download_file_name;
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePanel} />
            </div>
            <PanelHeading title={_headerText()} subtitle={_subheaderText()} />
          </div>
        }
        footerContent={<PopoutNavFooter backAction={showEnrolUserFormAction} />}
      >
        <div style={{ padding: '2rem 0 4rem' }}>
          <div className="grid">
            <div className="row">
              <div className="col">
                <FileUploadInput
                  id="fileUploadInput"
                  label={isHubOverwrite ? CMS.fileUpload_input_csv_or_excel_label : CMS.fileUpload_input_label}
                  buttonText={CMS.fileUpload_button_chooseFile_text}
                  supportedFileTypes={supportedFileTypes}
                  handleFile={selectedFile => {
                    if (selectedFile) {
                      const reader = new FileReader();

                      reader.onload = () => {
                        selectFileAction({
                          name: selectedFile.name,
                          type: selectedFile.type,
                          data: reader.result
                        });

                        if (isHubMode() && featureIsEnabled('supervised-users')) {
                          csvBulkSubmitFormAction();
                        } else {
                          bulkSubmitFormAction();
                        }
                      };

                      if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                        reader.readAsArrayBuffer(selectedFile);
                      } else {
                        reader.readAsText(selectedFile);
                      }
                    } else {
                      resetFileAction();
                    }
                  }}
                  notSupportedMessage={CMS.not_supported_file_title}
                  notSupportedMessageLine2={
                    isHubOverwrite ? CMS.not_supported_file_subtitle_with_excel : CMS.not_supported_file_subtitle
                  }
                  retryMessage={CMS.try_again}
                />
                <BulkUploadInformationBubble
                  headerTitleText={CMS.download_header_text}
                  downloadTemplateText={CMS.downloadTemplate_button_text}
                  downloadXLSTemplateText={CMS.downloadXLSTemplate_button_text}
                  downloadUserXLSTemplatePath={getTemplateDownloadPath(context)}
                  downloadUserXLSTemplateName={getTemplateName(context)}
                  downloadFileName={getFileName(context)}
                  filecontent={_bulkTemplateText(context, isEltAssessmentMode() || isTestCenter)}
                  bodyText={_importText()}
                />
              </div>
            </div>
          </div>
          {isPrimarySchool && (
            <div className="grid">
              <Link to={administrationRightsSupportLink} className="gin-top2" target="_blank" role="button">
                {CMS.view_role_administration_rights}
              </Link>
            </div>
          )}
        </div>
      </ScrollContainer>
    </form>
  );
}

EnrolUserBulkInputting.propTypes = {
  closePanel: PropTypes.func.isRequired,
  bulkSubmitFormAction: PropTypes.func.isRequired,
  csvBulkSubmitFormAction: PropTypes.func.isRequired,
  selectFileAction: PropTypes.func.isRequired,
  resetFileAction: PropTypes.func.isRequired,
  showEnrolUserFormAction: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  orgRole: PropTypes.string.isRequired,
  administrationRightsSupportLink: PropTypes.string.isRequired,
  isPlacementTest: PropTypes.bool,
  organisationName: PropTypes.string.isRequired
};
export default connect(
  (state, { orgId }) => ({
    orgId,
    organisationName: get(state, ['organisations', 'data', state.organisationPage.orgId, 'name'], '')
  }),
  {
    selectFileAction: selectFile,
    resetFileAction: resetFile,
    bulkSubmitFormAction: bulkSubmitForm,
    csvBulkSubmitFormAction: csvBulkSubmitForm,
    showEnrolUserFormAction: showEnrolUserForm
  }
)(EnrolUserBulkInputting);
