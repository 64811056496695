import { select, put } from 'redux-saga/effects';
import get from 'lodash.get';
import getIdentityDetails from '../../apiCalls/auth/getIdentityDetails.api.js';
import { appError } from '../../../reducers/app.reducer.js';
import { setIdentityDetails } from '../../../reducers/sharedActions.js';
import { storePeople } from '../../../reducers/data/people.js';
import { featureIsEnabled, isOIDCStandard } from '../../../../globals/envSettings';
import { setOidcAuthCredentials } from '../../../reducers/identity.reducer.js';
import authSettings from '../../../../globals/authSettings.js';
import { completeLogout } from '../../appFunctions/authorisedFunctions/auth/handleLogout.saga.js';
import loadLocalizedContent from '../../appFunctions/authorisedFunctions/ote/amLocalizedContent.js';
import { isHubMode, getCurrentPlatform, isOteMode } from '../../../../utils/platform';
import { extractQuery, queryParams } from '../../../../services/extractQuery';
import session from '../../../../utils/session';

export default function* getIdentity() {
  console.log('[getIdentity] Starting');

  // At this point we either have loaded or new credentials. Or we would have been redirected to Login
  // eslint-disable-next-line prefer-const
  let { userId, orgRoleMap } = yield select(state => ({
    userId: state.identity.userId,
    orgRoleMap: state.identity.orgRoleMap
  }));

  const credentials = session.get();
  const currentOrgId = credentials && credentials.currentOrganisationId;
  // check useragent
  let foundOlb = false;
  if (navigator && navigator.userAgent) {
    foundOlb = /OxfordLearnersBookshelf/.test(navigator.userAgent);
  }
  let platform = getCurrentPlatform();
  if (foundOlb) {
    platform = 'olb';
  }

  console.log('[getIdentity] Getting user identity details', credentials);
  const identityDetails = yield getIdentityDetails(userId, Object.keys(orgRoleMap), currentOrgId, platform);

  if (identityDetails.status === 'error' || identityDetails.error || !identityDetails.data) {
    console.log(
      '[getIdentity] Identity details request failed. Error:',
      identityDetails.error || identityDetails.message
    );
    yield put(appError('----  [getIdentity] Identity details request failed. Error:'));
  } else {
    console.log('[getIdentity] Identity details request succeeded. Response:', identityDetails);
    // Save user details to state.people store and state.identity:
    const user = identityDetails.data.user || {};

    if (isOIDCStandard(authSettings.idpStandard)) {
      const orgs = {};
      // Get the Highest user role
      let currentRole = user.highestRoleInAnyOrg;
      let currentOrganisationId = null;

      identityDetails.data.orgs.forEach(org => {
        if (org.isDefault) {
          currentOrganisationId = org.id;
        }
        orgs[org.id] = org.roleName;
      });
      const { tcLocalizedContentLoaded } = yield select(state => ({
        tcLocalizedContentLoaded: state.tcLocalizedContentLoaded || false
      }));
      if (isOteMode() && !tcLocalizedContentLoaded) {
        yield loadLocalizedContent();
      }
      console.log(`setAuthUserDetails - ${JSON.stringify(user)} - ${JSON.stringify(orgs)}`);
      userId = user.userId;

      if (
        identityDetails.data.orgs.length > 0 &&
        featureIsEnabled(`nr-enabled`) &&
        featureIsEnabled(`nr-custom-attributes`)
      ) {
        try {
          window.newrelic.setCustomAttribute(`cesOrgId`, currentOrganisationId);
          if (featureIsEnabled(`nr-custom-attributes-pii`)) {
            window.newrelic.setCustomAttribute(`cesRole`, currentRole);
            window.newrelic.setCustomAttribute(`cesUserId`, user.userId);
          }
        } catch (error) {
          console.log(`failed to set newrelic.setCustomAttribute - ${error}`);
        }
      }
      const credentialData = session.get();
      if (credentialData.currentOrganisationId) {
        currentOrganisationId = credentialData.currentOrganisationId;
        currentRole = orgs[currentOrganisationId];
      }
      try {
        if (isHubMode()) {
          const returnTo = yield select(state => get(state, ['routing', 'locationBeforeTransitions', 'pathname']));
          const redirectoToOrg = returnTo && get(extractQuery(queryParams.ORG_ID_QUERY, returnTo), 1);
          if (redirectoToOrg) currentOrganisationId = redirectoToOrg;
        }

        const sessionInfo = session.get();
        sessionInfo.email = user.email;
        sessionInfo.userId = user.userId;
        sessionInfo.registrationStatus = user.registrationStatus;
        sessionInfo.currentRole = currentRole;
        sessionInfo.orgRoleMap = orgs;
        sessionInfo.currentOrganisationId = currentOrganisationId;
        session.set(sessionInfo);

        yield put(
          setOidcAuthCredentials({
            oidcIdToken: credentialData.oidcIdToken,
            oidcAccessToken: credentialData.oidcAccessToken,
            oidcRefreshToken: credentialData.oidcRefreshToken,
            oidcExpiresIn: credentialData.oidcExpiresIn,
            oidcSessionClock: credentialData.oidcClockExpiry,
            email: user.email,
            userId: user.userId,
            registrationStatus: user.registrationStatus,
            currentRole,
            orgRoleMap: orgs,
            currentOrganisationId
          })
        );
      } catch (error) {
        // In Case of error - do logout (as user & organisation details are mandatory)
        yield completeLogout();
      }
    }
    yield put(
      storePeople({
        [userId]: {
          firstname: user.firstName,
          lastname: user.lastName,
          email: user.email,
          username: user.userName || user.username
        }
      })
    );
    yield put(
      setIdentityDetails(
        identityDetails.data.orgs,
        identityDetails.data.user.missingFields,
        identityDetails.data.user.isSocial,
        identityDetails.data.user.registrationStatus
      )
    );

    if (featureIsEnabled(`nr-enabled`) && featureIsEnabled(`nr-custom-attributes`)) {
      const currentOrganisationId = yield select(state => state.identity.currentOrganisationId);

      const currentCustomId = yield select(state =>
        state.organisations.data[currentOrganisationId] ? state.organisations.data[currentOrganisationId].customId : []
      );
      if (currentCustomId !== undefined && currentCustomId.length > 0) {
        try {
          window.newrelic.setCustomAttribute(`cesCustomId`, currentCustomId);
        } catch (error) {
          console.log(`failed to set newrelic.setCustomAttribute - ${error}`);
        }
      }
    }
  }

  console.log('[getIdentity] Complete');

  return identityDetails;
}
