import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import get from 'lodash.get';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';

// Action imports
import { gotoTeacherSelection, submitTeachers, createClass } from '../../../../../../redux/reducers/classroomCreate.js';
import { isHubMode } from '../../../../../../utils/platform';
import UserRoles, { roleIsAtLeast } from '../../../../../../globals/userRoles';
import { closeCreateClassModal } from '../../../../../../redux/actions/hubUi';

// Component imports
import ClassroomNameInput from './ClassroomNameInput.js';
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import PanelHeading from '../../../../../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';

import cmsContent from '../../../../../../utils/cmsContent.js';

// Diddy form for adding name of NEW CLASS:
class AddClassroomNaming extends Component {
  createClass() {
    // eslint-disable-next-line no-shadow
    const { createClass, submitTeachers, isTeacherInClass } = this.props;
    const userId = get(this.props, 'userId');
    const userRole = get(this.props, 'userRole');
    if (isTeacherInClass && roleIsAtLeast(UserRoles.TEACHER, userRole)) {
      submitTeachers([userId]);
    }
    createClass();
  }

  render() {
    const {
      closePopoutAction,
      gotoTeacherSelectionAction,
      classroomNameValue,
      classroomNameIsInvalid,
      classroomNamePending,
      // eslint-disable-next-line no-shadow
      closeCreateClassModal,
      localizedContent: { hubGlossary: hubContent }
    } = this.props;

    const CMS = cmsContent.addClassPanel || {};

    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          if (classroomNameValue && !classroomNameIsInvalid && !classroomNamePending) gotoTeacherSelectionAction();
        }}
      >
        <ScrollContainer
          headerContent={
            <div>
              <div className="text-right">
                <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePopoutAction} />
              </div>
              <PanelHeading title={CMS.add_class_title_text} subtitle={CMS.add_class_subtitle_text} />
            </div>
          }
          footerContent={
            <PopoutNavFooter
              nextButtonText={isHubMode() ? hubContent.create_class_modal : CMS.next_button_text}
              nextAction={() => (isHubMode() ? this.createClass() : gotoTeacherSelectionAction())}
              nextButtonDisabled={!classroomNameValue || classroomNameIsInvalid || classroomNamePending}
              backAction={isHubMode() && closeCreateClassModal}
              backButtonText={isHubMode() && hubContent.cancel}
            />
          }
        >
          <div style={{ margin: '1rem' }}>
            <ClassroomNameInput />
          </div>
        </ScrollContainer>
      </form>
    );
  }
}

AddClassroomNaming.propTypes = {
  closePopoutAction: PropTypes.func.isRequired,
  gotoTeacherSelectionAction: PropTypes.func.isRequired,
  classroomNameValue: PropTypes.string.isRequired,
  classroomNameIsInvalid: PropTypes.bool.isRequired,
  classroomNamePending: PropTypes.bool.isRequired,
  createClass: PropTypes.func.isRequired,
  submitTeachers: PropTypes.func.isRequired,
  closeCreateClassModal: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  isTeacherInClass: PropTypes.bool
};

export default compose(
  withLocalizedContent('hubGlossary'),
  connect(
    state => ({
      classroomNameValue: state.classroomCreate.classroomNameValue,
      classroomNameIsInvalid: state.classroomCreate.classroomNameValidationIsInvalid,
      classroomNamePending: state.classroomCreate.classroomNameValidationPending,
      userId: state.identity.userId,
      userRole: state.identity.role,
      isTeacherInClass: state.hubUi.isTeacherInClass
    }),
    {
      gotoTeacherSelectionAction: gotoTeacherSelection,
      createClass,
      submitTeachers,
      closeCreateClassModal
    }
  )
)(AddClassroomNaming);
