import { put, select, takeEvery } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import actions from '../../../../actions';
import checkLicenceApi from '../../../apiCalls/checkLicenceApi';
import checkVstLicenceApi from '../../../apiCalls/checkVstLicenceApi';
import { getCurrentPlatform } from '../../../../../utils/platform';

function* checkLicenceDetails() {
  const { redeemCodeValue, isVstCode, reCAPTCHAToken } = yield select(state => ({
    redeemCodeValue: state.activationCode.redeemCodeValue,
    isVstCode: state.activationCode.isVstCode,
    reCAPTCHAToken: state.reCAPTCHA.token
  }));

  const activationCode = isVstCode ? redeemCodeValue : redeemCodeValue.replace(/-/g, '');
  const checkLicencePayload = {
    activationCode,
    reCAPTCHAToken,
    reCAPTCHATokenSource: getCurrentPlatform()
  };
  const apiResults = yield (isVstCode ? checkVstLicenceApi : checkLicenceApi)(checkLicencePayload);

  if (apiResults.error || apiResults.status !== 'success') {
    const errorMessageData = {};
    const errorCode = apiResults.message.match(/\d+/);

    if (errorCode) {
      errorMessageData.code = errorCode[0];
      const codeActivationDate = apiResults.message.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/);
      if (codeActivationDate) {
        errorMessageData.activationDate = codeActivationDate[0];
      }
    }
    if (apiResults.code) {
      errorMessageData.code = apiResults.code;
    }
    yield put(actions.checkActivationCodeRequestFailure({ status: 'error', errorMessage: errorMessageData }));
  } else {
    yield put(actions.checkActivationCodeRequestSuccess(apiResults.data));
  }
}

export default function* getLicenceDetails() {
  console.log('[checkLicence] OALD licence check');
  yield takeEvery(t.CHECK_ACTIVATION_CODE_REQUEST, checkLicenceDetails);
}
