import PropTypes from 'prop-types';
import React from 'react';
import { isHubMode } from '../../utils/platform';
import withLocalizedContent from '../../language/withLocalizedContent';
import ActionList, { Action } from '../ActionList/ActionList';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';

function PasswordResetSuccess({
  localizedContent: { passwordResetSuccessComponent: content },
  firstName,
  lastName,
  onBack,
  onDone,
  currentOrganisationLti = false
}) {
  return (
    <ScrollContainer
      footerContent={
        <ActionList title={content.actions_title}>
          {!currentOrganisationLti && (
            <Action label={isHubMode() ? content.back_to_profile : content.back_button} onClick={onBack} />
          )}
          <Action label={content.done_button} primary onClick={onDone} />
        </ActionList>
      }
    >
      {isHubMode() ? (
        <PopoutPanelIllustrationHeading
          title={[content.title_pre, firstName, lastName, content.title_post].join(' ')}
          illustrationSrc={HubIllustrationConstants.SUCCESS}
          illustrationAltText={HubIllustrationAltText.SUCCESS}
        />
      ) : (
        <PopoutPanelIconHeading
          type={popoutPanelIconHeadingTypes.VALID}
          title={[content.title_pre, firstName, lastName, content.title_post].join(' ')}
        />
      )}
    </ScrollContainer>
  );
}

PasswordResetSuccess.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  currentOrganisationLti: PropTypes.bool
};

export default withLocalizedContent('passwordResetSuccessComponent')(PasswordResetSuccess);
