import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './DropdownSearchModal.scss';
import Modals from '../Modals/Modals';
import TextInputFederatedAccess from '../TextInputFederatedAccess/TextInputFederatedAccess.js';
import TextInputField from '../TextInputField/TextInputField.js';

function DropdownSearchModal({
  id,
  label,
  placeholder,
  options,
  buttonText,
  searchPlaceholder,
  modalTitle,
  required,
  disableAutoComplete,
  onSetAction,
  onValidationAction
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedOption, setSelectedOption] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const searchInputRef = useRef(null);
  const optionsListRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(options.filter(option => option.toLowerCase().includes(searchTerm.toLowerCase())));
    setHighlightedIndex(0);
  }, [searchTerm, options]);

  const handleOptionClick = option => {
    setSelectedOption(option);
    setIsOpen(false);
    setSearchTerm('');
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
    onSetAction(option);
  };

  const handleOptionKeyDown = (e, option) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleOptionClick(option);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setHighlightedIndex(prevIndex => (prevIndex === filteredOptions.length - 1 ? 0 : prevIndex + 1));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedIndex(prevIndex => (prevIndex === 0 ? filteredOptions.length - 1 : prevIndex - 1));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (isOpen) {
        handleOptionClick(filteredOptions[highlightedIndex]);
      } else {
        setIsOpen(true);
      }
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setIsOpen(false);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setSearchTerm('');
    onValidationAction();
  };

  return (
    <>
      <TextInputFederatedAccess
        id={id}
        placeholder={placeholder || buttonText}
        label={label}
        value={selectedOption || ''}
        onClick={() => setIsOpen(!isOpen)}
        aria-autocomplete="list"
        aria-controls="dropdown-list"
        onKeyDown={handleKeyDown}
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        customStyleName={styles.iconBg}
        required={required}
        disableAutoComplete={disableAutoComplete}
      />
      {isOpen && (
        <div>
          <Modals
            customClassName={styles.customName}
            visibilityChecker={isOpen}
            subTitle={modalTitle}
            bodyHtml={[
              <div className={styles.dropdownPopup} key={0}>
                <TextInputField
                  id={`${id}SearchTerm`}
                  placeholder={searchPlaceholder}
                  value={searchTerm}
                  onChange={e => setSearchTerm(e)}
                  aria-autocomplete="list"
                  aria-controls="dropdown-list"
                  input={searchInputRef}
                />
                <ul className={styles.dropdownOptions} role="listbox" ref={optionsListRef}>
                  {filteredOptions.map((option, index) => (
                    <li
                      key={option}
                      className={classnames(
                        styles.dropdownOption,
                        highlightedIndex === index ? styles.highlighted : ''
                      )}
                      onClick={() => handleOptionClick(option)}
                      onMouseEnter={() => setHighlightedIndex(index)}
                      role="option"
                      aria-selected={highlightedIndex === index}
                      tabIndex={0}
                      onKeyDown={e => handleOptionKeyDown(e, option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            ]}
            closeCallBack={() => closeModal(true)}
            customClassConfModal={styles.confirmationModal}
            bottomBorderForClose
          />
        </div>
      )}
    </>
  );
}

export default DropdownSearchModal;

DropdownSearchModal.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  buttonText: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  modalTitle: PropTypes.string,
  required: PropTypes.bool,
  disableAutoComplete: PropTypes.bool,
  onSetAction: PropTypes.func,
  onValidationAction: PropTypes.func
};
