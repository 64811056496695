import React from 'react';
import PropTypes from 'prop-types';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import Button from '@oup/shared-front-end/src/components/Button';
import colors from '../../globals/colors.js';
import { isCesMode, getCurrentPlatform, isHubMode } from '../../utils/platform';
import Card from '../../components/Card/Card.js';
import RegisterFormInput from './RegisterFormInput.js';
import PageHeading from '../../components/PageHeading/PageHeading.js';
import { formStates } from '../../redux/reducers/registration/registration.reducer.js';
import { getIdpLoginUrl } from '../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import { socialIdpIsEnabled } from '../../globals/envSettings';
import { prepareTargetUrl, getProviderId } from '../../utils/url';
import styles from './RegisterPage.scss';

import cmsContent from '../../utils/cmsContent.js';
import RegisterFormFederatedAccess from './RegisterFormFederatedAccess.js';

// This is mostly a wrapper around <RegisterFormInput> so it can be positioned next to other content.
// IMPORTANT: This renders forms for both FULL and PARTIAL registration.
function RegisterFormInputPage({ platform }) {
  const CMS = cmsContent.registrationPage || {};
  const oxfordIdSupportLink = isHubMode()
    ? APP_CONSTANTS.HUB_SUPPORT_OXFORD_ID
    : `${window.location.origin}/support/articles/oxford-id`;
  return (
    <div className="row">
      <div className="col sm10 pad-bot2">
        <PageHeading title={CMS.pageTitle} subtitle={CMS.create_id_text} />
      </div>

      <div className="col md8">
        <Card headingText={CMS.registration_title} headingColor={colors.REGISTER}>
          {socialIdpIsEnabled(getCurrentPlatform()) ? (
            <RegisterFormFederatedAccess platform={platform} formState={formStates.INPUTTING_NEW} />
          ) : (
            <RegisterFormInput platform={platform} formState={formStates.INPUTTING_NEW} />
          )}
        </Card>
      </div>

      <div className="col md4 xs-hide md-show">
        <Card headingText={CMS.support_heading_text} headingColor={colors.SUPPORT_ICON}>
          <div>
            <h2 className={styles.helpCard} id="help-links-heading">
              {CMS.support_links_heading_text}
              <TextLink to={oxfordIdSupportLink} target="_blank">
                {CMS.support_link_heading}
              </TextLink>
            </h2>
          </div>

          <p>
            {CMS.sign_in_text}
            <div className="block gin-top1">
              <Button
                id="link-to-login"
                variant="filled"
                text={CMS.button_sign_in_text}
                onClick={async () => {
                  // Handle code redemption to autopopulate when routed from redeem page and password reset
                  if (isCesMode() && localStorage.getItem(APP_CONSTANTS.REDEEM_CODE)) {
                    sessionStorage.setItem('goto-path', '/myProfile/learningMaterial/addCode');
                  }

                  const redirectTo = prepareTargetUrl();
                  const providerId = getProviderId();
                  const params = { withReturnTo: false };

                  if (redirectTo) {
                    // attention: this URL must be added to Auth0 client callbacks list
                    params.redirect_uri = redirectTo;
                  }
                  if (providerId) {
                    params.navigateTo = providerId;
                  }

                  const url = await getIdpLoginUrl(null, params);

                  window.location.href = url;
                }}
              />
            </div>
          </p>
        </Card>
      </div>
    </div>
  );
}
RegisterFormInputPage.propTypes = {
  platform: PropTypes.string
};

export default RegisterFormInputPage;
