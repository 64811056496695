import pick from 'lodash.pick';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { ErrorStatus, PageWrapper, PopoutPanelIconHeading } from '../../../components';
import actions from '../../../redux/actions';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import OicEmailsSuccess from '../../../components/OicEmailsForm/OicEmailsSuccess';
import Button, { buttonTypes } from '../../../components/Button/Button';
import ControlledForm from '../../../components/ControlledForm/ControlledForm';
import InformationBox from '../../../components/InformationBox/InformationBox.js';

function OicEmailToolsPage(props) {
  const {
    localizedContent: { oicEmailToolsPage: content },
    submitting,
    success,
    response,
    failure,
    submit,
    reset
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _submit = () => {
    submit();
  };

  const _reset = () => {
    reset();
    window.scrollTo(0, 0);
  };

  return (
    <PageWrapper>
      <Helmet title={content.title} />
      <PageHeading className="pad-bot4" title={content.title} subtitle={content.page_title} />

      {(() => {
        switch (true) {
          case submitting:
            return <PopoutPanelIconHeading type={types.LOADING} title={content.loading} />;
          case success:
            return <OicEmailsSuccess response={response} />;
          case failure:
            return (
              <ErrorStatus
                title={content.error_title}
                buttonText={content.error_button}
                buttonOnClickHandler={_reset}
              />
            );
          default:
            return (
              <ControlledForm>
                <br />
                <InformationBox title="WARNING" firstMessage={content.warning_message} />
                <br />
                <Button
                  type={buttonTypes.PRIMARY}
                  text={content.send_emails_button}
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals
                    // if (confirm(content.confirmation_message)) {
                    _submit();
                    // }
                  }}
                />
              </ControlledForm>
            );
        }
      })()}
    </PageWrapper>
  );
}

OicEmailToolsPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  response: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired
};

export default compose(
  withLocalizedContent('oicEmailToolsPage'),
  connect(
    state => ({
      ...pick(state.sendOicEmails, ['submitting', 'success', 'failure', 'errors', 'response'])
    }),
    dispatch => ({
      submit: (template = '') => {
        dispatch(actions.sendOicEmailsRequest(template));
      },
      reset: () => {
        dispatch(actions.sendOicEmailsReset());
      }
    })
  )
)(OicEmailToolsPage);
