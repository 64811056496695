import React from 'react';
import PropTypes from 'prop-types';

import appSettings from '../../globals/appSettings';
import withLocalizedContent from '../../language/withLocalizedContent';
import ActionList, { Action } from '../ActionList/ActionList';
import ControlledForm from '../ControlledForm/ControlledForm';
import OrgFormBody from '../OrgFormBody/OrgFormBody';
import ScrollContainer from '../ScrollContainer/ScrollContainer';

function EditOrg({
  localizedContent: { editOrg: localizedContent, curriculumTypeNames },
  roles = {},
  countries = {},
  name = '',
  role = '',
  countryCode = '',
  orgRegion = '',
  orgPostcode = '',
  sapNumber = '',
  curriculumType = '',
  orgUniqueId = '',
  primaryEmail = '',
  webAddress = '',
  isLmsLtiTool = false,
  disabled = [],
  hidden = [],
  errors = {},
  createChangeHandler = () => {},
  createBlurHandler = () => {},
  onSubmit = () => {}
}) {
  return (
    <ScrollContainer
      headerContent={
        <header className="gin-top2 gin-bot2">
          <h2>{name}</h2>
          <div>{orgUniqueId}</div>
        </header>
      }
      footerContent={
        <ActionList>
          <Action
            label={localizedContent.submit_button}
            primary
            disabled={Object.values(errors).some(Boolean)}
            onClick={onSubmit}
          />
        </ActionList>
      }
    >
      <ControlledForm className="pad2">
        <OrgFormBody
          localizedContent={localizedContent}
          localizedCurriculumTypeNames={curriculumTypeNames}
          roles={roles}
          countries={countries}
          name={name}
          role={role}
          countryCode={countryCode}
          orgRegion={orgRegion}
          orgPostcode={orgPostcode}
          sapNumber={sapNumber}
          curriculumType={curriculumType}
          curriculumTypes={appSettings.curriculumTypes}
          orgUniqueId={orgUniqueId}
          primaryEmail={primaryEmail}
          webAddress={webAddress}
          isLmsLtiTool={isLmsLtiTool}
          disabled={disabled}
          hidden={hidden}
          errors={errors}
          createChangeHandler={createChangeHandler}
          createBlurHandler={createBlurHandler}
        />
      </ControlledForm>
    </ScrollContainer>
  );
}

EditOrg.propTypes = {
  localizedContent: PropTypes.object,
  roles: PropTypes.object,
  countries: PropTypes.object,
  name: PropTypes.string,
  role: PropTypes.string,
  countryCode: PropTypes.string,
  orgRegion: PropTypes.string,
  orgPostcode: PropTypes.string,
  sapNumber: PropTypes.string,
  curriculumType: PropTypes.string,
  orgUniqueId: PropTypes.string,
  primaryEmail: PropTypes.string,
  webAddress: PropTypes.string,
  isLmsLtiTool: PropTypes.bool,
  disabled: PropTypes.arrayOf(PropTypes.string),
  hidden: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.object,
  createChangeHandler: PropTypes.func,
  createBlurHandler: PropTypes.func,
  onSubmit: PropTypes.func
};

export default withLocalizedContent('editOrg', 'curriculumTypeNames')(EditOrg);
