import sumBy from 'lodash.sumby';
import sum from 'lodash.sum';

/**
 * Wrapper function for sum iterator.
 *
 * @param {Array} target The target array
 * @param {String|Function} key String object key or function for iteration
 */
export default (target, key) => (key ? sumBy(target, key) : sum(target));
