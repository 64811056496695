import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import prefixKeys from '../../utils/object/prefixKeys';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './Checkbox.scss';

class Checkbox extends React.Component {
  static selectCheckbox = e => {
    if (e.key === 'Enter' && e.target && typeof e.target.click === 'function') {
      e.target.click();
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      programmaticId: Math.random()
        .toString(36)
        .substring(7)
    };
  }

  getGlyph() {
    const { value, partiallySelected, iconType } = this.props;

    if (partiallySelected && iconType !== 'radio') {
      return GLYPHS.CHECKBOX_PARTIAL;
    }

    if (value) {
      return GLYPHS.ICON_CHECKBOX_CHECKED;
    }

    return GLYPHS.ICON_CHECKBOX_UNCHECKED;
  }

  render() {
    const { programmaticId } = this.state;
    const {
      id = '',
      label,
      labelHidden,
      value,
      onChange,
      disabled,
      required,
      aria,
      children,
      tabindex,
      dataTestId
    } = this.props;
    const ariaAttrs = prefixKeys(aria, 'aria-');

    return (
      <div className={classnames(styles.checkbox, { [styles.disabled]: disabled })}>
        {children ? (
          <div>
            <input
              type="checkbox"
              id={`control-${id}-${programmaticId}`}
              name={id}
              value={value}
              className={styles.input}
              onChange={() => onChange(!value)}
              onClick={e => {
                e.stopPropagation();
              }}
              tabIndex={tabindex}
              disabled={disabled}
              required={required}
              onKeyPress={e => {
                Checkbox.selectCheckbox(e);
              }}
              {...ariaAttrs}
            />
            <label
              className={classnames(styles.label, { [styles['a11-hide-label']]: labelHidden })}
              htmlFor={`control-${id}-${programmaticId}`}
            >
              <SVGIcon className={styles.svg} glyph={this.getGlyph()} />
              <span className={classnames(styles.text, { [styles['a11-hide-text']]: labelHidden })}>{label}</span>
              {children}
            </label>
          </div>
        ) : (
          <div>
            <input
              type="checkbox"
              data-testid={dataTestId}
              id={`control-${id}-${programmaticId}`}
              name={id}
              value={value}
              className={styles.input}
              onChange={() => onChange(!value)}
              onClick={e => {
                e.stopPropagation();
              }}
              tabIndex={tabindex}
              disabled={disabled}
              required={required}
              onKeyPress={e => {
                Checkbox.selectCheckbox(e);
              }}
              {...ariaAttrs}
            />
            <label
              className={classnames(styles.label, { [styles['a11-hide-label']]: labelHidden })}
              htmlFor={`control-${id}-${programmaticId}`}
            >
              <SVGIcon className={styles.svg} glyph={this.getGlyph()} />
              <span className={classnames(styles.text, { [styles['a11-hide-text']]: labelHidden })}>{label}</span>
            </label>
          </div>
        )}
      </div>
    );
  }
}

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  labelHidden: PropTypes.bool,
  value: PropTypes.bool,
  partiallySelected: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  aria: PropTypes.object,
  iconType: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  tabindex: PropTypes.number,
  dataTestId: PropTypes.string
};

Checkbox.defaultProps = {
  labelHidden: false,
  value: false,
  partiallySelected: false,
  onChange: () => {},
  disabled: false,
  required: false,
  iconType: 'checkbox',
  aria: {},
  dataTestId: ''
};

export default Checkbox;
