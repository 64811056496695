import PropTypes from 'prop-types';
import numeral from 'numeral';
import Media from 'react-media';
import React, { Component } from 'react';
import GradedReadersCard from './GradedReadersCard';
import GradedReadersBanner from './GradedReadersBanner';
import GradebookTable from '../GradebookTable/GradebookTable';
import SortHeading from '../GradebookTable/SortHeading';
import { rowTypes, colTypes } from '../../globals/gradebookTableConstants';
import breakpoints from '../../styles/constants/_breakpoints.scss';
import styles from './GradedReadersCard.scss';

class GradedReadersClassViewTable extends Component {
  /**
   * Sorts rows based on various cell criteria
   *
   * @param {array} records
   * @return {array}
   */
  static sortRows = (records, accessor, direction) => {
    const isAscending = direction === 'asc';

    return records.sort((a, b) => {
      const aBaseContents = a[accessor];
      const bBaseContents = b[accessor];

      const aContents = typeof aBaseContents === 'object' ? aBaseContents.props.text : aBaseContents;
      const bContents = typeof bBaseContents === 'object' ? bBaseContents.props.text : bBaseContents;

      // Keep average row at top if sorting names
      if (accessor === colTypes.NAME.key || accessor === colTypes.ACCESSED.key) {
        if (a.meta === rowTypes.AVERAGE.key) return -1;
        if (b.meta === rowTypes.AVERAGE.key) return 1;
      }

      if (accessor === colTypes.ACCESSED.key) {
        // Move empties to top or to the bottom
        if (aContents === 'Never') return isAscending ? 1 : -1;
        if (bContents === 'Never') return isAscending ? -1 : 1;
        // Sort date for lastAccessed
        return isAscending
          ? new Date(b.lastAccessed) - new Date(a.lastAccessed)
          : new Date(a.lastAccessed) - new Date(b.lastAccessed);
      }

      if (accessor === 'readingTime') {
        if (a.readingTimeDateFormat > b.readingTimeDateFormat) return isAscending ? 1 : -1;
        if (a.readingTimeDateFormat < b.readingTimeDateFormat) return isAscending ? -1 : 1;
      }

      if (!Number.isNaN(Number(parseInt(aContents, 10)))) {
        // Sort with numbers if present
        return isAscending
          ? numeral(aContents).value() - numeral(bContents).value()
          : numeral(bContents).value() - numeral(aContents).value();
      }

      // Else we just sort on the content strings
      if (aContents > bContents) return isAscending ? 1 : -1;
      if (aContents < bContents) return isAscending ? -1 : 1;

      return 0;
    });
  };

  render() {
    const { sortKey, sortDirection = 'none', sortOnChange, tableSpacing, columns, records, classTitle } = this.props;

    return (
      <div>
        <Media query={JSON.parse(breakpoints.smMedia)}>
          {matches =>
            matches ? (
              <div className="grid">
                <div className="row">
                  <div className="col">
                    <GradebookTable spacing={tableSpacing} stickyColumn>
                      <GradebookTable.Head>
                        <GradebookTable.Row>
                          {columns.map(({ accessor, Header, stickyColumn = false }, colI) => (
                            <SortHeading
                              key={colI}
                              sortKey={accessor}
                              direction={sortKey === accessor ? sortDirection : 'none'}
                              onClick={() => sortOnChange(accessor)}
                              stickyColumn={stickyColumn}
                              shaded={colI % 2 === 0 && colI !== 0}
                            >
                              {Header}
                            </SortHeading>
                          ))}
                        </GradebookTable.Row>
                      </GradebookTable.Head>
                      <GradebookTable.Body>
                        {GradedReadersClassViewTable.sortRows(records, sortKey, sortDirection).map((row, rowI) => (
                          <GradebookTable.Row key={`row${rowI}`}>
                            {Object.entries(row).map(([column, cell], colI) =>
                              column !== 'meta' && column !== 'lastAccessed' && column !== 'readingTimeDateFormat' ? (
                                <GradebookTable.Cell
                                  key={`col${colI}`}
                                  highlight={row.meta === 'average'}
                                  stickyColumn={column === 'name'}
                                  shaded={colI % 2 === 0 && colI !== 0}
                                >
                                  {cell}
                                </GradebookTable.Cell>
                              ) : null
                            )}
                          </GradebookTable.Row>
                        ))}
                      </GradebookTable.Body>
                      <GradebookTable.Foot>
                        <GradebookTable.Row>
                          {columns.map(({ accessor, Header, stickyColumn = false }, colI) => (
                            <SortHeading
                              key={colI}
                              sortKey={accessor}
                              direction={sortKey === accessor ? sortDirection : 'none'}
                              onClick={() => sortOnChange(accessor)}
                              stickyColumn={stickyColumn}
                              shaded={colI % 2 === 0 && colI !== 0}
                            >
                              {Header}
                            </SortHeading>
                          ))}
                        </GradebookTable.Row>
                      </GradebookTable.Foot>
                    </GradebookTable>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.cardContainer}>
                <GradedReadersBanner
                  mostReadLevel={records[0].mostReadLevel}
                  booksFinishedAverage={records[0].booksFinished}
                />
                {GradedReadersClassViewTable.sortRows(records, sortKey, sortDirection).map((record, recordI) =>
                  record.meta !== 'average' ? (
                    <GradedReadersCard
                      key={`card${recordI}`}
                      studentNameElement={record.name}
                      lastAccessed={record.accessed}
                      booksFinished={record.booksFinished}
                      currentLevel={record.currentLevel}
                      mostReadLevel={record.mostReadLevel}
                      readingTime={record.readingTime}
                      title={record.title}
                      wordsRead={record.wordsRead}
                      classTitle={classTitle}
                    />
                  ) : null
                )}
              </div>
              // eslint-disable-next-line prettier/prettier
            )}
        </Media>
      </div>
    );
  }
}

GradedReadersClassViewTable.propTypes = {
  records: PropTypes.array,
  columns: PropTypes.any,
  tableSpacing: PropTypes.string,
  sortKey: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  sortOnChange: PropTypes.func.isRequired,
  classTitle: PropTypes.string.isRequired
};

export default GradedReadersClassViewTable;
