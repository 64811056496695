import React, { Component } from 'react';
import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Action imports
import {
  backToSearch,
  toggleDetailsPopupWithId,
  setAssignLicences,
  gotoReviewAssignment,
  loadLicenceData,
  setTeacherAssignLicences
} from '../../../redux/reducers/assignLearningMaterial.reducer';

// Component imports
import ScrollContainer from '../../../components/ScrollContainer/ScrollContainer';
import PanelNavigationLink from '../../../components/PanelNavigationLink/PanelNavigationLink';
import PopoutActionFooter from '../../../components/PopoutActionFooter/PopoutActionFooter';
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail';
import MaterialRepresentation from '../../../components/MaterialRepresentation/MaterialRepresentation';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import Button, { buttonTypes } from '../../../components/Button/Button';
import { sanitizeUrl } from '../../../utils/url';

import AssignmentSummary from './ReviewLicencesViews/AssignmentSummary';
import UserAssignments from './ReviewLicencesViews/UserAssignments';
import cmsContent from '../../../utils/cmsContent';
import { licenceAvailabilityText } from './AssignLearningMaterialSearch';
import { STATUS_TYPE } from '../../../globals/appConstants';
import { assignmentContexts } from '../../../globals/assignmentConstants';
import { isHubMode } from '../../../utils/platform';

const licenceAvailabilityStatus = (product, totalUser, availableCount) => {
  if (product?.availableCount > 0 && totalUser <= availableCount) {
    return STATUS_TYPE.SUCCESS;
  }

  if (product?.availableCount > 0 && totalUser > availableCount) {
    return STATUS_TYPE.WARNING;
  }

  if (product?.assignedCount > 0 || product?.redeemedCount > 0) {
    return STATUS_TYPE.ERROR;
  }
  return null;
};

class AssignLearningMaterialReviewLicences extends Component {
  notEnoughLicenceForTeacher = false;

  constructor(props) {
    super(props);

    this.state = {
      licenceViewOpen: false
    };
  }

  componentDidUpdate() {
    const {
      loadingLicences,
      assignLicences,
      usersWithoutAssignments,
      numberOfUsersNeedingLicences,
      licencesAvailableCount,
      setAssignLicencesAction,
      setTeacherAssignLicencesAction,
      learnerIds
    } = this.props;

    if (loadingLicences) {
      return;
    }

    const enoughLicencesAvailable =
      usersWithoutAssignments.length + numberOfUsersNeedingLicences <= licencesAvailableCount;

    if (!this.notEnoughLicenceForTeacher && assignLicences && !enoughLicencesAvailable && learnerIds.length > 0) {
      setTeacherAssignLicencesAction(false);
      this.notEnoughLicenceForTeacher = true;
      return;
    }

    if (assignLicences && !enoughLicencesAvailable) {
      setAssignLicencesAction(false);
    }
  }

  toggleView = () => {
    this.setState(prevState => ({
      licenceViewOpen: !prevState.licenceViewOpen
    }));
  };

  goBack = () => {
    const { backToSearchAction } = this.props;
    backToSearchAction();
  };

  render() {
    const {
      existingAssignmentId,
      closePopoutAction,
      people,
      product,
      loadingLicences,
      loadingLicencesFailed,
      assignLicences,
      teacherAssignLicences,
      licencesAvailable,
      licencesTypes,
      numberOfUsersNeedingLicences,
      proposedAssignments,
      usersIdsWithLicences,
      usersExistingLicenceDetails,
      teacherIds,
      learnerIds,
      toggleDetails,
      setAssignLicencesAction,
      setTeacherAssignLicencesAction,
      gotoReviewAssignmentAction,
      triggerLicenceLoad,
      licencesAvailableCount,
      userIdsToAssignLicencesTo,
      userIdsToNotAssignLicencesTo,
      totalUserCount,
      userIdsWithoutPermission,
      context
    } = this.props;

    const { licenceViewOpen } = this.state;
    const CMS = cmsContent.assignLearningMaterialPanel || {};
    const classAssignmentContext = [
      assignmentContexts.CLASS,
      assignmentContexts.CLASS_CREATION,
      assignmentContexts.CLASS_RENEW,
      assignmentContexts.CLASS_ONBOARDING_WIZARD
    ].includes(context);

    return (
      <form onSubmit={e => e.preventDefault()}>
        <ScrollContainer
          headerContent={
            <div>
              <div className="text-right">
                <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePopoutAction} />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '1rem',
                  alignItems: 'center'
                }}
              >
                <MaterialRepresentation
                  thumbnailSize={thumbnailSizes.TABLE}
                  // To be removed
                  // #PRODUCT_FINDER_REFACTOR
                  imageSrc={sanitizeUrl(product?.coverImage || product?.cover || '')}
                  name={product?.title}
                  subtext={product?.subtitle}
                  statusText={licenceAvailabilityText(product)}
                  statusColor={licenceAvailabilityStatus(product, totalUserCount, licencesAvailableCount)}
                  headerSizeName
                  ellipsisHeading
                  statusIndication
                />
                <div style={{ alignSelf: 'flex-start' }}>
                  <Button
                    type={buttonTypes.INFO_NO_BORDER}
                    glyph={GLYPHS.ICON_INFORMATION_CIRCLE}
                    iconOnly
                    onClick={() => toggleDetails(product?.productId, null, null, context)}
                  />
                </div>
              </div>
            </div>
          }
          footerContent={
            <PopoutActionFooter
              secondaryButtonText={CMS.button_back_text}
              secondaryButtonAction={existingAssignmentId ? closePopoutAction : this.goBack}
              primaryButtonText={CMS.next_button_text}
              primaryButtonAction={gotoReviewAssignmentAction}
              primaryButtonDisabled={!isHubMode() && numberOfUsersNeedingLicences === 0}
              primaryButtonTestHook="ASSIGN_LEARNING_MATERIAL_REVIEW_LICENCES_NEXT"
              wizardButtons
            />
          }
        >
          <div style={{ margin: '1rem' }}>
            {loadingLicences ? (
              <div className="pad-top4 pad-bot4" style={{ textAlign: 'center' }}>
                <div style={{ width: '5rem', margin: '2rem auto' }}>
                  <SVGIcon glyph={GLYPHS.ICON_LOADING} />
                </div>
                <p className="gin-top2">{CMS.loading_licences_text}</p>
              </div>
            ) : (
              <div>
                {loadingLicencesFailed ? (
                  <div style={{ textAlign: 'center' }}>
                    <div style={{ width: '5rem', margin: '2rem auto 0' }}>
                      <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
                    </div>
                    <p className="gin2">{CMS.loading_licences_error_text}</p>
                    <Button text={CMS.retry_button_text} onClick={triggerLicenceLoad} />
                  </div>
                ) : (
                  <div>
                    {!licenceViewOpen ? (
                      <AssignmentSummary
                        shouldAssignLicences={assignLicences}
                        shouldTeacherAssignLicences={teacherAssignLicences}
                        hasLicencesAvailable={licencesAvailable}
                        licencesTypes={licencesTypes}
                        setAssignLicencesAction={setAssignLicencesAction}
                        setTeacherAssignLicencesAction={setTeacherAssignLicencesAction}
                        toggleView={this.toggleView}
                        licencesAvailableCount={licencesAvailableCount}
                        usersNeedingLicenceCount={numberOfUsersNeedingLicences}
                        totalUserCount={totalUserCount}
                        usersWithLicenceCount={usersIdsWithLicences.length}
                        context={context}
                        teacherLicenceAssignStatus={this.notEnoughLicenceForTeacher}
                        productTargetUsertype={product?.target_usertype}
                        productPlatform={product?.platform}
                        userIdsWithExistingLicence={usersIdsWithLicences}
                        teacherIds={teacherIds}
                      />
                    ) : (
                      <UserAssignments
                        users={people}
                        assignLicences={assignLicences}
                        newLicenceAssignments={proposedAssignments}
                        existingLicenceAssignments={usersExistingLicenceDetails}
                        userIdsWithExistingLicence={usersIdsWithLicences}
                        userIdsToAssignLicencesTo={userIdsToAssignLicencesTo}
                        userIdsToNotAssignLicencesTo={userIdsToNotAssignLicencesTo}
                        teacherIds={teacherIds}
                        learnerIds={learnerIds}
                        toggleView={this.toggleView}
                        shouldTeacherAssignLicences={classAssignmentContext ? teacherAssignLicences : true}
                        userIdsWithoutPermission={userIdsWithoutPermission}
                        productTargetUsertype={product?.target_usertype}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </ScrollContainer>
      </form>
    );
  }
}

export default connect(
  state => {
    const licencesAvailableCount = state.assignLearningMaterial.licencesTypes.reduce(
      (total, currentValue) => total + currentValue.availableCount,
      0
    );

    const {
      usersWithoutAssignments = [],
      userIdsInOrder = [],
      teacherIdsInOrder = [],
      learnerUserIdsInOrder = [],
      assignLicences,
      userIdsWithoutPermission = []
    } = state.assignLearningMaterial;

    return {
      ...pick(state.assignLearningMaterial, [
        'existingAssignmentId',
        'loadingLicences',
        'loadingLicencesFailed',
        'licencesAvailable',
        'licencesTypes',
        'proposedAssignments',
        'usersIdsWithLicences',
        'usersExistingLicenceDetails',
        'teacherAssignLicences',
        'context'
      ]),
      numberOfUsersNeedingLicences: userIdsInOrder.length,
      assignLicences,
      licencesAvailableCount,
      userIdsToAssignLicencesTo: assignLicences ? userIdsInOrder : [],
      userIdsToNotAssignLicencesTo: assignLicences
        ? usersWithoutAssignments
        : userIdsInOrder.concat(usersWithoutAssignments),
      usersWithoutAssignments,
      totalUserCount: usersWithoutAssignments.length + userIdsInOrder.length,
      people: state.people.data || {},
      teacherIds: teacherIdsInOrder,
      learnerIds: learnerUserIdsInOrder,
      userIdsWithoutPermission,
      userRoleName: state.identity.role
    };
  },
  {
    backToSearchAction: backToSearch,
    toggleDetails: toggleDetailsPopupWithId,
    setAssignLicencesAction: setAssignLicences,
    gotoReviewAssignmentAction: gotoReviewAssignment,
    triggerLicenceLoad: loadLicenceData,
    setTeacherAssignLicencesAction: setTeacherAssignLicences
  }
)(AssignLearningMaterialReviewLicences);

AssignLearningMaterialReviewLicences.propTypes = {
  existingAssignmentId: PropTypes.string,
  closePopoutAction: PropTypes.func.isRequired,
  people: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  loadingLicences: PropTypes.bool.isRequired,
  loadingLicencesFailed: PropTypes.bool.isRequired,
  assignLicences: PropTypes.bool.isRequired,
  teacherAssignLicences: PropTypes.bool.isRequired,
  licencesAvailable: PropTypes.bool,
  licencesTypes: PropTypes.array.isRequired,
  numberOfUsersNeedingLicences: PropTypes.number,
  proposedAssignments: PropTypes.object,
  usersIdsWithLicences: PropTypes.array,
  usersExistingLicenceDetails: PropTypes.object,
  teacherIds: PropTypes.array,
  learnerIds: PropTypes.array,
  backToSearchAction: PropTypes.func.isRequired,
  toggleDetails: PropTypes.func.isRequired,
  setAssignLicencesAction: PropTypes.func.isRequired,
  setTeacherAssignLicencesAction: PropTypes.func.isRequired,
  gotoReviewAssignmentAction: PropTypes.func.isRequired,
  triggerLicenceLoad: PropTypes.func.isRequired,
  licencesAvailableCount: PropTypes.number,
  userIdsToAssignLicencesTo: PropTypes.array.isRequired,
  userIdsToNotAssignLicencesTo: PropTypes.array.isRequired,
  totalUserCount: PropTypes.number.isRequired,
  usersWithoutAssignments: PropTypes.array.isRequired,
  userIdsWithoutPermission: PropTypes.array.isRequired,
  context: PropTypes.string.isRequired
};
