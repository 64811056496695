import omit from 'lodash.omit';
import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash.pick';
import ImportCTA from '../ImportCTA/ImportCTA';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import ControlledForm from '../ControlledForm/ControlledForm';
import UserFormBody from '../UserFormBody/UserFormBody';
import styles from './UserEnrolForm.scss';
import withLocalizedContent from '../../language/withLocalizedContent';
import PopoutNavFooter from '../PopoutNavFooter/PopoutNavFooter';

function UserEnrolForm(props) {
  const {
    localizedContent: { userEnrolFormComponent: content },
    orgName,
    formInputChanges = {},
    formInputErrors = {},
    isCheckingUserNameTaken = false,
    isSubmitDisabled = true,
    onShowImportUsers,
    onAddStudentSubmit,
    onCancel,
    orgCurriculumType,
    userEnrolAddToClassView,
    selectedClassIds,
    classrooms,
    removeClassIdAction,
    hidden
  } = props;
  const isHidden = [
    ...(hidden || []),
    'email',
    'oldPassword',
    'newPassword',
    'confirmPassword',
    'roleName',
    'social',
    'social-unlink-confirm'
  ];
  const errorValues = Object.values(omit(formInputErrors, isHidden));
  const disabled = isSubmitDisabled || isCheckingUserNameTaken || !errorValues.length || errorValues.some(Boolean);
  return (
    <ScrollContainer
      headerContent={
        <header className={styles.header}>
          <h2 className={styles.heading}>{content.title}</h2>
          <div className="gin-top1">
            {content.subtitle} {orgName}
          </div>
        </header>
      }
      footerContent={
        <PopoutNavFooter
          backAction={onCancel}
          backButtonText={content.cancel_button}
          nextAction={onAddStudentSubmit}
          nextButtonText={content.add_student_button}
          nextButtonDisabled={disabled}
        />
      }
    >
      <ControlledForm className="pad2">
        <div>
          {onShowImportUsers && (
            <ImportCTA
              importText={content.import_students_title}
              buttonText={content.import_students_button}
              bulkInputAction={onShowImportUsers}
            />
          )}
          <UserFormBody
            {...pick(props, [
              'password',
              'formInputErrors',
              'isCheckingUserNameTaken',
              'isUserNameTaken',
              'isGeneratingPassword',
              'isGeneratingUserName',
              'onChange',
              'onBlur',
              'onGeneratePassword',
              'onGenerateUserName'
            ])}
            {...formInputChanges}
            userEnrolAddToClassView={userEnrolAddToClassView}
            isCharLimited
            isGeneratePasswordDisabled={!formInputChanges.firstName || !formInputChanges.lastName}
            localizedContent={content}
            hidden={isHidden}
            orgCurriculumType={orgCurriculumType}
            showAppropriatePermissionsNotice
            isUserEnrollForm
            selectedClassIds={selectedClassIds}
            classrooms={classrooms}
            removeClassIdAction={removeClassIdAction}
          />
        </div>
      </ControlledForm>
    </ScrollContainer>
  );
}

UserEnrolForm.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgName: PropTypes.string.isRequired,
  password: PropTypes.string,
  hidden: PropTypes.array,
  formInputChanges: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    yearGroup: PropTypes.string
  }),
  formInputErrors: PropTypes.shape({
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
    userName: PropTypes.bool,
    password: PropTypes.bool,
    yearGroup: PropTypes.bool
  }),
  isCheckingUserNameTaken: PropTypes.bool,
  isUserNameTaken: PropTypes.bool,
  isGeneratingPassword: PropTypes.bool,
  isGeneratePasswordDisabled: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onShowImportUsers: PropTypes.func,
  onGeneratePassword: PropTypes.func,
  onGenerateUserName: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  orgCurriculumType: PropTypes.string.isRequired,
  onAddStudentSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  userEnrolAddToClassView: PropTypes.func,
  selectedClassIds: PropTypes.array,
  classrooms: PropTypes.object,
  removeClassIdAction: PropTypes.func.isRequired
};

export default withLocalizedContent('userEnrolFormComponent')(UserEnrolForm);
