import { takeLatest, take, put, select } from 'redux-saga/effects';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import {
  invoiceReportValidateInput,
  createInvoiceReportSuccess,
  createInvoiceReportFailure
} from '../../../../actions/invoiceReport.js';
import { CREATE_INVOICE_REPORT_REQUEST, CREATE_INVOICE_REPORT_VALIDATE } from '../../../../actionTypes.js';
import invoiceReportRequest from './invoiceReportRequest';
import validateInvoiceReport from './validateInvoiceReport';

const moment = require('moment');

function* downloadReportXLS() {
  const xlsContentArray = [];

  const { itemsList } = yield select(state => ({
    itemsList: state.invoiceReport.formData
  }));

  const itemstoArray = Object.values(itemsList);
  itemstoArray.forEach(({ productName, ISBN, count }) => {
    xlsContentArray.push({
      ProductName: productName,
      ProductISBN: ISBN,
      'Count check': count
    });
  });
  const worksheet = XLSX.utils.json_to_sheet(xlsContentArray);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Buffer to store the generated Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  });
  const timestamp = moment().format('YYYYMMDD');
  const fileName = `report_${timestamp}.xlsx`;
  saveAs(blob, fileName);
}

export default function* invoiceReport() {
  console.log('[invoiceReport Saga] Beginning');

  while (true) {
    yield takeLatest(CREATE_INVOICE_REPORT_VALIDATE, ({ payload: input }) => validateInvoiceReport(input));
    yield takeLatest(CREATE_INVOICE_REPORT_VALIDATE, ({ payload: input }) => invoiceReportValidateInput(input));
    yield take(CREATE_INVOICE_REPORT_REQUEST);

    console.log('[invoiceReport Saga] Start Request');
    const result = yield invoiceReportRequest();
    const status = result.status ? result.status.toLowerCase() : 'error';

    const failed = !!result.error || status !== 'success' || !result.response.length;
    const apiError = result.error || status !== 'success' ? result.message : null;

    if (!failed) {
      console.log('[invoiceReport Saga] Request completed, showing results');
      yield put(createInvoiceReportSuccess(result.response));
      yield downloadReportXLS();
    } else {
      console.log('[invoiceReport Saga] Request completed, but failed, showing error');

      if (result && result.response && !result.response.length) {
        yield put(createInvoiceReportFailure({ message: 'There are no results in this date range.' }));
      } else {
        yield put(createInvoiceReportFailure({ message: apiError }));
      }
    }
  }
}
