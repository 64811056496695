import { put, select } from 'redux-saga/effects';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import { setFiltersClass } from '../../../../reducers/data/search.reducer';
import actions from '../../../../actions';

export const PollOrgClassOperation = {
  ADD: 'ADD',
  REMOVE: 'REMOVE'
};

export default function* pollOrgClasses(ids, operation) {
  if (!featureIsEnabled('data-recency') || !featureIsEnabled('data-recency-org-classes') || !ids.length) {
    return;
  }
  const orgId = yield select(state => state.identity.currentOrganisationId);
  yield put(setFiltersClass('orgClasses', { active: true, orgId, isOrgClassTab: true }));
  yield put(
    actions.pollEntitiesSynced({
      orgId,
      classes: ids.map(classId => ({ classId })),
      operation,
      collection: 'orgClasses',
      target: 'classes',
      responseEntityKey: 'classPollingStatus',
      identifier: 'classId',
      search: 'orgClasses'
    })
  );
}

export function pollOrgClassesAdded(ids) {
  return pollOrgClasses(ids, PollOrgClassOperation.ADD);
}

export function pollOrgClassesRemoved(ids) {
  return pollOrgClasses(ids, PollOrgClassOperation.REMOVE);
}
