import * as t from '../../actionTypes';

const initialState = {
  lmsConfig: { acceptMultiple: false, gradeSyncEnabled: false, token: '' },
  selectedProducts: [],
  confirmedProducts: [],
  selectedLinks: [],
  productDetailsModal: { isOpen: false, product: {} }
};

function ngiContentSelector(state = initialState, { type, payload }) {
  switch (type) {
    case t.NGI_SET_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: payload
      };
    case t.NGI_SET_CONFIRMED_PRODUCTS:
      return {
        ...state,
        confirmedProducts: payload
      };
    case t.NGI_SET_LMS_CONFIG:
      return {
        ...state,
        lmsConfig: payload
      };
    case t.NGI_SHOW_PRODUCT_DETAILS_MODAL:
      return {
        ...state,
        productDetailsModal: {
          isOpen: true,
          product: payload
        }
      };
    case t.NGI_CLOSE_PRODUCT_DETAILS_MODAL:
      return {
        ...state,
        productDetailsModal: {
          isOpen: false,
          product: {}
        }
      };
    case t.NGI_SET_SELECTED_LINKS:
      return {
        ...state,
        selectedLinks: payload
      };

    default:
      return state;
  }
}

export default ngiContentSelector;
