import { put, select } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import cookies from '../../../../../utils/cookies';

export const getClassReportEnvironmentUrl = orgId => `${__API_BASE__}/org/${orgId}/class-report-environment`;

function* getClassReportEnvironment(orgId) {
  const response = yield signedFetch('getClassReportEnvironment', getClassReportEnvironmentUrl(orgId), 'GET');

  if (response.status === 'success') {
    if (featureIsEnabled('mat-report-change-for-reducers')) {
      yield put(actions.gradebookClassReportEnvironmentSuccessV2(response.data, orgId));
      if (response.data.classrooms && response.data.locationId && response.data.academicYear) {
        const classrooms = response.data.classrooms.filter(classroom => classroom.archived === false);
        if (classrooms.length) {
          const { startDate, endDate } = yield select(state => state.savedSettings.settings.schoolReport);
          yield put(
            actions.gradebookGroupClassReportRequestV2(
              orgId,
              cookies.get(`${orgId}-classroom`) || classrooms[0].id,
              response.data.locationId,
              response.data.academicYear,
              startDate.toISOString(),
              endDate.toISOString()
            )
          );
        }
      }
    } else {
      yield put(actions.gradebookClassReportEnvironmentSuccess(response.data));
    }
    return;
  }

  if (featureIsEnabled('mat-report-change-for-reducers')) {
    const orgName = yield select(state => state.organisations.data[orgId].name);
    const locationId = 'uk';
    yield put(actions.gradebookClassReportEnvironmentFailureAddOrgDetails(orgId, orgName, locationId));
    yield put(actions.gradebookClassReportEnvironmentFailureV2(response.message, response.code, orgId));
  } else {
    yield put(actions.gradebookClassReportEnvironmentFailure(response.message, response.code));
  }
}

export default getClassReportEnvironment;
