import PropTypes from 'prop-types';
import React, { Component } from 'react';
import pick from 'lodash.pick';
import toString from 'lodash.tostring';
import mapValues from 'lodash.mapvalues';
import upperFirst from 'lodash.upperfirst';
import SidePanel from '../SidePanel/SidePanel';
import ImportUsersCheckingData from './ImportUsersCheckingData';
import ImportUsersConfirmation from './ImportUsersConfirmation';
import ImportUsersCopyPaste from './ImportUsersCopyPaste';
import ImportUsersFileUpload from './ImportUsersFileUpload';
import ImportUsersOptions from './ImportUsersOptions';
import ImportUsersReview from './ImportUsersReview';
import ImportUsersSuccess from './ImportUsersSuccess';
import ImportUsersErrorStatus from './ImportUsersErrorStatus';
import downloadAsFile from '../../utils/downloadAsFile';

export const IMPORT_USERS_OPTIONS_STEP = 'IMPORT_USERS_OPTIONS_STEP';
export const IMPORT_USERS_COPY_PASTE_STEP = 'IMPORT_USERS_COPY_PASTE_STEP';
export const IMPORT_USERS_FILE_UPLOAD_STEP = 'IMPORT_USERS_FILE_UPLOAD_STEP';
export const IMPORT_USERS_REVIEW_STEP = 'IMPORT_USERS_REVIEW_STEP';
export const IMPORT_USERS_CONFIRMATION_STEP = 'IMPORT_USERS_CONFIRMATION_STEP';
export const IMPORT_USERS_SUCCESS_STEP = 'IMPORT_USERS_SUCCESS_STEP';
export const IMPORT_USERS_EMAIL_CARDS_STEP = 'IMPORT_USERS_EMAIL_CARDS_STEP';

const initialState = {
  step: IMPORT_USERS_OPTIONS_STEP,
  source: ''
};

export default class ImportUsers extends Component {
  static _handleExportCsv = users => {
    const columns = ['firstName', 'lastName', 'userName', 'password', 'yearGroup', 'class'];
    const lines = Object.values(users)
      .map(item => mapValues(pick(item, columns), toString))
      .map(o => `${o.firstName},${o.lastName},${o.userName},${o.password},${o.yearGroup},${o.class}`);

    const filecontent = `${columns.map(upperFirst).join(',')}\n${lines.join('\n')}\n`;

    downloadAsFile(filecontent, 'BulkUploadTable.csv', 'text/csv');
  };

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentWillReceiveProps(nextProps) {
    // Reset location if retrying from a failed parse state
    const { failure } = this.props;
    if (failure && !nextProps.failure) {
      this._goToStep(IMPORT_USERS_OPTIONS_STEP);
    }
  }

  _goToStep = step => {
    this.setState({ step });
  };

  _updateSource = source => {
    this.setState({ source });
  };

  _parseSource = () => {
    const { parse = () => {} } = this.props;
    const { source } = this.state;
    parse(source);
    this._goToStep(IMPORT_USERS_REVIEW_STEP);
  };

  _upload = queue => {
    const { upload = () => {} } = this.props;
    upload(queue);
    this._goToStep(IMPORT_USERS_SUCCESS_STEP);
  };

  _handleClosePanel = () => {
    const { failure, closePanel = () => {}, onComplete } = this.props;
    const { step } = this.state;

    this.setState(initialState);

    if (onComplete && (failure || step === IMPORT_USERS_SUCCESS_STEP)) {
      onComplete();
    } else {
      closePanel();
    }
  };

  _renderContent() {
    const { step, source } = this.state;
    const {
      parsing = false,
      failure = false,
      checking = false,
      users = {},
      yearGroupOptions,
      curriculumType,
      numberOfErrors = 0,
      update = () => {},
      destroy = () => {},
      validate = () => {},
      retrySource = () => {},
      storeUnsavedUsers,
      batchId,
      currentOrgId,
      importRecordsCount,
      unsavedUsers = {}
    } = this.props;

    const { type, reason } = failure;

    switch (true) {
      case parsing:
      case checking:
        return <ImportUsersCheckingData />;
      case !!failure:
        return <ImportUsersErrorStatus type={type} reason={reason} onBack={retrySource} />;
      default:
        switch (step) {
          case IMPORT_USERS_OPTIONS_STEP:
            return (
              <ImportUsersOptions
                onCopyAndPasteSelected={() => this._goToStep(IMPORT_USERS_COPY_PASTE_STEP)}
                onFileUploadSelected={() => this._goToStep(IMPORT_USERS_FILE_UPLOAD_STEP)}
                curriculumType={curriculumType}
              />
            );
          case IMPORT_USERS_COPY_PASTE_STEP:
            return (
              <ImportUsersCopyPaste
                source={source.name ? '' : source}
                onPaste={this._updateSource}
                onBack={() => this._goToStep(IMPORT_USERS_OPTIONS_STEP)}
                onNext={this._parseSource}
              />
            );
          case IMPORT_USERS_FILE_UPLOAD_STEP:
            return (
              <ImportUsersFileUpload
                source={typeof source === 'object' ? source : {}}
                onFileUpload={this._updateSource}
                onBack={() => this._goToStep(IMPORT_USERS_OPTIONS_STEP)}
                onNext={this._parseSource}
              />
            );
          case IMPORT_USERS_REVIEW_STEP:
            return (
              <ImportUsersReview
                users={users}
                yearGroupOptions={yearGroupOptions}
                update={update}
                destroy={destroy}
                validate={validate}
                numberOfErrors={numberOfErrors}
                onBack={() => this._goToStep(IMPORT_USERS_OPTIONS_STEP)}
                onNext={() => this._goToStep(IMPORT_USERS_CONFIRMATION_STEP)}
                onExportCsv={ImportUsers._handleExportCsv}
                storeUnsavedUsers={storeUnsavedUsers}
                unsavedUsers={unsavedUsers}
              />
            );
          case IMPORT_USERS_CONFIRMATION_STEP:
            return (
              <ImportUsersConfirmation
                yearGroupOptions={yearGroupOptions}
                users={users}
                onNext={this._upload}
                onBack={() => this._goToStep(IMPORT_USERS_REVIEW_STEP)}
              />
            );
          case IMPORT_USERS_SUCCESS_STEP:
            return (
              <ImportUsersSuccess
                onDismiss={this._handleClosePanel}
                currentOrgId={currentOrgId}
                batchId={batchId}
                importRecordsCount={importRecordsCount}
              />
            );
          default:
            return null;
        }
    }
  }

  render() {
    const { id, ariaLabel, open = true, parsing, checking, failure } = this.props;
    const { step } = this.state;

    return (
      <SidePanel
        id={id}
        ariaLabel={ariaLabel}
        isOpen={open}
        onClose={this._handleClosePanel}
        panelSize={!(parsing || checking || failure) && step === IMPORT_USERS_REVIEW_STEP ? 'large' : null}
      >
        {this._renderContent()}
      </SidePanel>
    );
  }
}

ImportUsers.propTypes = {
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  open: PropTypes.bool,
  parsing: PropTypes.bool,
  failure: PropTypes.bool,
  checking: PropTypes.bool,
  users: PropTypes.object,
  yearGroupOptions: PropTypes.object.isRequired,
  numberOfErrors: PropTypes.number,
  parse: PropTypes.func,
  retrySource: PropTypes.func,
  update: PropTypes.func,
  destroy: PropTypes.func,
  upload: PropTypes.func,
  validate: PropTypes.func,
  closePanel: PropTypes.func,
  onComplete: PropTypes.func,
  curriculumType: PropTypes.string,
  batchId: PropTypes.string,
  currentOrgId: PropTypes.string,
  importRecordsCount: PropTypes.string,
  storeUnsavedUsers: PropTypes.func,
  unsavedUsers: PropTypes.object
};
