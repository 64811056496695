import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setFormState } from '../../../redux/actions/productFinderActions';
import { formStates, initialiseJourney, resetJourney } from '../../../redux/reducers/assignLearningMaterial.reducer';
import { productFinderFormStates } from '../../../redux/reducers/productFinder.reducer';
import { initialiseInstance } from '../../../redux/reducers/data/search.reducer';
import cmsContent from '../../../utils/cmsContent';
import AssignLearningMaterialConfirmation from './AssignLearningMaterialConfirmation';
import AssignLearningMaterialReview from './AssignLearningMaterialReview';
import AssignLearningMaterialReviewLicences from './AssignLearningMaterialReviewLicences';
import AssignLearningMaterialMultipleReviewLicences from './AssignLearningMaterialMultipleReviewLicences';
import AssignLearningMaterialSearch from './AssignLearningMaterialSearch';
import ProductFinderMaterialSearch from '../../../components/ProductFinder/formStates/ProductFinderMaterialSearch';
import ProductFinderSeriesOverview from '../../../components/ProductFinder/formStates/ProductFinderSeriesOverview';
import AssignLearningMaterialSubmitting from './AssignLearningMaterialSubmitting';
import { featureIsEnabled } from '../../../globals/envSettings';
import { isHubMode, getCurrentPlatform } from '../../../utils/platform';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import { assignmentContexts } from '../../../globals/assignmentConstants';
import withLocalizedContent from '../../../language/withLocalizedContent';
import withBreakpoint from '../../../decorators/withBreakpoint';

const { TARGET_USERTYPE } = HubLayoutConstants;
const bypassInitContexts = ['USER_PROFILE'];

class AssignLearningMaterial extends Component {
  componentWillMount() {
    this._initialDataSetup();
  }

  resetAll = () => {
    const { resetAction, setFormStateAction } = this.props;
    if (featureIsEnabled('product-finder-redesign') && isHubMode()) {
      setFormStateAction(productFinderFormStates.SEARCHING);
    }
    resetAction();
  };

  _resetWithInitialSetup = () => {
    this.resetAll();
    this._initialDataSetup();
  };

  reset = () => {
    setTimeout(() => {
      this.resetAll();
    }, 500);
  };

  _handleClosePanel = () => {
    const { closePopoutAction } = this.props;
    closePopoutAction();

    if (isHubMode()) {
      this.reset();
    }
  };

  _handleOnComplete = () => {
    const { onComplete } = this.props;

    this.reset();
    if (onComplete) onComplete();
  };

  _initialDataSetup() {
    const { initialiseAction, context, initialiseSearch, defaultSelectedFilterUsertype, orgId } = this.props;
    if (!bypassInitContexts.includes(context)) {
      initialiseAction(context);
      initialiseSearch('assignmentProducts', true, {
        orgId,
        showOnlyRedeemed: false,
        isAssignable: true,
        usertype: isHubMode() ? [defaultSelectedFilterUsertype] : [],
        platformCode: getCurrentPlatform().toUpperCase()
      });
    }
  }

  _renderRedesignedProductFinder() {
    const {
      productFinderFormState,
      formState,
      contextName,
      context,
      product,
      assignLicences,
      selectedUsers,
      userIdsInOrder,
      errorOccurred,
      defaultFilterUsertype,
      defaultSelectedFilterUsertype,
      localizedContent: { productFinder: productFinderContent },
      breakpoint
    } = this.props;

    if (productFinderFormState === productFinderFormStates.SEARCHING && formState === formStates.SEARCHING) {
      return (
        <ProductFinderMaterialSearch
          selectedUsers={selectedUsers}
          productFinderContent={productFinderContent}
          defaultFilterUsertype={defaultFilterUsertype}
          defaultSelectedFilterUsertype={defaultSelectedFilterUsertype}
          contextName={contextName}
          closePopoutAction={this._handleClosePanel}
        />
      );
    }
    if (productFinderFormState === productFinderFormStates.SERIES_OVERVIEW && formState === formStates.SEARCHING) {
      return (
        <ProductFinderSeriesOverview
          selectedUsers={selectedUsers}
          productFinderContent={productFinderContent}
          defaultFilterUsertype={defaultFilterUsertype}
          defaultSelectedFilterUsertype={defaultSelectedFilterUsertype}
          contextName={contextName}
          closePopoutAction={this._handleClosePanel}
          breakpoint={breakpoint}
        />
      );
    }
    if (formState === formStates.REVIEW_LICENCES) {
      return featureIsEnabled('add-multiple-learning-material') ? (
        <AssignLearningMaterialMultipleReviewLicences
          contextName={contextName}
          closePopoutAction={this._handleClosePanel}
          defaultFilterUsertype={defaultFilterUsertype}
        />
      ) : (
        <AssignLearningMaterialReviewLicences product={product} closePopoutAction={this._handleClosePanel} />
      );
    }
    if (formState === formStates.REVIEWING) {
      return (
        <AssignLearningMaterialReview
          product={product}
          contextName={contextName}
          closePopoutAction={this._handleClosePanel}
        />
      );
    }
    if (formState === formStates.SUBMITTING) {
      return <AssignLearningMaterialSubmitting />;
    }
    if (formState === formStates.CONFIRMATION) {
      return (
        <AssignLearningMaterialConfirmation
          context={context}
          contextName={contextName}
          product={product}
          assignLicences={assignLicences}
          userIdsInOrder={userIdsInOrder}
          errorOccurred={errorOccurred}
          onAddAnotherClick={this._resetWithInitialSetup}
          closePanel={this._handleClosePanel}
          onComplete={this._handleOnComplete}
        />
      );
    }
    return (
      <div>
        <p>Error. Missing a state for `{productFinderFormState}`.</p>
      </div>
    );
  }

  _renderLegacyProductFinder() {
    const {
      context,
      formState,
      contextName,
      product,
      assignLicences,
      userIdsInOrder,
      errorOccurred,
      selectedUsers,
      defaultFilterUsertype,
      defaultSelectedFilterUsertype
    } = this.props;

    if (formState === formStates.SEARCHING) {
      return (
        <AssignLearningMaterialSearch
          selectedUsers={selectedUsers}
          defaultFilterUsertype={defaultFilterUsertype}
          defaultSelectedFilterUsertype={defaultSelectedFilterUsertype}
          contextName={contextName}
          closePopoutAction={this._handleClosePanel}
        />
      );
    }
    if (formState === formStates.REVIEW_LICENCES) {
      return featureIsEnabled('add-multiple-learning-material') ? (
        <AssignLearningMaterialMultipleReviewLicences
          contextName={contextName}
          closePopoutAction={this._handleClosePanel}
          defaultFilterUsertype={defaultFilterUsertype}
        />
      ) : (
        <AssignLearningMaterialReviewLicences product={product} closePopoutAction={this._handleClosePanel} />
      );
    }
    if (formState === formStates.REVIEWING) {
      return (
        <AssignLearningMaterialReview
          product={product}
          contextName={contextName}
          closePopoutAction={this._handleClosePanel}
        />
      );
    }
    if (formState === formStates.SUBMITTING) {
      return <AssignLearningMaterialSubmitting />;
    }
    if (formState === formStates.CONFIRMATION) {
      return (
        <AssignLearningMaterialConfirmation
          context={context}
          contextName={contextName}
          product={product}
          assignLicences={assignLicences}
          userIdsInOrder={userIdsInOrder}
          errorOccurred={errorOccurred}
          onAddAnotherClick={this._resetWithInitialSetup}
          closePanel={this._handleClosePanel}
          onComplete={this._handleOnComplete}
        />
      );
    }
    return (
      <div>
        <p>Error. Missing a state for `{formState}`.</p>
      </div>
    );
  }

  render() {
    if (featureIsEnabled('product-finder-redesign') && isHubMode()) {
      return this._renderRedesignedProductFinder();
    }

    return this._renderLegacyProductFinder();
  }
}

// If class learning material assignment then only take learner total count learner will get piority to assign licence over other user
const getUserIdsForContext = (context, state) => {
  let userIds = [];
  if (context === assignmentContexts.CLASS || context === assignmentContexts.CLASS_RENEW) {
    const classData = state.classrooms.data[state.classroomPage.classroomId];
    if (isHubMode()) {
      // CLASS on HUB - Teachers and Students are on the same list.
      userIds = [...classData.studentIdList, ...classData.teacherIdList];
    } else {
      // CLASS - All class members
      userIds = classData.studentIdList;
    }
  } else if (context === assignmentContexts.CLASS_CREATION) {
    // CLASS_CREATION - All new class members
    userIds = state.classroomCreate.selectedStudentIds;
  } else if (context === assignmentContexts.CLASS_ADD_STUDENTS) {
    // CLASS_ADD_STUDENTS - All new class students
    userIds = state.addStudentsToClassroom.selectedStudentIds;
  } else if (context === assignmentContexts.CLASS_STUDENTS) {
    // CLASS_STUDENTS - Selected Students
    userIds = state.classroomPage.selectedStudentIds;
  } else if (context === assignmentContexts.CLASS_TEACHERS) {
    // CLASS_TEACHERS - Selected Teachers
    userIds = state.classroomPage.selectedTeacherIds;
  } else if (context === assignmentContexts.CLASS_USERS) {
    // CLASS_USERS - Selected Students and Teachers
    userIds = [...state.classroomPage.selectedStudentIds, ...state.classroomPage.selectedTeacherIds];
    //
  } else if (context === assignmentContexts.ORG_STUDENTS) {
    // ORG_STUDENTS - Selected Students
    userIds = state.organisationPage.selectedStudentIds;
  } else if (context === assignmentContexts.ORG_STAFF) {
    // ORG_STAFF - Selected staff
    userIds = state.organisationPage.selectedTeacherIds;
  } else if (context === assignmentContexts.USER_PROFILE || context === assignmentContexts.CLASS_RENEW) {
    // In these cases the userIds are passed to the state in initialisation
    userIds = state.assignLearningMaterial.userIds;
  } else if (context === assignmentContexts.CLASS_ONBOARDING_WIZARD) {
    const teacherIdList = [state.identity.userId];
    const studentIdList = Object.keys(state?.classOnboardingWizard?.userDetails?.students || {});
    userIds = [...teacherIdList, ...studentIdList];
  }
  return userIds;
};

const getDefaultTargetUsertype = () => [TARGET_USERTYPE.STUDENT, TARGET_USERTYPE.TEACHER];
const getSelectedTargetUserType = (context, classroomPage, selectedUsers) => {
  if (context === assignmentContexts.ORG_STUDENTS || classroomPage.selectedStudentIds.length === selectedUsers.length) {
    return TARGET_USERTYPE.STUDENT;
  }
  if (context === assignmentContexts.ORG_STAFF || classroomPage.selectedTeacherIds.length === selectedUsers.length) {
    return TARGET_USERTYPE.TEACHER;
  }

  return [TARGET_USERTYPE.STUDENT, TARGET_USERTYPE.TEACHER];
};
const getNameOfUser = (state, userId) =>
  `${state.people.data[userId]?.firstname || ''} ${state.people.data[userId]?.lastname || ''}`;

// Helper to subtitle text
const contextName = (context, state) => {
  const CMS = cmsContent.assignLearningMaterialPanel || {};
  let amount;
  if (context === assignmentContexts.CLASS_CREATION) {
    // Assign material straight from creation
    return state.classroomCreate.classroomNameValue;
    //
  }
  if (context === assignmentContexts.CLASS || context === assignmentContexts.CLASS_RENEW) {
    // Full Class assignment
    return state.classrooms.data[state.classroomPage.classroomId].name;
    //
  }
  if (context === assignmentContexts.CLASS_STUDENTS) {
    // Count the amount of students currently selected in the classroom page
    amount = state.classroomPage.selectedStudentIds.length;
    return amount === 1
      ? getNameOfUser(state, state.classroomPage.selectedStudentIds[0])
      : `${amount}${' '}${CMS.students_text}`;
    //
  }
  if (context === assignmentContexts.CLASS_ADD_STUDENTS) {
    amount = state.addStudentsToClassroom.selectedStudentIds.length;
    return amount === 1
      ? getNameOfUser(state, state.addStudentsToClassroom.selectedStudentIds[0])
      : `${amount}${' '}${CMS.students_text}`;
    //
  }
  if (context === assignmentContexts.CLASS_TEACHERS) {
    // Count the amount of teachers currently selected in the classroom page
    amount = state.classroomPage.selectedTeacherIds.length;
    return amount === 1
      ? getNameOfUser(state, state.classroomPage.selectedTeacherIds[0])
      : `${amount}${' '}${CMS.teachers_text}`;
    //
  }
  if (context === assignmentContexts.CLASS_USERS) {
    const userText = CMS.users_text;
    // Count the amount of teachers and students currently selected in the classroom page
    amount = state.classroomPage.selectedTeacherIds.length + state.classroomPage.selectedStudentIds.length;
    return amount === 1
      ? getNameOfUser(
          state,
          state.classroomPage.selectedStudentIds[0]
            ? state.classroomPage.selectedStudentIds[0]
            : state.classroomPage.selectedTeacherIds[0]
        )
      : `${amount}${' '}${userText}`;
    //
  }
  if (context === assignmentContexts.ORG_STUDENTS) {
    const userText = featureIsEnabled('add-multiple-learning-material') ? CMS.users_text : CMS.students_text;
    // Count the amount of teachers currently selected in the classroom page
    amount = state.organisationPage.selectedStudentIds.length;
    return amount === 1
      ? getNameOfUser(state, state.organisationPage.selectedStudentIds[0])
      : `${amount}${' '}${userText}`;
    //
  }
  if (context === assignmentContexts.ORG_STAFF) {
    const userText = featureIsEnabled('add-multiple-learning-material') ? CMS.users_text : CMS.staff_text;
    // Count the amount of teachers currently selected in the classroom page
    amount = state.organisationPage.selectedTeacherIds.length;
    return amount === 1
      ? getNameOfUser(state, state.organisationPage.selectedTeacherIds[0])
      : `${amount}${' '}${userText}`;
  }
  if (context === assignmentContexts.USER_PROFILE) {
    return state.assignLearningMaterial.userIds && state.assignLearningMaterial.userIds.length
      ? state.people.data[state.assignLearningMaterial.userIds[0]].firstname
      : '';
  }
  if (context === assignmentContexts.CLASS_ONBOARDING_WIZARD) {
    return state.classOnboardingWizard.classNameValue;
  }

  // Fallback
  return '';
};

function getProducts(state) {
  let products = [];
  if (featureIsEnabled('add-multiple-learning-material')) {
    const { selectedProductIds, assignLicencesMultiple } = state.assignLearningMaterial;
    selectedProductIds.forEach(productId => {
      const product = {
        ...state.products.data[productId],
        assignLicences: assignLicencesMultiple[productId],
        productId
      };
      products.push(product);
    });
    return products;
  }

  if (featureIsEnabled('product-finder-redesign') && isHubMode()) {
    // This is a temporary fix to ensure the assign learning material flow works properly
    // It will be removed once we implement the product finder feature start to finish
    // #PRODUCT_FINDER_REFACTOR
    products = [
      ...(state.productFinder?.products?.filter(p => p.productid === state.assignLearningMaterial.productId) || [])
    ];
  }
  if (products?.length) {
    return products[0];
  }
  return state.products.data[state.assignLearningMaterial.productId];
}

const getClassroomName = state => {
  const { classrooms, classroomPage } = state;
  return get(classrooms, ['data', [classroomPage.classroomId], 'name'], '');
};

// Wire up component with redux:
export default compose(
  connect(
    (state, props) => {
      const selectedUsers = getUserIdsForContext(props.context, state);
      const defaultFilterUsertype = getDefaultTargetUsertype();
      const defaultSelectedFilterUsertype = getSelectedTargetUserType(
        props.context,
        state.classroomPage,
        selectedUsers
      );
      return {
        productFinderFormState: state.productFinder.formState,
        formState: state.assignLearningMaterial.formState,
        contextName: contextName(props.context, state, props.orgId, getClassroomName(state)),
        selectedUsers,
        defaultFilterUsertype,
        defaultSelectedFilterUsertype,
        product: getProducts(state),
        assignLicences: state.assignLearningMaterial.assignLicences,
        userIdsInOrder: state.assignLearningMaterial.userIdsInOrder,
        errorOccurred: state.assignLearningMaterial.errorOccurred
      };
    },
    {
      initialiseAction: initialiseJourney,
      initialiseSearch: initialiseInstance,
      resetAction: resetJourney,
      setFormStateAction: setFormState
    }
  ),
  withLocalizedContent('productFinder'),
  withBreakpoint
)(AssignLearningMaterial);

AssignLearningMaterial.propTypes = {
  context: PropTypes.oneOf(Object.keys(assignmentContexts)).isRequired,
  contextName: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  formState: PropTypes.string.isRequired,
  productFinderFormState: PropTypes.string,
  product: PropTypes.object.isRequired,
  localizedContent: PropTypes.object,
  userIdsInOrder: PropTypes.array.isRequired,
  assignLicences: PropTypes.bool.isRequired,
  errorOccurred: PropTypes.bool.isRequired,
  closePopoutAction: PropTypes.func,
  onComplete: PropTypes.func,
  initialiseAction: PropTypes.func.isRequired,
  initialiseSearch: PropTypes.func.isRequired,
  resetAction: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  defaultFilterUsertype: PropTypes.string,
  defaultSelectedFilterUsertype: PropTypes.string,
  breakpoint: PropTypes.string,
  setFormStateAction: PropTypes.func
};
