import get from 'lodash.get';
import { courseResourceIsTeacherOnly } from '@oup/shared-node-browser/course.js';

export function hasResourcesForStudent(subFolder, resources) {
  const hasStudentResources = get(subFolder, ['resources'], []).some(
    id => resources[id] && !courseResourceIsTeacherOnly(resources[id])
  );
  return hasStudentResources;
}

export function getSubfolderTitles(folder = {}) {
  const subfolderTitles = Object.keys(folder).filter(folderTitle => !!folder[folderTitle]?.title);
  return subfolderTitles;
}

export function hasFoldersWithStudentResources(folder = {}, resources) {
  let hasStudentResources = false;
  const subFolders = getSubfolderTitles(folder);
  if (!hasStudentResources && folder?.resources) {
    hasStudentResources = hasResourcesForStudent(folder, resources);
  }
  if (!hasStudentResources && folder && subFolders.length) {
    for (let i = 0; i < subFolders.length; i += 1) {
      hasStudentResources = hasStudentResources || hasFoldersWithStudentResources(folder[subFolders[i]], resources);
    }
  }
  return hasStudentResources;
}
