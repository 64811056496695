import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
// Constants
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import userRoles from '../../globals/userRoles.js';
import { productInformationContext, HubLayoutConstants } from '../../globals/hubConstants.js';
// Redux
import { toggleDetailsPopupWithId } from '../../redux/reducers/assignLearningMaterial.reducer.js';
import { setMaterialToRemove } from '../../redux/reducers/removeLearningMaterial.reducer';
// Components
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal.js';
import Button, { buttonTypes } from '../../components/Button/Button.js';
import Lozenge from '../../components/Lozenge/Lozenge';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import Link from '../../components/Link/Link.js';
import HubLabel from '../../components/HubLabel/HubLabel.js';
// Utils
import { sanitizeUrl } from '../../utils/url.js';
import { safePanelLink } from '../../utils/links/panelLinks';
import { isHubMode, isCesMode } from '../../utils/platform.js';
import cmsContent from '../../utils/cmsContent.js';
// Style
import styles from './SitewideProductPopup.scss';
import colors from '../../globals/colors';

function SitewideProductPopup({
  detailsOpen,
  products,
  productId,
  toggleDetails,
  lozengeName,
  linkedListParentId,
  productLinkedList,
  userProductLinkedList,
  productInfoContext,
  setMaterialToRemoveAction,
  instance,
  productFinderProducts,
  classAssignments,
  userRole,
  content,
  productFinderLicenceDetails
}) {
  const history = useHistory();

  const isActualProduct = product => {
    if (product && product.isbn) {
      return product;
    }

    return null;
  };

  const getProductFromProductFinder = () => {
    const selectedProduct = productFinderProducts.find(product => product.productid === productId);
    return selectedProduct;
  };

  const CMS = cmsContent.mySchoolLibraryTab || {};
  const product =
    get(products, ['data', productId], null) ||
    isActualProduct(productId) ||
    (isHubMode() && getProductFromProductFinder()) ||
    {};

  let productFinal = product;
  if (linkedListParentId && productId) {
    if (instance === 'profileAssignments')
      productFinal = productLinkedList.data[productId].linkedProductDetails[linkedListParentId];
    else if (instance === 'userAssignments')
      productFinal = userProductLinkedList.data[productId].linkedProductDetails[linkedListParentId];
  }

  const getHelpAndSupportLink = () => (
    <Link openInNewTab to={content.what_is_a_licence_link} className={styles.helpAndSupportLink} underlined>
      {content.learn_about_licences}
      <SVGIcon glyph={GLYPHS.ICON_LINK} className={styles.linkGlyph} />
    </Link>
  );

  const getAvailableLicencesDetails = () => {
    if (
      userRole === userRoles.USER ||
      userRole === userRoles.LEARNER ||
      productInfoContext === productInformationContext.MY_COURSES
    ) {
      return null;
    }

    let { availableCount = 0 } = product;

    if (
      (productInfoContext === productInformationContext.USER_PROFILE_MATERIALS ||
        productInfoContext === productInformationContext.CLASS_MATERIALS) &&
      classAssignments?.data?.[productId]?.licenceDetails
    ) {
      const { licenceDetails } = classAssignments?.data?.[productId] || {};
      availableCount = licenceDetails.available;
    }

    if (productInfoContext === productInformationContext.PRODUCT_FINDER) {
      const licenceDetails = productFinderLicenceDetails?.[productId] || {};
      availableCount = licenceDetails.licencesTypes?.reduce(
        (acc, currentValue) => acc + currentValue.availableCount,
        0
      );
    }

    return (
      <div className={styles.licenceInfoContainer}>
        <div className={styles.licencesStatus}>
          {availableCount > 0 ? (
            <>
              <SVGIcon glyph={GLYPHS.ICON_CHECK_CIRCLE} />
              <span style={{ color: HubLayoutConstants.ORG_LICENCES_STATUS.CHECK_COLOUR }}>
                {availableCount} {content.licences_available}
              </span>
            </>
          ) : (
            <>
              <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
              <span>{content.no_licences_available}</span>
            </>
          )}
        </div>
        {getHelpAndSupportLink()}
      </div>
    );
  };

  const getProductImage = () => {
    const imageUrl =
      get(productFinal, ['image', 'defaultUrl'], '') ||
      get(productFinal, ['coverImage'], '') ||
      get(productFinal, ['cover'], '');
    const altText = get(productFinal, ['image', 'altText'], '') || productFinal.title;

    return {
      imageUrl,
      altText
    };
  };
  const { imageUrl, altText } = getProductImage();

  const getProductTypeLabel = () => {
    const productType = productFinal.target_usertype || productFinal.productUserRole;

    if (productType && (isHubMode() || isCesMode())) {
      const studentMaterialLabel = isHubMode() ? content.hub_label_student_material : content.student_material;
      const teacherMaterialLabel = isHubMode() ? content.hub_label_teacher_material : content.teacher_material;

      return (
        <div className={styles.productTypeLabelContainer}>
          <HubLabel
            text={
              productType === HubLayoutConstants.COURSE_USER_ROLES.TEACHER ? teacherMaterialLabel : studentMaterialLabel
            }
            isCollapsed={false}
            PrefixIcon={false}
          />
        </div>
      );
    }

    return null;
  };

  return (
    detailsOpen &&
    productFinal && (
      <div>
        <ConfirmationModal
          title={productFinal.title}
          body={productFinal.title}
          positiveClick={toggleDetails}
          negativeClick={toggleDetails}
        >
          <div className={styles.panel}>
            <div className={styles.close}>
              <Button
                text="Close"
                type={buttonTypes.CLOSE}
                onClick={() => toggleDetails(productId, null, null, productInfoContext)}
              />
            </div>

            <div className={styles.description}>
              <div className={styles.headerContainer}>
                <div className={styles.imge}>
                  <img alt={altText} src={sanitizeUrl(imageUrl)} className={styles.bookIcon} />
                </div>
                <div className={styles.headerContainerTitle}>
                  {getProductTypeLabel()}
                  <h2 className={styles.title}>{productFinal.title}</h2>
                  {isHubMode() && getAvailableLicencesDetails()}
                </div>
              </div>

              <div className={styles.bookInfo}>
                {productFinal.subtitle !== APP_CONSTANTS.NO_DATA_HERE && (
                  <p className="lead">{productFinal.subtitle}</p>
                )}
                <hr />
                <p>
                  <strong>{CMS.product_series_title_text} </strong>
                  {productFinal.series}
                </p>
                <hr />
                <p>
                  <strong>{CMS.product_ISBN_title_text} </strong>
                  {productFinal.isbn}
                </p>
                <hr />

                {productFinal.subtitle !== APP_CONSTANTS.NO_DATA_HERE && (
                  <p>
                    <strong>{CMS.product_Author_title_text} </strong>
                    {productFinal.author}
                  </p>
                )}
                <hr />
                <p>
                  <strong>{CMS.product_level_title_text} </strong>
                  {productFinal.cefrLevel || productFinal.cefr_level}
                </p>
                <hr />
                {productFinal.description !== APP_CONSTANTS.NO_DATA_HERE && <p>{productFinal.description}</p>}
              </div>
            </div>
          </div>
          <div className={styles.linkedContentContainer}>
            <div className={styles.content}>
              <div className={styles.linkedContent}>
                {lozengeName && lozengeName.length > 0 ? <h3 className="gin0">{CMS.linked_content}</h3> : ''}
              </div>
              <div className={styles.lozenge}>
                {lozengeName && lozengeName.length > 0
                  ? lozengeName.map(org =>
                      org.orgName && org.orgName !== undefined ? (
                        <Lozenge
                          key={org.orgName}
                          className="gin-right1"
                          text={org.orgName}
                          backgroundColor={colors.ORGANIZATION}
                          buttonAction={
                            org.removeLearningMaterialDetails.length > 0
                              ? () => {
                                  toggleDetails();
                                  setMaterialToRemoveAction(org.removeLearningMaterialDetails);
                                  history.push(safePanelLink('archiveAssignment'));
                                }
                              : null
                          }
                        />
                      ) : (
                        ''
                      )
                    )
                  : ''}
              </div>
            </div>
          </div>
        </ConfirmationModal>
      </div>
    )
  );
}

SitewideProductPopup.propTypes = {
  products: PropTypes.any,
  detailsOpen: PropTypes.bool.isRequired,
  toggleDetails: PropTypes.func.isRequired,
  productId: PropTypes.string,
  lozengeName: PropTypes.array,
  linkedListParentId: PropTypes.string,
  productInfoContext: PropTypes.string,
  instance: PropTypes.string.isRequired,
  userProductLinkedList: PropTypes.object,
  setMaterialToRemoveAction: PropTypes.func.isRequired,
  productLinkedList: PropTypes.object,
  productFinderProducts: PropTypes.array,
  classAssignments: PropTypes.object,
  userRole: PropTypes.string,
  content: PropTypes.object,
  productFinderLicenceDetails: PropTypes.object
};

export default connect(
  state => ({
    detailsOpen: state.assignLearningMaterial.detailsOpen,
    products: state.products,
    productId: state.assignLearningMaterial.productId,
    lozengeName: state.assignLearningMaterial.lozengeName,
    linkedListParentId: state.assignLearningMaterial.linkedListParentId,
    productInfoContext: state.assignLearningMaterial.context,
    instance: state.search.instance,
    productLinkedList: state.search.profileAssignments,
    userProductLinkedList: state.search.userAssignments,
    productFinderProducts: state.productFinder?.products || [],
    classAssignments: state.search.classAssignments,
    userRole: state.identity.role,
    productFinderLicenceDetails: state.productFinder.licenceStructure
  }),
  {
    setMaterialToRemoveAction: setMaterialToRemove,
    toggleDetails: toggleDetailsPopupWithId
  }
)(SitewideProductPopup);
