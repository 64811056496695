import assign from 'lodash.assign';

/**
 * Builds an array representation of the exercise for the
 * learner results.
 *
 * @param {number} score Score scored by learner
 * @param {number} total Total score available
 *
 * @return {array} The array question representation
 */
export default function getLearnerProgressResultsArray(score, total) {
  const remainder = +(score % 1).toFixed(1);

  // prettier-ignore
  return assign(
    new Array(Math.floor(total)).fill(0),
    new Array(Math.floor(score)).fill(1).concat(remainder || [])
  );
}
