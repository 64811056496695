/* eslint-disable react/jsx-props-no-spreading */
import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { parse as parseQueryString } from 'query-string';

import { selfDefinedUserTypes } from '@oup/shared-node-browser/user';
import { SidePanel, UserEnrolConfirmation, UserEnrolForm, UserEnrolSelectCohortForm } from '../components';
import withFormHandlers from '../decorators/withFormHandlers';
import { generateManagedUserPasswordRequest, generateManagedUserSignInCardRequest } from '../redux/actions/managedUser';
import {
  userEnrolRequest,
  userEnrolReset,
  userEnrolValidateInput,
  userEnrolDownloadSigninCard,
  userEnrolAddToClassView
} from '../redux/actions/userEnrol';
import { generateUserNameForManagedUserRequest } from '../redux/actions/managedUserChangeUsername';
import { defaultYearGroup, YEAR_GROUPS } from '../globals/yearGroup';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ActionList, { Action } from '../components/ActionList/ActionList';
import Button, { buttonTypes } from '../components/Button/Button';
import ScrollContainer from '../components/ScrollContainer/ScrollContainer';
import withLocalizedContent from '../language/withLocalizedContent';
import EnrolUserAddToClass from '../routes/MySchool/tabs/StaffTab/panels/EnrolUser/EnrolUserAddToClass';
import { clearSelectedUser, setClassroomSelected } from '../redux/reducers/organisationPage.reducer';

const USER_ENROL_FORM = 'USER_ENROL_FORM';
const USER_SELECT_COHORT_FORM = 'USER_SELECT_COHORT_FORM';
const DEBUG_PARAM = 'debug_enrol_managed_user';

class UserEnrolPanel extends React.Component {
  _getHiddenInputs = [
    'email',
    'oldPassword',
    'newPassword',
    'confirmPassword',
    'roleName',
    'social',
    'social-unlink-confirm'
  ];

  constructor(props) {
    super(props);
    this.state = {
      form: USER_ENROL_FORM
    };
  }

  async componentWillReceiveProps(nextProps) {
    const { orgId, password, onChange, onBlurWithParams, isGeneratingUserName } = this.props;
    if (nextProps.password !== password) {
      await onChange(nextProps.password, 'password');
      onBlurWithParams(orgId)('password');
    }
    if (isGeneratingUserName && !nextProps.isGeneratingUserName && nextProps.generatedUserName) {
      await onChange(nextProps.generatedUserName, 'userName');
      onBlurWithParams(orgId)('userName');
    }
  }

  _getUserEnrolFormIncomplete = () => {
    const { formInputErrors } = this.props;
    return ['firstName', 'lastName', 'userName', 'password'].some(prop => formInputErrors[prop] !== false);
  };

  _goToForm = form => {
    setTimeout(() => {
      this.setState({ form });
    }, 50);
  };

  _handleGenerateSignInCard = () => {
    const { userId, onGenerateSignInCard } = this.props;

    onGenerateSignInCard(userId);
  };

  _handleSubmit = () => {
    const { formInputChanges, onSubmit } = this.props;
    onSubmit({
      ...pick(formInputChanges, ['firstName', 'lastName', 'userName', 'password']),
      yearGroup: formInputChanges.yearGroup === undefined ? YEAR_GROUPS[defaultYearGroup] : formInputChanges.yearGroup
    });
  };

  _handleClosePanel = () => {
    const { onClearFormChanges, onReset, onClosePanel } = this.props;
    this.setState({ form: USER_ENROL_FORM });
    onClearFormChanges();
    onReset();
    onClosePanel();
  };

  _renderContent = () => {
    const {
      orgId,
      isSubmitting,
      isConfirmation,
      isAddToClassView,
      formInputChanges,
      firstName,
      lastName,
      orgStudentsDataRecency,
      onBlurWithParams,
      isDownloading,
      isDownloadingSuccess,
      isDownloadingFail,
      onDownloadSigninCard,
      size,
      hasFailed,
      userEnrolAddToClassViewAction,
      selectedClassIds,
      classrooms,
      removeClassIdAction,
      localizedContent: { userEnrolPanel: content }
    } = this.props;
    const params = parseQueryString(window.location.search);
    const isDebug = params[DEBUG_PARAM];
    const isSyncingData = orgStudentsDataRecency.ids.length && !orgStudentsDataRecency.synced;
    const { form } = this.state;

    switch (true) {
      case isSubmitting:
        return (
          <ScrollContainer>
            <PopoutPanelIconHeading
              type={popoutPanelIconHeadingTypes.LOADING}
              title={content.loading_page_title}
              subtitle={content.loading_page_subtitle}
            />
          </ScrollContainer>
        );
      case isDownloading:
        return (
          <PopoutPanelIconHeading
            title={content.loading_page_title}
            subtitle={content.loading_page_title_card_signin_card}
            type={popoutPanelIconHeadingTypes.LOADING}
          />
        );
      case isDownloadingSuccess:
        return (
          <ScrollContainer
            footerContent={
              <ActionList>
                <Button
                  type={buttonTypes.DOWNLOAD}
                  text={(content.button_download_signin_card || '').replace('{kb}', size)}
                  onClick={onDownloadSigninCard}
                  disabled={false}
                  fullWidth
                />
                <Action label={content.button_done} primary onClick={this._handleClosePanel} />
              </ActionList>
            }
          >
            <PopoutPanelIconHeading
              title={content.download_success_title}
              subtitle={content.download_success_sub_title}
              type={popoutPanelIconHeadingTypes.VALID}
            />
          </ScrollContainer>
        );
      case isDownloadingFail:
        return (
          <ScrollContainer
            footerContent={
              <ActionList>
                <Action label={content.button_try_again} primary onClick={this._handleClosePanel} />
              </ActionList>
            }
          >
            <PopoutPanelIconHeading title={content.download_fail_title} type={popoutPanelIconHeadingTypes.ERROR} />
          </ScrollContainer>
        );
      case isAddToClassView:
        return (
          <EnrolUserAddToClass
            closePanel={this._handleClosePanel}
            orgId={orgId}
            userType={selfDefinedUserTypes.CLASS_STUDENT}
          />
        );
      case isConfirmation:
        return hasFailed ? (
          <ScrollContainer
            footerContent={
              <ActionList>
                <Action label={content.button_try_again} primary onClick={this._handleClosePanel} />
              </ActionList>
            }
          >
            <PopoutPanelIconHeading title={content.adding_student_error} type={popoutPanelIconHeadingTypes.ERROR} />
          </ScrollContainer>
        ) : (
          <UserEnrolConfirmation
            {...pick(this.props, ['orgName', 'signedUrl', 'isGeneratingSignInCard', 'hasFailed'])}
            {...(isDebug ? { ...pick(formInputChanges, ['firstName', 'lastName']) } : { firstName, lastName })}
            isSyncingData={isSyncingData}
            onGenerateSignInCard={this._handleGenerateSignInCard}
            onDone={this._handleClosePanel}
            downloadSigninCard={onDownloadSigninCard}
          />
        );
      default:
        switch (form) {
          case USER_SELECT_COHORT_FORM:
            return (
              <UserEnrolSelectCohortForm
                {...pick(this.props, ['orgName', 'orgCurriculumType', 'formInputChanges', 'onChange'])}
                onBack={() => this._goToForm(USER_ENROL_FORM)}
                onSubmit={this._handleSubmit}
              />
            );
          default:
            return (
              <UserEnrolForm
                {...pick(this.props, [
                  'orgName',
                  'formInputChanges',
                  'formInputErrors',
                  'isCheckingUserNameTaken',
                  'isUserNameTaken',
                  'isGeneratingPassword',
                  'onChange',
                  'onBlur',
                  'onShowImportUsers',
                  'onGeneratePassword',
                  'onGenerateUserName',
                  'isGeneratingUserName',
                  'orgCurriculumType'
                ])}
                userEnrolAddToClassView={userEnrolAddToClassViewAction}
                hidden={this._getHiddenInputs}
                isSubmitDisabled={this._getUserEnrolFormIncomplete()}
                onBlur={onBlurWithParams(orgId)}
                onSubmit={() => this._goToForm(USER_SELECT_COHORT_FORM)}
                onAddStudentSubmit={this._handleSubmit}
                onCancel={this._handleClosePanel}
                selectedClassIds={selectedClassIds}
                classrooms={classrooms}
                removeClassIdAction={removeClassIdAction}
              />
            );
        }
    }
  };

  render() {
    const { id, ariaLabel, formInputChanges, isOpen } = this.props;
    const isConfirmBeforeClose = !!Object.keys(formInputChanges).length;

    return (
      <SidePanel
        id={id}
        ariaLabel={ariaLabel}
        confirmBeforeClose={isConfirmBeforeClose}
        isOpen={isOpen}
        onClose={this._handleClosePanel}
      >
        {this._renderContent()}
      </SidePanel>
    );
  }
}

UserEnrolPanel.propTypes = {
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  orgCurriculumType: PropTypes.string.isRequired,
  userId: PropTypes.string,
  password: PropTypes.string,
  signedURL: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  isCheckingUserNameTaken: PropTypes.bool,
  isUserNameTaken: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isConfirmation: PropTypes.bool,
  isGeneratingPassword: PropTypes.bool,
  isGeneratingSignInCard: PropTypes.bool,
  hasFailed: PropTypes.bool,
  isAddToClassView: PropTypes.bool,
  formInputChanges: PropTypes.object.isRequired,
  formInputErrors: PropTypes.object.isRequired,
  orgStudentsDataRecency: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlurWithParams: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  onGeneratePassword: PropTypes.func,
  onGenerateSignInCard: PropTypes.func.isRequired,
  onShowImportUsers: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onClearFormChanges: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onClosePanel: PropTypes.func.isRequired,
  generatedUserName: PropTypes.string.isRequired,
  isGeneratingUserName: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isDownloading: PropTypes.bool,
  isDownloadingSuccess: PropTypes.bool,
  isDownloadingFail: PropTypes.bool,
  onDownloadSigninCard: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  size: PropTypes.any,
  userEnrolAddToClassViewAction: PropTypes.func,
  selectedClassIds: PropTypes.array,
  classrooms: PropTypes.object,
  removeClassIdAction: PropTypes.func
};

export default compose(
  withLocalizedContent('userEnrolPanel'),
  connect(
    ({
      userEnrol,
      managedUserChangePassword,
      managedUserSignInCard,
      managedUserChangeUsername,
      organisationPage,
      classrooms
    }) => ({
      ...pick(userEnrol, [
        'userId',
        'isCheckingUserNameTaken',
        'isUserNameTaken',
        'hasFailed',
        'isSubmitting',
        'isAddToClassView',
        'isConfirmation',
        'firstName',
        'lastName',
        'isDownloading',
        'isDownloadingSuccess',
        'isDownloadingFail',
        'size'
      ]),
      password: managedUserChangePassword.password,
      isGeneratingPassword: managedUserChangePassword.generating,
      isGeneratingUserName: managedUserChangeUsername.generating,
      signedUrl: managedUserSignInCard.signedURL,
      formInputErrors: userEnrol.errors,
      isGeneratingSignInCard: managedUserSignInCard.submitting,
      generatedUserName: managedUserChangeUsername.generatedUserName,
      selectedClassIds: organisationPage.selectedClassroomIds,
      classrooms: classrooms.data
    }),
    (dispatch, { orgId }) => ({
      validate: (id, input) => {
        dispatch(userEnrolValidateInput(id, input));
      },
      onGeneratePassword: () => {
        dispatch(generateManagedUserPasswordRequest(orgId));
      },
      onGenerateUserName: (firstName, lastName) => {
        dispatch(generateUserNameForManagedUserRequest({ orgId, firstName, lastName }));
      },
      onGenerateSignInCard: userId => {
        dispatch(generateManagedUserSignInCardRequest(orgId, userId));
      },
      onSubmit: user => {
        dispatch(userEnrolReset());
        dispatch(userEnrolRequest(orgId, user));
      },
      onReset: () => {
        dispatch(userEnrolReset());
        dispatch(clearSelectedUser());
      },
      onDownloadSigninCard: () => {
        dispatch(userEnrolDownloadSigninCard());
      },
      userEnrolAddToClassViewAction: () => {
        dispatch(userEnrolAddToClassView());
      },
      removeClassIdAction: id => {
        dispatch(setClassroomSelected(id, false));
      }
    })
  ),
  withFormHandlers({ firstName: '', lastName: '', userName: '', password: '' })
)(UserEnrolPanel);
