import zipObject from 'lodash.zipobject';

/**
 * Return an object containing the named parts from a fully-qualified username
 * string.
 * @param {string} prefixedUsername
 * @return {{orgCusomId: string|null, username: string|null}} username parts
 *
 */
export default function getUsernameParts(prefixedUsername = '') {
  return zipObject(
    ['orgCustomId', 'username'],
    prefixedUsername.split(/_(.+)/, 2).map(segment => segment || null)
  );
}
