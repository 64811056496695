import classnames from 'classnames';
import pickBy from 'lodash.pickby';
import { marked } from 'marked';
import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './OxfordIdHelp.scss';

function OxfordIdHelp({ localizedContent: { oxfordIdHelpComponent: content } }) {
  return (
    <div>
      <section>
        <h1>{content.question}</h1>
        <p className={styles.answer}>{content.answer}</p>
        <ul className={styles.list}>
          {Object.values(pickBy(content, (value, name) => name.startsWith('link_'))).map((value, $0) => (
            <li
              key={$0}
              className={classnames(styles.item, { [styles.borderless]: !$0 })}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: marked(value) }}
            />
          ))}
        </ul>
        <p className={styles.copy}>{content.guidance}</p>
      </section>
    </div>
  );
}

OxfordIdHelp.propTypes = { localizedContent: PropTypes.object.isRequired };

export default withLocalizedContent('oxfordIdHelpComponent')(OxfordIdHelp);
