import React, { Component } from 'react';
import classnames from 'classnames';
import mapValues from 'lodash.mapvalues';
import upperFirst from 'lodash.upperfirst';
import moment from 'moment';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import withGetYearGroupOptions from '../../language/withGetYearGroupOptions';
import withLocalizedContent from '../../language/withLocalizedContent';
import { DATE_SHORT } from '../../globals/dateFormats';
import Button, { buttonTypes } from '../Button/Button';
import EntityListing from '../EntityListing/EntityListing';
import StatusIcon, { StatusType } from '../StatusIcon/StatusIcon';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import UserRepresentation from '../UserRepresentation/UserRepresentation';
import styles from './UserListing.css';
import { DATE_CONSTANTS } from '../../globals/hubConstants';
import isInThePast from '../../utils/isInThePast';
import { isHubMode } from '../../utils/platform';
import { featureIsEnabled } from '../../globals/envSettings';

/**
 * The placeholder to use when the displayed user date is hidden.
 *
 * @type {string}
 */
const DATE_PLACEHOLDER = DATE_SHORT.replace(/\w/g, '-');

export const ListingTypes = {
  REGISTRATION: Symbol('REGISTRATION'),
  JOIN: Symbol('JOIN'),
  ENROLMENT: Symbol('ENROLMENT'),
  PANEL: Symbol('PANEL')
};

class UserListing extends Component {
  static _getLicenceExpirationDate = (licenceStatusList, id) => {
    if (Object.keys(licenceStatusList).includes(id)) return licenceStatusList[id];
    return DATE_CONSTANTS.NO_DATE;
  };

  _getClassStatusType = (user, id) => {
    const { processingItems = [], classroomId } = this.props;
    const isArchivedFromClass = classroomId ? user[`classArchiveStatus-${classroomId}`] === 'ARCHIVED' : false;
    switch (true) {
      case processingItems.includes(id):
        return StatusType.PROCESSING;
      case isArchivedFromClass:
        return StatusType.ARCHIVED;
      default:
        return this._getOrgStatusType(user, id);
    }
  };

  _getColumnHeadings = listingType => {
    const {
      localizedContent: { userListingComponent: content }
    } = this.props;
    const commonHeadings = {
      createdOn: content.heading_enroled,
      status: content.heading_status,
      archive: content.heading_remove,
      resend_organization_mail: content.heading_resend_organization_mail
    };

    switch (listingType) {
      case ListingTypes.JOIN:
        return {
          ...commonHeadings,
          createdOn: content.heading_joined
        };
      case ListingTypes.REGISTRATION:
        return {
          ...commonHeadings,
          createdOn: content.heading_registered
        };
      case ListingTypes.PANEL:
        return {};
      case ListingTypes.ENROLMENT:
      default:
        return {
          ...commonHeadings,
          createdOn: content.heading_enroled
        };
    }
  };

  _getOrgStatusType = (user, id) => {
    const { processingItems = [] } = this.props;

    switch (true) {
      case processingItems.includes(id):
        return StatusType.PROCESSING;
      case user.isDeleted:
        return StatusType.DELETED;
      case user.orgInviteStatus === 'PENDING':
        return StatusType.USER_INVITED;
      case user.orgInviteStatus === 'REQUESTED':
        return StatusType.USER_REQUESTED;
      case user.orgArchiveStatus === 'ARCHIVED':
        return StatusType.ARCHIVED;
      default:
        return StatusType.ACTIVE;
    }
  };

  _hasLicenceCurrentStudent = studentId => {
    const { licenceStatusList } = this.props;
    return Object.keys(licenceStatusList).includes(studentId);
  };

  _expirationDateComparison = (currentStudent, nextStudent) => {
    const { licenceStatusList } = this.props;
    if (this._hasLicenceCurrentStudent(currentStudent) && this._hasLicenceCurrentStudent(nextStudent)) {
      // currentStudent's licence is expired, nextStudent's licence is NOT expired
      if (
        isInThePast(licenceStatusList[currentStudent].expiresOn) &&
        !isInThePast(licenceStatusList[nextStudent].expiresOn)
      ) {
        return -1;
      }
      // currentStudent's licence is NOT expired, nextStudent's licence is expired
      if (
        isInThePast(licenceStatusList[currentStudent].expiresOn) &&
        !isInThePast(licenceStatusList[nextStudent].expiresOn)
      ) {
        return 1;
      }
    } else {
      // currentStudent has a licence , nextStudent does NOT have a licence
      if (this._hasLicenceCurrentStudent(currentStudent) && !this._hasLicenceCurrentStudent(nextStudent)) {
        return 1;
      }
      // currentStudent does NOT have a licence, nextStudent has a licence
      if (!this._hasLicenceCurrentStudent(currentStudent) && this._hasLicenceCurrentStudent(nextStudent)) {
        return -1;
      }
    }
    return 0;
  };

  _sortStudentsWithoutLicenceAtTop = students => {
    const arraySortedStudents = Object.entries(students).sort((currentItem, nextItem) =>
      this._expirationDateComparison(currentItem[0], nextItem[0])
    );
    const objectSortedStudents = Object.fromEntries(arraySortedStudents);
    return objectSortedStudents;
  };

  render() {
    const {
      items,
      processingItems = [],
      heading,
      showYearGroups = false,
      authUserId,
      orgCurriculumType,
      listingType = ListingTypes.ENROLMENT,
      onItemLabelClick,
      onRemoveClick,
      onRestoreClick,
      onResendInviteClick,
      getYearGroupOptions,
      getOptionNameByValue,
      classroomId,
      preventArchivedLinkClick = false,
      orgPending,
      licenceStatusItem = null,
      licenceStatusList = null,
      localizedContent: { sharedTerms, userListingComponent: content },
      ...props
    } = this.props;
    const headings = this._getColumnHeadings(listingType);

    let usersInformation = items;
    if (isHubMode() && licenceStatusList) usersInformation = this._sortStudentsWithoutLicenceAtTop(items);

    return (
      <EntityListing
        orgPending={orgPending}
        {...props}
        items={mapValues(usersInformation, (user, id) => {
          const processing = processingItems.includes(id);
          const statusType = classroomId ? this._getClassStatusType(user, id) : this._getOrgStatusType(user, id);
          const archived = statusType === StatusType.ARCHIVED;
          const deleted = statusType === StatusType.DELETED;
          const isAuthUser = id === authUserId;
          const tooltipLabel = !deleted
            ? [
                content[`label_${onRestoreClick && archived ? 'restore' : 'remove'}`],
                user.firstname,
                user.lastname
              ].join(' ')
            : null;

          const inviteDate = classroomId && user.classInviteDate ? user.classInviteDate : user.orgInviteDate;
          return {
            [heading || upperFirst(sharedTerms.USERS)]: (
              <div className={classnames(styles.user, { [styles.processing]: processing })}>
                <UserRepresentation
                  firstName={user.firstname || ''}
                  lastName={user.lastname || ''}
                  identifier={user.email}
                  role={user.roleName}
                  yearGroup={
                    showYearGroups ? getOptionNameByValue(getYearGroupOptions(orgCurriculumType), user.yearGroup) : null
                  }
                  locked={user.isLocked}
                  onLabelClick={
                    archived && preventArchivedLinkClick && ListingTypes.ENROLMENT ? null : () => onItemLabelClick(id)
                  }
                  processing={processing}
                  archived={archived}
                  deleted={deleted}
                  licenceStatusItem={licenceStatusItem}
                  expirationDateLicence={
                    licenceStatusList ? UserListing._getLicenceExpirationDate(licenceStatusList, id) : ''
                  }
                />
              </div>
            ),
            ...(headings.resend_organization_mail
              ? {
                  '': (
                    <div>
                      {onResendInviteClick ? (
                        <Button
                          id={`button-resend-invitation-${id}`}
                          text={content.button_resend_organization_setup_email}
                          disabled={archived}
                          onClick={() => onResendInviteClick(id)}
                          type={buttonTypes.ROUNDED}
                        />
                      ) : null}
                    </div>
                  )
                }
              : {}),
            ...(headings.status ? { [headings.status]: <StatusIcon type={statusType} /> } : {}),
            ...(headings.createdOn
              ? {
                  [headings.createdOn]: (
                    <div className={classnames({ [styles.processing]: processing })}>
                      {deleted || !inviteDate ? DATE_PLACEHOLDER : moment(inviteDate).format(DATE_SHORT)}
                    </div>
                  )
                }
              : {}),
            ...(onRemoveClick || onRestoreClick
              ? {
                  [headings.archive]: (
                    <div className={classnames({ [styles.processing]: processing })}>
                      <Button
                        type={buttonTypes.NO_BORDER}
                        title={tooltipLabel}
                        iconOnly
                        {...(onRemoveClick && !archived && !deleted
                          ? {
                              glyph: GLYPHS.ICON_REMOVE,
                              onClick: () => onRemoveClick(id)
                            }
                          : {})}
                        {...(onRestoreClick && archived
                          ? {
                              glyph:
                                featureIsEnabled('archive-update-username-timestamp') &&
                                user.roleName === 'MANAGED_USER'
                                  ? GLYPHS.ICON_REMOVE
                                  : GLYPHS.ICON_RESTORE,
                              onClick: () => onRestoreClick(id)
                            }
                          : {})}
                        disabled={
                          (isAuthUser && !classroomId) ||
                          (isAuthUser && user.orgRoleName === 'TEACHER') ||
                          deleted ||
                          (archived && !onRestoreClick) ||
                          (featureIsEnabled('archive-update-username-timestamp') &&
                            archived &&
                            user.roleName === 'MANAGED_USER')
                        }
                      />
                    </div>
                  )
                }
              : {})
          };
        })}
      />
    );
  }
}

UserListing.propTypes = {
  items: PropTypes.object.isRequired,
  processingItems: PropTypes.arrayOf(PropTypes.string),
  heading: PropTypes.string,
  authUserId: PropTypes.string,
  selectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
  showYearGroups: PropTypes.bool,
  orgCurriculumType: PropTypes.string,
  listingType: PropTypes.oneOf(Object.values(ListingTypes)),
  onItemLabelClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  onRestoreClick: PropTypes.func,
  onResendInviteClick: PropTypes.func,
  getYearGroupOptions: PropTypes.func.isRequired,
  getOptionNameByValue: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  classroomId: PropTypes.string,
  preventArchivedLinkClick: PropTypes.bool,
  orgPending: PropTypes.bool,
  licenceStatusItem: PropTypes.bool,
  licenceStatusList: PropTypes.object
};

export default compose(
  withGetYearGroupOptions,
  withLocalizedContent('sharedTerms', 'userListingComponent')
)(UserListing);
