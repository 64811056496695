/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { PLATFORMS } from '@oup/shared-node-browser/constants';
import Button from '@oup/shared-front-end/src/components/Button';
import styles from './PreAuthConfirmationPage.scss';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import {
  isHubMode,
  isOteMode,
  isOptMode,
  isOicMode,
  isEltAssessmentMode,
  isEbookSampleMode
} from '../../utils/platform';
import { getIdpLoginUrl } from '../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import { featureIsEnabled, isOIDCStandard } from '../../globals/envSettings';
import userRole from '../../globals/userRoles';
import { inspLayoutConstants } from '../../globals/inspConstants';
import { sanitizeUrl, isUrlFromTrustedDomain, prepareTargetUrl } from '../../utils/url';
import RegisterStep from './RegisterStep';

class PreAuthConfirmationPage extends React.Component {
  static errorPanel(errorCode, message, content, subtitle) {
    if (!isHubMode()) {
      return errorCode === 'TOKEN_INVALID' ? (
        <PopoutPanelIconHeading type={types.ERROR} title={message}>
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: content.error_invalid_token_message }} />
        </PopoutPanelIconHeading>
      ) : (
        <PopoutPanelIconHeading
          type={types.ERROR}
          title={message}
          subtitle={(content.account_create_fail_text || '').replace('{subtitle}', subtitle)}
          link={content.contact_us_link}
          linkText={content.contact_us}
        />
      );
    }

    return errorCode === 'TOKEN_INVALID' ? (
      <PopoutPanelIllustrationHeading
        type={types.ERROR}
        title={message}
        illustrationSrc={HubIllustrationConstants.ERROR}
        illustrationAltText={HubIllustrationAltText.ERROR}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: content.error_invalid_token_message }} />
      </PopoutPanelIllustrationHeading>
    ) : (
      <PopoutPanelIllustrationHeading
        type={types.ERROR}
        title={message}
        subtitle={(content.account_create_fail_text || '').replace('{subtitle}', subtitle)}
        link={content.contact_us_link}
        linkText={content.contact_us}
        illustrationSrc={HubIllustrationConstants.ERROR}
        illustrationAltText={HubIllustrationAltText.ERROR}
      />
    );
  }

  handleSignIn = async () => {
    const { platform, offeredRole, location } = this.props;
    const query = new URLSearchParams(location.search);
    let returnUrl;
    let selectPlatform;
    if (isHubMode()) {
      selectPlatform = PLATFORMS.HUB;
    } else if (isOptMode()) {
      selectPlatform = PLATFORMS.OPT;
    } else if (isOteMode()) {
      selectPlatform = PLATFORMS.OTE;
    } else if (isOicMode()) {
      selectPlatform = PLATFORMS.OIC;
    } else if (offeredRole === userRole.LEARNER) {
      selectPlatform = platform;
    } else {
      selectPlatform = PLATFORMS.CES;
    }

    const redirectTo = prepareTargetUrl();

    if (redirectTo) {
      returnUrl = redirectTo;
    } else if (isHubMode() || isEltAssessmentMode() || isOicMode()) {
      returnUrl = await getIdpLoginUrl(null, {
        targetUrl: sanitizeUrl(query.get('returnUrl')),
        withReturnTo: false,
        platform: selectPlatform
      });
    } else if (query.get('returnUrl') && !(selectPlatform === PLATFORMS.OLB && offeredRole === userRole.LEARNER)) {
      const sanitizedUrl = sanitizeUrl(query.get('returnUrl'));
      returnUrl = isUrlFromTrustedDomain(sanitizedUrl)
        ? sanitizedUrl
        : await getIdpLoginUrl(null, { withReturnTo: false, platform: selectPlatform });
    } else {
      const options = {
        withReturnTo: false,
        platform: selectPlatform
      };

      if (isOIDCStandard()) {
        options.targetUrl = sanitizeUrl(query.get('returnUrl'));
      }
      if (isEbookSampleMode()) {
        options.targetUrl = inspLayoutConstants.PATH_NAMES.START_PAGE;
      }

      returnUrl = await getIdpLoginUrl(null, options);
    }

    window.location.href = returnUrl;
  };

  render() {
    const {
      errorOccurred,
      firstNameValue,
      errorCode,
      isRegister,
      errorMessage,
      localizedContent: { registrationPage: content }
    } = this.props;

    console.log('PreAuthConfirmationPage', errorMessage, errorCode);

    let message;
    let subtitle;
    // Try to figure out what happened:
    switch (errorCode) {
      case 'TOKEN_NOT_FOUND':
        message = content.error_no_token_text;
        subtitle = '';

        break;
      case 'USER_NOT_FOUND':
        message = content.error_user_not_found_text;
        subtitle = '';

        break;
      case 'TOKEN_INVALID':
        message = content.link_does_not_work;
        subtitle = '';

        break;
      default:
        message = content.registration_error_message;
        subtitle = content.registration_error_subtitle_message;
    }

    const title = isRegister
      ? (content.account_create_success_text || '').replace('{firstName}', firstNameValue)
      : content.account_update_username_success_text || '';

    const isExtRegFeature = featureIsEnabled('ote-extend-registration');
    if (isOteMode() && isExtRegFeature) {
      return (
        <div className="row">
          <div className="col">
            {!errorOccurred ? (
              <RegisterStep
                title={content.register_step1_completed_title_text}
                subtitle={content.register_step1_completed_subtitle_text}
                btnText={content.register_step1_completed_button_text}
                btnAction={this.handleSignIn}
              />
            ) : (
              <div>
                {/* ERROR: */}
                {PreAuthConfirmationPage.errorPanel(errorCode, message, content, subtitle)}
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col">
          {!errorOccurred ? (
            <div>
              {/* SUCCESS: */}
              {isHubMode ? (
                <PopoutPanelIllustrationHeading
                  type={types.VALID}
                  title={title}
                  illustrationSrc={HubIllustrationConstants.SUCCESS}
                  HubIllustrationAltText={HubIllustrationAltText.SUCCESS}
                />
              ) : (
                <PopoutPanelIconHeading type={types.VALID} title={title} />
              )}

              <div className={`text-center gin-top2 ${styles.confirmCenter}`}>
                {isRegister ? (
                  <Button
                    id="link-to-login"
                    variant="filled"
                    text={
                      isHubMode() ? content.button_enter_application_text_hub : content.button_enter_application_text
                    }
                    onClick={this.handleSignIn}
                  />
                ) : (
                  <Button
                    id="link-to-login"
                    variant="filled"
                    text={content.button_sign_in_text}
                    onClick={this.handleSignIn}
                  />
                )}
              </div>
            </div>
          ) : (
            <div>
              {/* ERROR: */}
              {PreAuthConfirmationPage.errorPanel(errorCode, message, content, subtitle)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

PreAuthConfirmationPage.propTypes = {
  errorOccurred: PropTypes.bool,
  isRegister: PropTypes.bool,
  firstNameValue: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  errorCode: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  platform: PropTypes.string,
  offeredRole: PropTypes.string,
  location: PropTypes.object
};

export default compose(
  withLocalizedContent('registrationPage'),
  connect(
    state => ({
      errorOccurred: state.registration.errorOccurred,
      firstNameValue: state.registration.firstNameValue,
      errorMessage: state.registration.errorMessage,
      errorCode: state.registration.errorCode
    }),
    {}
  )
)(PreAuthConfirmationPage);
