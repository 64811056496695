import { put, take, all } from 'redux-saga/effects';
import { showPreview, showErrorReview, SUBMIT_JOINING_CODE } from '../../../../reducers/joinClass.reducer.js';
import getClassDetailsRequest from './getDetailsFromJoiningCodeRequest.js';

export default function* getClassDetailsFromCode() {
  while (true) {
    yield take(SUBMIT_JOINING_CODE);

    const result = yield getClassDetailsRequest();
    const status = result.status?.toLowerCase();
    const failed = !!result.error || status !== 'success';
    const apiError = result.error || status !== 'success' ? { code: result.code, message: result.message } : null;
    const classroomName = result.data?.groupName || '';
    const organisationName = result.data?.orgName || '';
    const groupType = result.data?.groupType || '';

    if (!failed) {
      yield all([put(showPreview(failed, apiError, classroomName, organisationName, groupType))]);
    } else {
      yield put(showErrorReview(apiError));
    }
  }
}
