import pick from 'lodash.pick';
import { SYSTEM_NOTIFICATION_PLATFORMS } from '../../globals/appConstants';
/** App reducer
 *  This reducer should contain all state related to the global application UI.
 *  This includes menu states in the header & nav menu. This shouldn't include
 *  state related to specific pages or content.
 */

// APP_READY is fired once the initial Auth check and Settings load has completed
const APP_READY = 'app/APP_READY';

const APP_NOT_READY = 'app/APP_NOT_READY';

// APP_ERROR is fired in the case of an unrecoverable error state
export const APP_ERROR = 'app/APP_ERROR';

// TODO: Remove these and covert them to local state:
const TOGGLE_USER_POPUP = 'app/TOGGLE_USER_POPUP';

// When CMS content loaded:
const CMS_READY = 'app/CMS_READY';

// When Environment settings loaded:
const ENV_READY = 'app/ENV_READY';

const SET_CLOCK_OFFSET = 'app/SET_CLOCK_OFFSET';

const SET_SYSTEM_NOTIFICATION = 'app/SET_SYSTEM_NOTIFICATION';

const SET_AUTH0_IDLE_TIMEOUT = 'app/SET_AUTH0_IDLE_TIMEOUT';

// UI Banner actions:
export const SHOW_COOKIE_AGREEMENT = 'app/SHOW_COOKIE_AGREEMENT';
export const ACCEPT_COOKIE_AGREEMENT = 'app/ACCEPT_COOKIE_AGREEMENT';
const SET_COMPATIBILITY_ACK = 'app/SET_COMPATIBILITY_ACK';
const SET_BROWSER_INCOMPATIBLE = 'app/SET_BROWSER_INCOMPATIBLE';
export const CLOSE_COMPATIBILITY_BANNER = 'app/CLOSE_COMPATIBILITY_BANNER';
export const SET_INFORMATION_POPUP_VIEWED = 'app/SET_INFORMATION_POPUP_VIEWED';

// Embedded credentials:
export const EMBED_CREDS_READY = 'app/EMBED_CREDS_READY';

// APP Language - language selector
export const SET_LANGUAGE = 'app/SET_LANGUAGE';

const initialState = {
  // Header popup states
  helpPopupOpen: false,
  accountWidgetOpen: false,

  appReady: false,
  environment: {},
  appError: false,
  appErrorReference: null,
  envReady: false,
  cmsReady: false,
  embedCredsReady: false,
  cookiesAccepted: false,
  cookieBannerOpen: false,
  // Used in the MyProfile page when a user is part of no org
  informationPopupViewed: false,

  compatibilityBannerOpen: true,
  browserIncompatible: null,
  systemClockOffset: 0,

  // APP Language
  language: 'en'
};

export default function app(state = initialState, action = {}) {
  switch (action.type) {
    case APP_READY:
      return {
        ...state,
        appReady: true
      };
    case APP_NOT_READY:
      return {
        ...state,
        appReady: false
      };
    case CMS_READY:
      console.log('cms ready');
      return {
        ...state,
        cmsReady: true
      };
    case EMBED_CREDS_READY:
      console.log('embed creds ready');
      return {
        ...state,
        embedCredsReady: true
      };
    case ENV_READY:
      return {
        ...state,
        envReady: true,
        environment: action.payload
      };
    case SET_CLOCK_OFFSET:
      return {
        ...state,
        systemClockOffset: action.clockOffset
      };
    case SET_SYSTEM_NOTIFICATION: {
      let allPlatforms = true;

      const systemNotification = {};
      (SYSTEM_NOTIFICATION_PLATFORMS || []).forEach(platform => {
        const platformNotification = action.notifications.find(
          notification => notification.platform === platform.toLowerCase()
        );

        if (!platformNotification || !platformNotification.showNotification) {
          allPlatforms = false;
        }
        systemNotification[platform] = platformNotification;
      });
      systemNotification.allPlatforms = allPlatforms;
      return {
        ...state,
        systemNotification
      };
    }
    case SET_AUTH0_IDLE_TIMEOUT:
      return {
        ...state,
        auth0IdleTimeout: action.idleTimeout
      };
    case APP_ERROR:
      return {
        ...state,
        appError: true,
        appErrorReference: action.appErrorReference
      };
    case TOGGLE_USER_POPUP:
      return {
        ...state,
        accountWidgetOpen: !state.accountWidgetOpen,
        helpPopupOpen: false
      };
    case SHOW_COOKIE_AGREEMENT:
      return {
        ...state,
        cookieBannerOpen: true
      };
    case ACCEPT_COOKIE_AGREEMENT:
      return {
        ...state,
        cookiesAccepted: true,
        cookieBannerOpen: false
      };
    case SET_INFORMATION_POPUP_VIEWED:
      return {
        ...state,
        ...pick(action, 'informationPopupViewed')
      };
    case SET_BROWSER_INCOMPATIBLE:
      return {
        ...state,
        browserIncompatible: action.isIncompatible
      };
    case SET_COMPATIBILITY_ACK:
    case CLOSE_COMPATIBILITY_BANNER:
      return {
        ...state,
        compatibilityBannerOpen: false
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language
      };
    default:
      return state;
  }
}

export const appReady = () => ({
  type: APP_READY
});

export const appNotReady = () => ({
  type: APP_NOT_READY
});

export const appError = appErrorReference => ({
  type: APP_ERROR,
  appErrorReference
});

export const cmsReady = () => ({
  type: CMS_READY
});

export const embedCredsReady = () => ({
  type: EMBED_CREDS_READY
});

export const envReady = payload => ({
  type: ENV_READY,
  payload
});

export const setSystemClockOffset = clockOffset => ({
  type: SET_CLOCK_OFFSET,
  clockOffset
});

export const setSystemNotification = notifications => ({
  type: SET_SYSTEM_NOTIFICATION,
  notifications
});

export const setAuth0IdleTimeout = idleTimeout => ({
  type: SET_AUTH0_IDLE_TIMEOUT,
  idleTimeout
});

export const toggleAccountWidget = () => ({
  type: TOGGLE_USER_POPUP
});

export const showCookieAgreement = () => ({
  type: SHOW_COOKIE_AGREEMENT
});

export const acceptCookieAgreement = () => ({
  type: ACCEPT_COOKIE_AGREEMENT
});

export const setBrowserIncompatible = isIncompatible => ({
  type: SET_BROWSER_INCOMPATIBLE,
  isIncompatible
});

export const setCompatibilityAck = () => ({
  type: SET_COMPATIBILITY_ACK
});

export const closeCompatibilityBanner = () => ({
  type: CLOSE_COMPATIBILITY_BANNER
});

export const setInformationPopupViewed = informationPopupViewed => ({
  type: SET_INFORMATION_POPUP_VIEWED,
  informationPopupViewed
});

export const setStoredLanguage = language => ({
  type: SET_LANGUAGE,
  language
});
