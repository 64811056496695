import isEmpty from 'lodash.isempty';
import numeral from 'numeral';

/**
 * Check if the value passed is either a `number` or a numeric string.
 *
 * @param {?(number|string)} value
 *
 * @return {boolean}
 */
export function isNumeric(value) {
  return typeof value === 'string'
    ? /^-?[\d.]+$/.test(value) && !Number.isNaN(parseFloat(value, 10))
    : typeof value === 'number';
}

/**
 * Format a value as a string using the Numeral.js library if the value is
 * numeric, otherwise return input string or a placeholder.
 *
 * @param {?(number|string)} value
 * @param {string}           strformat   The Numeral.js string format to use.
 * @param {?string}          placeholder The string to return if `value` is null.
 *
 * @return {string}
 */
export function format(value, strformat = '0', placeholder = '') {
  // prettier-ignore
  // eslint-disable-next-line no-nested-ternary
  return isNumeric(value)
    ? numeral(value).format(strformat)
    : isEmpty(value) ? placeholder : value;
}

/**
 * Format a percentage value as a string if numeric.
 *
 * @param {?(number|string)} value
 * @param {?string}          options.format      The Numeral.js string format to use.
 * @param {?string}          options.placeholder The string to return if `value` is null.
 * @param {?boolean}         options.literal     Whether to interpret the value
 *     as parts out of 100, rather than a decimal proportion.
 *
 * @return {string}
 */
export function formatPercentage(value, options = {}) {
  const { format: strformat = '0%', placeholder, literal = false } = options;

  return format(isNumeric(value) ? value / (literal ? 100 : 1) : value, strformat, placeholder);
}
