import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import SearchOptions from '../SearchOptions/SearchOptions';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './PanelSearchControl.scss';
import { isHubMode } from '../../utils/platform';
import { HubLayoutConstants } from '../../globals/hubConstants';

class PanelSearchControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: ''
    };
  }

  _handleInputChange = searchTerm => {
    const { setSearchTerm } = this.props;
    setSearchTerm(searchTerm);
    this.setState({ searchTerm });
  };

  _handleSetFilter = selectedValues => {
    const { setFilter } = this.props;
    setFilter(selectedValues);
  };

  _handleSetSearchTerm = () => {
    const { searchTerm } = this.state;
    const { setSearchTerm } = this.props;
    setSearchTerm(searchTerm);
  };

  _handleSetSortFilter = selectedSortOptions => {
    const { setSortFilter } = this.props;
    setSortFilter(selectedSortOptions);
  };

  _hubHandleSetSortFilter = selectedValues => {
    const { setFilterByUserType } = this.props;
    setFilterByUserType(selectedValues);
  };

  render() {
    const {
      allowMultipleFilterOptions,
      filterOptions = [],
      filterOptionsByUserType = [],
      sortOptions = [],
      placeholder = 'Search',
      initialSelectedFilterOptions,
      panelType,
      isOnlineTestStudentsPanel = false,
      filterOptionsSelected,
      filtersReset = false
    } = this.props;

    let resetFilterOptions = [];
    if (filterOptions.length && initialSelectedFilterOptions) {
      resetFilterOptions = filterOptions.map(option => {
        const isInitialFilter = initialSelectedFilterOptions.includes(option.value);
        return { ...option, checked: isInitialFilter };
      });
    }

    return (
      <div className={styles.panelSearchControl}>
        <TextInputWithButton
          id="selectionSearchTerm"
          placeholder={placeholder}
          onChange={this._handleInputChange}
          buttonAction={this._handleSetSearchTerm}
          aria={{ controls: 'selectionViewResults' }}
          value={filtersReset ? '' : undefined}
        />
        {!isHubMode() && filterOptions.length ? (
          <div className={styles.searchOptions}>
            <SearchOptions
              buttonId="filterButton"
              buttonGlyph={GLYPHS.ICON_FILTER}
              inputType={allowMultipleFilterOptions ? 'checkbox' : 'radio'}
              options={filtersReset ? resetFilterOptions : filterOptions}
              onChange={this._handleSetFilter}
              a11yHeading="Filter by"
              ariaControls="searchResults"
              iconOnly
              initialSelectedValues={initialSelectedFilterOptions}
            />
          </div>
        ) : null}
        {isHubMode() && panelType === HubLayoutConstants.FILTER_PANEL_TYPES.MATERIALS && !isOnlineTestStudentsPanel && (
          <div className={styles.searchOptions}>
            <SearchOptions
              buttonId="filterButton"
              buttonGlyph={GLYPHS.ICON_FILTER}
              inputType="checkbox"
              options={filterOptionsByUserType}
              onChange={this._hubHandleSetSortFilter}
              a11yHeading="Show materials for:"
              ariaControls="searchResults"
              iconOnly
            />
          </div>
        )}
        {isHubMode() &&
          panelType === HubLayoutConstants.FILTER_PANEL_TYPES.ADD_STUDENTS_CLASS &&
          !isOnlineTestStudentsPanel && (
            <div className={styles.searchOptions}>
              <SearchOptions
                buttonId="filterButton"
                buttonGlyph={GLYPHS.ICON_FILTER}
                inputType="checkbox"
                options={filtersReset ? resetFilterOptions : filterOptionsByUserType}
                onChange={this._hubHandleSetSortFilter}
                filterOptionsSelected={filterOptionsSelected}
                a11yHeading="Filter by"
                ariaControls="searchResults"
                iconOnly
              />
            </div>
          )}
        {sortOptions.length ? (
          <div className={styles.searchOptions}>
            <SearchOptions
              buttonId="sortButton"
              buttonGlyph={GLYPHS.ICON_SORT}
              inputType="radio"
              onChange={this._handleSetSortFilter}
              options={sortOptions}
              a11yHeading="Sort by"
              ariaControls="searchResults"
              iconOnly
            />
          </div>
        ) : null}
      </div>
    );
  }
}

PanelSearchControl.propTypes = {
  allowMultipleFilterOptions: PropTypes.bool,
  filterOptions: PropTypes.array,
  filterOptionsByUserType: PropTypes.array,
  initialSelectedFilterOptions: PropTypes.array,
  placeholder: PropTypes.string,
  setFilter: PropTypes.func,
  setSearchTerm: PropTypes.func.isRequired,
  setSortFilter: PropTypes.func,
  sortOptions: PropTypes.array,
  setFilterByUserType: PropTypes.func,
  isOnlineTestStudentsPanel: PropTypes.bool,
  panelType: PropTypes.string,
  filterOptionsSelected: PropTypes.array,
  filtersReset: PropTypes.bool
};

export default PanelSearchControl;
