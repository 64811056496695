import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Button from '@oup/shared-front-end/src/components/Button';
import content from '../../utils/cmsContent';
import { userRoleFriendlyNames } from '../../globals/userRoles';
import { SIZES as thumbnailSizes } from '../EntityRepresentation/EntityRepresentation';
import Thumbnail from '../Thumbnail/Thumbnail';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './invitationActions.scss';
import { isCesMode, isHubMode } from '../../utils/platform';
import Illustration from '../Illustration/Illustration';
import { HubIllustrationAltText, HubIllustrationConstants } from '../../globals/hubConstants';

function InvitationActionReviewing({ onJoin, onRefuse, onDismissPage, userId, orgId, invites }) {
  const invite = invites.find(item => item.orgId === orgId);
  const { orgName, roleName } = invite;

  const inviteHeading = content.invitationBanner.invitation_confirmation_heading
    .replace('{orgName}', orgName)
    .replace('{orgRole}', userRoleFriendlyNames(roleName, true));

  const inviteSubHeading = content.invitationBanner.invitation_confirmation_sub_heading.replace('{orgName}', orgName);
  const inviteThumbnail = isHubMode() ? (
    <Illustration
      illustrationSrc={HubIllustrationConstants.WARNING}
      illustrationAltText={HubIllustrationAltText.WARNING}
    />
  ) : (
    <Thumbnail size={thumbnailSizes.SECTION} glyph={GLYPHS.ICON_WARNING_CIRCLE} />
  );

  return (
    <div className="row">
      <div className="col sm1" />
      <div className="col sm10">
        <div className={styles.inviteActionsSummary}>
          {inviteThumbnail}
          <div className={styles.inviteActionSummaryText}>
            <div>
              <span>{inviteHeading}</span>
            </div>
            <div>
              <span>{inviteSubHeading}</span>
            </div>
          </div>

          <div className='row cols-center gin-top4'>
            {(!isHubMode() || isCesMode()) && (
              <div className="col">
                <Button
                  variant="filled"
                  onClick={() => onJoin(userId, orgId)}
                  text={content.invitationBanner.button_text_join}
                />
              </div>
            )}
            {isHubMode() && (
              <div className="col">
                <Button variant="outline" text={content.hubGlossary.cancel} onClick={onDismissPage} />
              </div>
            )}

            <div className="col">
              <Button
                icon={{ component: <SVGIcon glyph={GLYPHS.ICON_DELETE} /> }}
                className={isHubMode() ? styles.buttonValidationRed : ''}
                onClick={() => onRefuse(userId, orgId)}
                text={content.invitationBanner.button_text_not_join}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InvitationActionReviewing.propTypes = {
  onJoin: PropTypes.func.isRequired,
  onRefuse: PropTypes.func.isRequired,
  userId: PropTypes.string,
  orgId: PropTypes.string,
  invites: PropTypes.array,
  onDismissPage: PropTypes.func
};

export default connect(
  state => ({
    orgId: state.invites.orgId,
    invites: state.invites.invites
  }),
  {}
)(InvitationActionReviewing);
