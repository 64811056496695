import pick from 'lodash.pick';
import { all, put, select } from 'redux-saga/effects';
import editUserSchema from '../../../../../../static/schema/draft-4/edit-user-request.schema.json';
import actions from '../../../../actions';
import editCurrentUserAuth from '../../../api/editCurrentUserAuth';
import updatePartialUserRegistration from '../../../api/updatePartialUserRegistration';
import { getUserDetails } from '../user/userProfile.saga';
import validateEditUserInput from './validateEditUserInput';
import { getCurrentPlatform } from '../../../../../utils/platform.js';
import { RESPONSE_STATUS, ERROR_INVALID_CURRENT_PASSWORD } from '../../../../../globals/appConstants.js';

function* editUserAuth(input) {
  const { currentOrganisationId, userId } = yield select(state => state.identity);
  const errors = yield validateEditUserInput(currentOrganisationId, input);
  const changeAuthInput = Object.keys(editUserSchema.definitions.changeAuth.properties);
  const changeAuthHasErrors = Object.values(pick(errors, changeAuthInput)).some(Boolean);
  const changeEmailInput = Object.keys(editUserSchema.definitions.changeEmail.properties);
  const changeEmailHasErrors = Object.values(pick(errors, changeEmailInput)).some(Boolean);
  let origUserName;
  let origEmail;
  if (input.isSupervised) {
    const { userAccount: { email } = {} } = yield select(state => state.editUser);
    origUserName = email;
    origEmail = email;
  } else {
    ({ userName: origUserName, email: origEmail } = yield select(state => state.editUser));
  }
  const isEmailSupported = origEmail !== origUserName;
  if ((isEmailSupported && changeAuthHasErrors && changeEmailHasErrors) || (!isEmailSupported && changeAuthHasErrors)) {
    yield put(actions.editUserValidateInputError());
    return;
  }
  input.orgId = currentOrganisationId;
  input.platformCode = getCurrentPlatform();
  const requests = [];
  if (!changeAuthHasErrors) {
    requests.push(editCurrentUserAuth(input));
  }
  const { email } = input;
  if ((isEmailSupported && email && !changeEmailHasErrors) || (!isEmailSupported && email && !changeAuthHasErrors)) {
    requests.push(updatePartialUserRegistration(userId, { email }));
  }
  if (requests.length) {
    const editCurrentUserAuthResponse = yield all([requests[0]]);
    if (editCurrentUserAuthResponse[0].status && editCurrentUserAuthResponse[0].status.toLowerCase() === 'success') {
      if (requests.length > 1) {
        const updatePartialUserRegistrationResponse = yield all([requests[1]]);
        if (
          !updatePartialUserRegistrationResponse[0].status ||
          (updatePartialUserRegistrationResponse[0].status &&
            updatePartialUserRegistrationResponse[0].status.toLowerCase() !== 'success')
        ) {
          yield put(actions.editUserFailure());
          return;
        }
      }
      yield put(actions.editUserGetDetailsRequest(currentOrganisationId, userId));
      yield put(actions.editUserSuccess());
      yield getUserDetails();
      return;
    }
    if (
      editCurrentUserAuthResponse[0].status === RESPONSE_STATUS.ERROR &&
      editCurrentUserAuthResponse[0].code === ERROR_INVALID_CURRENT_PASSWORD
    ) {
      yield put(actions.editUserFailure(editCurrentUserAuthResponse[0].code));
      return;
    }
  }
  yield put(actions.editUserFailure());
}

export default editUserAuth;
