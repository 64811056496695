import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '@oup/shared-front-end/src/components/Button';
import withLocalizedContent from '../../language/withLocalizedContent';
import ControlledForm from '../ControlledForm/ControlledForm';
import PasswordFieldDetail from '../PasswordFieldDetail/PasswordFieldDetail';
import TextInput from '../TextInput/TextInput';
import ValidationStatus from '../ValidationStatus/ValidationStatus';

/**
 * A form allowing entry of a new user password value that is confirmed before
 * submission.
 *
 * This component behaves like a fully-controlled form with a single `password`
 * input, but can only be submitted once there are no errors externally set for
 * this value, and the user has matched this value in the password confirmation
 * field.
 */
class PasswordUpdateForm extends Component {
  // eslint-disable-next-line react/destructuring-assignment
  _onPasswordChange = this.props.createChangeHandler('password');

  constructor(props) {
    super(props);
    this.state = {
      passwordConfirmation: '',
      showPasswordMismatched: null
    };
  }

  /**
   * Set the next value of `state.showPasswordMismatched` based on comparing
   * the input values and whether the `passwordConfirmation` input is filled.
   *
   * @param {string} password
   * @param {string} passwordConfirmation
   */

  _handlePasswordChange = password => {
    this._onPasswordChange(password);
    const { passwordConfirmation } = this.state;
    this._updateShowPasswordMismatched(password, passwordConfirmation);
  };

  _handlePasswordConfirmationChange = passwordConfirmation => {
    this.setState({ passwordConfirmation });
  };

  _handlePasswordConfirmationBlur = () => {
    const { password } = this.props;
    const { passwordConfirmation } = this.state;
    this._updateShowPasswordMismatched(password, passwordConfirmation);
  };

  _updateShowPasswordMismatched(password, passwordConfirmation) {
    this.setState({
      showPasswordMismatched: !isEmpty(passwordConfirmation) ? password !== passwordConfirmation : null
    });
  }

  render() {
    const {
      password,
      errors = {},
      createBlurHandler,
      onSubmit,
      localizedContent: { passwordUpdateForm: content }
    } = this.props;
    const { passwordConfirmation, showPasswordMismatched } = this.state;

    const canSubmit = passwordConfirmation && showPasswordMismatched === false && !Object.values(errors).includes(true);

    return (
      <ControlledForm onSubmit={canSubmit ? onSubmit : undefined}>
        <ValidationStatus forId="password" isActive={errors.password} message={content.new_password_error}>
          <TextInput
            id="password"
            type="password"
            name="password"
            label={content.new_password_label}
            placeholder={content.new_password_placeholder}
            value={password}
            onChange={this._handlePasswordChange}
            onBlur={createBlurHandler('password')}
            required
          />
        </ValidationStatus>
        <PasswordFieldDetail updating />
        <ValidationStatus
          className="gin-bot2"
          forId="password-confirmation"
          isActive={showPasswordMismatched}
          message={content.password_confirmation_error}
        >
          <TextInput
            id="password-confirmation"
            type="password"
            name="passwordConfirmation"
            label={content.password_confirmation_label}
            placeholder={content.password_confirmation_placeholder}
            value={passwordConfirmation}
            onChange={this._handlePasswordConfirmationChange}
            onBlur={this._handlePasswordConfirmationBlur}
            required
          />
        </ValidationStatus>
        <p className="pad-bot2">{content.new_password_guidance}</p>
        <Button text={content.button_submit} onClick={canSubmit ? onSubmit : undefined} disabled={!canSubmit} />
      </ControlledForm>
    );
  }
}

PasswordUpdateForm.propTypes = {
  password: PropTypes.string.isRequired,
  errors: PropTypes.object,
  createChangeHandler: PropTypes.func.isRequired,
  createBlurHandler: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default withLocalizedContent('passwordUpdateForm')(PasswordUpdateForm);
