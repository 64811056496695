import pick from 'lodash.pick';
import { put, select, call } from 'redux-saga/effects';
import actions from '../../../../actions';
// import checkUsername from '../../../api/checkUsername';
import checkUsernameBulk from '../../../api/checkUsernameBulk';
import generatePassword from './generatePassword';

function* checkImportUsers() {
  const { users, orgId, customId } = yield select(state => ({
    ...pick(state.importUsers, ['users']),
    orgId: state.organisationPage.orgId,
    customId: state.organisations.data[state.organisationPage.orgId].customId
  }));
  const ids = Object.keys(users);
  const existing = [];
  const archived = [];

  const userNames = ids
    .map(id => (users[id].userName ? `${customId}_${users[id].userName}` : ''))
    .filter(username => !!username);
  const responses = yield call(checkUsernameBulk, { userNames }, orgId);

  (responses.data.userExist || []).forEach(response => {
    const regex = new RegExp(`^${response}$`, 'i');
    userNames.forEach((name, index) => {
      if (regex.test(name)) {
        existing.push(ids[index]);
      }
    });
  });

  (responses.data.userArchived || []).forEach(response => {
    const regex = new RegExp(`^${response}$`, 'i');
    userNames.forEach((name, index) => {
      if (regex.test(name)) {
        archived.push(ids[index]);
      }
    });
  });

  // Generate password for new user and archived users
  ids.forEach(id => {
    if (existing.indexOf(id) < 0 || archived.indexOf(id) !== -1) {
      // new user
      users[id].password = users[id].password || generatePassword();
    }
  });

  let _userNames = [];
  const duplicates = ids.reduce((carry, id) => {
    const { userName } = users[id];
    if (!_userNames.includes(userName)) {
      _userNames = [..._userNames, userName];
      return carry;
    }
    return [...carry, id];
  }, []);

  yield put(actions.parseImportUsersSourceCompleted(users));
  yield put(actions.checkImportUsersCompleted({ existing, duplicates, archived }));
  const usersList = [];
  Object.values(users).forEach(({ yearGroup = '', ...user }) => {
    usersList.push({ ...user, yearGroup });
  });
  yield put(actions.validateImportUsersRequest(0, usersList));
}

export default checkImportUsers;
