import { take, select } from 'redux-saga/effects';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import { SWITCH_ORG } from '../../../../reducers/identity.reducer.js';

import redirectToIdp from '../../../preRender/auth/getCredentials/redirectToIdP.saga.js';
import { embeddedByPlatform, getPlatformByCodeOrDomain } from '../../../../../utils/platform.js';
import { HubLayoutConstants } from '../../../../../globals/hubConstants';
import authSettings from '../../../../../globals/authSettings.js';
import { isOIDCStandard, featureIsEnabled, getPlatformDashboardUrl } from '../../../../../globals/envSettings';
import session from '../../../../../utils/session';
import user from '../../../../../utils/user';

export default function* handleOrgSwitch() {
  console.log('[handleOrgSwitch] Waiting for user to press the logout button');

  // Lookup the User's role in the new Org:
  // Note: After accepting an invitation, the roleName is provided explicitly
  // because it won't be available to look up in state.identity.orgRoleMap.
  const { orgId, roleName: specifiedRoleName, returnBack, redirectPlatform } = yield take(SWITCH_ORG);
  const roleName = specifiedRoleName || (yield select(state => state.identity.orgRoleMap[orgId]));
  if (roleName) {
    console.log('[handleOrgSwitch] Switching to role', roleName, 'in org', orgId);

    // Store the orgId in session storage in order to persist across tabs and page reloads.
    // This is most relevant when user has same role in more than one org.
    if (session.exists()) {
      console.log('[handleOrgSwitch] Found creds in storage');

      if (featureIsEnabled('single-client-session-store')) {
        const userInfo = user.get();
        userInfo.organisationId = orgId;
        userInfo.role = roleName;
        user.set(userInfo);
      } else {
        const sessionInfo = session.get();
        console.log('[handleOrgSwitch] Updating creds in storage with new role');
        sessionInfo.doOrgSwitch = true;
        sessionInfo.currentRole = roleName;
        sessionInfo.currentOrganisationId = orgId;
        session.set(sessionInfo);
      }
      if (
        redirectPlatform ===
        `${HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH}${HubLayoutConstants.PATH_NAMES.STUDENTS}`
      ) {
        sessionStorage.setItem(
          'goto-path',
          `${HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH}${HubLayoutConstants.PATH_NAMES.STUDENTS}`
        );
      }

      // Redirect to IDP to refresh app with permissions for the new role:
      // Note we explicitly choose to omit the returnTo param when switching orgs.
      if (!embeddedByPlatform()) {
        const isRedeemCodeAvailable = !!localStorage.getItem(APP_CONSTANTS.REDEEM_CODE);

        if (!isOIDCStandard(authSettings.idpStandard)) {
          if (isRedeemCodeAvailable) {
            sessionStorage.setItem('goto-path', '/myProfile/learningMaterial/addCode');
          }
          const options = {
            withReturnTo: returnBack,
            navigateTo: redirectPlatform
          };
          yield redirectToIdp(roleName, options);
        }
        if (isRedeemCodeAvailable) {
          window.location.href = '/myProfile/learningMaterial/addCode';
        }
        window.location.href = getPlatformDashboardUrl(getPlatformByCodeOrDomain(redirectPlatform).toLowerCase());
      }
    }
  } else {
    console.error("[handleOrgSwitch] Failed to look up user's role in org", orgId, '. Unable to switch org');
  }
}
