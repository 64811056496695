import * as t from '../actionTypes';

const requestStatusOptions = { loading: true, success: false, error: false };
const successStatusOptions = { loading: false, success: true, error: false };
const failureStatusOptions = { loading: false, success: false, error: true };

const initialState = {
  placementTests: [],
  refreshData: false
};

function loadPlacementTestsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_PLACEMENT_TESTS:
      return {
        ...initialState,
        ...requestStatusOptions
      };
    case t.GET_PLACEMENT_TESTS_SUCCESS:
      return {
        ...state,
        placementTests: payload,
        ...successStatusOptions
      };
    case t.GET_PLACEMENT_TESTS_FAILURE:
      return { ...state, ...failureStatusOptions };
    case t.REFRESH_PLACEMENT_TEST_LIST:
      return {
        ...state,
        refreshData: true,
        ...successStatusOptions
      };
    default:
      return state;
  }
}

export default loadPlacementTestsReducer;
