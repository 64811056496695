export const CARD_CONSTANTS = {
  PAGE_BG_IMG_URL: '/media/sign-in-card/page-bg.png',
  SUCCESS_CARD_IMG_URL: '/media/sign-in-card/sign-in-card-success.png',
  ERROR_CARD_IMG_URL: '/media/sign-in-card/sign-in-card-error.png',
  SCHOOL_CODE: 'School code:',
  USERNAME: 'Your username:',
  ERROR_USERNAME_LABEL: 'Username',
  PASSWORD: 'Password:',
  CLASS: 'Class:'
};

const environments = ['dev', 'test', 'uat', 'infra', 'preprod'];
const environmentHeader = '<env>.oxfordreadingbuddy.com';
const currentEnvironment = window.location.host.split('.')[0];
const headerText = environments.includes(currentEnvironment)
  ? environmentHeader.replace('<env>', currentEnvironment)
  : environmentHeader.replace('<env>', 'www');

export const CARD_STYLES = {
  SUCCESS: {
    HEADER: {
      TEXT: headerText,
      COLOR: '#166993',
      FONT: 'bold 12pt arial',
      FONT_TYPE: 'bold',
      FONT_SIZE: 12
    },
    LABEL: {
      COLOR: '#166993',
      FONT_TYPE: 'bold',
      FONT_SIZE: 7
    },
    VALUE: {
      COLOR: '#000000',
      FONT_TYPE: 'bold',
      FONT_SIZE: 12
    },
    CLASS: {
      COLOR: '#000000',
      FONT_TYPE: 'bold',
      FONT_SIZE: 7
    }
  },
  ERROR: {
    HEADER: {
      TEXT: 'Error creating sign in card',
      COLOR: '#b55118',
      FONT: 'bold 12pt arial',
      FONT_TYPE: 'bold',
      FONT_SIZE: 12
    },
    LABEL: {
      COLOR: '#b55118',
      FONT_TYPE: 'bold',
      FONT_SIZE: 7
    },
    VALUE: {
      COLOR: '#000000',
      FONT_TYPE: 'bold',
      FONT_SIZE: 12
    },
    PARA: {
      COLOR: '#000000',
      FONT_TYPE: 'normal',
      FONT_SIZE: 10,
      FONT: 'bold 10pt arial'
    }
  },
  EMPTY: {
    HEADER: {
      TEXT: 'There are no students in this class',
      COLOR: '#166993',
      FONT: 'bold 12pt arial',
      FONT_TYPE: 'bold',
      FONT_SIZE: 16
    }
  }
};

export const LICENCE_CONSTANTS = {
  LICENCE_TYPE: {
    ROLLING: 'ROLLING',
    CONCURRENT: 'CONCURRENT',
    USAGE: 'USAGE'
  },
  BEGIN_ON: {
    FIRST_USE: 'FIRST_USE',
    CREATION: 'CREATION'
  }
};

export const INVITATION_STATUS = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  ACCEPTED: 'ACCEPTED',
  CANCELLED: 'CANCELLED',
  REQUESTED: 'REQUESTED',
  NONE: 'NONE'
};

export const TOKEN_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export const ARCHIVE_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED'
};

export const SIGN_IN_CARD_USER_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  ERROR: 'ERROR'
};

export const MANAGED_USER_ACCOUNT_STATUS = {
  EXISTS: 'EXISTS',
  NEW: 'NEW'
};

export const LICENSE_STATUS_COLOR = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  TEXT: 'TEXT'
};

export const STATUS_TYPE = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  VALID: 'VALID'
};
export const PARENT_PLATFORM = ['CES', 'HUB', 'ORB'];

export const PLATFORM_NAME = {
  EPS: 'Oxford University Press',
  ORB: 'Oxford Reading Buddy',
  OLB: "Oxford Learner's Bookshelf",
  OTE: 'Oxford Test of English',
  OPT: 'Oxford Placement Test',
  RVS: 'Results Verification Service',
  OIC: 'Oxford International Standardized Assessments'
};

export const USER_REGISTRATION_STATUS = {
  REGISTRATION_PENDING: 'REGISTRATION_PENDING',
  REGISTRATION_COMPLETE: 'REGISTRATION_COMPLETE',
  REGISTRATION_EXISTS: 'REGISTRATION_EXISTS'
};

export const RETURN_PAGE_TARGET = {
  CLASS_PROGRESS: 'ClassProgress'
};

export const GRADEBOOK_TYPE = {
  WORKBOOK: 'workbook',
  ONLINE_PRACTICE: 'onlinepractice',
  ASSESSMENT: 'assessment',
  READER: 'reader'
};

export const ALIGNMENT = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right'
};

export const BROWSER_PLATFORMS = {
  DEKSTOP: 'desktop',
  MOBILE_ANDROID: 'mobile-android',
  MOBILE_APPLE: 'mobile-apple'
};

export const SYSTEM_NOTIFICATION_PLATFORMS = ['ces', 'hub', 'ote', 'oic', 'opt', 'orb', 'oald', 'olb'];

export const NO_AUTH_PLATFORMS = ['rvs', 'oic'];

export const RESPONSE_STATUS = {
  ERROR: 'error',
  SUCCESS: 'success',
  FAIL: 'fail'
};
export const ERROR_INVALID_CURRENT_PASSWORD = 'current_password_invalid';
export const ERROR_UNLINK_INVALID_CURRENT_PASSWORD = 'Unauthorised Request!';
