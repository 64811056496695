import pick from 'lodash.pick';
import * as t from '../actionTypes';

const initialState = {
  status: '',
  sessions: {},
  books: {},
  skills: [],
  buddy: {},
  submitting: false,
  success: false,
  error: false
};

function studentEngagementReport(state = initialState, { type, payload }) {
  switch (type) {
    case t.STUDENT_ENGAGEMENT_REPORT_REQUEST:
      return {
        ...state,
        submitting: true,
        success: false,
        error: false
      };
    case t.STUDENT_ENGAGEMENT_REPORT_FAILURE:
      return {
        ...state,
        submitting: false,
        success: false,
        error: true
      };
    case t.STUDENT_ENGAGEMENT_REPORT_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: false,
        success: true,
        status: payload.status,
        ...pick(payload, ['sessions', 'books', 'buddy']),
        // API side inconsistency
        skills: payload.skill
      };
    default:
      return state;
  }
}

export default studentEngagementReport;
