import { select } from 'redux-saga/effects';

import getDetailsFromJoiningCode from './getDetailsFromJoiningCodeApi.js';

export default function* getClassDetailsRequest() {
  const { joinCode } = yield select(state => ({
    joinCode: state.joinClass.classCodeValue
  }));

  return yield getDetailsFromJoiningCode(joinCode);
}
