import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import pick from 'lodash.pick';
import colors from '../../globals/colors';
import Button, { buttonTypes } from '../Button/Button';
import Card from '../Card/Card';
import FileUploadInput from '../FileUploadInput/FileUploadInput';
import TextInputLabel from '../TextInputLabel/TextInputLabel';
import PageHeading from '../PageHeading/PageHeading';
import TextInput from '../TextInput/TextInput';
import { StatusEnum } from '../../redux/sagas/appFunctions/authorisedFunctions/csvUserUpload/csvUserUpload';
import actions from '../../redux/actions';

const supportedFileTypes = ['.csv'];

class CsvUserUpload extends Component {
  _handleChange = (value, name) => {
    const { csvUserInputUpdate } = this.props;
    csvUserInputUpdate({ [name]: value });
  };

  _handleFileChange = value => {
    const { fileParse } = this.props;
    fileParse(value);
  };

  _onSubmit = () => {
    const { status, results, email, isFormValid, fileName, formSubmit } = this.props;

    if (isFormValid && status !== StatusEnum.InProgress) {
      formSubmit({ emailAddress: email, fileContents: results, fileName });
    }
  };

  render() {
    const { content, status, goBackToDashboard, isFormValid } = this.props;
    return (
      <div className="grid pad-top2 pad-bot4">
        <div className="row gin-bot3">
          <div className="col sm10 pad-bot2">
            <PageHeading title={content.title} subtitle={content.subtitle} />
          </div>
          <div className="col md12">
            <Card headingColor={colors.PROFILE} showHeadingLabel={false}>
              <TextInputLabel id="fileUploadInputLabel" label={content.fileInputTextLabel} />
              <FileUploadInput
                id="fileUploadInput"
                label={content.fileInputLabel}
                buttonText={content.fileInputButtonLabel}
                supportedFileTypes={supportedFileTypes}
                handleFile={file => this._handleFileChange(file)}
              />
              <TextInput
                id="emailInput"
                name="email"
                label={content.textInputLabel}
                placeholder={content.textInputPlaceholder}
                onChange={this._handleChange}
              />
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div>
              <span className="gin-right2">
                <Button
                  type={buttonTypes.PRIMARY}
                  text={content.uploadButton}
                  onClick={this._onSubmit}
                  disabled={!isFormValid || status === StatusEnum.InProgress}
                />
              </span>
              <span className="gin-right2">
                <Button type={buttonTypes.SECONDARY} text={content.closeButton} onClick={goBackToDashboard} />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CsvUserUpload.propTypes = {
  status: PropTypes.string.isRequired,
  results: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  fileParse: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  formSubmit: PropTypes.func.isRequired,
  csvUserInputUpdate: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  goBackToDashboard: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool.isRequired
};

export default connect(
  state => pick(state.csvUserUpload, ['status', 'results', 'email', 'isFormValid', 'fileName']),
  dispatch => ({
    fileParse: source => {
      dispatch(actions.csvUserUploadFileParseRequest({ source, status: StatusEnum.InProgress }));
    },
    formSubmit: formData => {
      dispatch(actions.csvUserUpload(formData));
    },
    csvUserInputUpdate: data => {
      dispatch(actions.csvUserInputUpdate(data));
    }
  })
)(CsvUserUpload);
