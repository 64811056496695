const sanitizeContent = content => content.replace('’', '`');

export default function downloadAsFile(content, fileName, type = 'application/octet-stream', isBase64 = false) {
  const data = isBase64 ? Uint8Array.from(atob(content), c => c.charCodeAt(0)) : sanitizeContent(content);

  const blob = new Blob([data], { type });
  let url;

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, fileName); // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
  } else {
    url = URL.createObjectURL(blob);
  }

  const element = window.document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', fileName);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
