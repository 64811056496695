import keyBy from 'lodash.keyby';
import { put, select } from 'redux-saga/effects';
import actions from '../../../../actions';
import ModifyORBLevelRequest from '../../../api/modifyORBLevel';
import { featureIsEnabled } from '../../../../../globals/envSettings';

/**
 * Submit a request to modify the student level for a set of users.
 *
 * @param {string[]} userIds
 * @param {Symbol}   action
 * @param {Integer}   level
 *
 * @yield {Object} The next Redux effect description
 */
function* ModifyORBLevel(userIds, action, orgId, level = null) {
  const _orgId = orgId || (yield select(state => state.identity.currentOrganisationId));

  const students = featureIsEnabled('mat-report-change-for-reducers')
    ? keyBy(yield select(state => state.resultsReadingBuddyReducer.data[_orgId].group.students), 'id')
    : keyBy(yield select(state => state.resultsReadingBuddy.group.students), 'id');
  const users = userIds.map(id => ({
    id,
    current_level: level ? parseInt(level, 10) : (students[id].levels[0] || {}).levelId
  }));

  try {
    const response = yield ModifyORBLevelRequest(action, users, _orgId);

    if (response.status === 'success' && response.data.success) {
      yield put(actions.gradebookModifyStudentSuccess());
      return;
    }

    yield put(actions.gradebookModifyStudentFailure(response.data.errorMessages));
  } catch (e) {
    yield put(actions.gradebookModifyStudentFailure(e.message));
  }
}

export default ModifyORBLevel;
