import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { GlyphIcon, EmblemSize } from '../IconEmblem/IconEmblem';
import ContentPlayerNavigation from '../ContentPlayerNavigation/ContentPlayerNavigation';
import {
  openMobileMenuModal,
  clearCurrentContent,
  toggleContentsSubmenuModal
} from '../../redux/actions/structuredContentPlayer';
import Link from '../Link/Link';
import styles from './ContentPlayerHeader.scss';
import SVGIcon from '../SVGIcon/SVGIcon';
import Button, { buttonTypes } from '../Button/Button';

function ContentPlayerHeader({
  title = null,
  subtitle = null,
  hasNavigation = false,
  closePanel,
  openMobileMenuModalAction,
  clearCurrentContentData,
  cptContent,
  modalCloseLink,
  toggleContentsSidebarModal,
  navigation
}) {
  const { currentActivities, currentActivityIndexInLesson, parentLevelTitle } = navigation;

  const mapCurrentActivities = () =>
    currentActivities.map((item, index) => {
      if (index <= 5) {
        return (
          <div
            className={`${
              index <= currentActivityIndexInLesson ? styles.cpNavRowBarActive : styles.cpNavRowBarInactive
            }`}
            key={item.title}
          />
        );
      }
      return null;
    });

  const handleClosePanel = () => {
    clearCurrentContentData(null);
    closePanel();
  };

  const handleMobileTitleEvent = () => {
    openMobileMenuModalAction();
    toggleContentsSidebarModal();
  };

  const renderCloseButton = () => {
    if (hasNavigation) return null;
    if (modalCloseLink) {
      return (
        <div className={styles.cpCloseDiv}>
          <Link to={modalCloseLink} onClick={handleClosePanel} aria-label={cptContent.close_button_text}>
            <Button
              type={buttonTypes.CLOSE}
              customClassName={styles.cpClose}
              iconOnly
              ariaLabel={cptContent.close_button_text}
              tabIndex={0}
            />
          </Link>
        </div>
      );
    }

    return (
      <div className={styles.cpCloseDiv}>
        <Button
          onClick={handleClosePanel}
          type={buttonTypes.CLOSE}
          customClassName={styles.cpClose}
          iconOnly
          ariaLabel={cptContent.close_button_text}
          tabIndex={0}
        />
      </div>
    );
  };

  return (
    <div className={styles.cpHeader}>
      <div className={styles.cpDesktopView}>
        <div className={styles.cpHeaderRow}>
          <div className={styles.cpTitleDiv}>
            {title && (
              <div className={styles.cpTitle}>
                <h1>{title}</h1>
              </div>
            )}
            {subtitle && <p className={styles.cpSubtitle}> {subtitle}</p>}
          </div>
          <div className={styles.cpNavigationDiv}>
            {hasNavigation && (
              <ContentPlayerNavigation
                cptContent={cptContent}
                modalCloseLink={modalCloseLink}
                handleClosePanel={handleClosePanel}
                navigation={navigation}
              />
            )}
          </div>
          {renderCloseButton()}
        </div>
      </div>

      <div className={styles.cpMobileView} aria-hidden>
        <div className={`${styles.cpHeaderRowMobile} ${hasNavigation ? styles.cpHeaderRowMobileNavigation : null}`}>
          <div className={styles.cpTitleDiv}>
            <button onClick={openMobileMenuModalAction} type="button">
              <div className={classnames(styles.emblem, EmblemSize.MEDIUM, styles.cpMenuButton)}>
                <SVGIcon glyph={GlyphIcon.ICON_HAMBURGER} />
              </div>
            </button>
          </div>
          <div
            className={styles.cpNavProgressTitle}
            onClick={handleMobileTitleEvent}
            onKeyDown={e => e.key === 'enter' && handleMobileTitleEvent()}
            role="button"
            tabIndex="0"
          >
            {hasNavigation && <p>{parentLevelTitle}</p>}
          </div>
          <div className={styles.cpCloseDiv}>
            {modalCloseLink ? (
              <Link to={modalCloseLink} onClick={handleClosePanel}>
                <button type="button">
                  <div className={classnames(styles.emblem, EmblemSize.MEDIUM, styles.cpClose)}>
                    <SVGIcon glyph={GlyphIcon.ICON_CLOSE} />
                  </div>
                </button>
              </Link>
            ) : (
              <Link onClick={handleClosePanel}>
                <button type="button">
                  <div className={classnames(styles.emblem, EmblemSize.MEDIUM, styles.cpClose)}>
                    <SVGIcon glyph={GlyphIcon.ICON_CLOSE} />
                  </div>
                </button>
              </Link>
            )}
          </div>
        </div>
        {hasNavigation && (
          <div className={`${styles.cpHeaderRow} ${styles.cpNavRowBullet}`}>{mapCurrentActivities()}</div>
        )}
      </div>
    </div>
  );
}

ContentPlayerHeader.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  hasNavigation: PropTypes.bool,
  closePanel: PropTypes.func.isRequired,
  openMobileMenuModalAction: PropTypes.func,
  clearCurrentContentData: PropTypes.func,
  cptContent: PropTypes.object,
  modalCloseLink: PropTypes.string,
  toggleContentsSidebarModal: PropTypes.func,
  navigation: PropTypes.object
};

const mapStateToProps = state => ({ modalCloseLink: state.structuredContentPlayer.modalCloseLink });

const mapDispatchToProps = {
  openMobileMenuModalAction: openMobileMenuModal,
  clearCurrentContentData: clearCurrentContent,
  toggleContentsSidebarModal: toggleContentsSubmenuModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContentPlayerHeader);
