import pick from 'lodash.pick';
import * as t from '../actionTypes';

const initialState = {
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  password: '',
  confirmPassword: '',
  roleName: '',
  errors: {},
  isLoading: false,
  isCheckingUserNameTaken: false,
  isUserNameTaken: false,
  isCheckingEmailTaken: false,
  isEmailTaken: false,
  isSubmitting: false,
  isSuccessful: false,
  hasFailed: false,
  hasFailedUnlink: false,
  isPasswordWrong: false,
  isSuccessfulExtraDetails: false,
  userAccount: {},
  primaryUserId: '',
  errorCode: '',
  unlinkErrorCode: null,
  unlinkErrorMessage: '',
  isSupervised: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.EDIT_USER_GET_DETAILS_REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case t.EDIT_USER_GET_DETAILS_SUCCESS:
      return {
        ...state,
        ...pick(payload, [
          'firstName',
          'lastName',
          'userName',
          'email',
          'roleName',
          'isSupervised',
          'claimedSchool',
          'countryCode',
          'selfSelectedRole'
        ]),
        isLoading: false
      };
    case t.EDIT_USER_CHECKING_USERNAME:
      return {
        ...state,
        isCheckingUserNameTaken: true,
        isUserNameTaken: false
      };
    case t.EDIT_USER_CHECKING_USERNAME_COMPLETED:
      return {
        ...state,
        isCheckingUserNameTaken: false,
        isUserNameTaken: payload
      };
    case t.EDIT_USER_CHECKING_EMAIL:
      return {
        ...state,
        isCheckingEmailTaken: true,
        isEmailTaken: false
      };
    case t.EDIT_USER_CHECKING_EMAIL_COMPLETED:
      return {
        ...state,
        isCheckingEmailTaken: false,
        isEmailTaken: payload
      };
    case t.EDIT_USER_VALIDATE_INPUT_COMPLETED:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload
        }
      };
    case t.EDIT_USER_VALIDATE_INPUT_ERROR:
      return {
        ...state,
        isSubmitting: false
      };
    case t.EDIT_USER_IDENTITY_REQUEST:
    case t.EDIT_USER_AUTH_REQUEST:
    case t.EDIT_USER_IN_ORG_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasFailed: false
      };
    case t.EDIT_USER_SUCCESS:
      return {
        ...state,
        errors: {},
        isSubmitting: false,
        isSuccessful: true,
        isLoading: false
      };
    case t.EDIT_USER_ACCEPT_OLB_TERMS_SUCCESS:
      return {
        ...state,
        errors: {},
        isSubmitting: false,
        isSuccessfulExtraDetails: true,
        isSuccessful: true
      };
    case t.EDIT_USER_FAILURE:
      return {
        ...state,
        ...pick(payload, ['errorCode']),
        isSubmitting: false,
        hasFailed: true,
        isLoading: false
      };
    case t.EDIT_USER_GET_SOCIAL_IDENTITIES_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case t.EDIT_USER_GET_SOCIAL_IDENTITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...pick(payload, ['userAccount', 'primaryUserId'])
      };
    case t.EDIT_USER_UNLINK_SOCIAL_ACCOUNTS_SUBMITTING:
      return {
        ...state,
        isSubmitting: true,
        isLoading: true
      };
    case t.EDIT_USER_INITIAL_CONSENT:
      return {
        ...state,
        isSubmitting: true,
        isLoading: true
      };
    case t.EDIT_USER_UNLINK_SOCIAL_ACCOUNTS_PASS_FAILURE:
      return {
        ...state,
        ...pick(payload, ['unlinkErrorCode', 'unlinkErrorMessage']),
        isSubmitting: false,
        isPasswordWrong: true,
        hasFailedUnlink: true,
        isLoading: false
      };
    case t.EDIT_USER_RESET:
      return {
        ...state,
        ...pick(initialState, [
          'errors',
          'isLoading',
          'isCheckingUserNameTaken',
          'isUserNameTaken',
          'isCheckingEmailTaken',
          'isEmailTaken',
          'isSubmitting',
          'isSuccessful',
          'hasFailed',
          'hasFailedUnlink',
          'isPasswordWrong',
          'isSuccessfulExtraDetails',
          'unlinkErrorCode',
          'unlinkErrorMessage',
          'errorCode'
        ])
      };
    default:
      return state;
  }
};
