import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Feature from '../environment/Feature';
import envSettings from '../globals/envSettings';
import actions from '../redux/actions';
import { postNotificationMessage } from '../redux/actions/postMessage';
import {
  getTeacherResourceProduct,
  getTeacherResources,
  getTeacherResourcesCategories,
  getTeacherResourcesVertical,
  getTeacherResourceUnits
} from '../redux/selectors';
import { TeacherResource as TeacherResourcePropType } from '../types';
import NotFoundView from '../views/errors/NotFoundView';
import TeacherResourcesView from '../views/TeacherResourcesView';

class ItalyTeacherResourcesPage extends Component {
  constructor() {
    super();
    this.state = {
      categoryFilter: '',
      unitFilter: null
    };
  }

  componentDidMount() {
    this._loadResources();
  }

  componentDidUpdate({
    match: {
      params: { titleId: previousTitleId }
    }
  }) {
    const {
      match: {
        params: { titleId }
      }
    } = this.props;
    if (titleId !== previousTitleId) {
      this._loadResources();
    }
  }

  _handleCategoryFilterChange = value => {
    this.setState({ categoryFilter: value });
  };

  _handleUnitFilterChange = value => {
    this.setState(({ unitFilter: prevUnitFilter }) => ({
      unitFilter: prevUnitFilter === value ? null : value
    }));
  };

  _loadResources = () => {
    const {
      loadResources,
      match: {
        params: { titleId }
      }
    } = this.props;
    loadResources(titleId);
  };

  _downloadResource = resourceId => {
    const {
      downloadResource,
      match: {
        params: { titleId }
      }
    } = this.props;
    postNotificationMessage({ eventName: 'TR_PANEL_DOWNLOAD', resourceId });
    downloadResource(titleId, resourceId);
  };

  render() {
    const {
      product: { name = 'Loading', cover = null }
    } = this.props;

    return (
      <Feature
        name="teacher-resources-panel"
        render={() => (
          <TeacherResourcesView
            productName={name}
            productImage={cover ? `${envSettings.integrations.premium.baseUrl}${cover}` : null}
            onCategoryFilterChange={this._handleCategoryFilterChange}
            onUnitFilterChange={this._handleUnitFilterChange}
            clearFailure={this._loadResources}
            downloadResource={this._downloadResource}
            {...pick(this.props, [
              'resources',
              'isLoading',
              'hasFailed',
              'downloadIsLoading',
              'downloadHasFailed',
              'currentDownload',
              'errors',
              'horizontalOptions',
              'verticalOptions',
              'categories',
              'consumer'
            ])}
            {...pick(this.state, ['categoryFilter', 'unitFilter'])}
          />
        )}
        renderFallback={NotFoundView}
      />
    );
  }
}

ItalyTeacherResourcesPage.propTypes = {
  match: PropTypes.object.isRequired,
  resources: PropTypes.arrayOf(TeacherResourcePropType).isRequired,
  product: PropTypes.object,
  horizontalOptions: PropTypes.object,
  verticalOptions: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  hasFailed: PropTypes.bool.isRequired,
  downloadIsLoading: PropTypes.bool.isRequired,
  downloadHasFailed: PropTypes.bool.isRequired,
  currentDownload: PropTypes.string,
  loadResources: PropTypes.func.isRequired,
  downloadResource: PropTypes.func.isRequired,
  consumer: PropTypes.object,
  errors: PropTypes.object
};

export default withRouter(
  connect(
    (
      state,
      {
        match: {
          params: { titleId }
        }
      }
    ) => ({
      ...pick(state.teacherResources, [
        'isLoading',
        'hasFailed',
        'downloadIsLoading',
        'downloadHasFailed',
        'currentDownload',
        'consumer',
        'errors'
      ]),
      resources: getTeacherResources(state, titleId),
      product: getTeacherResourceProduct(state, titleId),
      horizontalOptions: getTeacherResourceUnits(state),
      verticalOptions: getTeacherResourcesVertical(state),
      categories: getTeacherResourcesCategories(state, titleId)
    }),
    dispatch => ({
      loadResources: titleId => {
        dispatch(actions.teacherResourcesRequest(titleId));
      },
      downloadResource: (titleId, resourceId) => {
        dispatch(actions.teacherResourceRequest(titleId, resourceId));
      }
    })
  )(ItalyTeacherResourcesPage)
);
