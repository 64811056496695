import groupBy from 'lodash.groupby';
import orderBy from 'lodash.orderby';
import startCase from 'lodash.startcase';
import moment from 'moment';
import { createSelector } from 'reselect';

export default createSelector(
  state => state.activity.log,
  state => state.identity.currentOrganisationId,
  state => state.people.data,
  (log, orgId, people) => {
    const sorted = orderBy(log, ({ activityDate }) => moment(activityDate).format('YYYY-MM-DD HH:mm:ss'), 'desc');
    const grouped = groupBy(sorted, 'activityDate');
    return Object.entries(grouped).reduce((activity, [date, activities]) => {
      const formattedDate = moment(date).calendar(null, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: 'D MMMM YYYY',
        sameElse: 'D MMMM YYYY'
      });
      return {
        ...activity,
        [formattedDate]: activities.reduce(
          (
            carry,
            {
              type,
              signInCardLink: url,
              completedByUserFirstName,
              completedByUserLastName,
              completedByUserId,
              successCount: count,
              signInCardLinkExpired: isExpired
            }
          ) =>
            type === 'managedUserUpload'
              ? [
                  ...carry,
                  {
                    url,
                    creatorName: startCase(`${completedByUserFirstName} ${completedByUserLastName}`),
                    creatorLink: `/org/${orgId}/user/${completedByUserId}`,
                    creatorRole: people[completedByUserId] ? people[completedByUserId].roleName : null,
                    date,
                    count,
                    isExpired
                  }
                ]
              : carry,
          activity[formattedDate] || []
        )
      };
    }, {});
  }
);
