import pick from 'lodash.pick';
import * as t from '../actionTypes';

const initialState = {
  gradebookProducts: [],
  submitting: false,
  success: false,
  errors: {}
};

function gradebookProducts(state = initialState, { type, payload }) {
  switch (type) {
    case t.RESET_GB_PRODUCTS:
      return {
        ...initialState
      };
    case t.GET_GB_PRODUCTS_REQUEST:
      return {
        ...initialState,
        submitting: true
      };
    case t.GET_GB_PRODUCTS_FAILURE:
      return {
        ...state,
        submitting: false,
        success: false,
        errors: payload
      };
    case t.GET_GB_PRODUCTS_SUCCESS:
      return {
        ...state,
        submitting: false,
        errors: {},
        success: true,
        ...pick((payload || {}).data, ['gradebookProducts'])
      };
    default:
      return state;
  }
}

export default gradebookProducts;
