import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@oup/shared-front-end/src/components/Button';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PopoutPanelIllustrationHeading from '../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';

import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import { isHubMode } from '../../utils/platform';
import { setFormState } from '../../redux/reducers/data/forms.reducer.js';
import TextWithLink from '../../components/TextWithLink/TextWithLink';
import cmsContent from '../../utils/cmsContent.js';
import interpolateContent from '../../utils/interpolateContent';
import styles from '../Register/RegisterPage.scss';

function getPopoutPanelHeading(failed) {
  const CMS = cmsContent.registerPasswordReset || {};
  if (isHubMode()) {
    if (!failed) {
      return (
        <PopoutPanelIllustrationHeading
          title={CMS.success_password_reset_email_sent_text}
          illustrationSrc={HubIllustrationConstants.SUCCESS}
          illustrationAltText={HubIllustrationAltText.SUCCESS}
        />
      );
    }
    return (
      <PopoutPanelIllustrationHeading
        title={CMS.password_reset_error_title}
        illustrationSrc={HubIllustrationConstants.ERROR}
        illustrationAltText={HubIllustrationAltText.ERROR}
      />
    );
  }
  if (!failed) {
    return <PopoutPanelIconHeading type={types.VALID} title={CMS.success_password_reset_email_sent_text} />;
  }
  return <PopoutPanelIconHeading type={types.ERROR} title={CMS.password_reset_error_title} />;
}
function ResetPasswordConfirmation({ failed, resetFormAction, helpAndSupportUrl }) {
  const CMS = cmsContent.registerPasswordReset || {};
  return (
    <div className="grid">
      <div className="row">
        <div className="col sm2" />
        <div className="col md8">
          {!failed ? (
            <div className="pad-top8 pad-bot8">
              {getPopoutPanelHeading(failed)}
              <p className="pad3" style={{ textAlign: 'center' }}>
                <p className="pad1">{CMS.success_password_reset_email_reminder}</p>
                <p className="pad1">{CMS.success_password_reset_email_body}</p>
                <TextWithLink
                  className={`pad1 ${styles.helpCard}`}
                  text={interpolateContent(CMS.success_contact_customer_support_text, { url: helpAndSupportUrl })}
                />
              </p>
            </div>
          ) : (
            <div>
              {getPopoutPanelHeading(failed)}
              <div className={`text-center gin-top2 gin-bot2 ${styles.buttonCenter}`}>
                <Button variant="filled" text={CMS.button_reset_password_text} onClick={resetFormAction} />
              </div>
            </div>
          )}
        </div>
        <div className="col sm2" />
      </div>
    </div>
  );
}

ResetPasswordConfirmation.propTypes = {
  failed: PropTypes.bool.isRequired,
  resetFormAction: PropTypes.func.isRequired,
  helpAndSupportUrl: PropTypes.string.isRequired
};

export default connect(
  state => ({
    failed: state.forms.requestPasswordReset.failed
  }),
  {
    resetFormAction: () => setFormState('requestPasswordReset', 'INPUTTING')
  }
)(ResetPasswordConfirmation);
