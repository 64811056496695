import React, { useRef, useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './EreaderContentPlayer.scss';
import withLocalizedContent from '../../language/withLocalizedContent';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import withFocusTrap from '../../decorators/withFocusTrap';

// Temporary store the based URLs matching the eReader app, in order to match them against the origin of posted messages
const allowedOrigins = ['https://temp.oupereader.com'];

function EreaderContentPlayer({ eReaderBookSignedUrl, closePanel }) {
  const parentRef = useRef();

  useEffect(() => {
    window.addEventListener(
      'message',
      event => {
        if (!allowedOrigins.includes(event.origin)) return;

        if (event.data.actionCode === 'closeBook') {
          closePanel();
        }
      },
      false
    );
  });

  let content = '';
  if (eReaderBookSignedUrl) {
    content = (
      <div className={styles.cpModal} ref={parentRef}>
        <div className={styles.cpModalContent}>
          <iframe title="Opening Book" src={eReaderBookSignedUrl} />
        </div>
      </div>
    );
  } else {
    content = <LoadingSpinner />;
  }

  return content;
}

EreaderContentPlayer.propTypes = {
  params: PropTypes.object,
  closePanel: PropTypes.func.isRequired,
  localizedContent: PropTypes.object,
  eReaderBookSignedUrl: PropTypes.string
};

const mapStateToProps = state => ({
  eReaderBookSignedUrl: state.structuredContentPlayer.eReaderBookSignedUrl
});

const TrappedStructuredContentPlayer = withFocusTrap(EreaderContentPlayer);
export default compose(
  connect(mapStateToProps, null),
  withLocalizedContent('cptGlossary')
)(TrappedStructuredContentPlayer);
