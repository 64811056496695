import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import keys from 'lodash.keys';
import get from 'lodash.get';
import has from 'lodash.has';
// Constants
import hubSubSideNavbarConstants from './HubSubSideNavbarConstants';
// Services
import getAssessmentHomeLink from '../../HubCourseAssessments/Services/getAssessmentHomeLink';
import getResourceLink from '../Services/getResourceLink';
import { licenceStatus as LICENCE_STATUS, HubLayoutConstants, COURSE_FOLDERS } from '../../../globals/hubConstants.js';

import getUserLicencesStatusData from '../../HubOrganizationLayout/OrganizationLicences/services/getUserLicencesStatusData';
// Components
import CourseComponentsMenuItems from './CourseComponentsMenuItems';
import Link from '../../../components/Link/Link';
// Styles
import styles from './CourseComponentsMenu.scss';

class CourseComponentsMenu extends Component {
  static getDropDownPath = resourceFolderName => {
    if (resourceFolderName === COURSE_FOLDERS.GET_STARTED) return HubLayoutConstants.PATH_NAMES.GET_STARTED_HOME;
    if (resourceFolderName === COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT)
      return HubLayoutConstants.PATH_NAMES.PROFESSIONAL_DEVELOPMENT_HOME;
    if (resourceFolderName === COURSE_FOLDERS.TESTS) return HubLayoutConstants.PATH_NAMES.TEST_HOME;
    return HubLayoutConstants.PATH_NAMES.RESOURCE_HOME;
  };

  static isStudentFolder = (courseRole, resourceFolderName) => {
    const isStudentRole = courseRole === 'student';

    if (
      isStudentRole &&
      (resourceFolderName === COURSE_FOLDERS.GET_STARTED ||
        resourceFolderName === COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT)
    ) {
      return false;
    }

    return true;
  };

  constructor() {
    super();
    this.state = {
      toggledItem: null,
      activeItem: null
    };
  }

  componentDidUpdate(prevProps) {
    const { pathname, params } = this.props;
    if (pathname !== prevProps.pathname) {
      /** It is updated the navigation menu in My Courses when 'Back to assessement' button is pressed */
      const assessmentHome = HubLayoutConstants.PATH_NAMES.ASSESSMENT_HOME.substring(1);
      const { toggledItem } = this.state;
      if (pathname.split('/').pop() === assessmentHome) {
        this.handleCategorySelection(HubLayoutConstants.ASSESSMENT_HOME, false);
        // Since the above fix is needed, the below if needs to set back the "Assessment" menu parent as active
        // otherwise Assessment parent is not shown as active unless is double-clicked - EPS-14955
        if (toggledItem === undefined) {
          this.setState({ activeItem: 'Assessment' });
        }
      } else {
        this.handleCategorySelection(params.subFolder, false);
      }
    }
  }

  getTitleMenuItem = resourceFolderName => {
    const { hubContent } = this.props;
    if (resourceFolderName === COURSE_FOLDERS.GET_STARTED) return hubContent.get_started;
    if (resourceFolderName === COURSE_FOLDERS.PROFESSIONAL_DEVELOPMENT) return hubContent.professional_development;
    return resourceFolderName;
  };

  toggleNavbarItem = (folderName, assessmentId = '') => {
    const {
      props: { params, course },
      state: { toggledItem }
    } = this;

    let returnObj = {};

    if (toggledItem === folderName) {
      returnObj = { ...returnObj, toggledItem: undefined };
    } else {
      returnObj = { ...returnObj, toggledItem: folderName };
    }

    // Expand dropdown and set the resource/assessment home as active
    if (!params.subFolder && (returnObj.toggledItem === params.folder || returnObj.toggledItem === folderName)) {
      returnObj.activeItem = `${params.folder}Home`;
    }

    // Expand dropdown and set a subfolder as active
    if (returnObj.toggledItem === params.folder || returnObj.toggledItem === folderName) {
      // in case of folder menu items that are 2 words (Get Started/Professional Development)
      // we need to pass the resource folder name otherwise will not go in the below IF statement
      // and will not correctly highlight the active menu item - EPS-14955
      const resourceFolder = returnObj.toggledItem === folderName ? params.folder : returnObj.toggledItem;
      if (
        (!assessmentId && has(course, ['resourceFolders', resourceFolder, params.subFolder])) ||
        (assessmentId && has(course, ['assessmentFolders', assessmentId, params.subFolder]))
      )
        returnObj = { ...returnObj, activeItem: params.subFolder };
    } else {
      // Close dropdown or open a different dropdown and set the previously selected folder as active
      returnObj = {
        ...returnObj,
        activeItem: params.folder
      };
    }

    this.setState(returnObj);
  };

  handleCategorySelection = (category, closeDropdown) => {
    if (!closeDropdown && category !== undefined) {
      this.setState({ activeItem: category });
    } else if (category !== undefined) {
      this.setState({ activeItem: category, toggledItem: undefined });
    }
  };

  renderMenuItems = assessmentData => {
    const { course, hubContent } = this.props;
    const { activeItem, toggledItem } = this.state;
    const statusDetails = getUserLicencesStatusData({
      expiryDate: assessmentData.expiryDate
    });
    const isLicenseExpired = statusDetails.status === LICENCE_STATUS.EXPIRED;

    if (isLicenseExpired) return null;
    const assessmentId = 'assessments';
    const foldersData = assessmentData.assessmentFolders.assessments;
    return (
      <CourseComponentsMenuItems
        course={course}
        folderName={hubContent.assessment}
        folder={foldersData}
        toggleNavbarItem={this.toggleNavbarItem}
        handleCategorySelection={this.handleCategorySelection}
        activeItem={activeItem}
        assessmentId={assessmentId}
        toggledItem={toggledItem}
        dropdownPath={`${getAssessmentHomeLink(course.id, assessmentId, hubContent.assessment)}`}
      />
    );
  };

  render() {
    const {
      state: { activeItem, toggledItem },
      props: { course, hubContent, pathname, isPreview },
      toggleNavbarItem,
      handleCategorySelection
    } = this;

    const userIsTeacherInCourse = get(course, 'userRole', '') === HubLayoutConstants.COURSE_USER_ROLES.TEACHER;
    let assessmentData = {};
    let products = {};
    if (userIsTeacherInCourse && course) {
      products = get(course, ['products'], {});
      const firstProduct = products.data[products.ids[0]];
      assessmentData = { ...firstProduct };
      assessmentData.assessmentFolders = get(course, ['assessmentFolders'], {});
      assessmentData.resources = get(course, ['resources'], {});
    }
    return (
      <div className={styles.navBarContainer} role="navigation" aria-label="Course navigation">
        <ul>
          {has(course, 'id') &&
            hubSubSideNavbarConstants.getSideNavbarItems(course.id, hubContent, isPreview).map(navBarItem => (
              <li key={navBarItem.name}>
                <div>
                  <Link
                    to={navBarItem.path}
                    className={classnames([styles.navBarItem], {
                      [styles.navBarItemActive]: pathname === navBarItem.path
                    })}
                    onClick={() => handleCategorySelection(navBarItem.name)}
                  >
                    <div className={styles.buttonName}>{navBarItem.name}</div>
                  </Link>
                </div>
              </li>
            ))}
          {userIsTeacherInCourse &&
          assessmentData &&
          assessmentData.assessmentFolders &&
          assessmentData.assessmentFolders.assessments
            ? this.renderMenuItems(assessmentData)
            : null}
          {course &&
            keys(course.resourceFolders).map(resourceFolderName =>
              // Check if folder has subfolders
              CourseComponentsMenu.isStudentFolder(course.userRole, resourceFolderName) &&
              keys(course.resourceFolders[resourceFolderName]).length ? (
                <CourseComponentsMenuItems
                  key={resourceFolderName}
                  course={course}
                  folderName={this.getTitleMenuItem(resourceFolderName)}
                  folder={course.resourceFolders[resourceFolderName]}
                  toggleNavbarItem={toggleNavbarItem}
                  handleCategorySelection={handleCategorySelection}
                  activeItem={activeItem}
                  toggledItem={toggledItem}
                  resourceFolderName={resourceFolderName}
                  isPreview={isPreview}
                  dropdownPath={`${getResourceLink(
                    course?.id,
                    resourceFolderName,
                    isPreview
                  )}${CourseComponentsMenu.getDropDownPath(resourceFolderName)}`}
                />
              ) : null
            )}
        </ul>
      </div>
    );
  }
}

export default CourseComponentsMenu;

CourseComponentsMenu.propTypes = {
  course: PropTypes.object,
  hubContent: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  userRole: PropTypes.string,
  isPreview: PropTypes.bool
};
