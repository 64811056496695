import pick from 'lodash.pick';
import * as t from '../actionTypes';

const initialState = {
  status: '',
  levelGroup: '',
  levelName: '',
  expected: '',
  badges: [],
  submitting: false,
  success: false,
  error: false
};

function studentAttainmentReport(state = initialState, { type, payload }) {
  switch (type) {
    case t.STUDENT_ATTAINMENT_REPORT_REQUEST:
      return {
        ...state,
        submitting: true,
        success: false,
        error: false
      };
    case t.STUDENT_ATTAINMENT_REPORT_FAILURE:
      return {
        ...state,
        submitting: false,
        success: false,
        error: true
      };
    case t.STUDENT_ATTAINMENT_REPORT_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: false,
        success: true,
        status: payload.status,
        ...pick(payload, ['levelGroup', 'levelName', 'expected', 'badges'])
      };
    default:
      return state;
  }
}

export default studentAttainmentReport;
