/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-danger */
import isEmpty from 'lodash.isempty';
import pick from 'lodash.pick';
import pickBy from 'lodash.pickby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import Button, { buttonTypes } from '../../../components/Button/Button';
import ContentWithThumbnail from '../../../components/ContentWithThumbnail/ContentWithThumbnail';
import getProductDates from '../../../utils/normalizations/getProductDates';
import ListPageControls from '../../../components/ListPageControls/ListPageControls';
import PageHeading from '../../../components/PageHeading/PageHeading';
import ProductListing, { ProductStatus, getProductStatus } from '../../../components/ProductListing/ProductListing';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import Badge from '../../../components/Badge/Badge';
import SearchStatus from '../../../components/SearchStatus/SearchStatus';
import { SIZES as ThumbnailSizes } from '../../../components/Thumbnail/Thumbnail';
import withLocalizedContent from '../../../language/withLocalizedContent';
import colors from '../../../globals/colors';
import { searchProductSortOptions } from '../../../globals/searchFilters';
import { setTerm, triggerSearch, setSort, setFilter } from '../../../redux/reducers/data/search.reducer';
import getCurrentOrganisation from '../../../redux/selectors/getCurrentOrganisation';
import getCurrentUser from '../../../redux/selectors/getCurrentUser';
import { postGotoMessage } from '../../../redux/actions/postMessage';
import { embeddedByPlatform, isOrbMode } from '../../../utils/platform';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Link from '../../../components/Link/Link';
import { safePanelLink, safePanelUnlink } from '../../../utils/links/panelLinks';
import PopoutPanel from '../../../components/PopoutPanel/PopoutPanel.js';
import AddToProfile from './panels/AddToProfile/AddToProfile.js';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal.js';
import { showClosePanelModal } from '../../../redux/reducers/archiveUsers.reducer.js';
import Feature from '../../../environment/Feature';
import { featureIsEnabled } from '../../../globals/envSettings';
import Modals from '../../../components/Modals/Modals';
import { sanitizeUrl } from '../../../utils/url';
import styles from './MyProfileMaterialSearch.scss';
import PlatformEntitlementListing from '../../../components/PlatformEntitlementListing/PlatformEntitlementListing.js';

function SortingComponent({ children, sort }) {
  const childrenArray = React.Children.toArray(children);
  console.log(childrenArray);
  childrenArray.sort((a, b) => {
    const aElm = (a.props.children.props.children.props.productTypeHeading || '').toLowerCase();
    const bElm = (b.props.children.props.children.props.productTypeHeading || '').toLowerCase();
    if (aElm > bElm) return 1;
    if (bElm > aElm) return -1;
    return 0;
  });
  if (sort && sort.indexOf('desc') >= 0) {
    childrenArray.reverse();
  }
  return <div> {childrenArray} </div>;
}

SortingComponent.propTypes = {
  children: PropTypes.any.isRequired,
  sort: PropTypes.string
};

class MyProfileMaterialSearch extends Component {
  // TODO: This logic will likely go server side in future, needs some input from @davipinc and myself @json-born
  static generatePlatformEntitlementsFromUserPlatformStatistics = (userPlatformStatistics, searchTerm) => {
    const otcProduct = () => ({
      productDetails: {
        id: 'otc',
        title: 'Oxford Teachers Club',
        url: 'https://elt.oup.com/teachersclub/',
        registrationPath: '/platform-registration/otc'
      }
    });

    const otcEntitlement = Object.keys(userPlatformStatistics.redeemed)
      .filter(product => product.includes(searchTerm))
      .includes('otc');

    if (otcEntitlement) {
      return [otcProduct()];
    }

    return [];
  };

  constructor(props) {
    super(props);
    this.state = {
      toggleModal: false,
      modalTitle: '',
      modalCoverImage: null,
      iosAppLink: '',
      androidAppLink: ''
    };
  }

  get _localizedContent() {
    const { localizedContent } = this.props;
    return localizedContent.myProfileMaterialSearchTab;
  }

  static getRedeemCodePanel = (rlmModalContent, showModalAction, history, showConfirmModal, params) => (
    <div>
      <PopoutPanel ariaLabel="AddCode" isOpen={params.panelName === 'addCode'}>
        <AddToProfile
          closePanel={() => showModalAction(true)}
          onComplete={() => {
            history.push(safePanelUnlink('addCode'));
          }}
        />
      </PopoutPanel>
      {showConfirmModal && (
        <ConfirmationModal
          title={rlmModalContent.title}
          body={rlmModalContent.body}
          positiveClickText={rlmModalContent.positiveClickText}
          negativeClickText={rlmModalContent.negativeClickText}
          positiveClick={() => {
            // Close the Popout panel
            history.push(safePanelUnlink('addCode'));
            showModalAction(false);
          }}
          negativeClick={() => showModalAction(false)}
        />
      )}
    </div>
  );

  _renderLicenceAction = (id, product) => {
    let status;
    let toLink = product.productLaunchUrl || product.productDetails.productLaunchUrl;
    let targetLink = '_blank';
    const {
      licenceDetails,
      licenceNotStartedDetails,
      learningAssignments,
      productDetails: { platform }
    } = product;
    const { localizedContent } = this.props;

    if (platform === APP_CONSTANTS.PLATFORMS.OALD) {
      targetLink = null;
      toLink = safePanelLink('addCode');
    }
    const learningAssignmentStatus =
      learningAssignments && (learningAssignments[0].endDate || learningAssignments[0].activationCode);
    if (licenceDetails || licenceNotStartedDetails) {
      status = getProductStatus(licenceDetails || licenceNotStartedDetails);
    } else if (learningAssignmentStatus) {
      status = ProductStatus.NOT_STARTED;
    } else {
      status = ProductStatus.EXPIRED;
    }

    if (status === ProductStatus.EXPIRED) {
      return (
        <Button
          type={buttonTypes.GHOST_INVERTED}
          glyph={GLYPHS.ICON_PLUS}
          text={localizedContent.productListing.button_enter_code_text}
          to={toLink}
          target={targetLink}
          disableExternalBehaviour
        />
      );
    }

    return null;
  };

  _toggleModal = (toggleVarName, coverimage, title, iosAppLink, androidAppLink) => {
    // eslint-disable-next-line no-unused-expressions, react/destructuring-assignment
    this.state[toggleVarName]
      ? this.setState(prevState => ({
          ...prevState,
          [toggleVarName]: false
        }))
      : this.setState(prevState => ({
          ...prevState,
          modalTitle: title,
          androidAppLink,
          iosAppLink,
          modalCoverImage: coverimage,
          [toggleVarName]: true
        }));
  };

  render() {
    const {
      localizedContent: { removeLearningMaterialModal: rlmModalContent },
      searchTerm,
      filters,
      sort,
      results: { profileAssignments },
      userPlatformStatistics,
      resultCount,
      defaultSearch,
      loading,
      setSearchTerm,
      searchAction,
      setSortAction,
      setFilterAction,
      profileIsSelfLearner,
      isEmbeddedApp,
      onProductUrlClick,
      showModalAction,
      showConfirmModal,
      history,
      params
    } = this.props;

    const platformEntitlements = MyProfileMaterialSearch.generatePlatformEntitlementsFromUserPlatformStatistics(
      userPlatformStatistics,
      searchTerm
    );

    const showRedeemButton = !isOrbMode() && featureIsEnabled('oald-redeem-code');
    const { modalTitle, modalCoverImage, iosAppLink, androidAppLink, toggleModal } = this.state;

    if (profileIsSelfLearner && defaultSearch && isEmpty(profileAssignments) && isEmpty(platformEntitlements)) {
      return (
        <div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="pad4" style={{ textAlign: 'center' }}>
              <div className="row cols-center">
                <ContentWithThumbnail
                  size={ThumbnailSizes.HEADING}
                  glyph={GLYPHS.ICON_LEARNING_MATERIAL}
                  backgroundColor={colors.COURSE}
                />
              </div>
              <PageHeading
                title={this._localizedContent.no_content_title}
                subtitle={showRedeemButton ? this._localizedContent.no_content_subtitle : ''}
              />
              <Feature
                name="oald-redeem-code"
                render={() => (
                  <div>
                    <br />
                    {showRedeemButton ? (
                      <Link id="addToProfile" to={safePanelLink('addCode')}>
                        {this._localizedContent.button_redeem_code}
                      </Link>
                    ) : null}
                  </div>
                )}
              />
            </div>
          )}

          <Feature
            name="oald-redeem-code"
            render={() =>
              MyProfileMaterialSearch.getRedeemCodePanel(
                rlmModalContent,
                showModalAction,
                history,
                showConfirmModal,
                params
              )
            }
          />
        </div>
      );
    }

    const learningAssignments = pickBy(
      profileAssignments,
      result => isEmpty(result.subscriptions) && result.productDetails.productType === APP_CONSTANTS.PRODUCT_TYPE_EBOOK
    );
    const subscriptions = pickBy(profileAssignments, result => !isEmpty(result.subscriptions));
    const dictionarieProducts = pickBy(
      profileAssignments,
      result =>
        isEmpty(result.subscriptions) && result.productDetails.productType === APP_CONSTANTS.PRODUCT_TYPE_DICTIONARY
    );

    Object.keys(subscriptions).forEach(id => {
      subscriptions[id] = {
        ...subscriptions[id],
        ...getProductDates(subscriptions[id])
      };
    });

    return (
      <div>
        <ListPageControls
          idPrefix="product"
          searchInputLabel={this._localizedContent.search_profile_label}
          searchInputPlaceholder={this._localizedContent.search_profile_placeholder}
          searchInputValue={searchTerm}
          searchInputOnChange={setSearchTerm}
          searchInputOnSubmit={searchAction}
          filterOptions={[
            {
              text: this._localizedContent.filter_not_started,
              id: 'myProfileProductSearch-filterNotStarted',
              name: 'myProfileProductSearch-filterNotStarted',
              value: 'notStarted',
              checked: filters.notStarted,
              onChange: setFilterAction
            },
            {
              text: this._localizedContent.filter_active,
              id: 'myProfileProductSearch-filterActive',
              name: 'myProfileProductSearch-filterActive',
              value: 'active',
              checked: filters.active,
              onChange: setFilterAction
            },
            {
              text: this._localizedContent.filter_expiring,
              id: 'myProfileProductSearch-filterExpiring',
              name: 'myProfileProductSearch-filterExpiring',
              value: 'expiring',
              checked: filters.expiring,
              onChange: setFilterAction
            },
            {
              text: this._localizedContent.filter_expired,
              id: 'myProfileProductSearch-filterExpired',
              name: 'myProfileProductSearch-filterExpired',
              value: 'expired',
              checked: filters.expired,
              onChange: setFilterAction
            },
            {
              text: this._localizedContent.filter_no_licence,
              id: 'myProfileProductSearch-filterNoLicence',
              name: 'myProfileProductSearch-filterNoLicence',
              value: 'noLicence',
              checked: filters.noLicence,
              onChange: setFilterAction
            }
          ]}
          sortOnChange={setSortAction}
          sortOptions={searchProductSortOptions('libraryProductSearch', sort)}
          newButtonText={
            resultCount > 0 && featureIsEnabled('oald-redeem-code') && showRedeemButton
              ? this._localizedContent.button_redeem_code
              : null
          }
          newButtonTo={
            resultCount > 0 && featureIsEnabled('oald-redeem-code') && showRedeemButton
              ? safePanelLink('addCode')
              : null
          }
          ariaControls="searchResults"
          showSkeletonLoader={loading}
        />
        <SearchStatus
          searchSource="profileAssignments"
          resultPlatformEntitlements={platformEntitlements}
          noResultsFoundContent={
            <div className="grid">
              <div className="row">
                <div role="region" aria-live="polite" aria-atomic="true" className="col">
                  <p className="gin-top1 gin-bot1">{this._localizedContent.no_library_search_results}</p>
                </div>
              </div>
            </div>
          }
        />

        {!loading && resultCount > 0 ? (
          <div className="grid" id="searchResults">
            <SortingComponent sort={sort}>
              {Object.keys(platformEntitlements).length ? (
                <div className="row">
                  <div className="col horizantal-scroll-mobile">
                    <PlatformEntitlementListing items={platformEntitlements} />
                  </div>
                </div>
              ) : null}

              {Object.keys(subscriptions).length ? (
                <div className="row">
                  <div className="col horizantal-scroll-mobile">
                    <ProductListing
                      items={subscriptions}
                      productTypeHeading={this._localizedContent.subscriptions_heading}
                      enableExternalLinks
                      onProductUrlClick={isEmbeddedApp ? onProductUrlClick : undefined}
                    />
                  </div>
                </div>
              ) : null}

              {Object.keys(dictionarieProducts).length && featureIsEnabled('oald-redeem-code') ? (
                <div className="row">
                  <div className="col horizantal-scroll-mobile">
                    <ProductListing
                      items={dictionarieProducts}
                      productTypeHeading={this._localizedContent.dictionary_product_heading}
                      renderAction={this._renderLicenceAction}
                      enableExternalLinks
                      showLicenseActions
                      showLicenceDetailContent
                      onProductUrlClick={isEmbeddedApp ? onProductUrlClick : undefined}
                      type={featureIsEnabled('oald-redeem-code') ? APP_CONSTANTS.COLLECTION_LIST : null}
                      toggleModal={this._toggleModal}
                    />
                  </div>
                </div>
              ) : null}

              {Object.keys(learningAssignments).length ? (
                <div className="row">
                  <div className="col horizantal-scroll-mobile">
                    <ProductListing
                      items={learningAssignments}
                      productTypeHeading={this._localizedContent.learning_assignments_heading}
                      renderAction={this._renderLicenceAction}
                      enableExternalLinks
                      showLicenseActions
                      showLicenceDetailContent
                      onProductUrlClick={isEmbeddedApp ? onProductUrlClick : undefined}
                      type={featureIsEnabled('oald-redeem-code') ? APP_CONSTANTS.COLLECTION_LIST : null}
                      toggleModal={this._toggleModal}
                    />
                  </div>
                </div>
              ) : null}
            </SortingComponent>
          </div>
        ) : (
          <div>
            {loading ? null : (
              <Feature
                name="oald-redeem-code"
                render={() => (
                  <div className="pad4" style={{ textAlign: 'center' }}>
                    <div className="row cols-center">
                      <ContentWithThumbnail
                        size={ThumbnailSizes.HEADING}
                        glyph={GLYPHS.ICON_LEARNING_MATERIAL}
                        backgroundColor={colors.COURSE}
                      />
                    </div>
                    <PageHeading
                      title={this._localizedContent.no_content_title}
                      subtitle={showRedeemButton ? this._localizedContent.no_content_subtitle : ''}
                    />
                    <br />

                    {showRedeemButton ? (
                      <Link id="addToProfile" to={safePanelLink('addCode')}>
                        {this._localizedContent.button_redeem_code}
                      </Link>
                    ) : null}
                  </div>
                )}
              />
            )}
          </div>
        )}
        <Feature
          name="oald-redeem-code"
          render={() =>
            MyProfileMaterialSearch.getRedeemCodePanel(
              rlmModalContent,
              showModalAction,
              history,
              showConfirmModal,
              params
            )
          }
        />
        <Modals
          visibilityChecker={toggleModal}
          noCustomStyles="false"
          closeCallBack={() => {
            this._toggleModal('toggleModal');
          }}
          bottomBorderForClose={false}
          bodyHtml={
            <div className={styles.body}>
              <div className={styles.coverImage}>
                {modalCoverImage && <img src={sanitizeUrl(modalCoverImage)} alt={modalTitle} />}
              </div>
              {modalTitle && <h1 className={styles.title}>{modalTitle}</h1>}
              <div className={styles.listItems}>
                <ul>
                  <li className="gin-top1">
                    <Badge noCustomStyles="false" value="1" />
                    &nbsp;&nbsp;
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this._localizedContent.oald_dictionary_guideline1
                      }}
                    />
                  </li>
                  <li className="gin-top1">
                    <Badge noCustomStyles="false" value="2" />
                    &nbsp;&nbsp;
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this._localizedContent.oald_dictionary_guideline2
                      }}
                    />
                  </li>
                  <li className="gin-top1">
                    <Badge noCustomStyles="false" value="3" />
                    &nbsp;&nbsp;
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this._localizedContent.oald_dictionary_guideline3
                      }}
                    />
                  </li>
                </ul>
              </div>
              <div className={styles.images}>
                <a rel="noreferrer" href={sanitizeUrl(androidAppLink)} target="_blank">
                  <img
                    src={this._localizedContent.google_play_badge_img_src}
                    alt={this._localizedContent.google_play_badge_img_alt}
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a rel="noreferrer" href={sanitizeUrl(iosAppLink)} target="_blank">
                  <img
                    src={this._localizedContent.app_store_badge_img_src}
                    alt={this._localizedContent.app_store_badge_img_alt}
                  />
                </a>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

MyProfileMaterialSearch.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  sort: PropTypes.string,
  results: PropTypes.object.isRequired,
  resultCount: PropTypes.number.isRequired,
  defaultSearch: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  searchAction: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  onProductUrlClick: PropTypes.func,
  isEmbeddedApp: PropTypes.bool.isRequired,
  // Used to show slightly different UI components if user is self learner and has no assignments/licences
  profileIsSelfLearner: PropTypes.bool.isRequired,
  localizedContent: PropTypes.object,
  showModalAction: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.any,
  userPlatformStatistics: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withLocalizedContent('myProfileMaterialSearchTab', 'removeLearningMaterialModal', 'productListing'),
  connect(
    state => ({
      organisation: getCurrentOrganisation(state),
      user: getCurrentUser(state),
      searchTerm: state.search.profileAssignments.term,
      ...pick(state.search.profileAssignments, ['filters', 'sort', 'defaultSearch', 'loading']),
      results: {
        profileAssignments: state.search.profileAssignments.data
      },
      userPlatformStatistics: state.userPlatformStatistics,
      resultCount:
        state.search.profileAssignments.totalResults + Object.keys(state.userPlatformStatistics.redeemed).length,
      isEmbeddedApp: !!embeddedByPlatform(),
      showConfirmModal: state.archiveUsers.showModal
    }),
    {
      setSearchTerm: term => setTerm('profileAssignments', term),
      setSortAction: sort => setSort('profileAssignments', sort[0]),
      setFilterAction: (valueName, value) => setFilter('profileAssignments', valueName, value),
      searchAction: triggerSearch.bind(null, 'profileAssignments'),
      onProductUrlClick: url => postGotoMessage(url),
      showModalAction: showClosePanelModal
    }
  )
)(MyProfileMaterialSearch);
