import toString from 'lodash.tostring';
import { select, put, takeLatest } from 'redux-saga/effects';
import {
  restoreStudentsSuccess,
  restoreStudentsError,
  restoreStudentsSubmitting
} from '../../../../actions/restoreUsers';
import * as t from '../../../../actionTypes';
import restoreOrgUsersApiRequest from '../../../api/restoreOrgUsers';
import { pollOrgUsersUpdated } from '../dataRecency/pollOrgUsers';
import { getCurrentPlatform } from '../../../../../utils/platform';

function* restoreOrgUsers() {
  yield put(restoreStudentsSubmitting());
  const platformCode = getCurrentPlatform();
  try {
    const payload = yield select(({ restoreUsers: { userIds = [], notifyUsers, orgId }, people }) => ({
      orgId,
      notifyUsers,
      userIdAndRoleNamePairs: userIds.map(userId => ({
        userId,
        roleName: people.data[userId].roleName
      })),
      platformCode
    }));

    const result = yield restoreOrgUsersApiRequest(payload);

    if (toString(result.status).toLowerCase() === 'fail') return yield put(restoreStudentsError(true));
    if (toString(result.status).toLowerCase() === 'success' && result.data) {
      if (
        result.data.resultPairs &&
        result.data.resultPairs.every(r => toString(r.status).toLowerCase() !== 'success')
      ) {
        throw new Error();
      }
      yield put(restoreStudentsSuccess(result.data));

      const successfullyRestoredUserIds = result.data.resultPairs.map(({ userId }) => userId);
      yield pollOrgUsersUpdated(successfullyRestoredUserIds);
    }
  } catch (_) {
    yield put(restoreStudentsError(true));
  }
  return null;
}

export default function* restoreUsersFromOrgSaga() {
  yield takeLatest(t.RESTORE_STUDENTS_REQUEST, restoreOrgUsers);
}
