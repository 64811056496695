import { PLACEMENT_TEST_STATUS } from '../../../globals/hubConstants';

const getPlacementTestActionButtons = (
  content,
  {
    viewProgress = null,
    viewSessionInfo = null,
    continueSetupSession = null,
    manageSession = null,
    viewResults = null,
    copyJoiningCode = null,
    accessTest = null,
    downloadReportCard = null
  },
  { testStatus = '', showResultsToStudents = false },
  isMyTasksPage
) => {
  if (testStatus === PLACEMENT_TEST_STATUS.DRAFT) {
    return [
      {
        title: content.placement_test_session_continue_set_up,
        action: continueSetupSession
      },
      {
        title: content.placement_test_session_view_session_information,
        action: viewSessionInfo
      }
    ];
  }

  if (testStatus === PLACEMENT_TEST_STATUS.ACTIVE) {
    return isMyTasksPage
      ? [
          {
            title: content.placement_test_session_access_test,
            action: accessTest,
            to: '#'
          },
          {
            title: content.placement_test_session_view_session_information,
            action: viewSessionInfo
          }
        ]
      : [
          {
            title: content.placement_test_session_manage_session,
            action: manageSession
          },
          {
            title: content.placement_test_session_view_progress,
            action: viewProgress
          },
          {
            title: content.placement_test_session_copy_joining_code,
            label: content.copied,
            action: copyJoiningCode
          },
          {
            title: content.placement_test_session_view_session_information,
            action: viewSessionInfo
          }
        ];
  }

  if (testStatus === PLACEMENT_TEST_STATUS.UPCOMING) {
    return isMyTasksPage
      ? [
          {
            title: content.placement_test_session_view_session_information,
            action: viewSessionInfo
          }
        ]
      : [
          {
            title: content.placement_test_session_manage_session,
            action: manageSession
          },
          {
            title: content.placement_test_session_copy_joining_code,
            label: content.copied,
            action: copyJoiningCode
          },
          {
            title: content.placement_test_session_view_session_information,
            action: viewSessionInfo
          }
        ];
  }

  if (testStatus === PLACEMENT_TEST_STATUS.COMPLETED) {
    if (isMyTasksPage) {
      return showResultsToStudents
        ? [
            {
              title: content.placement_test_session_view_progress,
              action: viewProgress
            },
            {
              title: content.placement_test_session_download_report_card,
              action: downloadReportCard
            },
            {
              title: content.placement_test_session_view_session_information,
              action: viewSessionInfo
            }
          ]
        : [
            {
              title: content.placement_test_session_view_session_information,
              action: viewSessionInfo
            }
          ];
    }
    return [
      {
        title: content.placement_test_session_manage_completed_session,
        action: manageSession
      },
      {
        title: content.placement_test_session_view_results,
        action: viewResults
      },
      {
        title: content.placement_test_session_view_session_information,
        action: viewSessionInfo
      }
    ];
  }

  if (testStatus === PLACEMENT_TEST_STATUS.MISSING) {
    return [
      {
        title: content.placement_test_session_view_session_information,
        action: viewSessionInfo
      }
    ];
  }

  return [];
};

export default getPlacementTestActionButtons;
