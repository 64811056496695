import * as t from '../actionTypes';

export const loadPlacementTestsRequest = data => ({
  type: t.GET_PLACEMENT_TESTS,
  payload: data
});

export const loadPlacementTestsSuccess = payload => ({
  type: t.GET_PLACEMENT_TESTS_SUCCESS,
  payload
});

export const loadPlacementTestsFailure = payload => ({
  type: t.GET_PLACEMENT_TESTS_FAILURE,
  payload
});

export const refreshPlacementTestList = payload => ({
  type: t.REFRESH_PLACEMENT_TEST_LIST,
  payload
});
