import { put, select } from 'redux-saga/effects';
import signedFetch from '../../../apiCalls/util/signedFetch.js';
import { setSystemNotification } from '../../../../reducers/app.reducer.js';

function* updateSystemNotification(data) {
  const response = yield signedFetch('systemNotification', `${__API_BASE__}/system-notification`, 'PUT', data);
  if (response.status.toLowerCase() === 'success' && data.systemNotification) {
    const systemNotification = yield select(state => state.app.systemNotification);
    const immutableSystemNotification = { ...systemNotification };
    (data.systemNotification || []).forEach(element => {
      immutableSystemNotification[element.platformCode] = element.enabled;
    });
    const updateNotificationData = [];
    Object.keys(immutableSystemNotification || {}).forEach(platformCode => {
      if (immutableSystemNotification[platformCode])
        updateNotificationData.push({ platform: platformCode, showNotification: true });
    });
    yield put(setSystemNotification(updateNotificationData));
  }
}

export default updateSystemNotification;
