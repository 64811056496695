import React from 'react';
import PropTypes from 'prop-types';
import GradebookTable from '../GradebookTable/GradebookTable';
import PencilReviewButton from '../PencilReviewButton/PencilReviewButton';

function AverageRow({ classAverageData, pendingMarks = [], levels = [] }) {
  const skipFirstColumn = 1;
  return (
    <GradebookTable.Row highlight>
      <GradebookTable.Header stickyColumn highlight textAlign="left">
        Class Average
      </GradebookTable.Header>
      <GradebookTable.Cell highlight textAlign="left">
        -
      </GradebookTable.Cell>
      {classAverageData.map((element, index) => {
              // Calculate the corresponding level index, adjusting for the first two elements
              // that are totals and not corresponding to any level
              const levelIndex = index < 2 ? -1 : Math.floor((index - 2) / 2);

              // Check if the corresponding level is locked, default to false for the first two indices
              const isLocked = levelIndex >= 0 ? levels[levelIndex]?.locked : false;



              return index % 2 === 0 ? (
                <GradebookTable.Cell
                  key={`completed-${index}`}
                  highlight
                  shaded
                  locked={isLocked}
                  >
                  {element}
                </GradebookTable.Cell>
              ) : (
                <GradebookTable.Cell
                  key={`score-${index}`}
                  highlight
                 locked={isLocked}
                  >
                  {pendingMarks[Math.floor(index / 2) - skipFirstColumn] && <PencilReviewButton />} {element}
                </GradebookTable.Cell>
              );
            })}
    </GradebookTable.Row>
  );
}
AverageRow.propTypes = {
  classAverageData: PropTypes.object,
  pendingMarks: PropTypes.array,
  levels: PropTypes.array
};

export default AverageRow;
