import isEmpty from 'lodash.isempty';
import orderBy from 'lodash.orderby';
import PropTypes from 'prop-types';
import React from 'react';
import Media from 'react-media';
import { TeacherResource as TeacherResourcePropType } from '../../types';
import randomString from '../../utils/randomString';
import Button from '../Button/Button';
import ElementWithText from '../ElementWithText/ElementWithText';
import EntityListing from '../EntityListing/EntityListing';
import IconEmblem, { Color, EmblemSize, GlyphIcon } from '../IconEmblem/IconEmblem';
import styles from './ResourcesTable.scss';
import breakpoints from '../../styles/constants/_breakpoints.scss';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import withLocalizedContent from '../../language/withLocalizedContent';

const _iconEmblemFormat = mimeType => {
  if (mimeType === 'audio') {
    return { icon: GlyphIcon.ICON_AUDIO, backgroundColour: Color.org_admin };
  }
  if (mimeType === 'video') {
    return { icon: GlyphIcon.ICON_VIDEO, backgroundColour: Color.learner };
  }
  return { icon: GlyphIcon.ICON_DOCUMENT, backgroundColour: Color.parent };
};

const _alphabetizeRows = collatedRows => orderBy(collatedRows, row => row.description, ['asc']);
const _categorisedResourceAmount = (resources, categoryId) =>
  resources.reduce(
    (matchingResources, resource) =>
      categoryId === ((resource.categories[0] && resource.categories[0].categoryId) || null)
        ? matchingResources + 1
        : matchingResources,
    0
  );

const _collateEntityTables = (resources, category, content, downloadResource, downloadIsLoading, currentDownload) =>
  _alphabetizeRows(resources).reduce((renderedCategories, resource) => {
    if (resource.categories[0]?.name === category.name) {
      const mimeType = resource.mimetype ? resource.mimetype.substring(0, resource.mimetype.indexOf('/')) : '';
      const iconFormat = _iconEmblemFormat(mimeType);
      const resourceQuantity = _categorisedResourceAmount(resources, category.categoryId);
      const headingText = resourceQuantity > 1 ? content.item_count_plural : content.item_count_singular;
      const resourceHeading = `${resource.categories[0].name || 'No category'} (${resourceQuantity} ${headingText})`;
      const thisDownloadIsLoading = downloadIsLoading && currentDownload === resource.uuid;

      renderedCategories[resource.uuid] = {
        [resourceHeading]: (
          <div className={styles.rowContentFormat}>
            <ElementWithText text={resource.name} className="gin-right1">
              <IconEmblem
                size={EmblemSize.SMALL}
                icon={iconFormat.icon}
                colour={iconFormat.backgroundColour}
                iconColour={Color.background}
              />
            </ElementWithText>
            <Media query={JSON.parse(breakpoints.smMedia)}>
              {matches => (
                <Button
                  id={resource.uuid}
                  text="Download"
                  glyph={thisDownloadIsLoading ? GLYPHS.ICON_LOADING : GLYPHS.ICON_DOWNLOAD}
                  glyphFill={Color.background}
                  onClick={() => downloadResource(resource.uuid)}
                  disabled={thisDownloadIsLoading}
                  iconOnly={!matches}
                />
              )}
            </Media>
          </div>
        )
      };

      return renderedCategories;
    }

    return renderedCategories;
  }, {});

const _renderCategorisedContent = (
  resources,
  categories,
  content,
  downloadResource,
  downloadIsLoading,
  currentDownload
) =>
  resources.length
    ? categories.map(category => {
        const items = _collateEntityTables(
          resources,
          category,
          content,
          downloadResource,
          downloadIsLoading,
          currentDownload
        );

        return !isEmpty(items) ? (
          <div className={styles.tableSpacing} key={randomString(8)}>
            <EntityListing className={styles.rowContainerFormat} items={items} />
          </div>
        ) : null;
      })
    : null;

function ResourcesTable({
  resources,
  categories,
  downloadResource,
  downloadIsLoading,
  currentDownload,
  localizedContent: { teacherResourcesView: content }
}) {
  return (
    <div className={styles.resourcesTable}>
      {_renderCategorisedContent(resources, categories, content, downloadResource, downloadIsLoading, currentDownload)}
    </div>
  );
}
ResourcesTable.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  resources: PropTypes.arrayOf(TeacherResourcePropType).isRequired,
  categories: PropTypes.array,
  downloadResource: PropTypes.func.isRequired,
  downloadIsLoading: PropTypes.bool,
  // downloadHasFailed: PropTypes.bool, // @todo: Currently no specs for failed download but could be handy
  currentDownload: PropTypes.string
};

export default withLocalizedContent('teacherResourcesView')(ResourcesTable);
