import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import isEmpty from 'lodash.isempty';
import { connect } from 'react-redux';
import Button, { buttonTypes } from '../../../components/Button/Button';
// import classnames from 'classnames';
import colors, { hexCodes } from '../../../globals/colors';
import styles from './TopBanner.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import LinkImg from '../../../../static/images/hub/homepage/linkImage.png';
import env from '../../../globals/envSettings';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import USER_ROLES, { isOupContent } from '../../../globals/userRoles';
import { isHubMode, isOrbMode, isEltAssessmentMode, isHeadlessPage } from '../../../utils/platform';

function TopBanner({
  localizedContent: { siteHeaders: content },
  pathname,
  userRole,
  orgId,
  classroomId,
  profileAssignments,
  profileAssignmentsLoading,
  widgetOption
}) {
  const myProfileUrl = /^\/myProfile.*$/;
  const mySchoolUrl = /^\/mySchool.*$/;
  const classUrl = /^\/org\/[^/]*\/class.*$/;
  const dynamicValues = { text: '', buttonLink: '', isVisible: false };

  const myClasses = `${env.integrations.hub.baseUrl}${HubLayoutConstants.PATH_NAMES.CLASSES_PATH}`;
  const myDashboard = `${env.integrations.hub.baseUrl}${HubLayoutConstants.PATH_NAMES.DASHBOARD_PATH}`;
  const myOrgStudents = `${env.integrations.hub.baseUrl}${HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH}${HubLayoutConstants.PATH_NAMES.STUDENTS}`;
  const hubWelcomePage = `${env.integrations.hub.baseUrl}${HubLayoutConstants.PATH_NAMES.WELCOME_HOME_PAGE}`;
  const isImpersonationMode = !!localStorage.getItem('impersonation-token');

  if (
    isOrbMode() ||
    isHubMode() ||
    isEltAssessmentMode() ||
    isOupContent(userRole) ||
    isHeadlessPage() ||
    isImpersonationMode
  ) {
    return '';
  }

  if (myProfileUrl.test(pathname)) {
    dynamicValues.text = content.eps_hub_account_banner_text;
    dynamicValues.buttonLink = myDashboard;
    if (profileAssignmentsLoading === false) {
      if (!isEmpty(profileAssignments)) {
        dynamicValues.isVisible = !Object.values(profileAssignments).filter(
          assignment => assignment.productDetails.platform === 'OALD'
        ).length;
      } else {
        dynamicValues.isVisible = true;
      }
    }
  } else if (classUrl.test(pathname)) {
    dynamicValues.text = content.eps_hub_class_banner_text;
    dynamicValues.buttonLink = `${myClasses}${HubLayoutConstants.PATH_NAMES.SINGLE_CLASS_PATH}/${classroomId}${HubLayoutConstants.PATH_NAMES.PROGRESS}/${orgId}${HubLayoutConstants.PATH_NAMES.GRADEBOOK}`;

    dynamicValues.isVisible = true;
  } else if (mySchoolUrl.test(pathname)) {
    dynamicValues.text = content.eps_hub_organization_banner_text;
    if (userRole === USER_ROLES.TEACHER) {
      dynamicValues.buttonLink = myClasses;
    } else {
      dynamicValues.buttonLink = myOrgStudents;
    }
    dynamicValues.isVisible = true;
  }

  return (
    dynamicValues.isVisible && (
      <div className={styles.TopBanner}>
        {!widgetOption ? (
          <div id="topBanner" className={styles.epsHubTopBanner}>
            <div className="grid">
              <div className="row">
                <div className="col">
                  <div id="mainMenu" className={styles.mainStyle}>
                    <div className={styles.textComponent}>
                      <p className={styles.textStyle}>{dynamicValues.text}</p>
                      <div className={styles.linkSTyle}>
                        <a className={styles.linkTextSTyle} href={hubWelcomePage} target="_blank" rel="noreferrer">
                          {content.eps_hub_find_out_more_text}
                        </a>
                        <img
                          className={styles.LinkimageStyle}
                          src={LinkImg}
                          alt={content.eps_hub_find_out_more_img_alt_text}
                        />
                      </div>
                    </div>
                    <div className={styles.buttonComponent}>
                      <div className={styles.addButtonContainer}>
                        <Button
                          type={buttonTypes.PRIMARY}
                          text={content.eps_hub_try_it_now_text}
                          to={dynamicValues.buttonLink}
                          glyphFill={hexCodes[colors.HUB_BLUE]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.topBannerWidget}>
            <div className={styles.textComponentWidget}>
              <p className={styles.textStyleWidget}>{dynamicValues.text}</p>
            </div>
            <div className={styles.ctaComponentsWidget}>
              <div className={styles.buttonComponentWidget}>
                <div className={styles.addButtonContainer}>
                  <Button
                    type={buttonTypes.PRIMARY}
                    text={content.eps_hub_try_it_now_text}
                    to={dynamicValues.buttonLink}
                    glyphFill={hexCodes[colors.HUB_BLUE]}
                  />
                </div>
              </div>
              <div className={styles.linkStyleWidget}>
                <a className={styles.linkTextStyleWidget} href={hubWelcomePage} target="_blank" rel="noreferrer">
                  {content.eps_hub_find_out_more_text}
                </a>
                <img
                  className={styles.LinkimageStyleWidget}
                  src={LinkImg}
                  alt={content.eps_hub_find_out_more_img_alt_text}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
}

TopBanner.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  orgId: PropTypes.string,
  classroomId: PropTypes.string,
  profileAssignments: PropTypes.object.isRequired,
  profileAssignmentsLoading: PropTypes.bool,
  widgetOption: PropTypes.string
};

export default compose(
  connect(state => ({
    pathname: window.location.pathname,
    userRole: state.identity.role,
    orgId: state.identity.currentOrganisationId,
    classroomId: state.classroomPage.classroomId || null,
    profileAssignments: state.search?.profileAssignments?.data || {},
    profileAssignmentsLoading: state.search?.profileAssignments?.loading
  })),
  withLocalizedContent('siteHeaders')
)(TopBanner);
