const APP_CONSTANTS = require('./constants');

const { ORG_REGISTRATION_STATUS, ORG_STATUS } = APP_CONSTANTS;

const orgRoles = {
  UNIVERSITY: 'UNIVERSITY',
  LANGUAGE_SCHOOL: 'LANGUAGE_SCHOOL',
  SECONDARY_SCHOOL: 'SECONDARY_SCHOOL',
  PRIMARY_SCHOOL: 'PRIMARY_SCHOOL',
  OTHER_EDUCATION: 'OTHER_EDUCATION',
  CORPORATE: 'CORPORATE',
  GOVERNMENT_AGENCY: 'GOVERNMENT_AGENCY',
  NON_GOVERNMENTAL_ORGANIZATION: 'NON_GOVERNMENTAL_ORGANIZATION',
  TEST_CENTER: 'TEST_CENTER',
  OIC_SCHOOL: 'OIC_SCHOOL'
};

const validOrgRoles = Object.values(orgRoles);

const eltAmRoles = [orgRoles.TEST_CENTER];

/**
 * ------------------------------------------------------------------
 * Groupings of orgRoles by the type of student they contain
 * ------------------------------------------------------------------
 * */

/** Orgs that contain MANAGED_USER students. These schools create their own users and can delete them. */
const managedOrgRoles = [orgRoles.PRIMARY_SCHOOL];

const isManagedOrg = orgRole => Object.keys(managedOrgRoles).includes(orgRole);

/** Orgs that contain self-registered LEARNER students. These students are invited by email address. */
const selfRegisterOrgRoles = [
  orgRoles.UNIVERSITY,
  orgRoles.LANGUAGE_SCHOOL,
  orgRoles.SECONDARY_SCHOOL,
  orgRoles.OTHER_EDUCATION,
  orgRoles.CORPORATE,
  orgRoles.GOVERNMENT_AGENCY,
  orgRoles.NON_GOVERNMENTAL_ORGANIZATION,
  orgRoles.TEST_CENTER
];

/**
 * ------------------------------------------------------------------
 * Groupings of orgRoles by how they are created
 * ------------------------------------------------------------------
 * */

/** Protected orgRoles that can only be created by integrated systems e.g. SAP, Assessment Master - never by end users */
const systemGeneratedOrgRoles = [orgRoles.PRIMARY_SCHOOL, orgRoles.TEST_CENTER, orgRoles.OIC_SCHOOL];

/** Orgs that can be created without validation by end users */
const userGeneratedOrgRoles = [
  orgRoles.UNIVERSITY,
  orgRoles.LANGUAGE_SCHOOL,
  orgRoles.SECONDARY_SCHOOL,
  orgRoles.OTHER_EDUCATION,
  orgRoles.CORPORATE,
  orgRoles.GOVERNMENT_AGENCY,
  orgRoles.NON_GOVERNMENTAL_ORGANIZATION
];

/** Avoid using these except in errors/warnings. Use locale-defined glossaries whenever possible */
const orgRoleDescriptions = {
  [orgRoles.UNIVERSITY]: 'Higher education/university',
  [orgRoles.LANGUAGE_SCHOOL]: 'Language school/training company',
  [orgRoles.SECONDARY_SCHOOL]: 'Secondary school',
  [orgRoles.PRIMARY_SCHOOL]: 'Primary school',
  [orgRoles.OTHER_EDUCATION]: 'Other educational',
  [orgRoles.CORPORATE]: 'Corporate',
  [orgRoles.GOVERNMENT_AGENCY]: 'Ministry/government agency',
  [orgRoles.NON_GOVERNMENTAL_ORGANIZATION]: 'Non-governmental organization',
  [orgRoles.TEST_CENTER]: 'Test center',
  [orgRoles.OIC_SCHOOL]: 'OIC school'
};

const hearAboutUsValues = {
  FRIEND_OR_COLLEAGUE: 'Friend or colleague',
  SEARCH_ENGINE: 'Search engine',
  OXFORD_UNIVERSITY_PRESS_BOOK: 'Oxford University Press Book',
  OXFORD_UNIVERSITY_PRESS_WEBSITE: 'Oxford University Press Website',
  OXFORD_EDUCATIONAL_CONSULTANT: 'Oxford Educational Consultant',
  SOCIAL_MEDIA: 'Social Media',
  OTHER: 'Other'
};

const orgSupportsCurriculumType = orgRole =>
  [orgRoles.PRIMARY_SCHOOL, orgRoles.TEST_CENTER, orgRoles.OIC_SCHOOL].includes(orgRole);

const orgSupportsSchoolCode = orgRole => orgRole === orgRoles.PRIMARY_SCHOOL;

const orgSupportsLocation = countryCode => countryCode === 'GB';

const orgIsPartialyRegistered = orgRegistrationStatus =>
  orgRegistrationStatus === ORG_REGISTRATION_STATUS.PARTIALLY_REGISTERED;

const orgIsActive = orgStatus => orgStatus === ORG_STATUS.ACTIVE;

function getPlacementCentreConfig(orgConfig) {
  const { configurations: { CONFIG_PLACEMENT_CENTER: placementCentreDetails = null } = {} } = orgConfig;

  return placementCentreDetails;
}
/**
 * Check whether this org config denotes a Placement centre organisation that can host Oxford Placement Tests
 * @param {Object} orgConfig - configuration object of the particular org
 * @returns Boolean
 */
function isPlacementCentre(orgConfig) {
  return getPlacementCentreConfig(orgConfig) !== null;
}

const getOrgMaxCreditsLimit = () => APP_CONSTANTS.ORG_MAX_CREDITS;

module.exports = {
  orgRoles,
  eltAmRoles,
  validOrgRoles,
  managedOrgRoles,
  selfRegisterOrgRoles,
  systemGeneratedOrgRoles,
  userGeneratedOrgRoles,
  orgRoleDescriptions,
  isManagedOrg,
  hearAboutUsValues,
  orgSupportsCurriculumType,
  orgSupportsSchoolCode,
  orgSupportsLocation,
  orgIsPartialyRegistered,
  orgIsActive,
  ORG_STATUS,
  getPlacementCentreConfig,
  isPlacementCentre,
  getOrgMaxCreditsLimit
};
