import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import ActionList, { Action } from '../ActionList/ActionList';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { isHubMode } from '../../utils/platform';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import styles from './UserEditFailure.scss';

function UserEditFailure({
  localizedContent: { userEditFailureComponent: content },
  failureMessage,
  wrongCurrentPassword,
  unlinkErrorWrongPassword,
  onBack,
  onDone
}) {
  const checkPasswordOxfordIdAndUnlink = wrongCurrentPassword || unlinkErrorWrongPassword;

  const getWarningPanelHeading = () => {
    if (isHubMode()) {
      return (
        <PopoutPanelIllustrationHeading
          title={checkPasswordOxfordIdAndUnlink ? content.wrong_password_entered : content.there_was_a_problem}
          subtitle={content.password_not_changed}
          illustrationSrc={HubIllustrationConstants.WARNING}
          illustrationAltText={HubIllustrationAltText.WARNING}
        />
      );
    }

    return (
      <PopoutPanelIconHeading
        title={checkPasswordOxfordIdAndUnlink ? content.wrong_password_entered : content.there_was_a_problem}
        subtitle={content.password_not_changed}
        type={popoutPanelIconHeadingTypes.WARNING}
      />
    );
  };

  const getErrorPanelHeading = () => {
    if (isHubMode()) {
      return (
        <PopoutPanelIllustrationHeading
          {...pick(content, ['title', 'subtitle'])}
          illustrationSrc={HubIllustrationConstants.ERROR}
          illustrationAltText={HubIllustrationAltText.ERROR}
        />
      );
    }

    return (
      <PopoutPanelIconHeading {...pick(content, ['title', 'subtitle'])} type={popoutPanelIconHeadingTypes.ERROR} />
    );
  };

  return (
    <ScrollContainer
      headerContent={failureMessage ? getWarningPanelHeading() : null}
      footerContent={
        <ActionList>
          {failureMessage && (
            <Action
              className={`${styles.button} ${styles.backButton}`}
              back
              label={content.back_button}
              onClick={onBack}
            />
          )}
          <Action
            className={`${styles.button} ${styles.doneButton}`}
            label={content.done_button}
            primary
            onClick={onDone}
          />
        </ActionList>
      }
    >
      {!failureMessage && getErrorPanelHeading()}

      {failureMessage && !checkPasswordOxfordIdAndUnlink && (
        <div className={`${styles.editUserFailure} pad2`}>
          <div className="gin2">
            <h1 className={styles.title}>Please:</h1>
            <ul className={styles.list}>
              <li>{content.rule_not_recent}</li>
              <li>{content.rule_password_correct}</li>
            </ul>
          </div>
        </div>
      )}
      {failureMessage && checkPasswordOxfordIdAndUnlink && (
        <div className={`${styles.editUserFailure} pad2`}>
          <div className="gin2">
            <h1 className={styles.title}>{content.check_the_wrong_password}</h1>
          </div>
        </div>
      )}
    </ScrollContainer>
  );
}

UserEditFailure.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  failureMessage: PropTypes.bool.isRequired,
  unlinkErrorWrongPassword: PropTypes.bool.isRequired,
  wrongCurrentPassword: PropTypes.bool.isRequired
};

export default withLocalizedContent('userEditFailureComponent')(UserEditFailure);
