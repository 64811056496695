import { select } from 'redux-saga/effects';
import generateReportApi from './generateReportApi';

export default function* schoolReport() {
  console.log(`[generateReport Saga] Submitting request to get report.`);

  const { reportType, options } = yield select(state => ({
    reportType: state.schoolReport.reportType,
    options: state.schoolReport.formData
  }));

  return yield generateReportApi(reportType, options);
}
