import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PopoutPanelIconHeading, { types } from '../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../../components/ScrollContainer/ScrollContainer';
import SidePanel from '../../components/SidePanel/SidePanel';
import withLocalizedContent from '../../language/withLocalizedContent';
import {
  formStates,
  downloadSignInCard,
  validateUserPasswordRequest,
  generateRandomPasswordRequest,
  resetSignInCard,
  savingLegacyUsers
} from '../../redux/reducers/userSignInCard.reducer';

import DownloadSignInCardPanel from './DownloadSignInCardPanel';
import ErrorSignInCardPanel from './ErrorSignInCardPanel';
import PasswordResetPanel from './PasswordResetPanel';
import ErrorStatus from '../../components/ErrorStatus/ErrorStatus';

class ManagedUserSignInCardPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legacyUsers: [],
      errors: {}
    };
  }

  componentDidMount() {
    const { userIds, classIds } = this.props;
    if (userIds.length === 0 || classIds.length === 0) this._handleClose();
  }

  componentWillReceiveProps(newProps) {
    this.setState({ legacyUsers: newProps.failedIds, errors: newProps.errors });
  }

  _handleClose = () => {
    const { formState, onComplete, onClosePanel } = this.props;
    (formState === formStates.CHECKING_SELECTED_USERS || formState === formStates.GET_CLASSROOM_STUDENTS
      ? onComplete
      : onClosePanel)();
  };

  _handleComplete = () => {
    const { onComplete, onResetPanel } = this.props;
    onResetPanel();
    if (onComplete) onComplete();
  };

  _handleOnPasswordBlur = (idx, user) => {
    const { validatePasswordAction } = this.props;
    validatePasswordAction(idx, user);
  };

  render() {
    const {
      id,
      ariaLabel,
      open = true,
      formState,
      size,
      downloadSignInCardAction,
      generatePassword,
      isPasswordGenerated,
      isPasswordDisabled,
      onSubmittingLegacyUsers,
      localizedContent: { managedUserSignInCardPanel: content }
    } = this.props;

    const { legacyUsers, errors } = this.state;
    const isRetrieveResponseError = formStates.ERROR_RETRIEVING_RESPONSE;
    let contentTemplate;

    switch (formState) {
      case 'CHECKING_SELECTED_USERS':
        contentTemplate = (
          <ScrollContainer>
            <PopoutPanelIconHeading
              type={types.LOADING}
              title={content.loading_page_title}
              subtitle={content.loading_page_subtitle}
            />
          </ScrollContainer>
        );
        break;
      case 'GET_CLASSROOM_STUDENTS':
        contentTemplate = (
          <ScrollContainer>
            <PopoutPanelIconHeading
              type={types.LOADING}
              title={content.loading_page_title}
              subtitle={content.password_reset_loading_subtitle}
            />
          </ScrollContainer>
        );
        break;
      case 'SAVING_LEGACY_USERS':
        contentTemplate = (
          <ScrollContainer>
            <PopoutPanelIconHeading
              type={types.LOADING}
              title={content.loading_page_title}
              subtitle={content.password_reset_loading_subtitle}
            />
          </ScrollContainer>
        );
        break;
      case 'RESET_PASSWORD_SELECTED_USERS':
        contentTemplate = (
          <PasswordResetPanel
            content={content}
            items={legacyUsers}
            errors={errors}
            onPasswordBlur={this._handleOnPasswordBlur}
            onGeneratePassword={generatePassword}
            isDisabled={isPasswordDisabled}
            isAutoFill={isPasswordGenerated}
            onDone={onSubmittingLegacyUsers}
          />
        );
        break;
      case 'SAVING_CHANGES':
        contentTemplate = (
          <ScrollContainer>
            <PopoutPanelIconHeading
              type={types.LOADING}
              title={content.loading_page_title}
              subtitle={content.password_reset_loading_subtitle}
            />
          </ScrollContainer>
        );
        break;
      case 'DOWNLOAD_SELECTED_USERS':
        contentTemplate = (
          <DownloadSignInCardPanel
            content={content}
            onDone={this._handleComplete}
            size={size}
            onDownload={downloadSignInCardAction}
          />
        );
        break;
      case 'ERROR_RETRIEVING_PASSWORD_RESPONSE':
        contentTemplate = (
          <ScrollContainer>
            <ErrorStatus
              title={content.error_password_title}
              subtitle={content.error_password_subtitle}
              buttonText={content.button_return_to_profile}
              buttonOnClickHandler={this._handleComplete}
              isPasswordFailure={formStates.ERROR_RETRIEVING_PASSWORD_RESPONSEue}
              buttonFullWidth
            />
          </ScrollContainer>
        );
        break;
      case 'ERROR_RETRIEVING_RESPONSE':
      case 'ERROR_RETRIEVING_USERS':
        contentTemplate = (
          <ErrorSignInCardPanel errorMsg={isRetrieveResponseError} content={content} onDone={this._handleComplete} />
        );
        break;
      default:
        contentTemplate = (
          <div>
            <p>Error. Missing a form for `{formState}`.</p>
          </div>
        );
    }

    return (
      <SidePanel id={id} ariaLabel={ariaLabel} isOpen={open} onClose={this._handleClose}>
        {contentTemplate}
      </SidePanel>
    );
  }
}

export default compose(
  withLocalizedContent('managedUserSignInCardPanel'),
  connect(
    state => ({
      ...pick(state.userSignInCard, [
        'userIds',
        'classIds',
        'formState',
        'showRemovalPending',
        'requestFailed',
        'result',
        'size',
        'failedIds',
        'errors',
        'isPasswordGenerated',
        'isPasswordDisabled'
      ])
    }),
    {
      downloadSignInCardAction: downloadSignInCard,
      validatePasswordAction: validateUserPasswordRequest,
      onResetPanel: resetSignInCard,
      generatePassword: generateRandomPasswordRequest,
      onSubmittingLegacyUsers: savingLegacyUsers
    }
  )
)(ManagedUserSignInCardPanel);

ManagedUserSignInCardPanel.propTypes = {
  id: PropTypes.string,
  userIds: PropTypes.array,
  classIds: PropTypes.array,
  ariaLabel: PropTypes.string.isRequired,
  open: PropTypes.bool,
  formState: PropTypes.string.isRequired,
  onClosePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  downloadSignInCardAction: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  size: PropTypes.any,
  validatePasswordAction: PropTypes.func.isRequired,
  onResetPanel: PropTypes.func.isRequired,
  generatePassword: PropTypes.func.isRequired,
  isPasswordGenerated: PropTypes.bool.isRequired,
  isPasswordDisabled: PropTypes.bool.isRequired,
  onSubmittingLegacyUsers: PropTypes.func.isRequired
};
