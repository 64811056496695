import { useState, useEffect } from 'react';

const getPrimarycolor = colors => {
  const colorCount = {};

  colors.forEach(color => {
    if (color in colorCount) {
      colorCount[color] += 1;
    } else {
      colorCount[color] = 1;
    }
  });

  let primaryColor = null;
  let maxCount = 0;

  Object.keys(colorCount).forEach(color => {
    if (colorCount[color] > maxCount) {
      maxCount = colorCount[color];
      primaryColor = color;
    }
  });

  return primaryColor;
};

function extractPrimaryColor(imageSrc, borderWidth, numberOfPoints) {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.src = imageSrc;
    image.crossOrigin = 'Anonymous';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = image.width;
      canvas.height = image.height;

      context.drawImage(image, 0, 0, image.width, image.height);

      const topBorderPixels = context.getImageData(0, 0, canvas.width, borderWidth).data;
      const bottomBorderPixels = context.getImageData(0, canvas.height - borderWidth, canvas.width, borderWidth).data;
      const leftBorderPixles = context.getImageData(0, borderWidth, borderWidth, canvas.height - 2 * borderWidth).data;
      const rightBorderPixels = context.getImageData(
        canvas.width - borderWidth,
        borderWidth,
        borderWidth,
        canvas.height - 2 * borderWidth
      ).data;

      const step = numberOfPoints < 1 ? 1 : numberOfPoints;

      const topPixelsStep = Math.round(topBorderPixels.length / step);
      const bottomPixelsStep = Math.round(bottomBorderPixels.length / step);
      const leftPixelsStep = Math.round(leftBorderPixles.length / step);
      const rightPixelsStep = Math.round(rightBorderPixels.length / step);

      const colors = [];

      for (let i = 0; i < topBorderPixels.length; i += topPixelsStep) {
        colors.push(`${topBorderPixels[i]},${topBorderPixels[i + 1]},${topBorderPixels[i + 2]}`);
      }
      for (let i = 0; i < bottomBorderPixels.length; i += bottomPixelsStep) {
        colors.push(`${bottomBorderPixels[i]},${bottomBorderPixels[i + 1]},${bottomBorderPixels[i + 2]}`);
      }
      for (let i = 0; i < leftBorderPixles.length; i += leftPixelsStep) {
        colors.push(`${leftBorderPixles[i]},${leftBorderPixles[i + 1]},${leftBorderPixles[i + 2]}`);
      }
      for (let i = 0; i < rightBorderPixels.length; i += rightPixelsStep) {
        colors.push(`${rightBorderPixels[i]},${rightBorderPixels[i + 1]},${rightBorderPixels[i + 2]}`);
      }

      resolve(`rgb(${getPrimarycolor(colors)})`);
    };

    image.onerror = error => {
      reject(error);
    };
  });
}

/**
  Returns the primary color that appears in the image border
  @param {string} imageSrc Image source
  @param {number} borderWidth How much space to consider from each border (Default: 10)
  @param {number} numberOfPoints The number of reference points on each border (Default: 100)
*/
function useExtractBorderColor(imageSrc, borderWidth = 10, numberOfPoints = 100) {
  const [borderColor, setBorderColor] = useState('');
  console.log(imageSrc);

  useEffect(() => {
    (async () => {
      const primaryColor = await extractPrimaryColor(imageSrc, borderWidth, numberOfPoints);
      setBorderColor(primaryColor);
    })();
  }, [imageSrc]);

  return borderColor;
}

export default useExtractBorderColor;
