import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import ProgressGauge from '../ProgressGauge/ProgressGauge';
import styles from './OrbReportDials.scss';
import withLocalizedContent from '../../language/withLocalizedContent';
import withLocalizedErrors from '../../language/withLocalizedErrors';
import DialsSkeletonLoader from '../SkeletonLoader/Orb/DialsSkeletonLoader';

class OrbReportDials extends Component {
  // TODO: function can only be removed when glossary REST API support accepting locale in headers/querystring
  _attainmentGlossary() {
    const {
      locationId = '',
      localizedContent: { orbReportDialsComponent: content }
    } = this.props;
    return ['ae', 'cn'].includes(locationId.toLowerCase()) ? content.achievement : content.attainment;
  }

  _renderTextDial() {
    const {
      dialsData: { attainment, progress, engagement },
      enableTextDial = false,
      localizedContent: { orbReportDialsComponent: content }
    } = this.props;

    return enableTextDial ? (
      <div className={styles.textDialContainer}>
        <div className={styles.textDialRow}>
          <p className={styles.textDialRowName}>{this._attainmentGlossary()}</p>
          <strong className={styles.textDialRowValue}>{Math.round(attainment)}%</strong>
        </div>
        <div className={styles.textDialRow}>
          <p className={styles.textDialRowName}>{content.progress}</p>
          <strong className={styles.textDialRowValue}>{Math.round(progress)}%</strong>
        </div>
        <div className={styles.textDialRow}>
          <p className={styles.textDialRowName}>{content.engagement}</p>
          <strong className={styles.textDialRowValue}>{Math.round(engagement)}%</strong>
        </div>
      </div>
    ) : null;
  }

  render() {
    const {
      dialsData,
      hideDescription = false,
      enableTextDial = false,
      getLocalizedErrorMessage,
      localizedContent: { orbReportDialsComponent: content }
    } = this.props;

    if (!dialsData) {
      return <DialsSkeletonLoader />;
    }
    if (dialsData.status && dialsData.code) {
      return (
        <div className={styles.loadingContainer}>
          <p className={styles.textCenter}>{getLocalizedErrorMessage(dialsData)}</p>
        </div>
      );
    }

    return (
      <div>
        {this._renderTextDial()}
        <div
          className={classnames(styles.imageDialsContainer, {
            [styles.imageDialsContainerTextMode]: enableTextDial
          })}
        >
          <div className={styles.dialCard}>
            <div className={styles.dialSeat}>
              <ProgressGauge
                error={!('attainment' in dialsData)}
                percentage={dialsData.attainment}
                label={this._attainmentGlossary()}
              />
            </div>
            {!hideDescription ? <p className={styles.textCenter}>{content.attainment_description}</p> : null}
          </div>

          <div className={styles.dialCard}>
            <div className={styles.dialSeat}>
              <ProgressGauge
                error={!('progress' in dialsData)}
                percentage={dialsData.progress}
                label={content.progress}
              />
            </div>
            {!hideDescription ? <p className={styles.textCenter}>{content.progress_description}</p> : null}
          </div>

          <div className={styles.dialCard}>
            <div className={styles.dialSeat}>
              <ProgressGauge
                error={!('engagement' in dialsData)}
                percentage={dialsData.engagement}
                label={content.engagement}
              />
            </div>
            {!hideDescription ? <p className={styles.textCenter}>{content.engagement_description}</p> : null}
          </div>
        </div>
      </div>
    );
  }
}

OrbReportDials.propTypes = {
  dialsData: PropTypes.shape({
    attainment: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    engagement: PropTypes.number.isRequired,
    status: PropTypes.string,
    code: PropTypes.string
  }),
  hideDescription: PropTypes.bool,
  enableTextDial: PropTypes.bool,
  locationId: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  getLocalizedErrorMessage: PropTypes.func
};

export default compose(
  withLocalizedContent('orbReportDialsComponent'),
  withLocalizedErrors('orb-reporting')
)(OrbReportDials);
