import React, { useEffect, useRef } from 'react';
import { FOCUSABLE_ELEMENTS } from '../services/cptHelperMethods';

function hasParentWithClass(element, searchedClassNames) {
  let refElement = element;
  while (refElement) {
    const elementsClassList = Array.from(refElement.classList);
    const hasSearchedClass = elementsClassList.some(className =>
      searchedClassNames.some(substring => className.includes(substring))
    );
    if (hasSearchedClass) {
      return true;
    }
    refElement = refElement.parentElement;
  }
  return false;
}

const filterFocusableElements = elements => {
  const forbiddenMobileClasses = ['cpMobileView', 'ContentPlayerFooter'];
  const isButtonInsideLink = element =>
    element.tagName === 'A' && element.children.length === 1 && element.children[0].tagName === 'BUTTON';

  const filteredElements = Array.from(elements)
    .filter(element => {
      let currentElement = element;
      while (currentElement) {
        if (hasParentWithClass(currentElement, forbiddenMobileClasses)) {
          return false;
        }

        if (currentElement.getAttribute('aria-hidden') === 'true') {
          return false;
        }

        currentElement = currentElement.parentElement;
      }
      return true;
    })
    .filter(element => !isButtonInsideLink(element));

  return filteredElements;
};

const getFocusableElements = element => element.querySelectorAll(FOCUSABLE_ELEMENTS);

function withFocusTrap(WrappedComponent, parentRef) {
  return function focusTrap({ isFocusTrapDisabled, ...props }) {
    const ref = useRef();

    useEffect(() => {
      if (!isFocusTrapDisabled) {
        const focusableElements = getFocusableElements(ref.current);
        const filteredFocusableElements = filterFocusableElements(focusableElements);

        const firstElement = filteredFocusableElements[0];
        const lastElement = filteredFocusableElements[filteredFocusableElements.length - 1];

        const handleKeyDown = event => {
          const isTab = event.key === 'Tab' || event.keyCode === 9;
          const isArrowLeft = event.key === 'ArrowLeft' || event.keyCode === 37;
          const isArrowUp = event.key === 'ArrowUp' || event.keyCode === 38;
          const isSpace = event.key === 'Space' || event.keyCode === 32;
          const isEnter = event.key === 'Enter' || event.keyCode === 13;

          if (isTab || isArrowLeft || isArrowUp) {
            if (event.shiftKey && document.activeElement === firstElement) {
              lastElement.focus();
              event.preventDefault();
            }

            if (!event.shiftKey && document.activeElement === lastElement) {
              firstElement.focus();
              event.preventDefault();
            }
          }

          if (!isSpace && !isEnter) {
            event.stopPropagation();
          }
        };

        ref.current.addEventListener('keydown', handleKeyDown);

        return () => {
          if (parentRef) {
            const parentFocusableElements = getFocusableElements(parentRef.current);
            const parentFilteredFocusableElements = filterFocusableElements(parentFocusableElements);
            const parentFirstElement = parentFilteredFocusableElements[0];
            parentFirstElement.focus();
          }

          ref.current.removeEventListener('keydown', handleKeyDown);
        };
      }

      return () => {};
    });

    return (
      <div id="hoc-test" ref={ref} aria-live="assertive">
        <WrappedComponent {...props} />
      </div>
    );
  };
}

export default withFocusTrap;
