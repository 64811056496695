import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PLATFORMS } from '@oup/shared-node-browser/constants';
import USER_ROLES, { userRoleFriendlyNames } from '../../globals/userRoles';
import content from '../../utils/cmsContent';
import styles from './BannerInvitation.scss';
import InformationPopupModal from '../InformationPopupModal/InformationPopupModal';
import getLearnMoreContent from '../../language/learnMoreContent.js';
import { getCurrentPlatform, getPlatformFromQueryString, isCesMode, isEltAssessmentMode } from '../../utils/platform';
import { getIntegrationPlatformKey, getPlatformAmStaffUrl, getPlatformAmStudentUrl } from '../../globals/envSettings';
// Constants
import bannerInvitationConstants from './BannerInvitationConstants';
// Components
import Invitation from './Invitation';
import InvitationActionLoading from '../InvitationNotificationHandler/InvitationActionLoading.js';
import InvitationActionResponse from '../InvitationNotificationHandler/InvitationActionResponse.js';
import InvitationActionReviewing from '../InvitationNotificationHandler/InvitationActionReviewing.js';
import destroyLocalSession from '../../utils/destroyLocalSession';

class BannerInvitation extends Component {
  constructor(props) {
    super(props);
    const { invites: invites1 } = this.props;
    const invites = invites1 || [];
    this.state = {
      page: 1,
      pages: invites.length,
      // Used to toggle displaying the information popup when clicking "Learn More"
      showPopup: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const invites = nextProps.invites || [];
    this.setState({
      page: 1,
      pages: invites.length
    });
  }

  onPrev = e => {
    e.preventDefault();
    const { page: page1 } = this.state;
    const page = Math.max(1, page1 - 1);
    this.setState({ page });
  };

  onNext = e => {
    e.preventDefault();
    const { pages, page: page1 } = this.state;
    const page = Math.min(pages, page1 + 1);
    this.setState({ page });
  };

  onSetPage = page => {
    this.setState({
      page
    });
  };

  onContinueFlow = (orgId, roleName) => {
    const { switchOrg, orgJoined, currentOrganisationId } = this.props;
    const organisationId = orgJoined ? orgId : currentOrganisationId;

    const isLearner = USER_ROLES.LEARNER === roleName;

    let redirectPlatform = '';
    let redirectToAmPlatform = false;

    if (orgJoined) {
      if (isLearner && isCesMode()) {
        redirectPlatform = PLATFORMS.OLB;
      }
      if (isEltAssessmentMode()) {
        redirectToAmPlatform = true;
        const platform = getIntegrationPlatformKey(getPlatformFromQueryString() || getCurrentPlatform());
        if (isLearner) {
          redirectPlatform = getPlatformAmStudentUrl(platform);
        } else {
          redirectPlatform = getPlatformAmStaffUrl(platform);
        }
        destroyLocalSession(redirectPlatform);
      }
    }

    if (!redirectToAmPlatform) switchOrg(organisationId, roleName, false, redirectPlatform);
  };

  renderBannerInvitation(
    invitationBannerContent,
    bannerHeading,
    inviteHeading,
    inviteHeadingExpired,
    inviteSubheadingExpired,
    inviteSentOn,
    currentInvite,
    learnMoreInvitationContent,
    contentList,
    pendingInvites,
    expiredInvites
  ) {
    const {
      onAccept,
      onDecline,
      onDismiss,
      invites,
      userId,
      formState,
      showReviewing,
      orgJoined,
      resetInvites
    } = this.props;
    const { page, pages, showPopup } = this.state;
    const { orgId } = currentInvite;
    const { SUBMITTING, CONFIRMATION, REVIEWING } = bannerInvitationConstants;

    switch (formState) {
      case SUBMITTING:
        return <InvitationActionLoading />;
      case CONFIRMATION:
        return (
          <InvitationActionResponse
            currentInvite={currentInvite}
            continueFlow={this.onContinueFlow}
            orgJoined={orgJoined}
          />
        );
      case REVIEWING:
        return (
          <InvitationActionReviewing
            onJoin={onAccept}
            onRefuse={onDecline}
            userId={userId}
            onDismissPage={resetInvites}
          />
        );
      default:
        return pages
          ? (pendingInvites || expiredInvites) && (
              <div>
                <Invitation
                  bannerHeading={bannerHeading}
                  inviteHeading={inviteHeading}
                  inviteHeadingExpired={inviteHeadingExpired}
                  inviteSubheadingExpired={inviteSubheadingExpired}
                  inviteSentOn={inviteSentOn}
                  currentInvite={currentInvite}
                  invites={invites}
                  invitationBannerContent={invitationBannerContent}
                  userId={userId}
                  orgId={orgId}
                  onAccept={onAccept}
                  onReviewing={() => showReviewing(orgId)}
                  onDismiss={onDismiss}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSetPage={this.onSetPage}
                  page={page}
                  pages={pages}
                />
                {/* Information Popup Modal */}
                {showPopup && (
                  <div>
                    <InformationPopupModal
                      negativeClick={() => {
                        this.setState({ showPopup: false });
                        document.getElementById('invitationLearnMoreButton').focus();
                      }}
                      firstCarouselButton={learnMoreInvitationContent.learn_more_content_final_page_button1_path}
                      firstCarouselButtonText={learnMoreInvitationContent.learn_more_content_final_page_button1_text}
                      secondCarouselButton={learnMoreInvitationContent.learn_more_content_final_page_button2_path}
                      secondCarouselButtonText={learnMoreInvitationContent.learn_more_content_final_page_button2_text}
                      displayAFinalPanel
                      finalPanelTitle={learnMoreInvitationContent.learn_more_content_final_page_title}
                      finalPanelSubtitle={learnMoreInvitationContent.learn_more_content_final_page_subtitle}
                      firstSuggestionButton={learnMoreInvitationContent.learn_more_content_final_page_button1_path}
                      firstSuggestionButtonText={learnMoreInvitationContent.learn_more_content_final_page_button1_text}
                      contentList={contentList}
                    />
                  </div>
                )}
              </div>
            )
          : null;
    }
  }

  render() {
    const { urlPrefix } = this.props;
    let { invites } = this.props;

    const invitationBannerContent = content.invitationBanner || {};
    const learnMoreInvitationContent = content.learnMoreInvitation || {};

    // Handy lookup to derive appropriate banner heading:
    const headingFor = {
      0: `${invitationBannerContent.heading_pendingInvitations_none}${' '}`,
      1: `${invitationBannerContent.heading_pendingInvitations_singular}${' '}`,
      multiple: `${invitationBannerContent.heading_pendingInvitations_plural}${' '}`
    };
    invites = invites || [];

    // Separate pending and expired invitation:
    const pendingInvites = invites.filter(invite => invite.status === 'PENDING' && !invite.expired);
    const expiredInvites = invites.filter(invite => invite.status === 'EXPIRED' || invite.expired);

    // Paging:
    const { page: currentPage } = this.state;
    const currentInvite = invites[currentPage - 1] || {};

    // Banner title:
    const bannerHeading = (headingFor[pendingInvites.length] || headingFor.multiple).replace(
      '{n}',
      pendingInvites.length
    );

    // Title of this individual invitation:
    const inviteHeading = invitationBannerContent.invitation_heading
      .replace('{orgName}', currentInvite.orgName)
      .replace('{orgRole}', userRoleFriendlyNames(currentInvite.roleName, true));

    const inviteHeadingExpired = invitationBannerContent.invitation_heading_expired
      .replace('{orgName}', currentInvite.orgName)
      .replace('{orgRole}', userRoleFriendlyNames(currentInvite.roleName, true));
    const inviteSubheadingExpired = invitationBannerContent.invitation_subHeading_expired;

    const inviteSentOn = invitationBannerContent.profile_invitation_summary_singular.replace(
      '{sentDate}',
      moment(currentInvite.sentDate).format('D MMMM YYYY')
    );

    const contentList = getLearnMoreContent(learnMoreInvitationContent);

    return (
      <div id="invitationBanner" className={urlPrefix ? '' : styles.bannerInvitation}>
        {this.renderBannerInvitation(
          invitationBannerContent,
          bannerHeading,
          inviteHeading,
          inviteHeadingExpired,
          inviteSubheadingExpired,
          inviteSentOn,
          currentInvite,
          learnMoreInvitationContent,
          contentList,
          pendingInvites,
          expiredInvites
        )}
      </div>
    );
  }
}

BannerInvitation.propTypes = {
  /** The user ID to get the invites for. Typically the current logged-in user. */
  userId: PropTypes.string,

  /** The list of pending invitations. */
  invites: PropTypes.arrayOf(
    PropTypes.shape({
      orgId: PropTypes.string.isRequired,
      orgName: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      roleName: PropTypes.string.isRequired,
      sentDate: PropTypes.string.isRequired,
      expiryDate: PropTypes.string.isRequired,
      expired: PropTypes.bool.isRequired
    })
  ),
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  switchOrg: PropTypes.func.isRequired,
  resetInvites: PropTypes.func,
  urlPrefix: PropTypes.string,
  orgJoined: PropTypes.bool,
  currentOrganisationId: PropTypes.string,
  formState: PropTypes.string,
  showReviewing: PropTypes.func
};

export default BannerInvitation;
