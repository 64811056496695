import pull from 'lodash.pull';
import React from 'react';

/**
 * Return a new component with local state handling for a supplied component
 * that receives `expandedItems` and yields an `onItemExpandToggle` action.
 *
 * @param {React.Component} Component
 *
 * @return {React.Component}
 */
export default function withExpandableItemState(Component) {
  return class WithExpandableItemState extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        expanded: []
      };
    }

    _handleItemExpandToggle = id => {
      this.setState(({ expanded }) => ({
        expanded: expanded.includes(id) ? pull(expanded, id) : expanded.concat(id)
      }));
    };

    render() {
      const { expanded } = this.state;
      return <Component {...this.props} expandedItems={expanded} onItemExpandToggle={this._handleItemExpandToggle} />;
    }
  };
}
