import * as t from '../actionTypes';

const initialState = {
  formData: {},
  errors: {}
};

function invoiceReport(state = initialState, { type, payload }) {
  switch (type) {
    case t.CREATE_INVOICE_REPORT_INPUT:
      return {
        ...state,
        formData: {
          ...state.formData,
          [payload.field]: payload.value || ''
        }
      };
    case t.CREATE_INVOICE_REPORT_REQUEST:
      return {
        ...state,
        toDate: payload.endDate,
        fromDate: payload.startDate
      };
    case t.CREATE_INVOICE_REPORT_FAILURE:
      return {
        ...state,
        errors: payload
      };
    case t.CREATE_INVOICE_REPORT_SUCCESS:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload
        },
        errors: {}
      };
    case t.CREATE_INVOICE_REPORT_VALIDATE:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload
        },
        errors: {}
      };
    case t.CREATE_INVOICE_REPORT_VALIDATED:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload
        }
      };
    default:
      return state;
  }
}
export default invoiceReport;
