import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Action imports
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import Button from '@oup/shared-front-end/src/components/Button';
import {
  formStates,
  setFirstName,
  setLastName,
  setUserName,
  setPassword,
  setPasswordConfirmation,
  setTermsAndConditions,
  setMarketPref,
  setUnderAgeAccepted,
  validateFirstName,
  validateLastName,
  validateUserName,
  validatePassword,
  validatePasswordConfirmation,
  validateTermsAndConditions,
  submitForm,
  signIn,
  sendEmailInvitation
} from '../../redux/reducers/registration/registration.reducer.js';

// Component imports
import Checkbox from '../../components/Checkbox/Checkbox.js';
import TextInput from '../../components/TextInput/TextInput.js';
import UserAgreement from '../../components/UserAgreement/UserAgreement.js';
import Validation from '../../components/Validation/Validation.js';
import MarketingPreferencesConsentForm from '../../components/MarketingPreferencesConsentForm/MarketingPreferencesConsentForm';

import { isCesMode, isValidPlatformMarketingPref } from '../../utils/platform';
import cmsContent from '../../utils/cmsContent.js';
import { featureIsEnabled } from '../../globals/envSettings';

import { USER_REGISTRATION_STATUS } from '../../globals/appConstants.js';

// Simple lookups to make code more readable: (Eg: isLegacyUser[formState] --> true/falsey)
const isLegacyUser = {
  [formStates.INPUTTING_PARTIAL_VIA_FINALISE_LEGACY]: true
};
const isExistingUser = {
  [formStates.INPUTTING_PARTIAL_VIA_FINALISE_EXISTING]: true
};

function RegisterFormInput({
  // This is used to adapt the username/email field to FULL/PARTIAL registration:
  formState,

  // Field values
  firstNameValue,
  lastNameValue,
  userNameValue,
  passwordValue,
  termsAndConditionsValue,

  // Field "is valid" flags are used to show a nice green tick net to each field:
  isFirstNameValid,
  isLastNameValid,
  isUserNameValid,
  isPasswordValid,
  isTermsAndConditionsValid,

  // Field errors:
  firstNameError,
  lastNameError,
  userNameError,
  passwordError,
  termsAndConditionsError,

  registrationStatus,

  // Field onChanges
  setFirstNameAction,
  setLastNameAction,
  setUserNameAction,
  setPasswordAction,
  setTermsAndConditionsAction,
  setUnderAgeAcceptedAction,
  setMarketPrefAction,

  // To validate on onBlur
  validateFirstNameAction,
  validateLastNameAction,
  validateUserNameAction,
  validatePasswordAction,

  // To validate on onChange (Checkboxes)
  validateTermsAndConditionsAction,

  submitFormAction,
  sendEmailInvitationAction,
  signInAction,
  platform,
  returnUrl
}) {
  // Decide whether to label userName field as email instead:
  const userNameOrEmail = formState === formStates.INPUTTING_NEW ? 'userName' : 'email';

  const CMS = cmsContent.registrationPage || {};

  // Decide whether all mandatory fields have been filled correctly:
  let isFormValid;
  switch (formState) {
    case formStates.INPUTTING_PARTIAL_VIA_FINALISE_EXISTING:
      isFormValid = isTermsAndConditionsValid;
      break;

    case formStates.INPUTTING_PARTIAL_VIA_FINALISE_LEGACY:
      isFormValid = isUserNameValid && isTermsAndConditionsValid;
      break;

    case formStates.INPUTTING_PARTIAL_VIA_TOKEN:
      isFormValid = isLastNameValid && isFirstNameValid && isPasswordValid && isTermsAndConditionsValid;
      break;
    // else:
    // case INPUTTING_NEW:

    default:
      isFormValid =
        isLastNameValid && isFirstNameValid && isUserNameValid && isPasswordValid && isTermsAndConditionsValid;
  }

  let userNameValidationMessage = '';
  let userNameValidationButtonText = '';
  let userNameValidationButtonLink = '';
  let userNameValidationButtonOnClick = null;

  switch (registrationStatus) {
    case USER_REGISTRATION_STATUS.REGISTRATION_PENDING:
      userNameValidationMessage = CMS.username_exists_partial_register_text;
      userNameValidationButtonText = CMS.button_send_mail_text;
      userNameValidationButtonLink = '';
      userNameValidationButtonOnClick = sendEmailInvitationAction;
      break;

    case USER_REGISTRATION_STATUS.REGISTRATION_COMPLETE:
    case USER_REGISTRATION_STATUS.REGISTRATION_EXISTS:
      userNameValidationMessage = CMS.username_exists_sign_in_text;
      userNameValidationButtonText = CMS.button_sign_in_text;
      userNameValidationButtonLink = '';
      userNameValidationButtonOnClick = () => {
        // Handle code redemption to autopopulate when routed from redeem page and password reset
        if (isCesMode() && localStorage.getItem(APP_CONSTANTS.REDEEM_CODE)) {
          sessionStorage.setItem('goto-path', '/myProfile/learningMaterial/addCode');
        }
        signInAction();
      };
      break;
    default:
      userNameValidationMessage = userNameError;
      userNameValidationButtonText = '';
      userNameValidationButtonLink = '';
      break;
  }

  return (
    <form>
      {!isLegacyUser[formState] && !isExistingUser[formState] ? (
        <div>
          <Validation
            forId={TextInput.defaultsFor().firstName.id}
            isValid={isFirstNameValid}
            isError={!!firstNameError}
            message={firstNameError}
          >
            <TextInput
              {...TextInput.defaultsFor().firstName}
              value={firstNameValue}
              disableAutoComplete
              onChange={setFirstNameAction}
              onBlur={validateFirstNameAction}
            />
          </Validation>

          <Validation
            forId={TextInput.defaultsFor().lastName.id}
            isValid={isLastNameValid}
            isError={!!lastNameError}
            message={lastNameError}
          >
            <TextInput
              {...TextInput.defaultsFor().lastName}
              value={lastNameValue}
              disableAutoComplete
              onChange={setLastNameAction}
              onBlur={validateLastNameAction}
            />
          </Validation>
        </div>
      ) : null}

      {/* This field displays as either userName or email depending on full or partial registration: */}
      {!isExistingUser[formState] ? (
        <div>
          {formState === formStates.INPUTTING_PARTIAL_VIA_TOKEN ? (
            <Validation forId={TextInput.defaultsFor().userName.id} isValid>
              <TextInput
                {...TextInput.defaultsFor()[userNameOrEmail]}
                id={TextInput.defaultsFor().userName.id}
                value={userNameValue}
                disabled
              />
            </Validation>
          ) : (
            <Validation
              forId={TextInput.defaultsFor().userName.id}
              isValid={isUserNameValid}
              isError={!!userNameError}
              message={userNameValidationMessage}
              buttonText={userNameValidationButtonText}
              buttonLink={userNameValidationButtonLink}
              buttonOnClick={userNameValidationButtonOnClick}
            >
              <TextInput
                {...TextInput.defaultsFor()[userNameOrEmail]}
                id={TextInput.defaultsFor().userName.id}
                value={userNameValue}
                onChange={setUserNameAction}
                onBlur={userNameError ? null : validateUserNameAction}
              />
            </Validation>
          )}

          {!isLegacyUser[formState] && formState !== formStates.INPUTTING_PARTIAL_VIA_TOKEN ? (
            <p
              className="pad-top1"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: TextInput.defaultsFor()[userNameOrEmail].tip }}
            />
          ) : null}
        </div>
      ) : null}

      {!isLegacyUser[formState] && !isExistingUser[formState] ? (
        <div>
          <Validation
            forId={TextInput.defaultsFor().password.id}
            isValid={isPasswordValid}
            isError={!!passwordError}
            message={passwordError}
          >
            <TextInput
              {...TextInput.defaultsFor().password}
              value={passwordValue}
              onChange={setPasswordAction}
              onBlur={validatePasswordAction}
            />
          </Validation>

          <p className="pad-top1">{TextInput.defaultsFor().password.tip}</p>
        </div>
      ) : null}

      {featureIsEnabled('enable-marketing-preferences') &&
      isValidPlatformMarketingPref() &&
      (!returnUrl || (returnUrl && !returnUrl.includes('oxfordreadingbuddy'))) ? (
        <>
          <div className="pad-top2">
            <MarketingPreferencesConsentForm
              platform={platform}
              setMarketTermsAndConditions={value => {
                setTermsAndConditionsAction(value);
              }}
              setUnderAgeAccepted={value => {
                setUnderAgeAcceptedAction(value);
              }}
              setMarketPreference={value => {
                setMarketPrefAction(value);
              }}
            />
          </div>
          <div className="pad-top2">
            <UserAgreement personalInformation />
          </div>
        </>
      ) : (
        <>
          <h4 className="pad-top2x">
            <em>{TextInput.defaultsFor().termsAndConditions.groupLabel}</em>
          </h4>

          <Validation
            forId={TextInput.defaultsFor().termsAndConditions.id}
            isError={!!termsAndConditionsError}
            message={termsAndConditionsError}
            noLabelAboveField
          >
            <Checkbox
              {...TextInput.defaultsFor().termsAndConditions}
              value={termsAndConditionsValue}
              required
              onChange={e => {
                setTermsAndConditionsAction(e);
                validateTermsAndConditionsAction(e);
              }}
            />
          </Validation>

          <div className="pad-top2">
            <UserAgreement dataUsage privacyPolicy />
          </div>
        </>
      )}
      <div className="pad-top2">
        <input type="hidden" value={platform} name="platformCode" />

        <Button
          id="registration-submit"
          variant="filled"
          text={
            isLegacyUser[formState] || isExistingUser[formState]
              ? CMS.button_registration_submit_update_text
              : CMS.button_registration_submit_create_text
          }
          onClick={submitFormAction}
          disabled={!isFormValid}
        />
      </div>
    </form>
  );
}

RegisterFormInput.propTypes = {
  formState: PropTypes.oneOf(Object.values(formStates)).isRequired,

  // Field values
  firstNameValue: PropTypes.string.isRequired,
  lastNameValue: PropTypes.string.isRequired,
  userNameValue: PropTypes.string.isRequired,
  passwordValue: PropTypes.string.isRequired,
  termsAndConditionsValue: PropTypes.bool.isRequired,

  // Field "is valid" flags:
  isFirstNameValid: PropTypes.bool.isRequired,
  isLastNameValid: PropTypes.bool.isRequired,
  isUserNameValid: PropTypes.bool.isRequired,
  isPasswordValid: PropTypes.bool.isRequired,
  isPasswordConfirmationValid: PropTypes.bool.isRequired,
  isTermsAndConditionsValid: PropTypes.bool.isRequired,

  // Field errors
  firstNameError: PropTypes.string,
  lastNameError: PropTypes.string,
  userNameError: PropTypes.string,
  passwordError: PropTypes.string,
  termsAndConditionsError: PropTypes.string,

  registrationStatus: PropTypes.string,

  // Field onChanges
  setFirstNameAction: PropTypes.func.isRequired,
  setLastNameAction: PropTypes.func.isRequired,
  setUserNameAction: PropTypes.func.isRequired,
  setPasswordAction: PropTypes.func.isRequired,
  setPasswordConfirmationAction: PropTypes.func.isRequired,
  setTermsAndConditionsAction: PropTypes.func.isRequired,
  setUnderAgeAcceptedAction: PropTypes.func.isRequired,
  setMarketPrefAction: PropTypes.func,
  submitFormAction: PropTypes.func.isRequired,
  sendEmailInvitationAction: PropTypes.func.isRequired,
  signInAction: PropTypes.func.isRequired,

  // To validate on onBlur
  validateFirstNameAction: PropTypes.func.isRequired,
  validateLastNameAction: PropTypes.func.isRequired,
  validateUserNameAction: PropTypes.func.isRequired,
  validatePasswordAction: PropTypes.func.isRequired,
  validatePasswordConfirmationAction: PropTypes.func.isRequired,
  validateTermsAndConditionsAction: PropTypes.func.isRequired,
  platform: PropTypes.string,
  returnUrl: PropTypes.string
};

export default connect(
  state => ({
    // Connect values
    firstNameValue: state.registration.firstNameValue,
    lastNameValue: state.registration.lastNameValue,
    userNameValue: state.registration.userNameValue,
    passwordValue: state.registration.passwordValue,
    termsAndConditionsValue: state.registration.termsAndConditionsValue,
    underAgeAcceptedValue: state.registration.underAgeAcceptedValue,
    marketingEmailAcceptedValue: state.registration.marketingEmailAcceptedValue,

    isFirstNameValid: state.registration.isFirstNameValid,
    isLastNameValid: state.registration.isLastNameValid,
    isUserNameValid: state.registration.isUserNameValid,
    isPasswordValid: state.registration.isPasswordValid,
    isPasswordConfirmationValid: state.registration.isPasswordConfirmationValid,
    isTermsAndConditionsValid: state.registration.isTermsAndConditionsValid,

    // Connect errors
    firstNameError: state.registration.firstNameError,
    lastNameError: state.registration.lastNameError,
    userNameError: state.registration.userNameError,
    passwordError: state.registration.passwordError,
    termsAndConditionsError: state.registration.termsAndConditionsError,

    registrationStatus: state.registration.registrationStatus
  }),
  {
    // Connect onChange actions
    setFirstNameAction: setFirstName,
    setLastNameAction: setLastName,
    setUserNameAction: setUserName,
    setPasswordAction: setPassword,
    setPasswordConfirmationAction: setPasswordConfirmation,
    setTermsAndConditionsAction: setTermsAndConditions,
    setMarketPrefAction: setMarketPref,
    setUnderAgeAcceptedAction: setUnderAgeAccepted,

    // Connect onBlur actions
    validateFirstNameAction: validateFirstName,
    validateLastNameAction: validateLastName,
    validateUserNameAction: validateUserName,
    validatePasswordAction: validatePassword,
    validatePasswordConfirmationAction: validatePasswordConfirmation,
    validateTermsAndConditionsAction: validateTermsAndConditions,

    submitFormAction: submitForm,
    sendEmailInvitationAction: sendEmailInvitation,
    signInAction: signIn
  }
)(RegisterFormInput);
