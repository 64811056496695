import pick from 'lodash.pick';
import * as t from '../actionTypes';

const initialState = {
  status: '',
  days: {},
  quizzes: {},
  skills: [],
  submitting: false,
  success: false,
  error: false
};

function studentProgressReport(state = initialState, { type, payload }) {
  switch (type) {
    case t.STUDENT_PROGRESS_REPORT_REQUEST:
      return {
        ...state,
        submitting: true,
        success: false,
        error: false
      };
    case t.STUDENT_PROGRESS_REPORT_FAILURE:
      return {
        ...state,
        submitting: false,
        success: false,
        error: true
      };
    case t.STUDENT_PROGRESS_REPORT_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: false,
        success: true,
        status: payload.status,
        ...pick(payload, ['days', 'quizzes', 'skills'])
      };
    default:
      return state;
  }
}

export default studentProgressReport;
