import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../redux/actions';
import { getManagedUserLoginHasErrors } from '../redux/selectors';
import cookies from '../utils/cookies';
import ManagedUserLoginView from '../views/ManagedUserLoginView';
import ManagedUserLoginHelpView from '../views/ManagedUserLoginHelpView';

class ManagedUserLoginPage extends Component {
  constructor() {
    super();
    this.state = {
      isHelpActive: false
    };
  }

  _toggleHelpView = () => {
    const { isHelpActive } = this.state;
    this.setState({
      isHelpActive: !isHelpActive
    });
  };

  render() {
    const {
      orgUniqueId = cookies.get('orgUniqueId'),
      submitting,
      success,
      failure = false,
      staticFailure = false,
      locked,
      staticLocked = false,
      errors,
      hasErrors,
      validate,
      login,
      signInUrl,
      serverErrorCode,
      serverErrorCodes,
      platform
    } = this.props;
    const { isHelpActive } = this.state;

    if (serverErrorCode) {
      switch (serverErrorCode) {
        case serverErrorCodes.SERVER_ERROR_SCHOOL_CODE:
          errors.orgUniqueId = true;
          break;
        case serverErrorCodes.SERVER_ERROR_USERNAME:
          errors.username = true;
          break;
        case serverErrorCodes.SERVER_ERROR_PASSWORD:
          errors.password = true;
          break;
        default:
          errors.serverError = true;
          break;
      }
    }

    return isHelpActive ? (
      <ManagedUserLoginHelpView onNavigateSignIn={this._toggleHelpView} />
    ) : (
      <ManagedUserLoginView
        orgUniqueId={orgUniqueId}
        submitting={submitting}
        success={success}
        failure={failure || staticFailure}
        locked={locked || staticLocked}
        errors={errors}
        hasErrors={hasErrors}
        validate={validate}
        onNavigateHelp={this._toggleHelpView}
        signInUrl={signInUrl}
        onSubmit={login}
        platform={platform}
      />
    );
  }
}

ManagedUserLoginPage.propTypes = {
  orgUniqueId: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  staticFailure: PropTypes.bool,
  locked: PropTypes.bool.isRequired,
  staticLocked: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  signInUrl: PropTypes.string.isRequired,
  serverErrorCode: PropTypes.number,
  serverErrorCodes: PropTypes.object,
  platform: PropTypes.string
};

export default connect(
  state => ({
    ...pick(state.managedUserLogin, ['submitting', 'success', 'failure', 'locked', 'errors']),
    hasErrors: getManagedUserLoginHasErrors(state)
  }),
  dispatch => ({
    validate: input => {
      dispatch(actions.validateManagedUserLoginInput(input));
    },
    login: credentials => {
      dispatch(actions.loginManagedUserRequest(credentials));
    }
  })
)(ManagedUserLoginPage);
