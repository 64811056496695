import { put } from 'redux-saga/effects';
import upperFirst from 'lodash.upperfirst';
import {
  csvProductStructureUploadFileParseCompleted as setSuccess,
  csvProductStructureUploadFileParseFailure as setFailure
} from '../../../../actions/csvProductStructureUpload';
import { StatusEnum } from './csvProductStructureUpload';
import sanitizeCSV from '../../../../../utils/sanitizeCSV';

export const ErrorTypes = {
  UNRECOGNISED_DATA: 'UNRECOGNISED_DATA',
  MISSING_COLUMNS: 'MISSING_COLUMNS',
  TOO_MANY_ROWS: 'TOO_MANY_ROWS'
};

/**
 * The maximum number of data rows to consider.
 *
 * @type {number}
 */
export const MAX_DATA_ROWS = 60000;

function* readSourceFromFile(file) {
  const reader = new FileReader();
  return yield new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error({ error: 'Error while reading the file' }));
    };
    reader.readAsText(file);
    reader.onload = event => resolve(event.target.result);
  });
}

function* parseImportProductStructureSource({ source }) {
  try {
    const raw = source instanceof File ? yield readSourceFromFile(source) : source;
    const processedFileContents = raw.replace(/\\n/g, '\n');
    const data = sanitizeCSV(processedFileContents, upperFirst, null);
    // total rows of bids
    const totalBids = data.split('\n').splice(1);

    if (totalBids.length > MAX_DATA_ROWS) {
      yield put(setFailure(ErrorTypes.TOO_MANY_ROWS));
      return;
    }

    yield put(setSuccess({ status: StatusEnum.Success, fileData: totalBids }));
  } catch (e) {
    yield put(setFailure(ErrorTypes.UNRECOGNISED_DATA));
  }
}

export default parseImportProductStructureSource;
