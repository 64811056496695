import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import pick from 'lodash.pick';
import { Helmet } from 'react-helmet';
import qs from 'query-string';

import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import colors from '../../../globals/colors';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { bgClass } from '../../../utils/colorClassNames';
import cookies from '../../../utils/cookies';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import TabsAdvanced from '../../../components/TabsAdvanced/TabsAdvanced';
import actions from '../../../redux/actions';
import PanelContent from './PanelContent';
import PopoutPanelIconHeading, { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import TabCaption from './TabCaption';
import MultiSchoolReportPage from './MATReport/MultiSchoolReportPage';
import { featureIsEnabled } from '../../../globals/envSettings';
import ClassReport from '../../../components/ClassReport/ClassReport';
import StudentReport from '../../../components/StudentReport/StudentReport';
import SchoolReport from '../../../components/SchoolReport/SchoolReport';
import ClassOrgLoader from '../../../components/SkeletonLoader/ClassOrgLoader';
import OrbClassReportLoader from '../../../components/SkeletonLoader/Orb/OrbClassReportLoader';
import LTIOpener from '../../../components/LTIOpener/LTIOpener';
import styles from './OrbClassReportPage.scss';
import { storeSavedSettings } from '../../../redux/reducers/savedSettings';
import mapObject from '../../../utils/object/mapObject';
import { orgRoles } from '@oup/shared-node-browser/org';
import { userRoles } from '@oup/shared-node-browser/user';

class OrbClassReportPage extends Component {
  constructor(props) {
    super(props);
    this.receiveMessage = this.receiveMessage.bind(this);
    this.state = {
      revealMore: {
        school: false,
        classroom: false,
        student: false
      },
      classroom: props.classroomId || cookies.get(`${props.orgId}-classroom`),
      student: cookies.get(`${props.orgId}-student`),
      // if these values are defined in URL, overrides cookie settings
      ...(props.tabName && props.objectId ? { [props.tabName]: props.objectId } : {})
    };
  }

  componentDidMount() {
    const { getOrgDetails, orgId, loadClasses } = this.props;
    if (this._isEnvironmentConfigPresent()) {
      loadClasses(orgId);
    } else {
      getOrgDetails(orgId);
    }
    window.addEventListener('message', this.receiveMessage);
  }

  componentDidUpdate(previousProps, previousState) {
    const { isLoaded, objectId, tabName, modifyStudent, openedContent } = this.props;
    const { classroom } = this.state;
    const { location } = this.props;

    if (objectId !== previousProps.objectId && objectId) {
      this.setState({ [tabName]: objectId });
    }

    if (
      !classroom ||
      classroom === 'undefined' ||
      (!previousProps.isLoaded && isLoaded) ||
      classroom !== previousState.classroom
    ) {
      this._loadClassroomReport();
    }

    if (modifyStudent.success !== previousProps.modifyStudent.success && modifyStudent.success) {
      this._refreshClassReport();
    }

    if (openedContent && !openedContent.downloadable) {
      if (!document.body.classList.contains('content-open')) document.body.classList.add('content-open');
    } else {
      document.body.classList.remove('content-open');
    }

    if (location.pathname !== previousProps.location.pathname) {
      this.setState({
        revealMore: {
          school: false,
          classroom: false,
          student: false
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  }

  _onItemClick = (tab, itemId, closePanel = false) => () => {
    const {
      history,
      location,
      orgId: propOrgId,
      basepath: basepathFromState,
      getOrgDetails,
      loadClasses,
      orgsLoaded,
      primaryOrgs
    } = this.props;
    const { classroom } = this.state;

    if (closePanel) {
      this._toggleMore(tab)();
    }

    const orgId = tab === 'school' ? primaryOrgs[itemId].id : propOrgId;

    // orb iframe initially point to / path before rerouting to report page
    cookies.set(`${orgId}-${tab}`, itemId, { path: '/' });

    const basepath = basepathFromState.split(/classroom|school/)[0].replace(/\/$/, '');

    if (tab === 'classroom') {
      this.setState({ classroom: itemId, student: null });
      history.replace(`${basepath}/${orgId}/classroom/${itemId}${location.search}`);
    } else if (tab === 'student') {
      this.setState({ student: itemId });
      history.replace(`${basepath}/${orgId}/classroom/${classroom}/student/${itemId}${location.search}`);
    } else if (featureIsEnabled('mat-report-change-for-reducers') && tab === 'school') {
      history.replace(`${basepath}/${orgId}/school${location.search}`);
      this.setState({ classroom: null, student: null });
      if (!orgsLoaded.includes(orgId)) {
        getOrgDetails(orgId);
        loadClasses(orgId);
      }
    }
  };

  _toggleMore = tabName => () => {
    const { revealMore } = this.state;
    this.setState({
      revealMore: {
        ...revealMore,
        [tabName]: !revealMore[tabName]
      }
    });
  };

  _refreshClassReport = () => {
    const { classroom } = this.state;
    const {
      orgId,
      orgDetails: { academicYear, locationId },
      getClassReport,
      storeSavedSettingsAction,
      startDate,
      endDate
    } = this.props;
    getClassReport(orgId, classroom, locationId, academicYear, startDate.toISOString(), endDate.toISOString());
    if (featureIsEnabled('orb-class-report-overview')) {
      storeSavedSettingsAction({ orbClassReportTab: 1 });
    }
    if (featureIsEnabled('orb-class-report-overview-ui-without-avallain-data')) {
      storeSavedSettingsAction({ orbClassReportTab: 0 });
    }
  };

  _onNavigateToOrg = () => {
    const { onNavigateToOrg, orgId } = this.props;
    onNavigateToOrg(orgId);
  };

  _getStudents = () => get(this, ['props', 'results', 'group', 'students']) || [];

  _getDials = classroomId => {
    const {
      orgId,
      getDials,
      orgDetails: { academicYear, locationId }
    } = this.props;
    getDials(orgId, classroomId, locationId, academicYear);
  };

  _getSchoolDials = locationId => {
    const { orgId, getSchoolDials } = this.props;
    getSchoolDials(orgId, locationId);
  };

  _setTabCookie(tabName) {
    const { orgId } = this.props;
    cookies.set(`${orgId}-tab`, tabName, { path: '/' });
  }

  _loadClassroomReport() {
    const {
      getClassReport,
      orgId,
      orgDetails: { classrooms, academicYear, locationId },
      startDate,
      endDate
    } = this.props;
    const { classroom } = this.state;
    let classId = classroom;
    if (isEmpty(classrooms)) {
      return;
    }

    const defaultClassroomId = classrooms[0].id;

    if (!classroom && !defaultClassroomId) {
      return;
    }

    if (!classroom || classroom === 'undefined') {
      this.setState({ classroom: defaultClassroomId });
      return;
    }

    if (classroom && !classrooms?.find(({ id }) => id === classroom)) {
      this.setState({ classroom: defaultClassroomId });
      classId = defaultClassroomId;
    }
    if (locationId && academicYear && classId) {
      if(startDate && endDate) {
        getClassReport(orgId, classId, locationId, academicYear, startDate.toISOString(), endDate.toISOString());
      } else {
        getClassReport(orgId, classId, locationId, academicYear);
      }
    }
  }

  _isEnvironmentConfigPresent() {
    const {
      orgDetails: { academicYear, locationId, curriculumType }
    } = this.props;
    return !!academicYear && !!locationId && !!curriculumType;
  }

  _displayClassReport(classrooms, classroom, currentClassroom, locationId, orbClassReportPage) {
    const { classroom: classroomFromState } = this.state;
    const {
      orgDetails,
      results,
      dials,
      modifyStudent,
      createGetLevelPanel,
      assignLevel,
      levelUp,
      levelDown,
      resetLevel,
      delayLevelChange,
      orbClassReportTab
    } = this.props;

    let classDisplay;

    if (
      orgDetails.loading === true ||
      modifyStudent.loading === true ||
      !results ||
      results?.loading === true ||
      results?.loading === undefined
    ) {
      classDisplay = <OrbClassReportLoader />;
    } else {
      classDisplay =
        classrooms?.length === 0 || !locationId ? (
          <PopoutPanelIconHeading type={types[types.WARNING]}>
            {orbClassReportPage.class_report_warning}
          </PopoutPanelIconHeading>
        ) : (
          <div style={{ position: 'relative', paddingTop: '30px' }}>
            <ClassReport
              locationId={locationId}
              org={orgDetails}
              classroom={{ ...(currentClassroom || {}), id: classroom }}
              dialsData={dials[classroomFromState]}
              results={results}
              getLevelPanel={createGetLevelPanel}
              assignLevel={assignLevel}
              levelUp={levelUp}
              levelDown={levelDown}
              resetLevel={resetLevel}
              delayLevelChange={delayLevelChange}
              onNavigateToOrg={this._onNavigateToOrg}
              completeLoad={modifyStudent.loading || results.loading || results.loading === undefined}
              currentTab={orbClassReportTab}
              refreshClassReport={this._refreshClassReport}
              showClassReportRefreshBtn={modifyStudent.failure}
            />
          </div>
        );
    }
    return classDisplay;
  }

  receiveMessage({ data: { actionCode } }) {
    if (actionCode === 'BOOK_CLOSED') {
      const { closeContent } = this.props;
      closeContent();
    }
  }

  tabsItems() {
    const {
      orgId,
      orgDetails,
      tabName,
      dials,
      attainmentReport,
      progressReport,
      engagementReport,
      progressItems,
      getStudentReport,
      schoolDials,
      localizedContent: { orbClassReportPage },
      openQuiz,
      primaryOrgs,
      stateOrgs,
      shouldShowMATReport,
      orbClassReportTab
    } = this.props;
    const { revealMore, classroom, student: studentFromState } = this.state;
    const { classrooms, locationId, failure } = orgDetails;

    const sortedStudents = this._getStudents().sort((a, b) => a.name.localeCompare(b.name));
    const dialsData = dials[classroom];
    const student = dialsData && dialsData.status !== 'error' ? studentFromState || get(sortedStudents[0], 'id') : null;
    const currentClassroom = classrooms?.find(({ id }) => id === classroom);

    const matReportItem = {
      action: this._setTabCookie('multi-school-report'),
      urlName: `${orgId}/multi-school-report`,
      tabText: '',
      tabTextCaption: <TabCaption>{orbClassReportPage.mat_report}</TabCaption>,
      panelContent: (
        <PanelContent tabName="mat">
          <MultiSchoolReportPage />
        </PanelContent>
      )
    };

    const _schoolReportItemWithMAT = {
      action: this._setTabCookie('school'),
      urlName: `${orgId}/school`,
      selected: orgId,
      tabText: orbClassReportPage.school_report,
      tabTextCaption: (
        <TabCaption active={revealMore.school} onButtonClick={this._toggleMore('school')}>
          {stateOrgs[orgId] ? stateOrgs[orgId].name : 'Select'}
        </TabCaption>
      ),
      glyphIcon: GLYPHS.ICON_DASHBOARD,
      glyphColor: colors.CLASSROOM,
      panelContent: (
        <PanelContent
          tabName="school"
          revealMore={revealMore.school}
          searchResultClick={item => this._onItemClick('school', item.id, true)()}
          listData={mapObject(primaryOrgs, (key, value) => ({
            label: value.name,
            action: this._onItemClick('school', key, true)
          }))}
        >
          <SchoolReport
            className="gin-top2"
            orgId={orgId}
            schoolDials={schoolDials}
            currentTab={orbClassReportTab}
            classrooms={classrooms}
            dials={dials}
            getDials={this._getDials}
            getSchoolDials={this._getSchoolDials}
            locationId={locationId}
            failure={failure}
          />
        </PanelContent>
      )
    };

    const _schoolReportItemWithoutMAT = {
      action: this._setTabCookie('school'),
      urlName: `${orgId}/school`,
      selected: orgId,
      tabText: orbClassReportPage.school_report,
      tabTextCaption: <TabCaption>{stateOrgs[orgId] ? stateOrgs[orgId].name : 'Select'}</TabCaption>,
      glyphIcon: GLYPHS.ICON_DASHBOARD,
      glyphColor: colors.CLASSROOM,
      panelContent: (
        <PanelContent tabName="school">
          <SchoolReport
            className="gin-top2"
            orgId={orgId}
            schoolDials={schoolDials}
            classrooms={classrooms}
            dials={dials}
            getDials={this._getDials}
            getSchoolDials={this._getSchoolDials}
            locationId={locationId}
            currentTab={orbClassReportTab}
            failure={failure}
          />
        </PanelContent>
      )
    };

    const schoolReportItem = shouldShowMATReport ? _schoolReportItemWithMAT : _schoolReportItemWithoutMAT;

    const classReportItem = {
      action: this._setTabCookie('classroom'),
      urlName: `${orgId}/classroom/${classroom}`,
      selected: classroom,
      tabText: orbClassReportPage.class_report,
      tabTextCaption: (
        <TabCaption active={revealMore.classroom} onButtonClick={this._toggleMore('classroom')}>
          {currentClassroom ? currentClassroom.name : 'Select'}
        </TabCaption>
      ),
      glyphIcon: GLYPHS.ICON_CLASS,
      glyphColor: colors.CLASSROOM,
      panelContent: (
        <PanelContent
          tabName="classroom"
          revealMore={revealMore.classroom}
          searchResultClick={item => this._onItemClick('classroom', item.id, true)()}
          listData={classrooms?.map(({ id, name }) => ({
            label: name,
            action: this._onItemClick('classroom', id, true)
          }))}
        >
          {this._displayClassReport(classrooms, classroom, currentClassroom, locationId, orbClassReportPage)}
        </PanelContent>
      )
    };
    const studentReportItem = {
      action: this._setTabCookie('student'),
      urlName: `${orgId}/classroom/${classroom}/student/${student}`,
      selected: student,
      tabText: orbClassReportPage.student_report,
      tabTextCaption: (
        <TabCaption active={revealMore.student} onButtonClick={this._toggleMore('student')}>
          {(this._getStudents().find(({ id }) => id === student) || {}).name || 'Select'}
        </TabCaption>
      ),
      glyphIcon: GLYPHS.ICON_USER,
      glyphColor: colors.LEARNER,
      panelContent: (
        <PanelContent
          tabName="student"
          revealMore={revealMore.student}
          listData={sortedStudents.map(({ id, name }) => ({
            label: name,
            action: this._onItemClick('student', id, true)
          }))}
        >
          <StudentReport
            studentId={student}
            studentName={(this._getStudents().find(({ id }) => id === student) || {}).name}
            orgId={orgId}
            locationId={locationId}
            getStudentReport={getStudentReport}
            openQuiz={openQuiz}
            attainmentReport={attainmentReport}
            progressReport={progressReport}
            engagementReport={engagementReport}
            progressItems={progressItems}
          />
        </PanelContent>
      )
    };

    return shouldShowMATReport
      ? [matReportItem, schoolReportItem, classReportItem, studentReportItem]
      : [schoolReportItem, classReportItem, studentReportItem];
  }

  render() {
    const {
      orgDetails,
      basepath,
      pathname,
      isLoaded,
      localizedContent: { orbClassReportPage },
      openedContent,
      shouldShowMATReport
    } = this.props;
    const { classrooms } = orgDetails;

    if (!isLoaded && orgDetails.loading === true) {
      return (
        <div className={classnames('gin-bot3', 'orb-class-report')}>
          <Helmet title={orbClassReportPage.pageTitle} titleTemplate="%s" />
          <div className={classnames('pad-top3')} style={{ backgroundColor: colors.COLOR_PANEL_BACKGROUND_LIGHT }} />
          <ClassOrgLoader
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED2}
            backgroundColor={colors.COLOR_WHITE}
            showUserDetails={false}
            tabHeight="80"
          />
          <PanelContent>
            <OrbClassReportLoader />
          </PanelContent>
        </div>
      );
    }

    return (
      <div className={classnames('gin-bot3', 'orb-class-report')}>
        <Helmet title={orbClassReportPage.pageTitle} titleTemplate="%s" />

        {openedContent && (
          <div className={openedContent.downloadable ? styles.downloadableContent : styles.openedContent}>
            <LTIOpener
              content={{
                external_id: openedContent.id,
                platform: 'unity',
                openQuizPanel: openedContent.openQuizPanel,
                studentId: openedContent.studentId
              }}
              formTarget={openedContent.downloadable ? '_blank' : 'iframe'}
            />
          </div>
        )}

        <div className={classnames('pad-top3', bgClass(colors.PRIMARY_DARK))} />
        <TabsAdvanced
          basepath={basepath}
          pathname={pathname}
          tabName={pathname.replace(basepath, '').replace(/^\/?/, '')}
          backgroundColor={colors.PRIMARY_BLUE}
          items={this.tabsItems()}
          truncate={shouldShowMATReport}
        />
      </div>
    );
  }
}

OrbClassReportPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  orgId: PropTypes.string,
  objectId: PropTypes.string,
  basepath: PropTypes.string,
  pathname: PropTypes.string,
  tabName: PropTypes.string,
  orgDetails: PropTypes.shape({
    id: PropTypes.string,
    academicYear: PropTypes.string,
    locationId: PropTypes.string,
    loading: PropTypes.bool,
    curriculumType: PropTypes.string,
    name: PropTypes.string,
    classrooms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    failure: PropTypes.bool
  }),
  classroomId: PropTypes.string,
  isLoaded: PropTypes.bool,
  results: PropTypes.object,
  dials: PropTypes.object,
  schoolDials: PropTypes.object,
  modifyStudent: PropTypes.object,
  getClassReport: PropTypes.func,
  getDials: PropTypes.func,
  getSchoolDials: PropTypes.func,
  createGetLevelPanel: PropTypes.func,
  loadClasses: PropTypes.func,
  getOrgDetails: PropTypes.func,
  levelUp: PropTypes.func,
  levelDown: PropTypes.func,
  assignLevel: PropTypes.func,
  resetLevel: PropTypes.func,
  delayLevelChange: PropTypes.func,
  onNavigateToOrg: PropTypes.func,
  attainmentReport: PropTypes.object,
  progressReport: PropTypes.object,
  engagementReport: PropTypes.object,
  progressItems: PropTypes.object,
  getStudentReport: PropTypes.func,
  openQuiz: PropTypes.func,
  localizedContent: PropTypes.object,
  openedContent: PropTypes.object,
  closeContent: PropTypes.func,
  storeSavedSettingsAction: PropTypes.func,
  orbClassReportTab: PropTypes.number,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  primaryOrgs: PropTypes.object,
  orgsLoaded: PropTypes.array,
  shouldShowMATReport: PropTypes.bool,
  exportReportAction: PropTypes.func
};

export default compose(
  withRouter,
  withLocalizedContent('orbClassReportPage', 'sharedTerms'),
  connect(
    (state, { match: { path, params }, location }) => {
      const orgId = params.orgId || state.identity.currentOrganisationId;
      const readingBuddyResults = featureIsEnabled('mat-report-change-for-reducers')
        ? state.resultsReadingBuddyReducer.data[orgId]
        : state.resultsReadingBuddy;
      const orgDetails = { ...readingBuddyResults?.orgDetails };
      orgDetails.id = orgDetails.id || orgId;
      orgDetails.classrooms = orgDetails.classrooms?.filter(classroom => classroom.archived === false);
      const { academicYear, locationId, curriculumType } = qs.parse(location.search);
      const { openedContent } = state.orbTeacherResources;

      Object.assign(
        orgDetails,
        academicYear && { academicYear },
        locationId && { locationId },
        curriculumType && { curriculumType }
      );

      // After a refresh, location.pathname is missing a "/" as the first character in the route string
      const handleInconsistentPathname = pathname => (pathname[0] === '/' ? pathname : `/${pathname}`);

      const stateOrgs = state.organisations.data;

      const organisations = mapObject(stateOrgs, (key, value) => ({ ...value, id: key })).filter(
        organisation => organisation.roleName === userRoles.ORG_ADMIN && organisation.role === orgRoles.PRIMARY_SCHOOL
      );
      const primaryOrgs = organisations.sort((a, b) => a.name.localeCompare(b.name));

      const shouldShowMATReport = featureIsEnabled('mat-report') && Object.keys(primaryOrgs).length > 1;
      const orgsLoaded = featureIsEnabled('mat-report-change-for-reducers')
        ? Object.keys(state.resultsReadingBuddyReducer.data).filter(
            key => !!state.resultsReadingBuddyReducer.data[key]?.orgDetails
          )
        : [];

      return {
        orgDetails,
        orgId,
        userId: state.identity.userId,
        openedContent,
        primaryOrgs,
        stateOrgs,
        shouldShowMATReport,
        results: readingBuddyResults,
        isLoaded: orgDetails.success,
        dials: state.gradebookClassReport.dials,
        schoolDials: state.gradebookSchoolDialReport,
        orgsLoaded,
        basepath: path.slice(0, path.indexOf(':') - 1),
        pathname: handleInconsistentPathname(location.pathname),
        tabName: params.tabName,
        objectId: params.objectId,
        orbClassReportTab: state.savedSettings.settings.orbClassReportTab,
        startDate: state.savedSettings.settings.startDate,
        endDate: state.savedSettings.settings.endDate,
        classroomId: params.classroomId,
        readingBuddyStudentId: readingBuddyResults?.level
          ? readingBuddyResults?.level.userLevelsData[0].userId
          : undefined,
        currentStudents: readingBuddyResults?.level ? readingBuddyResults?.group.students : undefined,
        previousGetLevelPanelApiPayload: readingBuddyResults?.levelPanelRequest,
        attainmentReport: pick(state.studentAttainmentReport, [
          'submitting',
          'success',
          'error',
          'status',
          'levelGroup',
          'levelName',
          'expected',
          'badges'
        ]),
        progressReport: pick(state.studentProgressReport, [
          'submitting',
          'success',
          'error',
          'status',
          'days',
          'quizzes',
          'skills'
        ]),
        engagementReport: pick(state.studentEngagementReport, [
          'submitting',
          'success',
          'error',
          'status',
          'sessions',
          'books',
          'skills',
          'buddy'
        ]),
        modifyStudent: state.gradebookModifyStudent,
        progressItems: pick(state.studentProgressItems, ['submitting', 'success', 'error', 'items'])
      };
    },
    (dispatch, props) => ({
      storeSavedSettingsAction: storeSavedSettings,
      getOrgDetails: orgId => {
        if (featureIsEnabled('mat-report-change-for-reducers')) {
          dispatch(actions.gradebookClassReportEnvironmentRequestV2(orgId));
        } else {
          dispatch(actions.gradebookClassReportEnvironmentRequest(orgId));
        }
      },
      loadClasses: orgId => {
        dispatch(actions.getClassroomsRequest(orgId));
      },
      getClassReport: (orgId, classId, locationId, academicYear, startDate, endDate) => {
        if (featureIsEnabled('mat-report-change-for-reducers')) {
          dispatch(
            actions.gradebookGroupClassReportRequestV2(orgId, classId, locationId, academicYear, startDate, endDate)
          );
        } else {
          dispatch(
            actions.gradebookGroupClassReportRequest(orgId, classId, locationId, academicYear, startDate, endDate)
          );
        }
      },
      getDials: (orgId, classId, locationId, academicYear) => {
        dispatch(actions.gradebookClassDialReportRequest(orgId, classId, locationId, academicYear));
      },
      getSchoolDials: (orgId, locationId) => {
        dispatch(actions.gradebookSchoolDialReportRequest(orgId, locationId));
      },
      levelUp: (userIds, orgId) => dispatch(actions.levelUp(userIds, orgId)),
      levelDown: (userIds, orgId) => dispatch(actions.levelDown(userIds, orgId)),
      assignLevel: (userIds, level, orgId) => dispatch(actions.assignLevel(userIds, level, orgId)),
      resetLevel: (userIds, orgId) => dispatch(actions.resetLevel(userIds, orgId)),
      delayLevelChange: (userIds, orgId) => dispatch(actions.delayLevelChange(userIds, orgId)),
      createGetLevelPanel: (orgId, classId, locationId) => (learnerId, levelId) => {
        if (featureIsEnabled('mat-report-change-for-reducers')) {
          dispatch(actions.gradebookLevelPanelRequestV2(orgId, classId, learnerId, levelId, locationId));
        } else {
          dispatch(actions.gradebookLevelPanelRequest(orgId, classId, learnerId, levelId, locationId));
        }
      },
      onNavigateToOrg: orgId => {
        props.history.push(`/org/${orgId}/students`);
      },
      getStudentReport: (orgId, studentId, locationId) => {
        dispatch(actions.studentReportRequest(orgId, studentId, locationId));
        dispatch(actions.studentProgressItemsRequest(orgId, studentId, locationId));
      },
      openQuiz: (_, id, studentId) => {
        dispatch(actions.teacherOpenContent(id, false, false, studentId));
      },
      closeContent: () => {
        dispatch(actions.teacherCloseContent());
      }
    })
  )
)(OrbClassReportPage);
