import pick from 'lodash.pick';
import pullAllBy from 'lodash.pullallby';
import { take, fork, put, race, select, delay } from 'redux-saga/effects';
import { MAX_POLL_ATTEMPTS } from '../../../../../globals/dataRecency';
import { triggerSearch, prepareForSearch, endSearch } from '../../../../reducers/data/search.reducer';
import actions from '../../../../actions';
import * as t from '../../../../actionTypes';
import { validateEntitiesSyncedRequest } from './utils/validateEntity';

function* getEntityApiRequest(payload, operationParams = ['role', 'operation']) {
  const orgId =
    payload.orgId || (yield select(state => state.organisationPage.orgId || state.identity.currentOrganisationId));
  const { target } = payload;
  const request = {
    constantString: 'EntitySync',
    requestType: 'POST',
    body: pick(payload, [target, ...operationParams])
  };
  switch (target) {
    case 'users':
      return {
        ...request,
        body: {
          ...request.body,
          ...pick(payload, 'classId')
        },
        apiUrl: `${__API_BASE__}/org/${orgId}/user-roles/poll`
      };
    case 'organisations':
      return {
        ...request,
        apiUrl: `${__API_BASE__}/orgs/poll`
      };
    case 'classes':
      return {
        ...request,
        apiUrl: `${__API_BASE__}/org/${orgId}/classes/poll`
      };
    case 'activationCodes':
      return {
        ...request,
        apiUrl: `${__API_BASE__}/org/${orgId}/activation-codes/poll`
      };
    case 'assignments':
      return {
        ...request,
        body: {
          ...request.body,
          ...pick(payload, 'classId')
        },
        apiUrl: `${__API_BASE__}/org/${orgId}/learning-assignments/poll`
      };
    case 'redemption':
      return {
        ...request,
        body: {
          ...request.body,
          ...pick(payload, 'userId')
        },
        apiUrl: `${__API_BASE__}/user/redemption/poll`
      };
    default:
      return null;
  }
}

const getEntitiesCollectionByStatus = (entities = [], identifier, status) =>
  entities.filter(entity => entity.status === status).map(entity => entity[identifier]);

function* pollEntitiesFlush() {
  const { flush } = yield race({
    flush: delay(3000),
    request: take(t.ENTITIES_SYNCED_REQUEST)
  });
  if (flush) {
    yield put(actions.pollEntitiesSyncedFlush());
  }
}

export default function* pollEntitiesSynced(payload, attempt = 1, operationParams) {
  const { target, identifier, collection, search } = payload;
  yield put(prepareForSearch(search));
  const request = yield getEntityApiRequest(payload, operationParams);
  const response = yield validateEntitiesSyncedRequest(request);
  const { status } = response;
  const entities = response.pollingStatus || [];
  const successes = getEntitiesCollectionByStatus(entities, identifier, 'success');
  if (successes.length) {
    yield put(actions.pollEntitiesSyncedSuccess(collection, successes));
    yield fork(pollEntitiesFlush);
    if (status === 'success') {
      yield put(triggerSearch(search));
      return;
    }
    if (status === 'partial_success') {
      if (attempt >= MAX_POLL_ATTEMPTS) {
        yield put(endSearch(search));
        yield put(actions.pollEntitiesSyncedTimeout(collection));
        return;
      }
      pollEntitiesSynced(
        {
          ...payload,
          [target]: pullAllBy(payload[target], successes, identifier)
        },
        attempt + 1
      );
      return;
    }
  }
  const timeouts = getEntitiesCollectionByStatus(entities, identifier, 'timeout');
  if (timeouts.length) {
    yield put(endSearch(search));
    yield put(actions.pollEntitiesSyncedTimeout(collection));
    const { retry, cancel } = yield race({
      retry: take(t.ENTITIES_SYNCED_RETRY),
      cancel: take(t.ENTITIES_SYNCED_CANCEL)
    });
    if (retry) {
      yield pollEntitiesSynced(payload);
      return;
    }
    if (cancel) {
      return;
    }
  }
  yield put(actions.pollEntitiesSyncedFailure(collection));
}
