import { put, select } from 'redux-saga/effects';
import cmsContent from '../../../../../utils/cmsContent.js';
import { PARENT_PLATFORM } from '../../../../../globals/appConstants';
import { setSystemNotification } from '../../../../reducers/app.reducer';

export default function* readOnlyMode() {
  const { currentOrganisationId } = yield select(state => state.identity);
  const { data: orgDetails } = yield select(state => state.organisations);

  // Notify the user that the current organization is in a BLOCKED state and the data may not be up-to-date
  if (currentOrganisationId && orgDetails && orgDetails[currentOrganisationId]) {
    const { isBlocked } = orgDetails[currentOrganisationId];
    if (isBlocked) {
      const notificationContent = cmsContent.systemNotification;
      const platformNotifications = PARENT_PLATFORM.map(system => ({
        platform: system.toLowerCase(),
        showNotification: true,
        message: notificationContent.org_read_only_notification
      }));
      yield put(setSystemNotification(platformNotifications));
    }
  }
}
