import qs from 'query-string';
import omitBy from 'lodash.omitby';
import isNull from 'lodash.isnil';
import signedFetch from '../util/signedFetch.js';

export default ({ orgId, studentId, courseId, from, to }) => {
  let url = `${__API_BASE__}/ngs/org/${orgId}/user/${studentId}/assignments`;

  if (courseId || from || to) {
    url += '?';
  }

  url += qs.stringify(
    omitBy(
      {
        courseIds: courseId,
        from,
        to
      },
      isNull
    )
  );

  return signedFetch('ngsGetHomework', url, 'GET');
};
