import { select, put } from 'redux-saga/effects';

import { sortLicence } from '@oup/shared-node-browser/licenceHelper.js';
import groupBy from 'lodash.groupby';
import get from 'lodash.get';
import { PRODUCT_TARGET_USERTYPE } from '@oup/shared-node-browser/constants';
import cmsContent from '../../../../../../utils/cmsContent.js';
import { getLicencePeriodDetails, isConcurrentLicence } from '../../../../../../utils/licences.js';
import {
  licenceDataLoadedMultiple,
  licenceDataFailed,
  SET_TEACHER_ASSIGN_LICENCES,
  storeLicenceData,
  setTeacherAssignLicences
} from '../../../../../reducers/assignLearningMaterial.reducer.js';
import { storePeople } from '../../../../../reducers/data/people.js';

import getDetails from './handleProductSelection/getDetails.js';
import orderUserIds from './handleProductSelection/orderUserIds.js';
import prepareRequest from './handleProductSelection/prepareRequest.js';
import { LICENCE_CONSTANTS } from '../../../../../../globals/appConstants';
import { canAssignToUser } from '../../../../../../globals/userRoles';
import { assignmentContexts } from '../../../../../../globals/assignmentConstants';

const ASSIGNMENT_ARCHIVED_STATUS = 'ARCHIVED';
const CMS = cmsContent.assignLearningMaterialSummary || {};

export default function* handleProductSelection({ type: actionType }) {
  console.log(`[assignLearningMaterial] User selected product, collecting licence information...`);

  const { orgId, userIds, learnerUserIds, productAvailableCount } = yield select(getDetails);
  const {
    teacherAssignLicencesMultiple,
    loadedLicencesData,
    context,
    assignerRoleName,
    assignerUserId,
    selectedProductIds,
    products
  } = yield select(state => ({
    teacherAssignLicencesMultiple: state.assignLearningMaterial.teacherAssignLicencesMultiple,
    loadedLicencesData: state.assignLearningMaterial.licenceData,
    context: state.assignLearningMaterial.context,
    assignerRoleName: state.identity.role,
    assignerUserId: state.identity.userId,
    selectedProductIds: state.assignLearningMaterial.selectedProductIds,
    products: state.products.data
  }));

  // For single selected user 'who is getting licence' button will not visible
  const isVisibleWhoIsGettingLicence = !(
    (context === 'ORG_STUDENTS' || context === 'ORG_STAFF') &&
    userIds.length <= 1
  );

  const classAssignmentContext = [
    assignmentContexts.CLASS_TEACHERS,
    assignmentContexts.CLASS,
    assignmentContexts.CLASS_CREATION,
    assignmentContexts.CLASS_RENEW,
    assignmentContexts.CLASS_ONBOARDING_WIZARD
  ].includes(context);

  let licenceData = {};
  if (SET_TEACHER_ASSIGN_LICENCES === actionType) {
    licenceData = loadedLicencesData;
  } else {
    licenceData =
      selectedProductIds && selectedProductIds.length > 0
        ? yield prepareRequest(orgId, selectedProductIds, userIds)
        : {};
  }

  if (licenceData.error || !licenceData.data) {
    console.log('[assignLearningMaterial] Failed to load licence data:', licenceData.error);
    yield put(licenceDataFailed());
  } else {
    console.log('[assignLearningMaterial] Loaded licence data:', licenceData);

    // Store users for rendering their names etc
    if (SET_TEACHER_ASSIGN_LICENCES !== actionType) {
      yield put(storePeople(licenceData.data.users));
      yield put(storeLicenceData(licenceData));
    }

    const groupLicenceData = groupBy(licenceData.data.productLicences || [], 'productId');

    const orderUserIdsSelect = yield select(state => orderUserIds(state, userIds));
    const orderUserIdsNotLearnerSelect = yield select(state =>
      orderUserIds(
        state,
        userIds.filter(id => !learnerUserIds.includes(id))
      )
    );

    const licenceDataGrouped = {
      licencesAvailable: {},
      licencesTypes: {},
      userIdsInOrder: {},
      proposedAssignments: {},
      usersIdsWithLicences: {},
      usersExistingLicenceDetails: {},
      usersWithoutAssignments: {},
      userIdsWithoutPermission: {},
      teacherIdsInOrder: {},
      learnerUserIdsInOrder: {},
      licencesAssignDetails: {},
      teacherAssignLicencesMultiple: {},
      isVisibleWhoIsGettingLicence
    };

    for (let i = 0; i < selectedProductIds.length; i += 1) {
      const productId = selectedProductIds[i];
      const productLicences = groupLicenceData[productId] || [];
      const productTargetType = get(products[productId], ['target_usertype'], null);

      console.log(`[assignLearningMaterial] Processing licence types and sorting by time length...`);
      let licencesTypes = Object.keys(productLicences).map(productLicenceId => ({
        id: productLicenceId,
        ...productLicences[productLicenceId]
      }));

      licencesTypes = sortLicence(licencesTypes);

      // Count available assignments and remove any which have none left
      licencesTypes.forEach(licence => {
        const assignmentCount =
          licence.assignments.length === 0
            ? licence.assignments.length
            : licence.assignments.filter(assign => assign.status !== ASSIGNMENT_ARCHIVED_STATUS).length;

        licence.availableCount = parseInt(licence.allowedUsages, 10) - assignmentCount;
      });

      console.log(`[assignLearningMaterial] Extracting users with existing assignments...`);
      const usersIdsWithLicences = [];
      const usersExistingLicenceDetails = {};

      licencesTypes.forEach(licence => {
        licence.assignments.forEach(assignment => {
          if (
            !usersIdsWithLicences.includes(assignment.userId) &&
            userIds.includes(assignment.userId) &&
            assignment.status !== ASSIGNMENT_ARCHIVED_STATUS
          ) {
            usersIdsWithLicences.push(assignment.userId);
          }
          if (
            (assignment.licenceEndDate || assignment.unitType || isConcurrentLicence(licence)) &&
            assignment.status !== ASSIGNMENT_ARCHIVED_STATUS
          ) {
            usersExistingLicenceDetails[assignment.userId] = {
              licenceType: licence.licenceType,
              beginOn: licence.beginOn,
              timePeriod: licence.timePeriod,
              unitType: licence.unitType,
              createdDate: licence.createdDate,
              startDate: licence.licenceStartDate,
              endDate: licence.licenceEndDate,
              status: assignment.status
            };
          }
        });
        licence.assignmentsFromDifferentActivationCode.forEach(assignment => {
          if (
            !usersIdsWithLicences.includes(assignment.userId) &&
            userIds.includes(assignment.userId) &&
            (assignment.status !== ASSIGNMENT_ARCHIVED_STATUS ||
              (assignment.licenceType === LICENCE_CONSTANTS.LICENCE_TYPE.CONCURRENT && !assignment.status)) &&
            (assignment.licenceEndDate ||
              assignment.unitType ||
              assignment.licenceType === LICENCE_CONSTANTS.LICENCE_TYPE.CONCURRENT)
          ) {
            usersIdsWithLicences.push(assignment.userId);
          }
          if (
            (assignment.licenceEndDate ||
              assignment.unitType ||
              assignment.licenceType === LICENCE_CONSTANTS.LICENCE_TYPE.CONCURRENT) &&
            (assignment.status !== ASSIGNMENT_ARCHIVED_STATUS ||
              (assignment.licenceType === LICENCE_CONSTANTS.LICENCE_TYPE.CONCURRENT && !assignment.status))
          ) {
            usersExistingLicenceDetails[assignment.userId] = {
              licenceType: assignment.licenceType,
              beginOn: assignment.beginOn,
              timePeriod: assignment.timePeriod,
              unitType: assignment.unitType,
              createdDate: assignment.createdDate,
              startDate: assignment.licenceStartDate,
              endDate: assignment.licenceEndDate,
              status: assignment.status
            };
          }
        });
      });
      console.log(`[assignLearningMaterial] Finding selected users to assign to in order of name alphabetically`);

      // this should always be at least 1, as the org admin will not be a student
      const teacherIdsInOrder = !learnerUserIds ? orderUserIdsSelect : orderUserIdsNotLearnerSelect;

      const userIdsWithoutPermission = [];
      // Checking user is a self assigner (user only able to assigne lm to self) or not.
      // Filter user ids not permission assign Licenses to them
      if (!classAssignmentContext) {
        teacherIdsInOrder.forEach(userId => {
          if (!canAssignToUser(assignerRoleName, assignerUserId, userId)) userIdsWithoutPermission.push(userId);
        });
      }

      const teacherAssignLicences =
        teacherAssignLicencesMultiple[productId] === undefined
          ? true
          : teacherAssignLicencesMultiple[productId].teacherAssignLicences;
      const notEnoughLicenceForTeacher =
        teacherAssignLicencesMultiple[productId] === undefined
          ? false
          : teacherAssignLicencesMultiple[productId].notEnoughLicenceForTeacher;

      let allUserIdsToAssign = [];
      switch (productTargetType) {
        case PRODUCT_TARGET_USERTYPE.STUDENT:
          allUserIdsToAssign = learnerUserIds;
          break;
        case PRODUCT_TARGET_USERTYPE.TEACHER:
          allUserIdsToAssign = teacherIdsInOrder;
          break;
        default:
          allUserIdsToAssign = teacherAssignLicences ? userIds : learnerUserIds;
      }

      const userIdsToAssignToInOrder = !allUserIdsToAssign
        ? []
        : yield select(state =>
            orderUserIds(
              state,
              allUserIdsToAssign.filter(
                id => !(usersIdsWithLicences.includes(id) || userIdsWithoutPermission.includes(id))
              )
            )
          );

      // condense licence types (eg when having two 1 YEAR licences with different allowed usages etc.)
      licencesTypes = licencesTypes.reduce((condensed, current) => {
        if (current.availableCount < 0) {
          return condensed;
        }
        const existingIndex = condensed.findIndex(
          type => getLicencePeriodDetails(CMS, type) === getLicencePeriodDetails(CMS, current)
        );
        if (existingIndex !== -1) {
          // Type already in the list so add to totals
          condensed[existingIndex].availableCount += current.availableCount;
          condensed[existingIndex].activationCodes.push(current.activationCode);
        } else {
          // Add new item to list
          condensed.push({
            licenceType: current.licenceType,
            beginOn: current.beginOn,
            timePeriod: current.timePeriod,
            unitType: current.unitType,
            availableCount: current.availableCount,
            activationCodes: [current.activationCode],
            createdDate: current.createdDate,
            licenceStartDate: current.licenceStartDate,
            licenceEndDate: current.licenceEndDate
          });
        }

        return condensed;
      }, []);

      // Working list of users to assign to
      let userIdsToAssign = [...userIdsToAssignToInOrder];

      const proposedAssignments = {};
      licencesTypes.forEach(licence => {
        licence.amountAssigning = Math.min(licence.availableCount, userIdsToAssign.length);
        if (licence.amountAssigning && userIdsToAssign.length) {
          // Take the users from the front that we can assign
          const usersForThisLicence = userIdsToAssign.slice(0, licence.amountAssigning);
          userIdsToAssign = userIdsToAssign.slice(licence.amountAssigning);

          // Store their proposed licence info
          usersForThisLicence.forEach(userId => {
            proposedAssignments[userId] = {
              licenceType: licence.licenceType,
              beginOn: licence.beginOn,
              timePeriod: licence.timePeriod,
              unitType: licence.unitType,
              createdDate: licence.createdDate,
              startDate: licence.licenceStartDate,
              endDate: licence.licenceEndDate
            };
          });
        }
      });

      const usersAssigned =
        userIdsToAssignToInOrder.length > productAvailableCount
          ? userIdsToAssignToInOrder
          : Object.keys(proposedAssignments);

      const licencesAssignDetails = licencesTypes.reduce(
        (total, currentValue) => ({
          licencesAvailableCount: total.licencesAvailableCount + currentValue.availableCount,
          licencesAmountAssigning: total.licencesAmountAssigning + currentValue.amountAssigning
        }),
        { licencesAvailableCount: 0, licencesAmountAssigning: 0 }
      );

      const totalUserCount = userIdsToAssign.length + usersAssigned.length;
      const enoughLicencesAvailable = totalUserCount <= licencesAssignDetails.licencesAvailableCount;

      licencesAssignDetails.enoughLicencesAvailable = enoughLicencesAvailable;
      licencesAssignDetails.totalUserCount = totalUserCount;

      const licencesAvailable =
        enoughLicencesAvailable && usersAssigned.length > 0 && Object.keys(licenceData.data.productLicences).length > 0;

      // if licence is short then learner will get piority to assign licence in class lm assign
      if (
        !notEnoughLicenceForTeacher &&
        teacherAssignLicences &&
        !enoughLicencesAvailable &&
        learnerUserIds.length > 0 &&
        licencesAssignDetails.licencesAvailableCount > 0
      ) {
        yield put(setTeacherAssignLicences(false, true, productId));
      }

      licenceDataGrouped.licencesAvailable[productId] = licencesAvailable;
      licenceDataGrouped.licencesTypes[productId] = licencesTypes;
      licenceDataGrouped.userIdsInOrder[productId] = usersAssigned;
      licenceDataGrouped.proposedAssignments[productId] = proposedAssignments;
      licenceDataGrouped.usersIdsWithLicences[productId] = usersIdsWithLicences;
      licenceDataGrouped.usersExistingLicenceDetails[productId] = usersExistingLicenceDetails;
      licenceDataGrouped.usersWithoutAssignments[productId] = userIdsToAssign;
      licenceDataGrouped.userIdsWithoutPermission[productId] = userIdsWithoutPermission;
      licenceDataGrouped.teacherIdsInOrder[productId] = teacherIdsInOrder;
      licenceDataGrouped.learnerUserIdsInOrder[productId] = learnerUserIds;
      licenceDataGrouped.licencesAssignDetails[productId] = licencesAssignDetails;
      licenceDataGrouped.teacherAssignLicencesMultiple[productId] = {
        teacherAssignLicences,
        notEnoughLicenceForTeacher
      };
    }

    yield put(licenceDataLoadedMultiple(licenceDataGrouped));
  }
}
