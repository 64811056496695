import pick from 'lodash.pick';
import * as t from '../actionTypes';

const initialState = {
  submitting: false,
  success: false,
  failure: false,
  orgName: '',
  productName: '',
  currentStartDate: '',
  currentEndDate: '',
  errors: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.UPDATE_ORG_SUBSCRIPTION_REQUEST:
      return {
        ...initialState,
        submitting: true
      };
    case t.UPDATE_ORG_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        ...pick(payload, ['productName', 'startDate', 'endDate'])
      };
    case t.UPDATE_ORG_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        submitting: false,
        failure: true,
        errors: payload
      };
    case t.UPDATE_ORG_SUBSCRIPTION_CLEAR_FAILURE:
      return {
        ...state,
        failure: false,
        errors: {}
      };
    case t.UPDATE_ORG_SUBSCRIPTION_RESET:
      return {
        ...initialState
      };
    case t.VALIDATE_UPDATE_ORG_SUBSCRIPTION_COMPLETED:
      return {
        ...state,
        failure: false,
        errors: {
          ...state.errors,
          ...payload
        }
      };
    case t.VALIDATE_UPDATE_ORG_SUBSCRIPTION_INPUT_ERROR:
      return {
        ...state,
        submitting: false
      };
    case t.UPDATE_FORM_DATA:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};
