import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';
import colors from '../../globals/colors.js';
import { orgRoles } from '../../globals/orgRoles';

// This is the first form in the EDIT CLASS journey.

// Action imports
import {
  gotoTeacherSelection,
  gotoStudentSelection,
  gotoArchive,
  removeTeacher,
  removeStudent,
  setEnableStudentLimit,
  setStudentLimit,
  editClass,
  setNotifyWithEmail
} from '../../redux/reducers/classroomEdit.js';

// Component imports
import ClassroomNameInput from './ClassroomNameInput.js';
import ClassCodeInput from './ClassCodeInput.js';
import Lozenge from '../../components/Lozenge/Lozenge.js';
import PanelHeading from '../../components/PanelHeading/PanelHeading.js';
import SelectionList from '../../components/SelectionList/SelectionList.js';
import ScrollContainer from '../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../components/PanelNavigationLink/PanelNavigationLink.js';
import Validation from '../../components/Validation/Validation.js';
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch.js';
import ToggleWithNumberInput from '../../components/ToggleWithNumberInput/ToggleWithNumberInput.js';
import PopoutActionFooter from '../../components/PopoutActionFooter/PopoutActionFooter.js';

import cmsContent from '../../utils/cmsContent.js';
import { featureIsEnabled } from '../../globals/envSettings';
import { isOrbMode, isHubMode } from '../../utils/platform';
import userRoles from '../../globals/userRoles';

function EditClassroomReview({
  closePopoutAction,
  people,
  userRole,
  // Input values
  classroomNameOrigValue,
  selectedTeacherIds,
  selectedStudentIds,
  enableStudentLimit,
  studentLimit,
  notifyWithEmail,
  currentTeacherId,

  // Validations
  classroomNameValidationIsInvalid,
  classroomNameValidationPending,
  classroomJoiningCodeValidationIsInvalid,

  // Notification actions
  setNotifyWithEmailAction,

  // Nav actions
  gotoTeacherSelectionAction,
  gotoStudentSelectionAction,
  gotoArchiveAction,

  // Remove individual users actions
  removeTeacherAction,
  removeStudentAction,

  // Limit Actions
  enableLimitAction,
  setLimitAction,

  isFormEdited,

  // Form submission action
  editClassAction,
  isSecondarySchool,
  joiningCodeEnabled,
  isPrimarySchool
}) {
  const toggleRender = featureIsEnabled('class-toggle-remove') ? !isOrbMode() : true;
  const CMS = cmsContent.classEditPanel || {};

  const isAllowedForCes = !isHubMode() && featureIsEnabled('class-joining-code');
  const currentPlatformAllowed = !isOrbMode() && (isAllowedForCes || isHubMode());
  const currentUserAllowed = userRole === userRoles.ORG_ADMIN || userRole === userRoles.TEACHER_ADMIN;
  const displayClassCodeInput = currentPlatformAllowed && currentUserAllowed;

  return (
    <form onSubmit={e => e.preventDefault()}>
      <ScrollContainer
        headerContent={
          <div>
            <div className="text-right">
              <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePopoutAction} />
            </div>
            <PanelHeading title={classroomNameOrigValue} subtitle={CMS.class_review_subtitle} />
          </div>
        }
        footerContent={
          <PopoutActionFooter
            secondaryButtonText={CMS.archive_class_text}
            secondaryButtonAction={gotoArchiveAction}
            primaryButtonText={CMS.button_save_text}
            primaryButtonAction={editClassAction}
            primaryButtonDisabled={
              classroomJoiningCodeValidationIsInvalid ||
              classroomNameValidationPending ||
              classroomNameValidationIsInvalid ||
              (enableStudentLimit && studentLimit < selectedStudentIds.length) ||
              (!isFormEdited && !joiningCodeEnabled)
            }
          />
        }
      >
        <div style={{ margin: '1rem' }}>
          <ClassroomNameInput />
          {displayClassCodeInput && !isPrimarySchool ? <ClassCodeInput /> : null}
          <div>
            <h3>{CMS.teacher_list_heading_text}</h3>
            <SelectionList
              linkId="editTeachersLink"
              linkText={CMS.select_teachers_text}
              linkAction={gotoTeacherSelectionAction}
            >
              {get(selectedTeacherIds, 'length') > 10 ? (
                <Lozenge
                  text={`${selectedTeacherIds.length}${' '}${CMS.page_heading_teachers}`}
                  backgroundColor={colors.TEACHER}
                  buttonAction={gotoTeacherSelectionAction}
                  useArrowIcon
                />
              ) : (
                selectedTeacherIds &&
                selectedTeacherIds.map(id => {
                  const person = people[id] || {};
                  const name = `${person.firstname} ${person.lastname}`;
                  return (
                    <Lozenge
                      key={id}
                      text={name}
                      backgroundColor={colors.TEACHER}
                      buttonAction={id !== currentTeacherId ? () => removeTeacherAction(id, selectedTeacherIds) : null}
                    />
                  );
                })
              )}
            </SelectionList>
          </div>

          <div>
            <h3>{CMS.student_list_heading_text}</h3>
            <SelectionList
              linkId="editStudentsLink"
              linkText={CMS.select_students_text}
              linkAction={gotoStudentSelectionAction}
            >
              {get(selectedStudentIds, 'length') > 10 ? (
                <Lozenge
                  text={`${selectedStudentIds.length}${' '}${CMS.page_heading_students}`}
                  backgroundColor={colors.LEARNERS}
                  buttonAction={gotoStudentSelectionAction}
                  useArrowIcon
                />
              ) : (
                selectedStudentIds &&
                selectedStudentIds.map(id => {
                  const person = people[id] || {};
                  const name = `${person.firstname} ${person.lastname}`;
                  return (
                    <Lozenge
                      key={id}
                      text={name}
                      backgroundColor={colors.LEARNERS}
                      buttonAction={() => removeStudentAction(id, selectedStudentIds)}
                    />
                  );
                })
              )}
            </SelectionList>
          </div>

          {toggleRender ? (
            <Validation
              isWarning={enableStudentLimit && studentLimit < selectedStudentIds.length}
              message={CMS.seat_limit_text}
              rightHandInput
              forId="numberOfSeats"
              noLabelAboveField
            >
              <ToggleWithNumberInput
                toggleId="numberOfSeatsToggle"
                toggleLabel={CMS.set_seat_limit_text}
                toggleValue={enableStudentLimit}
                toggleOnChange={enableLimitAction}
                numberInputId="numberOfSeats"
                numberInputLabel={CMS.seat_input_label_text}
                numberInputValue={studentLimit || 0}
                numberInputOnChange={setLimitAction}
                numberInputMin={1}
              />
            </Validation>
          ) : null}
          {toggleRender && !isSecondarySchool ? (
            <ToggleSwitch
              id="notifyWithEmail"
              value={notifyWithEmail}
              label={CMS.notify_students_class_change_text}
              onChange={() => setNotifyWithEmailAction(!notifyWithEmail)}
            />
          ) : null}
        </div>
      </ScrollContainer>
    </form>
  );
}

EditClassroomReview.propTypes = {
  closePopoutAction: PropTypes.func.isRequired,
  userRole: PropTypes.string,
  people: PropTypes.object.isRequired,
  // Input values
  classroomNameOrigValue: PropTypes.string.isRequired,
  classroomNameValidationIsInvalid: PropTypes.bool,
  classroomNameValidationPending: PropTypes.bool,
  classroomJoiningCodeValidationIsInvalid: PropTypes.bool,
  selectedTeacherIds: PropTypes.arrayOf(PropTypes.string),
  selectedStudentIds: PropTypes.arrayOf(PropTypes.string),
  enableStudentLimit: PropTypes.bool.isRequired,
  studentLimit: PropTypes.number,
  notifyWithEmail: PropTypes.bool.isRequired,
  isFormEdited: PropTypes.bool.isRequired,

  // Current user shouldn't be de-selectable if TEACHER
  currentTeacherId: PropTypes.string,

  // Navigation actions
  gotoTeacherSelectionAction: PropTypes.func.isRequired,
  gotoStudentSelectionAction: PropTypes.func.isRequired,
  gotoArchiveAction: PropTypes.func.isRequired,

  removeTeacherAction: PropTypes.func.isRequired,
  removeStudentAction: PropTypes.func.isRequired,
  enableLimitAction: PropTypes.func.isRequired,
  setLimitAction: PropTypes.func.isRequired,
  editClassAction: PropTypes.func.isRequired,
  setNotifyWithEmailAction: PropTypes.func.isRequired,
  isSecondarySchool: PropTypes.bool,
  isPrimarySchool: PropTypes.bool,
  joiningCodeEnabled: PropTypes.bool
};

export default connect(
  state => ({
    people: state.people.data,
    userRole: state.identity.role,
    // Connect values
    classroomNameValidationIsInvalid: state.classroomEdit.classroomNameValidationIsInvalid,
    classroomNameValidationPending: state.classroomEdit.classroomNameValidationPending,
    classroomJoiningCodeValidationIsInvalid: state.classroomEdit.classroomJoiningCodeValidationIsInvalid,
    selectedTeacherIds: state.classroomEdit.selectedTeacherIds,
    selectedStudentIds: state.classroomEdit.selectedStudentIds,
    enableStudentLimit: state.classroomEdit.enableStudentLimit,
    joiningCodeEnabled: state.classroomEdit.joiningCode && state.classroomEdit.joiningCode.enabled,
    studentLimit: parseInt(state.classroomEdit.studentLimit, 10),
    notifyWithEmail: state.classroomEdit.notifyWithEmailValue,
    isFormEdited: state.classroomEdit.isFormEdited,
    isSecondarySchool:
      state.organisations.data[state.identity.currentOrganisationId].role === orgRoles.SECONDARY_SCHOOL,
    isPrimarySchool: state.organisations.data[state.identity.currentOrganisationId].role === orgRoles.PRIMARY_SCHOOL,
    // Original values before any edit:
    classroomNameOrigValue: state.classrooms.data[state.classroomPage.classroomId]?.name,
    currentTeacherId: state.identity.role === 'TEACHER' ? state.identity.userId : null
  }),
  {
    // Navigation actions
    gotoTeacherSelectionAction: gotoTeacherSelection,
    gotoStudentSelectionAction: gotoStudentSelection,
    gotoArchiveAction: gotoArchive,

    // Connect onChange actions
    removeTeacherAction: removeTeacher,
    removeStudentAction: removeStudent,
    enableLimitAction: setEnableStudentLimit,
    setLimitAction: setStudentLimit,
    editClassAction: editClass,
    setNotifyWithEmailAction: setNotifyWithEmail
  }
)(EditClassroomReview);
