import classnames from 'classnames';
import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withLocalizedContent from '../language/withLocalizedContent';
import ControlledForm from '../components/ControlledForm/ControlledForm';
import ManagedUserAuthLayout, { Banner, Footer } from '../components/ManagedUserAuthLayout/ManagedUserAuthLayout';
import SVGIcon, { GLYPHS } from '../components/SVGIcon/SVGIcon';
import TextInputField from '../components/TextInputField/TextInputField';
import styles from './ManagedUserLoginView.scss';

class ManagedUserLoginView extends Component {
  constructor(props) {
    super(props);
    const { orgUniqueId = '' } = props;

    this.state = {
      username: '',
      password: '',
      isEditingOrgUniqueId: !orgUniqueId,
      ...pick(props, ['orgUniqueId'])
    };
  }

  _onToggleEditOrgUniqueId = () =>
    this.setState(prevState => ({ isEditingOrgUniqueId: !prevState.isEditingOrgUniqueId }));

  _createChangeHandler = name => value => this.setState({ [name]: value });

  _createBlurHandler = name => () => {
    const { validate } = this.props;
    validate(pick(this.state, [name]));
  };

  _onSubmit = () => {
    const { onSubmit } = this.props;
    onSubmit(pick(this.state, ['orgUniqueId', 'username', 'password']));
  };

  _getNotification = () => {
    const { isEditingOrgUniqueId } = this.state;
    const {
      failure = false,
      locked = false,
      errors: { orgUniqueId, username, password, serverError },
      localizedContent: { managedUserLoginView: localizedContent }
    } = this.props;

    let errorKey;
    switch (true) {
      case orgUniqueId:
        errorKey = 'school_code_input_error';
        break;
      case username:
        errorKey = 'username_input_error';
        break;
      case password:
        errorKey = 'password_input_error';
        break;
      case locked:
        errorKey = 'sign_in_locked_error';
        break;
      case serverError:
        errorKey = 'server_unavailable_error';
        break;
      case failure && isEditingOrgUniqueId:
        errorKey = 'sign_in_school_code_and_credentials_failure_error';
        break;
      case failure && !isEditingOrgUniqueId:
        errorKey = 'sign_in_credentials_failure_error';
        break;
      default:
        return null;
    }
    return {
      title: localizedContent[`${errorKey}_title`],
      message: localizedContent[`${errorKey}_message`]
    };
  };

  render() {
    const { orgUniqueId, username, password, isEditingOrgUniqueId } = this.state;
    const {
      submitting = false,
      success = false,
      errors = {},
      hasErrors = false,
      onNavigateHelp,
      signInUrl,
      onSubmit,
      localizedContent: { managedUserLoginView: content },
      platform
    } = this.props;
    const notification = this._getNotification();
    return (
      <ControlledForm className={styles.form} action={signInUrl} method="POST">
        <ManagedUserAuthLayout content={content} platform={platform}>
          {notification ? <Banner title={notification.title} detail={notification.message} /> : null}

          <div className={styles.field}>
            <label className={styles.label} htmlFor="orgUniqueId">
              {content.school_code_input_label_text}
            </label>
            <div className={styles.input}>
              {isEditingOrgUniqueId ? (
                <TextInputField
                  id="orgUniqueId"
                  value={orgUniqueId}
                  required
                  customStyleName={classnames(styles.input, { [styles.error]: errors.orgUniqueId })}
                  onChange={this._createChangeHandler('orgUniqueId')}
                  onBlur={this._createBlurHandler('orgUniqueId')}
                  autoCapitalize="none"
                />
              ) : (
                <div>
                  <div className={classnames(styles.hide)}>
                    <TextInputField id="orgUniqueId" value={orgUniqueId} />
                  </div>
                  <div className={styles.orgUniqueId}>
                    {orgUniqueId}
                    <div
                      className={styles.change}
                      role="button"
                      tabIndex={0}
                      onClick={this._onToggleEditOrgUniqueId}
                      onKeyPress={this._onToggleEditOrgUniqueId}
                    >
                      {content.change_school_friendly_id_button_text}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.field}>
            <label className={styles.label} htmlFor="username">
              {content.username_input_label_text}
            </label>
            <div className={styles.input}>
              <TextInputField
                id="username"
                name="j_username"
                required
                value={username}
                onChange={this._createChangeHandler('username')}
                onBlur={this._createBlurHandler('username')}
                customStyleName={classnames(styles.input, { [styles.error]: errors.username })}
              />
            </div>
          </div>
          <div className={styles.field}>
            <label className={styles.label} htmlFor="password">
              {content.password_input_label_text}
            </label>
            <div className={styles.input}>
              <TextInputField
                id="password"
                name="j_password"
                type="password"
                value={password}
                required
                onChange={this._createChangeHandler('password')}
                onBlur={this._createBlurHandler('password')}
                customStyleName={classnames(styles.input, { [styles.error]: errors.password })}
              />
            </div>
          </div>
          <div
            className={classnames(styles.info, styles.link)}
            role="button"
            tabIndex={0}
            onClick={onNavigateHelp}
            onKeyPress={onNavigateHelp}
          >
            <SVGIcon
              className={styles.infoIcon}
              glyph={platform === 'ereader' ? GLYPHS.ICON_HELP_CIRCLE : GLYPHS.ICON_INFORMATION_CIRCLE}
            />
            {content.sign_in_help_link_text}
          </div>

          <Footer
            actionLabel={content.sign_in_button_text}
            actionDisabled={submitting || success || hasErrors}
            onActionClick={onSubmit ? this._onSubmit : null}
          />
        </ManagedUserAuthLayout>
      </ControlledForm>
    );
  }
}

ManagedUserLoginView.propTypes = {
  orgUniqueId: PropTypes.string,
  submitting: PropTypes.bool,
  success: PropTypes.bool,
  failure: PropTypes.bool,
  locked: PropTypes.bool,
  errors: PropTypes.object,
  hasErrors: PropTypes.bool,
  validate: PropTypes.func.isRequired,
  onNavigateHelp: PropTypes.func.isRequired,
  signInUrl: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  platform: PropTypes.string
};

export default withLocalizedContent('managedUserLoginView')(ManagedUserLoginView);
