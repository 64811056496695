import pick from 'lodash.pick';
import get from 'lodash.get';
import user from '@oup/shared-node-browser/user.js';
import content from '../utils/cmsContent';
import localStorageWithCache from '../utils/localStorageWithCache';

const userRoles = user.userRoles;
const selfUserRoles = user.selfSelectedUserRoles;

/**
 * Functions shared between client and server
 */

export default userRoles;

export const orgUserRolesHighToLow = user.orgUserRolesHighToLow;
export const oupUserRolesHighToLow = user.oupUserRolesHighToLow;
export const externalContentRolesHighToLow = user.externalContentRolesHighToLow;
export const selfRegisteredOrgUserRoles = user.selfRegisteredOrgUserRoles;
export const userRoleFriendlyNamesObject = user.userRoleNameDefaults;
export const validUserRoles = user.validUserRoles;

export const roleIsAtLeast = user.roleIsAtLeast;
export const isTeacherAdminOrAbove = user.isTeacherAdminOrAbove;
export const isTeacherOrAbove = user.isTeacherOrAbove;
export const isOupStaff = user.isOupStaff;
export const isOupContent = user.isOupContent;
export const canSelfAssign = user.canSelfAssign;
export const getRolesAllowedToRegister = user.getRolesAllowedToRegister;

export const roleCanRegisterUser = user.roleCanRegisterUser;
export const roleCanCreateClass = user.roleCanCreateClass;
export const roleCanRegisterOupUser = user.roleCanRegisterOupUser;
export const roleCanArchiveOupUser = user.roleCanArchiveOupUser;
export const roleCanArchiveOrgUser = user.roleCanArchiveOrgUser;
export const roleCanArchiveOrg = user.roleCanArchiveOrg;
export const roleCanViewActivityLog = user.roleCanViewActivityLog;
export const canAssignToUser = user.canAssignToUser;
export const roleCanLinkOicOrgToJanison = user.roleCanLinkOicOrgToJanison;

/**
 * Functions that use Localisation of user roles (client only, based on content state not relevant to Lambda)
 */

export const userRoleFriendlyNames = (roleName, withArticle = false) => {
  const userRoleProperty = !withArticle ? roleName : `${roleName}_WITH_ARTICLE`;
  // @ts-ignore
  const { userRoleList: userRoleListGlossaries = null } = pick(content || {}, 'userRoleList');
  return userRoleListGlossaries && userRoleListGlossaries[`rolename_${userRoleProperty}`];
};

export const getRoleLabelPlural = roleName => {
  const rolePlural = user.getPluralFormOfRole(roleName);
  return userRoleFriendlyNames(rolePlural);
};

/**
 * Functions specific to client state objects
 */

// TODO: Can we give this access to the state.people.data and state.classrooms.data so we only pass IDs to it?
export function canUserEditClass(userId, userRole, classroom) {
  return classroom && !classroom.archived && userRole && user.canEditGroup(userId, userRole, classroom.teacherIdList);
}

export const getSelfSelectedRole = () => {
  const { getKey } = localStorageWithCache;
  const selfSelectedRole = get(JSON.parse(getKey('selectedRoleData')), ['role'], '');
  return selfSelectedRole;
};

export const roleCanCreateSecondaryOrg = roleName => {
  const isSelfSelectedStaff = roleName === userRoles.USER && getSelfSelectedRole() === selfUserRoles.SELF_TEACHER;
  const isStaff = user.isOrgStaff(roleName);
  return isStaff || isSelfSelectedStaff;
};
