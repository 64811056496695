import { put } from 'redux-saga/effects';
import {
  regenerateSignInCardSuccess,
  regenerateSignInCardError
} from '../../../../actions/regenerateSupervisedUserSignInCard.js';
import regenerateSupervisedUserSignInCardApi from '../../../apiCalls/supervisedUser/regenerateSupervisedUserSignInCard.api.js';

function* regenerateSupervisedUserSignInCard({ orgId, userId, userNameWithoutOrg, firstName, orgFriendlyId }) {
  const userDetails = {
    userId,
    userNameWithoutOrg,
    firstName
  };

  const response = yield regenerateSupervisedUserSignInCardApi(orgId, userDetails, orgFriendlyId);

  if (response.status === 'success' && response.signInCardUrl) {
    yield put(regenerateSignInCardSuccess(response.signInCardUrl));
  } else {
    yield put(regenerateSignInCardError('Failed to generate supervised user sign in card'));
  }
}

export default regenerateSupervisedUserSignInCard;
