import mapValues from 'lodash.mapvalues';
import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import LinkWithIcon from '../../LinkWithIcon/LinkWithIcon.js';
import { GLYPHS } from '../../SVGIcon/SVGIcon.js';
import UserLicenceListing from '../../UserLicenceListing/UserLicenceListing';
import { PRODUCT_TARGET_USERTYPE } from '../../../../sharedNodeBrowser/constants';
import styles from '../ProductFinder.scss';

const filterIds = (ids = [], filterId = []) => ids.filter(userId => filterId.includes(userId));

function ProductFinderUserAssignments({
  productFinderContent,
  users,
  userIdsToAssignLicencesTo = [],
  userIdsWithExistingLicence = [],
  userIdsToNotAssignLicencesTo = [],
  newLicenceAssignments = {},
  existingLicenceAssignments = {},
  teacherIdList = [],
  studentIdList = [],
  toggleView,
  shouldTeacherAssignLicences,
  userIdsWithoutPermission,
  productTargetUsertype
}) {
  const existingLicences = mapValues(existingLicenceAssignments, licenceDetails => ({
    ...licenceDetails,
    isAssigned: true
  }));
  // Filter for userIds To Assign Licences
  const learnerUserIdsToAssignLicencesTo = filterIds(userIdsToAssignLicencesTo, studentIdList);
  const teacherUserIdsToAssignLicencesTo = filterIds(userIdsToAssignLicencesTo, teacherIdList);
  // Filter for userIds With Existing Licences
  const learnerUserIdsWithExistingLicence = filterIds(userIdsWithExistingLicence, studentIdList);
  const teacherUserIdsWithExistingLicence = filterIds(userIdsWithExistingLicence, teacherIdList);
  // Filter for userIds Not Assign Licences
  const learnerUserIdsToNotAssignLicencesTo = filterIds(userIdsToNotAssignLicencesTo, studentIdList);
  const teacherUserIdsToNotAssignLicencesTo = filterIds(userIdsToNotAssignLicencesTo, teacherIdList);

  const finalUserIdsWithExistingLicence = (learnersWithLicence, teachersWithLicence) => {
    if (productTargetUsertype === PRODUCT_TARGET_USERTYPE.STUDENT) return learnersWithLicence;
    if (productTargetUsertype === PRODUCT_TARGET_USERTYPE.TEACHER) return teachersWithLicence;
    return userIdsWithExistingLicence;
  };

  return (
    <div className={styles.userAssignments}>
      <LinkWithIcon
        text={productFinderContent.back_to_licensing_text}
        isLhs
        glyph={GLYPHS.ICON_LEFT}
        action={toggleView}
      />

      {learnerUserIdsToAssignLicencesTo.length || teacherUserIdsToAssignLicencesTo.length ? (
        <div className={classnames(styles.informationContainer, styles.borderTop)}>
          <h3>{productFinderContent.assign_with_licence_text_single_lm}</h3>
        </div>
      ) : null}

      {!userIdsToAssignLicencesTo.length && !userIdsToNotAssignLicencesTo.length && !teacherIdList.length ? (
        <p className={styles.informationContainer}>{productFinderContent.none_text}</p>
      ) : null}

      {learnerUserIdsToAssignLicencesTo.length ? (
        <div className={styles.informationContainer}>
          <UserLicenceListing
            heading={productFinderContent.students_header_text}
            users={pick(users, learnerUserIdsToAssignLicencesTo)}
            userLicences={newLicenceAssignments}
          />
        </div>
      ) : null}

      {teacherUserIdsToAssignLicencesTo.length ? (
        <div className={styles.informationContainer}>
          <UserLicenceListing
            heading={productFinderContent.teachers_header_text}
            users={pick(users, teacherUserIdsToAssignLicencesTo)}
            userLicences={newLicenceAssignments}
          />
        </div>
      ) : null}

      {!!finalUserIdsWithExistingLicence(learnerUserIdsWithExistingLicence, teacherUserIdsWithExistingLicence).length ||
      !!userIdsToNotAssignLicencesTo.length ||
      (!shouldTeacherAssignLicences && !!teacherIdList.length) ? (
        <div className={styles.informationSpacedContainer}>
          <h3>{productFinderContent.assign_without_licence_text_single_lm}</h3>
        </div>
      ) : null}

      {(!!learnerUserIdsWithExistingLicence.length || !!learnerUserIdsToNotAssignLicencesTo.length) &&
      (productTargetUsertype === PRODUCT_TARGET_USERTYPE.STUDENT || !productTargetUsertype) ? (
        <div className={styles.informationContainer}>
          <UserLicenceListing
            heading={productFinderContent.students_header_text}
            users={pick(users, [...learnerUserIdsToNotAssignLicencesTo, ...learnerUserIdsWithExistingLicence])}
            userLicences={existingLicences}
          />
        </div>
      ) : null}

      {!shouldTeacherAssignLicences &&
      teacherIdList.length &&
      (productTargetUsertype === PRODUCT_TARGET_USERTYPE.TEACHER || !productTargetUsertype) ? (
        <div className={styles.informationContainer}>
          <UserLicenceListing
            heading={productFinderContent.teachers_header_text}
            users={pick(users, teacherIdList)}
            userIdsWithoutPermission={userIdsWithoutPermission}
            userLicences={existingLicences}
          />
        </div>
      ) : null}

      {shouldTeacherAssignLicences &&
      (!!teacherUserIdsWithExistingLicence.length ||
        !!teacherUserIdsToNotAssignLicencesTo.length ||
        !!userIdsWithoutPermission.length) &&
      (productTargetUsertype === PRODUCT_TARGET_USERTYPE.TEACHER || !productTargetUsertype) ? (
        <div className={styles.informationContainer}>
          <UserLicenceListing
            heading={productFinderContent.teachers_header_text}
            users={pick(users, [
              ...teacherUserIdsToNotAssignLicencesTo,
              ...teacherUserIdsWithExistingLicence,
              ...userIdsWithoutPermission
            ])}
            userIdsWithoutPermission={userIdsWithoutPermission}
            userLicences={existingLicences}
          />
        </div>
      ) : null}
    </div>
  );
}

ProductFinderUserAssignments.propTypes = {
  productFinderContent: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  teacherIdList: PropTypes.array,
  studentIdList: PropTypes.array,
  userIdsToAssignLicencesTo: PropTypes.array,
  userIdsToNotAssignLicencesTo: PropTypes.array,
  userIdsWithExistingLicence: PropTypes.array,
  newLicenceAssignments: PropTypes.object,
  existingLicenceAssignments: PropTypes.object,
  toggleView: PropTypes.func.isRequired,
  shouldTeacherAssignLicences: PropTypes.bool,
  userIdsWithoutPermission: PropTypes.array.isRequired,
  productTargetUsertype: PropTypes.string
};

export default ProductFinderUserAssignments;
