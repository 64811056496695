import { put, takeLatest } from 'redux-saga/effects';
import {
  ARCHIVE_USERS,
  setFoundLastOrgAdmin,
  setFoundLastOupAdmin,
  setFoundLastTeacher,
  setPreFlightCheckResults,
  setRequestFailed,
  setResult,
  SET_STAFF_LIST,
  SET_STUDENT_LIST,
  showRemovalPending,
  showSelectionReview
} from '../../../../reducers/archiveUsers.reducer';
import { pollOrgUsersRemoved } from '../dataRecency/pollOrgUsers';
import archiveUsersFromOrgRequest from './archiveUsersFromOrgRequest.saga';
import archiveUsersPreFlightChecksRequest from './archiveUsersPreFlightChecksRequest.saga';

const moduleName = '[archiveUsersFromOrg Saga]';

function* archiveUsersPreFlightChecks() {
  console.log(moduleName, 'performing pre-flight checks...');

  const result = yield archiveUsersPreFlightChecksRequest();
  console.log(`${moduleName} PreFlightChecks result`, result);
  if (result.status && result.status !== 'error' && result.data) {
    // check if among the selected user are users that are the only OUP Admin, Org Admin and the only Teacher
    let foundLastOupAdmin = false;
    let foundLastOrgAdmin = false;
    let foundLastTeacher = false;
    Object.keys(result.data).forEach(key => {
      if (result.data[key].isOnlyOupAdmin) {
        foundLastOupAdmin = true;
      }
      if (result.data[key].isOnlyOrgAdmin) {
        foundLastOrgAdmin = true;
      }
      if (result.data[key].isOnlyTeacher) {
        foundLastTeacher = true;
      }
    });

    yield put(setFoundLastOupAdmin(foundLastOupAdmin));
    yield put(setFoundLastOrgAdmin(foundLastOrgAdmin));
    yield put(setFoundLastTeacher(foundLastTeacher));

    yield put(setPreFlightCheckResults(result.data));
    yield put(showSelectionReview());
  } else {
    // show a generic error in the task pending page
    yield put(setRequestFailed(true));
    yield put(showRemovalPending(true));
  }
}

function* skipPreFlightChecks() {
  console.log(moduleName, 'Skipping pre-flight checks and displaying selection review');
  yield put(setFoundLastOrgAdmin(false));
  yield put(setFoundLastTeacher(false));
  yield put(showSelectionReview());
}

function* archiveUsersFromOrg() {
  console.log(moduleName, 'Archiving users...');
  // show pending screen before the results come back
  yield put(setRequestFailed(false));
  const result = yield archiveUsersFromOrgRequest();
  console.log(`${moduleName} result`, result);

  if (result.status === 'success' && result.data) {
    // show pending screen with any individual errors
    yield put(setResult(result.data.resultPairs));
    const successfullyRemovedUserIds = result.data.resultPairs
      .filter(user => user.status.toLowerCase() === 'success')
      .map(({ userId }) => userId);
    yield pollOrgUsersRemoved(successfullyRemovedUserIds);
  } else {
    // show a generic error page
    yield put(setRequestFailed(true));
  }
  yield put(showRemovalPending(true));
}

export default function* archiveUsersFromOrgSaga() {
  console.log(moduleName, 'Beginning');
  yield takeLatest(SET_STUDENT_LIST, skipPreFlightChecks);
  yield takeLatest(SET_STAFF_LIST, archiveUsersPreFlightChecks);
  yield takeLatest(ARCHIVE_USERS, archiveUsersFromOrg);
}
