import pick from 'lodash.pick';
import { put, select, take, takeLatest } from 'redux-saga/effects';

import userRoles from '../../../../../../globals/userRoles';
import {
  EMAIL_ON_BLUR,
  FIRSTNAME_ON_BLUR,
  LASTNAME_ON_BLUR,
  RESET_FORM,
  showEnrolUserForm,
  ROLE_ON_BLUR,
  SET_ROLE_VALUE,
  setEmailValue,
  setEmailNotice,
  setEmailValidation,
  setEmailWaiting,
  setFirstNameValidation,
  setFirstNameValue,
  setInvitePending,
  setLastNameValidation,
  setLastNameValue,
  setRoleValidation,
  setRoleValue,
  setSignInCardUrl,
  setUserExists,
  setUserHasNamesSet,
  showConfirmation,
  SUBMIT_FORM
} from '../../../../../reducers/enrolUser.reducer';
import { clearSelectedUser } from '../../../../../reducers/organisationPage.reducer';
import { pollOrgUsersAdded } from '../../dataRecency/pollOrgUsers';
import checkUserStatusRequest from './checkUserStatusRequest';
import enrolUserRequest from './enrolUserRequest';
import {
  validateEmail,
  validateEmailOnBlur,
  validateFirstName,
  validateFirstNameOnBlur,
  validateFullForm,
  validateLastName,
  validateLastNameOnBlur,
  validateRoleOnBlur
} from './fieldValidation';

function getUsersFromOrg(users, orgId, userEmail) {
  let data = {
    exists: false,
    firstName: null,
    invitationStatus: null,
    lastName: null,
    roleName: null
  };
  const listOfUsers = Object.values(users);
  const selectedUser = listOfUsers.filter(user => user.orgId === orgId && user.email === userEmail);

  if (selectedUser.length) {
    const userProfile = selectedUser[0];
    data = {
      exists: true,
      firstName: userProfile.firstname,
      invitationStatus: userProfile.orgInviteStatus,
      lastName: userProfile.lastname,
      roleName: userProfile.roleName
    };
  }

  return data;
}

function* checkUserEmail() {
  console.log('[enrolUser Saga] Checking user `email` onChange');
  const { emailValue, isOUPStaff, context, orgId, people, roleValue, userExists } = yield select(state => ({
    emailValue: state.enrolUser.emailValue,
    isOUPStaff: state.identity.role === userRoles.OUP_ADMIN,
    context: state.enrolUser.context,
    actioningUserId: state.identity.userId,
    orgId: state.organisationPage.orgId,
    // Details of users:
    people: state.people.data,
    roleValue: state.enrolUser.roleValue,
    userExists: state.enrolUser.userExists
  }));

  const emailValidationResult = validateEmail(emailValue, isOUPStaff, context, roleValue);
  yield put(setEmailValue(emailValue, isOUPStaff, context, userExists, roleValue));
  yield put(setEmailValidation(emailValidationResult.invalid, emailValidationResult.errorMessage));
  yield put(setInvitePending(false));
  yield put(setUserExists(false));
  yield put(setUserHasNamesSet(false));
  // save making api calls at the expense of an extra validation check
  if (!emailValidationResult.invalid) {
    // make lambda call to verify email
    yield put(setEmailWaiting(true));
    yield put(setEmailNotice(false, ''));

    // eslint-disable-next-line prefer-const
    let { data, statusCode } = yield checkUserStatusRequest();

    // extract the details for the user who was invited in the organisation

    // Unexpected token error
    // if statusCode is 403 and 'data' is undefined then
    // it is added in 'data' the details of the user that already exists in the state

    if (statusCode === 403) {
      data = getUsersFromOrg(people, orgId, emailValue);
    }
    const { exists, invitationStatus } = data;

    const resultExists = data && exists;
    const inviteState = resultExists ? invitationStatus : null;
    const hasInvitePending = inviteState === 'PENDING';
    const userExistsInOrg = resultExists ? inviteState === 'NONE' || inviteState === 'ACCEPTED' : null;
    // if user has a pending invite, populate name and roles from api response
    // if user exists not in organization but in DB and doesn't have an invitation, populate name
    if (hasInvitePending || userExistsInOrg || (data.firstName && data.lastName)) {
      yield put(setFirstNameValue(data.firstName));
      const firstNameValidation = validateFirstName(data.firstName);
      yield put(setFirstNameValidation(firstNameValidation.invalid, firstNameValidation.errorMessage));

      yield put(setLastNameValue(data.lastName));
      const lastNameValidation = validateLastName(data.lastName);
      yield put(setLastNameValidation(lastNameValidation.invalid, lastNameValidation.errorMessage));

      if (hasInvitePending || userExistsInOrg) {
        yield put(setRoleValue(Object.keys(userRoles).includes(data.roleName) ? data.roleName : userRoles.LEARNER));
        yield put(setRoleValidation(false, ''));

        if (userExistsInOrg) {
          // if the user has already accepted an invite, show an error message under the email field
          yield put(setEmailValidation(true, 'A user with this email address is already in this institution.'));
          // yield put(setEmailValidation(true, ''));
          yield put(setUserExists(userExistsInOrg));
          yield put(setEmailWaiting(false));
        } else if (hasInvitePending) {
          yield put(setInvitePending(hasInvitePending));
          yield put(
            setEmailNotice(true, 'This user has already been invited to this institution. They will be sent a reminder')
          );
        }
      } else {
        yield put(setUserHasNamesSet(true));
      }
    }
    // finished calculating so can give UI tick or cross
    yield put(setEmailWaiting(false));
    //
    //
  }
}

export default function* enrolUser() {
  console.log('[enrolUser Saga] Beginning');
  // email has to be checked onChange
  // yield takeLatest(SET_EMAIL_VALUE, checkUserEmail);
  // Setup input onBlur validation listeners
  yield takeLatest(FIRSTNAME_ON_BLUR, validateFirstNameOnBlur);
  yield takeLatest(LASTNAME_ON_BLUR, validateLastNameOnBlur);
  yield takeLatest(EMAIL_ON_BLUR, checkUserEmail);
  yield takeLatest(ROLE_ON_BLUR, validateRoleOnBlur);

  const isOUPStaff = yield select(state => state.identity.role === userRoles.OUP_ADMIN);
  if (isOUPStaff) {
    yield takeLatest(SET_ROLE_VALUE, validateEmailOnBlur);
  }

  while (true) {
    console.log('[enrolUser Saga] Waiting for user to submit form');
    yield take(SUBMIT_FORM);

    console.log('[enrolUser Saga] Form sumbission attempt, validating...');
    // Get all values and call their respective validation functions
    const { context, firstname, lastname, email, role, usernameGenerated } = yield select(state => ({
      context: state.enrolUser.context,
      // Details of user to create:
      firstname: state.enrolUser.firstNameValue,
      lastname: state.enrolUser.lastNameValue,
      email: state.enrolUser.emailValue,
      role: state.enrolUser.roleValue,
      usernameGenerated: state.enrolUser.usernameGenerated
    }));
    const validationFailed = yield validateFullForm({
      context,
      isOUPStaff,
      firstname,
      lastname,
      email,
      role,
      usernameGenerated
    });

    // If the validation passed, submit the form, else we wait for the user to submit again
    if (!validationFailed) {
      console.log('[enrolUser Saga] Submitting Enrol User request');
      const result = yield enrolUserRequest(usernameGenerated);
      const status = result.status.toLowerCase();
      const failed = !!result.error || status !== 'success';
      const apiError = result.error || status !== 'success' ? { code: result.code } : null;

      if (status !== 'success' && result.message === 'user already exists') {
        yield put(showEnrolUserForm());
        yield put(setEmailValidation(true, 'A user with this email address is already in this institution.'));
        yield put(setUserExists(true));
      } else {
        // clearing selected classRoom
        yield put(clearSelectedUser());

        let classesOnboardFailed = false;
        const signInCardUrl = result.signInCardUrl || result.data?.signInCardUrl;
        if (signInCardUrl) {
          yield put(setSignInCardUrl(signInCardUrl));
        }
        if (
          !failed &&
          !apiError &&
          result.data &&
          result.data.classOnboardStatus &&
          Array.isArray(result.data.classOnboardStatus)
        ) {
          const { classOnboardStatus } = result.data;
          classesOnboardFailed = classOnboardStatus.some(individualClass => individualClass.status === 'ERROR');
        }

        console.log('[enrolUser Saga] Enrol User request complete, showing confirmation page');
        yield put(showConfirmation(failed, apiError, classesOnboardFailed));

        if (!failed && !apiError && (result.data?.userId || result.userId)) {
          let people;
          let userId;
          if (usernameGenerated) {
            userId = result.userId;
            people = { [userId]: { userId, roleName: userRoles.LEARNER } };
          } else {
            userId = result.data?.userId;
            people = { [userId]: pick(result.data, ['userId', 'roleName']) };
          }
          yield pollOrgUsersAdded([userId], people);
        }

        yield take(RESET_FORM);
        console.log('[enrolUser Saga] Resetting enrolUser state and saga');
      }
    }
  }
}
