import React from 'react';
import PropTypes from 'prop-types';

import Button from '@oup/shared-front-end/src/components/Button';
import styles from './HubListHeader.scss';

import Checkbox from '../../components/Checkbox/Checkbox';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';

function HubListHeader({
  item,
  itemIds,
  selectable,
  checkboxData: { selectedIds, onSelectAll, areAllSelected },
  hasHeaderButtonAccess,
  showCheckboxHeader,
  customClassName,
  isPreview
}) {
  const getSelectableIds = () => {
    if (!selectable) {
      return [];
    }

    return Array.isArray(selectable) ? selectable : itemIds;
  };

  const getSelectedAll = () => {
    const selectableItemIds = getSelectableIds();
    // if no items then select all will always be unchecked
    return !selectableItemIds.length || !selectedIds ? false : selectableItemIds.every(id => selectedIds.includes(id));
  };

  return (
    item && (
      <div className={styles.hubListHeader}>
        <div className={`${styles.textContainer} ${styles[customClassName]}`}>
          {showCheckboxHeader && (
            <Checkbox
              id="people"
              label="Select entity"
              labelHidden
              value={getSelectedAll()}
              onChange={() => onSelectAll(itemIds, areAllSelected)}
              // disabled={!(Array.isArray(selectable) ? selectable.includes(id) : selectable)}
            />
          )}
          <span>{item.name}</span>
        </div>
        {!isPreview && (
          <div className={styles.textContainer}>
            <span>{item.status}</span>
          </div>
        )}
        <div className={styles.buttonContainer}>
          {hasHeaderButtonAccess && (
            <Button
              onClick={item.action}
              text={item.buttonText}
              variant="filled"
              icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
              dataAttributes={item.dataAttributes}
            />
          )}
        </div>
      </div>
    )
  );
}

HubListHeader.defaultProps = {
  itemIds: [],
  selectable: true,
  checkboxData: { selectedIds: [], onSelectAll: false, areAllSelected: false },
  showCheckboxHeader: true,
  customClassName: ''
};

HubListHeader.propTypes = {
  item: PropTypes.object,
  itemIds: PropTypes.array,
  checkboxData: PropTypes.object,
  selectable: PropTypes.bool,
  hasHeaderButtonAccess: PropTypes.bool,
  showCheckboxHeader: PropTypes.bool,
  customClassName: PropTypes.string,
  isPreview: PropTypes.bool
};

export default HubListHeader;
