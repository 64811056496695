import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import appSettings from '../../globals/appSettings';
import TextInput from '../TextInput/TextInput';
import Dropdown from '../Dropdown/Dropdown.js';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from '../../routes/MyProfile/MyProfile.scss';

function MissingFieldsFormBody({
  localizedContent: { countryCodes, ...content },
  claimedSchool = '',
  countryCode = '',
  selfSelectedRole = '',
  formInputErrors,
  onChange,
  onBlur
}) {
  const _getErrorMessage = (value, type) => {
    const actionValue = value.trim();
    const invalidCharacters = appSettings.INVALID_CHARACTERS_REGEX.test(actionValue);
    const errorType = invalidCharacters ? 'invalid_error' : 'error';
    const errorMessageKey = `missing_fields_form_body_${type}_${errorType}`;
    return content[errorMessageKey] || '';
  };

  const handlers = { onChange, onBlur };
  const _countries = Object.keys(countryCodes).map(code => ({ text: countryCodes[code], value: code }));

  return (
    <div className={styles.consentContainerInputsContainer}>
      {/* Missing fields required to generate report for Ebook Sample */}

      <ValidationStatus
        message={_getErrorMessage(selfSelectedRole, 'self_selected_role')}
        forId="selfSelectedRole"
        isActive={formInputErrors?.selfSelectedRole}
      >
        <TextInput
          {...handlers}
          id="selfSelectedRole"
          name="selfSelectedRole"
          label={content.missing_fields_form_body_selfSelectedRole_header}
          placeholder={content.missing_fields_form_body_selfSelectedRole_placeholder}
          value={selfSelectedRole}
        />
      </ValidationStatus>

      <ValidationStatus
        message={_getErrorMessage(claimedSchool, 'claimed_school')}
        forId="claimedSchool"
        isActive={formInputErrors?.claimedSchool}
      >
        <TextInput
          {...handlers}
          id="school"
          name="claimedSchool"
          label={content.missing_fields_form_body_claimedSchool_header}
          placeholder={content.missing_fields_form_body_claimedSchool_placeholder}
          value={claimedSchool}
        />
      </ValidationStatus>

      <ValidationStatus
        message={_getErrorMessage(countryCode, 'country_code')}
        forId="countryCode"
        isActive={formInputErrors?.countryCode}
      >
        <Dropdown
          {...handlers}
          id="countryCode"
          name="countryCode"
          label={content.missing_fields_form_body_countryCode_header}
          options={[{ text: content.missing_fields_form_body_countryCode_placeholder, value: '' }, ..._countries]}
          placeholder={content.missing_fields_form_body_countryCode_placeholder}
          value={countryCode}
        />
      </ValidationStatus>
    </div>
  );
}

MissingFieldsFormBody.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  formInputErrors: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  claimedSchool: PropTypes.string,
  countryCode: PropTypes.string,
  selfSelectedRole: PropTypes.string
};

export default compose(withLocalizedContent('countryCodes'))(MissingFieldsFormBody);
