import Ajv from 'ajv';
import dot from 'dot-object';
import { put } from 'redux-saga/effects';
import trim from 'lodash.trim';

import jsonSchema4 from 'ajv/lib/refs/json-schema-draft-04.json';

let ajv;
if (!ajv) {
  ajv = new Ajv({
    schemaId: 'auto',
    meta: false,
    extendRefs: true,
    unknownFormats: 'ignore',
    allErrors: true
  });

  ajv.addMetaSchema(jsonSchema4);
  ajv._opts.defaultMeta = jsonSchema4.id;
  ajv._refs['http://json-schema.org/schema'] = 'http://json-schema.org/draft-04/schema';

  ajv.removeKeyword('propertyNames');
  ajv.removeKeyword('contains');
  ajv.removeKeyword('const');
}

function* validateGeneratedPasswordUsingSchema(schema, type, input) {
  ajv.validate(schema, input);
  const scope = Object.keys(dot.dot(input));
  const defaults = scope.reduce(
    (defaulted, path) => ({
      ...defaulted,
      [path]: false
    }),
    {}
  );
  if (!ajv.errors) {
    return defaults;
  }

  const errors = dot.object({
    ...defaults,
    ...ajv.errors.reduce((_errors, { dataPath, keyword, message }) => {
      const path = trim(dataPath, '.');
      if (!scope.includes(path)) {
        return _errors;
      }
      return {
        ..._errors,
        [path]: true,
        keyword,
        message
      };
    }, {})
  });

  yield put({
    type,
    payload: errors
  });

  return errors;
}

export default validateGeneratedPasswordUsingSchema;
