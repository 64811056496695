import { select, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_INVITES,
  DO_ACCEPT,
  DO_DECLINE,
  DO_DISMISS,
  setInvites,
  setInvitesFailed,
  setInviteUpdating,
  setInviteUpdated,
  inviteStates,
  setRequestFailed,
  showConfirmation,
  // To trigger a refresh of role details after accepting an invite:
  REDIRECT_TO_IDP,
  doAccept
} from '../../../../reducers/invitationsBanner.reducer.js';
import isTestingUser from '../../../../../utils/verifyUser';
import getOrgInvitesApi from '../../../api/getOrgInvites.js';
import updateOrgInvitesApi from '../../../api/updateOrgInvite.js';
import redirectToIdP from '../../../preRender/auth/getCredentials/redirectToIdP.saga.js';
import { INVITATION_STATUS } from '../../../../../globals/appConstants';
import { getCurrentPlatform, isOteMode, isOrbMode } from '../../../../../utils/platform';
import { orgRoles, eltAmRoles } from '../../../../../globals/orgRoles';
import loadLocalizedContent from '../ote/amLocalizedContent.js';
import { featureIsEnabled } from '../../../../../globals/envSettings.js';

const moduleName = '[invitations Saga]';

// Filter out test center invites if the user is a test center user
// Filter out AM invites if the user is not an AM user
// Filter out OTE invites if the user is not an OTE user
const filteredInvites = (unfilteredInvites, email) => {
  if (isOteMode()) {
    return featureIsEnabled('opt-and-ote-emails-disabled') && isTestingUser(email)
      ? []
      : unfilteredInvites.filter(invite => invite.group.role === orgRoles.TEST_CENTER);
  }
  // temporary solution for excluding AM invites from non AM platforms
  return unfilteredInvites.filter(invite => !eltAmRoles.includes(invite.group.role));
};

function* fetchInvites() {
  // Fetch params from state:
  const { userId, email } = yield select(state => state.identity);
  const isExtRegFeature = featureIsEnabled('ote-extend-registration');

  console.log(moduleName, 'getOrgInvites Searching for invites for current user');
  let apiResults = {};

  try {
    apiResults = yield getOrgInvitesApi();
  } catch (err) {
    console.log(moduleName, 'getOrgInvites responded with error: ', err);
  }

  const invites = apiResults && apiResults.data && apiResults.data.invites;
  console.log(moduleName, 'getOrgInvites responded with ', apiResults);

  if (apiResults.status !== 'error' && invites) {
    console.log(moduleName, `Found ${invites.length} invitations`);
    // Filter invites to blocked organizations
    const validInvites = invites.filter(invite => invite.group.isBlocked === false);

    const filteredInvitesList = filteredInvites(validInvites, email);
    // Put the invites into state:
    yield put(setInvites(userId, filteredInvitesList));

    // auto accept invites
    if (isOteMode() && isExtRegFeature) {
      for (let index = 0; index < validInvites.length; index += 1) {
        yield put(doAccept(userId, validInvites[index].group.id));
      }
    }

    const { tcLocalizedContentLoaded } = yield select(state => ({
      tcLocalizedContentLoaded: state.tcLocalizedContentLoaded || false
    }));
    if (isOteMode() && !tcLocalizedContentLoaded) {
      yield loadLocalizedContent();
    }
  } else {
    // There was a problem searching
    console.log(moduleName, 'apiResults.error', apiResults.error);
    yield put(setInvitesFailed());
  }
}

function* updateInvite() {
  // Locate the invitiation thats ready to update:
  const { userId, invites, organisations } = yield select(state => ({
    userId: state.invites.userId,
    invites: state.invites.invites,
    currentOrganisationId: state.identity.currentOrganisationId,
    organisations: state.organisations.data
  }));
  const invite = invites.find(invitation => invitation.state === inviteStates.DO_UPDATE);

  if (invite) {
    const { orgId, roleName, newStatus: invitationStatus } = invite;

    const platformCode = getCurrentPlatform().toUpperCase() || '';
    const bodyParams = { roleName, invitationStatus, platformCode };

    yield put(setInviteUpdating(userId, orgId, invitationStatus));

    console.log(moduleName, `updateOrgInvite for:`, 'userId:', userId, 'orgId:', orgId, 'body:', bodyParams);
    const apiResults = yield updateOrgInvitesApi(userId, orgId, bodyParams);
    console.log(moduleName, 'updateOrgInvite responded with ', apiResults);

    if (!(apiResults.error || apiResults.status === 'error')) {
      // Got some results
      console.log(moduleName, `Invitation updated successfully`);

      // check whether user has a primary org or not
      let primarySchoolExists = false;
      Object.keys(organisations).forEach(key => {
        if (organisations[key].role === orgRoles.PRIMARY_SCHOOL) primarySchoolExists = true;
      });
      // If the ORB user logins for the first time setting showStartUpPages value to true
      if (INVITATION_STATUS.ACCEPTED === invitationStatus && isOrbMode() && !primarySchoolExists) {
        localStorage.setItem('showStartUpPages', 'true');
      }

      // Put the user information into state and pass results IDs to the UI:
      yield put(setRequestFailed(orgId, false));
      yield put(setInviteUpdated(userId, orgId, invitationStatus));
    } else {
      // There was a problem updating
      console.log(moduleName, 'apiResults:', apiResults.error || apiResults.message);
      yield put(setRequestFailed(orgId, true, 'there was an error'));
      yield put(showConfirmation(orgId));
    }
  } else {
    console.log(moduleName, 'updateInvite', 'No invite found matching userId:', userId, 'invite:', invite);
  }
}

export default function* invitationsBanner() {
  console.log(moduleName, 'Beginning');

  // Listen for all changes
  yield takeLatest([FETCH_INVITES], fetchInvites);
  yield takeLatest([DO_ACCEPT, DO_DECLINE, DO_DISMISS], updateInvite);
  yield takeLatest([REDIRECT_TO_IDP], redirectToIdP);

  yield fetchInvites();
}
