import omit from 'lodash.omit';
import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import ClassUserAddReview from '../../../../../../components/ClassUserAddReview/ClassUserAddReview';
import { isHubMode } from '../../../../../../utils/platform';
import { SidePanel } from '../../../../../../components';
import ClassUserConfirmation from '../../../../../../components/ClassUserConfirmation/ClassUserConfirmation';
import PopoutPanelIconHeading, {
  types as iconHeadingTypes
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';
import StudentSelectionPanel from '../../../../../../panels/StudentSelectionPanel';
import {
  assignMaterial,
  clearForm,
  formStates,
  gotoStudentSelection,
  removeStudent,
  setEnableStudentLimit,
  setSelectedStudents,
  setStudentLimit,
  submitForm
} from '../../../../../../redux/reducers/addStudentsToClassroom';
import USER_ROLES from '../../../../../../globals/userRoles';
import AssignLearningMaterial from '../../../../../panels/AssignLearningMaterial/AssignLearningMaterial';
import ProductFinderPanel from '../../../../../../components/ProductFinder/ProductFinderPanel';
import { CONTEXT_OPTIONS } from '../../../../../../views/UserSelectionView';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../../../globals/hubConstants.js';
import { featureIsEnabled } from '../../../../../../globals/envSettings';

class AddStudents extends React.Component {
  static canAssignLearningMaterialToAllUsers = users => {
    let canAssignLearningMaterial = true;
    Object.keys(users).forEach(key => {
      // Disable AssignLearningMaterial on Hub App for the moment
      if (users[key].roleName === USER_ROLES.MANAGED_USER || isHubMode()) {
        canAssignLearningMaterial = false;
      }
    });
    return canAssignLearningMaterial;
  };

  _getConfirmationHeading = () => {
    const {
      localizedContent: { addStudentsPanel: content },
      requestFailed,
      failedIds,
      students,
      classroom,
      isPlacementTest,
      placementTestSessionName
    } = this.props;
    const numberOfStudents = Object.keys(students).length;
    if (isPlacementTest && numberOfStudents % 2 !== 0) {
      return {
        title: content.add_students_error_text.replace('class', 'session'),
        type: iconHeadingTypes.ERROR,
        illustrationSrc: HubIllustrationConstants.ERROR,
        illustrationAltText: HubIllustrationAltText.ERROR
      };
    }
    if (requestFailed && !isPlacementTest) {
      return {
        title: content.add_students_error_text,
        type: iconHeadingTypes.ERROR,
        illustrationSrc: HubIllustrationConstants.ERROR,
        illustrationAltText: HubIllustrationAltText.ERROR
      };
    }
    if (failedIds.length && !isPlacementTest) {
      return {
        title: (content.add_students_partial_error_text || '').replace('{className}', classroom.name),
        type: iconHeadingTypes.WARNING,
        illustrationSrc: HubIllustrationConstants.WARNING,
        illustrationAltText: HubIllustrationAltText.WARNING
      };
    }
    return {
      title: (content.add_students_success_text || '')
        .replace('{className}', isPlacementTest ? placementTestSessionName : classroom.name)
        .replace('{numberOfStudents}', numberOfStudents),
      type: iconHeadingTypes.VALID,
      illustrationSrc: HubIllustrationConstants.SUCCESS,
      illustrationAltText: HubIllustrationAltText.SUCCESS
    };
  };

  reset = () => {
    const { resetForm } = this.props;

    setTimeout(resetForm, 500);
  };

  _handleOnComplete = () => {
    const { onComplete } = this.props;

    this.reset();
    if (onComplete) onComplete();
  };

  _handleClosePanel = () => {
    const { formState, closePanel } = this.props;

    if (formState === formStates.CONFIRMATION) this.reset();

    closePanel();
  };

  _handleOnSubmit = () => {
    const { onConfirmReview, onSubmit } = this.props;
    onConfirmReview();
    if (isHubMode() && onSubmit) {
      onSubmit();
    }
  };

  _getProductFinderContextName = () => {
    const {
      selectedStudentIds,
      people,
      localizedContent: { productFinder: productFinderContent }
    } = this.props;

    return selectedStudentIds.length === 1
      ? `${people[selectedStudentIds[0]].firstname || ''} ${people[selectedStudentIds[0]].lastname || ''}`
      : `${selectedStudentIds.length} ${productFinderContent.students_text}`;
  };

  _renderContent() {
    const {
      assignMaterials,
      orgId,
      classId,
      closePanel,
      formState,
      classroom,
      students,
      failedIds,
      classroomEditingState,
      totalStudentCount,
      seatLimit = 1,
      seatLimitEnable = false,
      hasClassChanged = false,
      onSeatLimitToggle,
      onSeatLimitChange,
      gotoStudentSelectionAction,
      removeStudentAction,
      submitAction,
      selectedStudentIds,
      localizedContent: { addStudentsPanel: content },
      isPlacementTest,
      setShowAddStudentsPanel,
      backAction,
      placementTestSessionName
    } = this.props;
    const initialLimitToggleValue = !!classroom.studentLimit || classroomEditingState?.enableStudentLimit || false;
    const initialLimitValue =
      parseInt(classroomEditingState?.studentLimit, 10) || parseInt(classroom?.studentLimit, 10) || 1;
    const limitExceeded = seatLimitEnable && seatLimit < totalStudentCount;

    switch (formState) {
      case formStates.SELECTING:
        return (
          <StudentSelectionPanel
            orgId={orgId}
            backAction={() => (backAction ? backAction() : closePanel())}
            submitAction={submitAction}
            context={CONTEXT_OPTIONS.ADD_STUDENTS}
            existingClassroom={classroom}
            classroomEditingState={classroomEditingState}
            initialRoles={[USER_ROLES.LEARNER, USER_ROLES.MANAGED_USER]}
            initialLimitToggleValue={initialLimitToggleValue}
            initialLimitValue={initialLimitValue}
            isPlacementTest={isPlacementTest}
            setShowAddStudentsPanel={setShowAddStudentsPanel}
            placementTestSessionName={placementTestSessionName}
          />
        );

      case formStates.REVIEW_SELECTIONS:
        return (
          <ClassUserAddReview
            localizedContent={content}
            classroomName={classroom.name}
            users={students}
            seatLimit={seatLimit}
            showSeatLimitWarning={limitExceeded}
            seatLimitEnable={seatLimitEnable}
            submitDisabled={(limitExceeded || !hasClassChanged) && !isPlacementTest}
            onSectionLinkClick={gotoStudentSelectionAction}
            onSeatLimitToggle={onSeatLimitToggle}
            onSeatLimitChange={onSeatLimitChange}
            onRemoveUserClick={removeStudentAction}
            onBackClick={gotoStudentSelectionAction}
            isPlacementTest={isPlacementTest}
            placementTestSessionName={placementTestSessionName}
            onSubmitClick={this._handleOnSubmit}
          />
        );

      case formStates.SUBMITTING:
        return (
          <div className="flex-column align-items-center">
            <PopoutPanelIconHeading
              title={content.loading_title}
              subtitle={content.loading_subtitle}
              type={iconHeadingTypes.LOADING}
            />
          </div>
        );

      case formStates.ASSIGNMATERIAL:
        return featureIsEnabled('product-finder-refactor') && isHubMode() ? (
          <ProductFinderPanel
            orgId={orgId}
            selectedUsers={{ studentIdList: [...selectedStudentIds] }}
            contextName={this._getProductFinderContextName()}
            onClose={this._handleClosePanel}
            onComplete={this._handleOnComplete}
            // needed for polling. will be removed when polling is removed
            context="CLASS_ADD_STUDENTS"
          />
        ) : (
          <AssignLearningMaterial
            orgId={orgId}
            context="CLASS_ADD_STUDENTS"
            closePopoutAction={this._handleClosePanel}
            onComplete={this._handleOnComplete}
          />
        );

      case formStates.CONFIRMATION:
        return (
          <ClassUserConfirmation
            {...this._getConfirmationHeading()}
            localizedContent={content}
            users={students}
            canAssignLearningMaterials={AddStudents.canAssignLearningMaterialToAllUsers(students)}
            failedIds={failedIds}
            onAddAnother={() => assignMaterials(classId)}
            onDoneClick={this._handleOnComplete}
            isPlacementTest={isPlacementTest}
          />
        );

      default:
        return (
          <div>
            <p>Error. Missing a form for `{formState}`.</p>
          </div>
        );
    }
  }

  render() {
    const { isOpen, isPlacementTest } = this.props;

    return (
      <SidePanel
        dataTestId="ADD_STUDENTS_PANEL"
        isOpen={isOpen}
        onClose={this._handleClosePanel}
        className={isPlacementTest ? 'noShadow' : ''}
      >
        {this._renderContent()}
      </SidePanel>
    );
  }
}

AddStudents.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  canAssignLearningMaterials: PropTypes.bool,
  formState: PropTypes.string.isRequired,
  classroom: PropTypes.object.isRequired,
  classroomEditingState: PropTypes.object.isRequired,
  classId: PropTypes.string,
  students: PropTypes.object.isRequired,
  failedIds: PropTypes.array.isRequired,
  seatLimit: PropTypes.number,
  totalStudentCount: PropTypes.number.isRequired,
  requestFailed: PropTypes.bool,
  seatLimitEnable: PropTypes.bool,
  hasClassChanged: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onSeatLimitToggle: PropTypes.func.isRequired,
  onSeatLimitChange: PropTypes.func.isRequired,
  onConfirmReview: PropTypes.func.isRequired,
  gotoStudentSelectionAction: PropTypes.func.isRequired,
  removeStudentAction: PropTypes.func.isRequired,
  assignMaterials: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  onSubmit: PropTypes.func,
  selectedStudentIds: PropTypes.array,
  people: PropTypes.object,
  isPlacementTest: PropTypes.bool,
  setShowAddStudentsPanel: PropTypes.func,
  backAction: PropTypes.func,
  placementTestSessionName: PropTypes.string
};

export default compose(
  withLocalizedContent('addStudentsPanel', 'productFinder'),
  connect(
    (state, ownProps) => {
      const { addStudentsToClassroom, classrooms, people } = state;
      const selectedStudentIds = addStudentsToClassroom?.selectedStudentIds;
      const students = pick(people?.data, selectedStudentIds);
      const classroom = classrooms ? classrooms.data[ownProps?.classId] : {};
      const existingStudentIds = classroom ? classroom.studentIdList : [];
      const newStudentIds = Object.keys(omit(students, existingStudentIds));
      const totalStudentCount = parseInt(existingStudentIds?.length, 2) + parseInt(newStudentIds?.length, 2);
      const seatLimitEnable = addStudentsToClassroom?.enableStudentLimit;

      return {
        ...pick(addStudentsToClassroom, ['formState', 'requestFailed', 'failedIds']),
        classroomEditingState: addStudentsToClassroom,
        classroom: classroom || {},
        totalStudentCount,
        selectedStudentIds,
        people: people?.data,
        hasClassChanged:
          selectedStudentIds > 0 || seatLimitEnable
            ? classroom && classroom.seatLimit !== addStudentsToClassroom?.studentLimit
            : classroom && classroom.seatLimit !== null,
        seatLimit: addStudentsToClassroom?.studentLimit,
        seatLimitEnable,
        students
      };
    },
    {
      submitAction: setSelectedStudents,
      resetForm: clearForm,
      assignMaterials: assignMaterial,
      onSeatLimitToggle: setEnableStudentLimit,
      onSeatLimitChange: setStudentLimit,
      onConfirmReview: submitForm,
      gotoStudentSelectionAction: gotoStudentSelection,
      removeStudentAction: removeStudent
    }
  )
)(AddStudents);
