import React from 'react';
import PropTypes from 'prop-types';
import styles from './SolidColorBackground.scss';
import useExtractBorderColor from '../../utils/hooks/useExtractBorderColor';

const borderWidth = 10;
const numberOfPoints = 100;
function SolidColorBackground({ imageSrc, imageAlt = '' }) {
  const primaryColor = useExtractBorderColor(imageSrc, borderWidth, numberOfPoints);

  return (
    <div className={styles.solidColorBackgroundContainer} aria-hidden>
      <div className={styles.solidColorBackground} style={{ backgroundColor: primaryColor }} />
      <img src={imageSrc} className={styles.normalImage} alt={imageAlt} />
    </div>
  );
}

SolidColorBackground.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string
};

export default SolidColorBackground;
