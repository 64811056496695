import * as t from '../../actionTypes.js';

const initialState = {
  loading: false,
  products: [],
  error: null
};

export default function getLinkedProducts(state = initialState, action) {
  switch (action.type) {
    case t.NGI_FETCH_LINKED_PRODUCTS_REQUEST:
      return { ...state, loading: true, error: null };
    case t.NGI_FETCH_LINKED_PRODUCTS_SUCCESS:
      return { ...state, loading: false, error: null, products: action.payload };
    case t.NGI_FETCH_LINKED_PRODUCTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
