import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import UserArchivePendingPane from '../components/UserArchive/UserArchivePendingPane';
import UserArchiveReviewPane from '../components/UserArchive/UserArchiveReviewPane';
import PopoutPanelIconHeading, { types } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../components/ScrollContainer/ScrollContainer';
import SidePanel from '../components/SidePanel/SidePanel';
import withLocalizedContent from '../language/withLocalizedContent';
import getCurrentUser from '../redux/selectors/getCurrentUser';
import {
  formStates,
  setNotifyWithEmail,
  showClosePanelModal,
  doArchiveUsers,
  closeForm
} from '../redux/reducers/archiveUsers.reducer';

class UserArchivePanel extends Component {
  _handleClose = () => {
    const { formState, onComplete, onClosePanel } = this.props;

    (formState === formStates.SHOW_TASK_PENDING ? onComplete : onClosePanel)();
  };

  render() {
    const {
      id,
      ariaLabel,
      userType,
      open = true,
      formState,
      hideNotifyOption,
      setNotifyWithEmailAction,
      submitArchiveUsersAction,
      isPrimarySchool,
      notifyWithEmail,
      localizedContent: { userArchivePanel: content },
      ...props
    } = this.props;

    return (
      <SidePanel id={id} ariaLabel={ariaLabel} isOpen={open} onClose={this._handleClose}>
        {{
          [formStates.CHECKING_SELECTED_USERS]: (
            <ScrollContainer>
              <PopoutPanelIconHeading
                type={types.LOADING}
                title={content.loading_page_title}
                subtitle={content.loading_page_subtitle}
              />
            </ScrollContainer>
          ),
          [formStates.REVIEW_SELECTIONS]: (
            <UserArchiveReviewPane
              userType={userType}
              {...pick(props, [
                'userIds',
                'orgId',
                'preFlightCheckResults',
                'foundLastOupAdmin',
                'foundLastOrgAdmin',
                'foundLastTeacher'
              ])}
              notifyWithEmailValue={notifyWithEmail}
              onNotifyWithEmailChange={setNotifyWithEmailAction}
              onSubmit={submitArchiveUsersAction}
              hideNotifyOption={hideNotifyOption}
              isPrimarySchool={isPrimarySchool}
            />
          ),
          [formStates.SHOW_TASK_PENDING]: (
            <UserArchivePendingPane
              userType={userType}
              closeFormAction={this._handleClose}
              {...pick(props, ['requestFailed', 'showRemovalPending', 'email'])}
            />
          )
        }[formState] || null}
      </SidePanel>
    );
  }
}

UserArchivePanel.propTypes = {
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  orgId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  open: PropTypes.bool,
  formState: PropTypes.string.isRequired,
  hideNotifyOption: PropTypes.bool,
  notifyWithEmail: PropTypes.bool.isRequired,
  setNotifyWithEmailAction: PropTypes.func.isRequired,
  submitArchiveUsersAction: PropTypes.func.isRequired,
  resetFormAction: PropTypes.func.isRequired,
  isPrimarySchool: PropTypes.bool,
  onClosePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('userArchivePanel'),
  connect(
    state => ({
      ...pick(state.archiveUsers, [
        'userIds',
        'formState',
        'notifyWithEmail',
        'preFlightCheckResults',
        'foundLastOupAdmin',
        'foundLastOrgAdmin',
        'foundLastTeacher',
        'showRemovalPending',
        'requestFailed'
      ]),
      email: getCurrentUser(state).email,
      actioningUserRole: state.identity.role
    }),
    {
      setNotifyWithEmailAction: setNotifyWithEmail,
      showClosePanelModalAction: showClosePanelModal,
      submitArchiveUsersAction: doArchiveUsers,
      resetFormAction: closeForm
    }
  )
)(UserArchivePanel);
