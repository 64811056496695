import * as t from '../actionTypes.js';

export const fetchProductsRequest = () => ({
  type: t.OPEN_PRODUCTS_FETCH_REQUEST
});

export const fetchProductsSuccess = products => ({
  type: t.OPEN_PRODUCTS_FETCH_SUCCESS,
  payload: products
});

export const fetchProductsFailure = error => ({
  type: t.OPEN_PRODUCTS_FETCH_FAILURE,
  payload: error
});

export const filterProducts = searchTerm => ({
  type: t.OPEN_PRODUCTS_FILTER,
  payload: searchTerm
});

export const saveSearchedValue = searchTerm => ({
  type: t.OPEN_PRODUCTS_SEARCHED_VALUE,
  payload: searchTerm
});
